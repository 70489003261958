(function() {
	'use strict';

	angular
		.module('myApp')
		.factory('agreementFactory', agreement);


	function agreement($http, $q, $log, orderEstimatePutFactory, SharedDataSvc, API_DOMAIN, PROXY_ACTION_URL, authentication, ServiceAgent) {
		var agreement = this,
				quotes = [],
				quoteAsPerSelection = '',
				formatMoney = function (money) {
					money = money + "";
					if (money.split(".")[1] && money.split(".")[1].length == 1) {
						money = money + "0";
					}
					return money;
				},
				checkInitialPayment = function (obj) {
					if (obj.initialPaymentWTax) {
        		obj.initialPaymentWTax = formatMoney(obj.initialPaymentWTax);
        	}
        	return obj;
				};

		return {
			quotes: quotes,
			setQuotes: setQuotes,
			setQuotesAsPerSelection:setQuotesAsPerSelection,
			createAgreement: createAgreement,
			retrieveAgreementsDashboard: retrieveAgreementsDashboard,
			agreementInventory: agreementInventory,
			agreementVerification: agreementVerification,
			updateAgreement: updateAgreement,
			getDisputeReasons: getDisputeReasons,
			searchAgreement: searchAgreement,
			validatePassPhrase: validatePassPhrase
		};

		function createAgreement(agreementData, keys) {
			console.log("[agreementFactory.createAgreement] agreementData: ", agreementData);
			var deferred = $q.defer();
			var aData = angular.toJson(agreementData);
			var headers = {
				'endTarget': 'rentacentervrto/rest/agreement/create',
				'sessionToken' : authentication.getCurrentSessionId()
			};
			var url = PROXY_ACTION_URL;
			ServiceAgent.httpCall(
				url, 
				'POST', 
				headers, 
				agreementData, 
				true
		    ).then(function (response) {
		    	console.log("[agreementFactory.createAgreement] success response: " + typeof response);
		    	deferred.resolve(response);
		    },
		    function (response) {
		    	console.log("[agreementFactory.createAgreement] fail ", response);
		      	deferred.reject(response);
		    });

		    return deferred.promise;
		}

		function retrieveAgreementsDashboard() {
			var deferred = $q.defer();
			var doEncrypt = true;
			var url = PROXY_ACTION_URL;
			var headers = {
				'endTarget': 'rentacentervrto/rest/agreementsPayable/retrieveAgreementsDashboard',
				'sessionToken' : authentication.getCurrentSessionId(),
				'Access-Control-Allow-Origin':'endTarget,sessionToken,Encrypt-Mode,RAC-KIOSK-LOCATION-ID,Public-Key,RAC-CORRELATION-ID'//AC 27054 code Changes
			};
			ServiceAgent.httpCall(
				url, 
				'POST',
				headers,
				null, 
				doEncrypt
		    ).then(function (response) {
		    	console.log("[agreementFactory.retrieveAgreementsDashboard] success response: " + typeof response);
		    	deferred.resolve(response);
		    },
		    function (response) {
		    	console.log("[agreementFactory.retrieveAgreementsDashboard] fail ", response);
		      	deferred.reject(response);
		    });

		    return deferred.promise;
		}


		function agreementInventory(agreementId) {
			console.log("[agreementFactory.agreementInventory] agreementId: ", agreementId);
			var deferred = $q.defer();
			var doEncrypt = true;
			if (!agreementId) {
				console.warn("[agreementFactory.agreementInventory] No agreement ID provided");
				deferred.reject(null);
				return deferred.promise;
			}
			var url = PROXY_ACTION_URL + '?agreementId=' + agreementId;
			var headers = {
				'endTarget': 'rentacentervrto/rest/agreementsPayable/agreementInventoryItems',
				'sessionToken' : authentication.getCurrentSessionId(),
				'Access-Control-Allow-Origin':'endTarget,sessionToken,Encrypt-Mode,RAC-KIOSK-LOCATION-ID,Public-Key,RAC-CORRELATION-ID'//AC 27054 code Changes
			};
			ServiceAgent.httpCall(
				url, 
				'POST',
				headers,
				null, 
				doEncrypt
		    ).then(function (response) {
		    	console.log("[agreementFactory.agreementInventory] success response: " + typeof response);
		    	deferred.resolve(response);
		    },
		    function (response) {
		    	console.log("[agreementFactory.agreementInventory] fail ", response);
		      	deferred.reject(response);
		    });

		    return deferred.promise;
		}

		function agreementVerification(engagementId) {
			console.log("[agreementFactory.agreementVerification] agreementId: ", engagementId);
			var deferred = $q.defer();
			var doEncrypt = true;
			if (!engagementId) {
				console.warn("[agreementFactory.agreementVerification] No engagement ID provided");
				deferred.reject(null);
				return deferred.promise;
			}
			var url = PROXY_ACTION_URL + '?engagementId=' + engagementId;
			var headers = {
				'endTarget': 'rentacentervrto/rest/customer/notifyPendingAgreement',
				'sessionToken' : authentication.getCurrentSessionId(),
				'Access-Control-Allow-Origin':'endTarget,sessionToken,Encrypt-Mode,RAC-KIOSK-LOCATION-ID,Public-Key,RAC-CORRELATION-ID'//AC 27054 code Changes
			};
			ServiceAgent.httpCall(
					url,
					'POST',
					headers,
					null,
					doEncrypt
			).then(function (response) {
						console.log("[agreementFactory.agreementVerification] success response: " + typeof response);
						deferred.resolve(response);
					},
					function (response) {
						console.log("[agreementFactory.agreementVerification] fail ", response);
						deferred.reject(response);
					});

			return deferred.promise;
		}


		function updateAgreement(updateData) {
			var deferred = $q.defer();
			var doEncrypt = true;
			var url = PROXY_ACTION_URL;
			var headers = {
				'endTarget': 'rentacentervrto/rest/agreementsPayable/updateAgreement',
				'sessionToken' : authentication.getCurrentSessionId(),
				'Access-Control-Allow-Origin':'endTarget,sessionToken,Encrypt-Mode,RAC-KIOSK-LOCATION-ID,Public-Key,RAC-CORRELATION-ID' //Story 26289 code changes
			};
			ServiceAgent.httpCall(
				url, 
				'POST',
				headers,
				updateData, 
				doEncrypt
		    ).then(function (response) {
		    	console.log("[agreementFactory.updateAgreement] success response: " + typeof response);
		    	deferred.resolve(response);
		    },
		    function (response) {
		    	console.log("[agreementFactory.updateAgreement] fail ", response);
		      	deferred.reject(response);
		    });

		    return deferred.promise;
		}

		function getDisputeReasons() {
			var deferred = $q.defer();
			var headers = {
				'endTarget': 'site/json/agreementDisputeReason.json',
				'sessionToken' : authentication.getCurrentSessionId(),
				'Public-Key': SharedDataSvc.globalVars.storeSetup.serverPublicRSAKey
			};
			ServiceAgent.httpCall(
			PROXY_ACTION_URL,
			'POST',
			headers,
			null,
			false
			).then(function (response) {
				console.log("[agreementFactory.disputeReasons] success response: ", response);
			  	deferred.resolve(response);
			},
			function (response) {
			  	console.log("[agreementFactory.disputeReasons] fail ", response);
			    deferred.reject(response);
			});

			return deferred.promise;
		}

		function searchAgreement(searchParameter, searchParameterValue) {
			var deferred = $q.defer();
			var doEncrypt = true;
			var url = PROXY_ACTION_URL + '?' + searchParameter + '=' + searchParameterValue;
			var headers = {
				'endTarget': 'rentacentervrto/rest/agreementsPayable/searchAgreement',
				'sessionToken' : authentication.getCurrentSessionId(),
				'Access-Control-Allow-Origin':'endTarget,sessionToken,Encrypt-Mode,RAC-KIOSK-LOCATION-ID,Public-Key,RAC-CORRELATION-ID'//AC 27054 code Changes
			};
			ServiceAgent.httpCall(
				url, 
				'POST',
				headers,
				null, 
				doEncrypt
		    ).then(function (response) {
		    	console.log("[agreementFactory.searchAgreement] success response: " + typeof response);
		    	deferred.resolve(response);
		    },
		    function (response) {
		    	console.log("[agreementFactory.searchAgreement] fail ", response);
		      	deferred.reject(response);
		    });

		    return deferred.promise;
		}

		function setQuotesAsPerSelection(orderData){
			var deferred = $q.defer();
			orderEstimatePutFactory.putData(orderData, false) 
	          .then(function(response){
	          	console.log("[orderEstimatePutFactory.putData] success", response);
	          	quoteAsPerSelection = checkInitialPayment(response);
	          	deferred.resolve(response);
	        },function(response){
	        	console.log("[orderEstimatePutFactory.putData] fail ", response);
	        	deferred.reject(response);
	        });
	        return deferred.promise;
		}
		

		function setQuotes(orderData) {
	      console.log('SET ORDER DATA', orderData);
	      return $q.all([
	        orderEstimatePutFactory.putData(orderData[0], false) //26894 - Modify attribute isDraft
	          .then(function(response){
	          	quotes[0] = checkInitialPayment(response);
	          }),
	        orderEstimatePutFactory.putData(orderData[1], false) //26894 - Modify attribute isDraft
	          .then(function(response){
	            quotes[1] = checkInitialPayment(response);
	          }),
	        orderEstimatePutFactory.putData(orderData[2], false) //26894 - Modify attribute isDraft
	          .then(function(response){
	            quotes[2] = checkInitialPayment(response);
	          })
	      ])
	      .then(function(data){
	        console.log("[agreementFactory.setQuotes] All quotes loaded");
	      });
		}

		function validatePassPhrase(value, path) {
			var deferred = $q.defer();
			var doEncrypt = true;
			var url = PROXY_ACTION_URL;
			if(path === 'epay') {
				url = PROXY_ACTION_URL + '?validateRequest=promoPayment';
			}else if( path === 'mastercode'){
				url = PROXY_ACTION_URL + '?validateRequest=phoneConfirmationOverride';
			}
			var headers = {
				'endTarget': 'rentacentervrto/rest/store/validateLogin',
				'sessionToken' : authentication.getCurrentSessionId(),
				'PASSPHRASE' : value,
				'Access-Control-Allow-Headers':'OPERATION-MODE,POS-TYPE,PASSPHRASE'
			};
			ServiceAgent.httpCall(
				url, 
				'POST',
				headers,
				null, 
				false
		    ).then(function (response) {
		    	console.log("[validate.passphrase] success response: " + response);
		    	deferred.resolve(response);
		    },
		    function (response) {
		    	console.log("[validate.passphrase] fail ", response);
		      	deferred.reject(response);
		    });

		    return deferred.promise;
		}

	}
})();
