///////////////////////////////////////////
//////////////////////////////////////////
///   APPLICATION CONTROLLER   //////////
////////////////////////////////////////
/////////////////////////////////////// 
(function () {

	'use strict';

	angular
		.module('app.application')
		.controller('Application', Application);

	/* @ngInject */
	function Application(
		$scope,
		$rootScope,
		$http,
		$cookies,
		$log,
		$timeout,
		$modal,
		$location,
		$anchorScroll, // Added for Scroll up VAN 10736
		$routeParams,
		$q,
		$idle,
		$translate,
		getSpecificApplication,
		SharedDataSvc,
		modalService,
		authentication,
		applicationService,
		agreementFactory,
		base64,
		getStoreInfoFactory,
		stateRestrictFactory,
		datesFactory, //AC-28614 Changes removed MANNED_STORE_PHONE and UNMANNED_STORE_PHONE
		updateEngagementItemFactory,
		customerDataFactory,
		productCategoriesFactory
	) {
		var createOrder,
			existingOrderTotal = 0;

		// CUSTOMER INFORMATION
		$scope.customerInfo = {
			address: {},
			driversLicense: {
				id: '',
				issuer: '',
				idType: '1'
			},
			phone1: {},
			phone2: {},
			incomeSources: {
				nextPayDate: {}
			},
			personalReferences: []
		};
		$scope.customerPrivate = {
			dob: {},
			ssn: {
				ssnType: 'SSN'
			},
			verifyssn: {
				ssnType: 'SSN'
			},
			verificationType: 'PText',
			mastercode:{
				employeeName: '',
				employeeId: '',
				comments: '',
				masterTextCode: '',
				mastertextCodeAttemptsNumber: 0,
				associatePass: '',
				noAssociatePass: true,
				invalidAssociatePass: false, 
				masterCodeCheckbox: false
			}
		};

		// CUSTOMER INFORMATION
		$scope.coCustomerInfo = {
			address: {},
			driversLicense: {
				id: '',
				issuer: '',
				idType: '1'
			},
			phone1: {},
			phone2: {},
			incomeSources: {
				nextPayDate: {}
			},
			personalReferences: [],
			dob: {}
		};

		$scope.coCustomerPrivate = {
			dob: {},
			ssn: {
				ssnType: 'SSN'
			}
		};

		$scope.custAdditionalInfo = {
			previousAddress : {},
			moveInDate : {},
			auto: {},
			rent: {},
			own: {},
			leaseEndDate: {},
			ownRentInfo: {},
			noCocustomer: false,
			noCocustomerIncome: false
		}

		$scope.custAdditionalInfo.auto.noCar = false;
		$scope.previousAddress = {
			moveInDate: {}

		}
		
		$scope.verifySsnMismatch = false;
		$scope.customerPrivate.TextCode = '';
		$scope.customerPrivate.textCodeAttemptsNumber = 0;
		$scope.customerPrivate.textCodeGenerateAttemptsNumber = 0;
		$scope.bankingInfoFormForACH = {
			routingNumber: '',
			accountNumber: ''
		}
		$scope.newReferences = {
			ref1: {},
			ref2: {},
			ref3: {},
			ref4: {}
		};
		$scope.appForm = [];
		$scope.customerInfoModal = {};
		$scope.customerId;
		$scope.engagementId = $routeParams.engagementId;
		$scope.customerFirstName;
		$scope.approvalExpiryDays;
		$scope.approvalAmount;
		$scope.servicePhoneNumber = '';
		$scope.showVerification = false;
		$scope.showVerficationWarning = false;
		$scope.showAddSecondaryPhoneDetails = false;
		$scope.customerName;

		// BOOLS
		$scope.customerExistsInHT = false; // true
		$scope.ssnSubmitted = false;
		$rootScope.hideMenuButtons = false;
		$scope.formSubmitted = false;
		$scope.draftSaved = false;
		$scope.amountBelowOrderTotal = false;
		$scope.secondaryNoTypeRequired = false;
		$scope.newCustomer = false;
		$scope.custAdditionalInfo.noCocustomer = false;
		$scope.custAdditionalInfo.noCocustomerIncome = false;
		//VAN-510 - Electronic Consent moved to app flow - Starts
		$scope.newCustomerElectronicConsent = newCustomerElectronicConsent;
		$scope.startApplication = startApplication;
		$scope.updateCustomerConstent = updateCustomerConstent;
		$scope.isConsentAgreed = false;
		$rootScope.isEnglishStore;
		$scope.createOrderApp=createOrderApp;
		//VAN-510 - Electronic Consent moved to app flow - Ends
		
		// VALIDATION REGEXES
		$scope.zipCodeVal = /^[0-9]{5}$/;
		$scope.onlyNumbers = /^\d+$/;
		$scope.ssnValidation1 = /^(?!666|000|9\d{2})\d{3}$/;
		$scope.ssnValidation2 = /^(?!00)\d{2}$/;
		$scope.ssnValidation3 = /^(?!0{4})\d{4}$/;
		$scope.ccssnValidation1 = /^(?!666|000|9\d{2})\d{3}|\*\*\*$/;
		$scope.ccssnValidation2 = /^(?!00)\d{2}|\*\*$/;
		$scope.itinValidation1 = /^(9\d{2})$/;
		$scope.itinValidation2 = /^((7\d{1})|(8[^9]{1})|(9[^3]{1}))$/;
		$scope.itinValidation3 = /^(\d{4})$/;
		$scope.phoneValidation = /^\(\d{3}\)[-.\s]?\d{3}[-.]?\d{4}$/;
		//$scope.POBoxValidation = /^(?!.*[Pp]\.? ?[Oo]\.? ?[BbOoXx])[-a-zA-Z\d .,\/]*$/;
		//$scope.POBoxValidation = /^(?!.*[Pp]\.? ?[Oo]\.? ?[Bb]\.? ?[Oo]\.? ?[Xx]\.?)[-a-zA-Z\d .,#\/]*$/; //AC-10778
		$scope.POBoxValidation = /^(?!.*[Pp]\.? ?[Oo]\.? ?[Bb]\.? ?[Oo]\.? ?[Xx]\.?)[-a-zA-Z\d\\\/ .,#\/]*$/;//VAN-913 Added to allow escape sequence character
		$scope.nameValidation = /^[a-zA-Z\s\-\']+$/; // Added for name validation 2542 & 2543. It will allows only letters
		$scope.lastNameValidation = /^\d*[a-zA-Z0-9\d\s\-\']*$/; // Added for name validation 2542 & 2543. It will allow letters, dashes, and apostrophes.
		$scope.phoneNumberValidation = /^(\({0,1}[2-9]{1}[0-9]{2}\){1} {1}[2-9]{1}[0-9]{2}-{0,1}[0-9]{0,4})/;  //AC-10616 - Code change to handle all zero phone number
		$scope.incomeValidation = /(?=.)^\$?(([1-9][0-9]{0,2}(,[0-9]{3})*)|[0-9]+)?(\.[0-9]{1,2})?$/;
		$scope.routingValidation = /^([0][1-9]|[1][0-2]|[2][1-9]|[3][0-2]|[6][1-9]|[7][0-2])([0-9]{7})$/;
		$scope.emailValidation = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		$scope.salaryValidation = /[1-9]\d*/; // AC13186 code changes 
		$scope.idValidation=/^(\(?\+?[0-9]*\)?)?[0-9_\- \(\)]*$/;//VAN-3402-Re-engagement with Code - AWP

		// BANKING INFO MODAL
		$scope.challenge = {
			bankRoutingNo: '',
			bankAccountNo: '',
			approvedAmt: 0,
			originalApprovedAmt: 0,
			outstandingAmt: 0
		};

		// DATE OF BIRTH INFO
		$scope.dobvalid = {
			day: moment().date(),
			month: moment().month() + 1,
			year: moment().year() - 18
		};
		$scope.birthYears = [];
		$scope.leaseYears = [];
		$scope.moveInYears = [];

		// customer zip and store radius
		$scope.isZipInStoreRadius = isZipInStoreRadius;
		$scope.resetZipData = resetZipData;
		$scope.zipValidation = zipValidation;
		$scope.zipSubmitFalse = zipSubmitFalse;
		$scope.customerInRadius = true;
		$scope.isZipValid = true;
		$scope.allowTextVerificationFlag = false;
		$scope.storeInfo;
		$scope.storeLatLng;
		$scope.isSpanishStore;
		$scope.customerDistance;
		$scope.storeRadius;
		$scope.restrictions;
		$scope.stateRestrictions;
		$scope.engagementDetails;
		$scope.ssnNumber;
		$scope.customerEngagementInfo;//story 25996 code changes
		$scope.declineAgreement = declineAgreement;//story 25996 code changes
		$scope.cancelAgreementDecline = cancelAgreementDecline;//story 25996 code changes
		$scope.createNewEngagement = createNewEngagement;
		// Validate date of birth
		$scope.validateDOB = validateDOB;
		// Check if selected address state is unsupported
		$scope.checkState = checkState;
		$scope.navigateTo = navigateTo;
		$scope.saveDraft = saveDraft;
		$scope.saveAndProcess = saveAndProcess;
		$scope.cancelCustomerEntry = cancelCustomerEntry;
		$scope.validateReferenceName = validateReferenceName;
		$scope.validateReferencePhone = validateReferencePhone;
		$scope.validateSecondaryPhone = validateSecondaryPhone;
		$scope.doPhoneValidation = doPhoneValidation; //AC-10616 - Code change to handle all zero phone number
		$scope.handleSaveAndExit = handleSaveAndExit;

		$scope.clearSSN = clearSSN;
		$scope.clearITIN = clearITIN;
		$scope.clearSecondary = clearSecondary;
		$scope.showModal = showModal;

		$scope.handleCustomerOutOfRadius = handleCustomerOutOfRadius;
		$scope.navigateToApplication = navigateToApplication;
		$scope.submitTextCode = submitTextCode;
		$scope.masterCodeModal = masterCodeModal;
		$scope.resetPromoPayment = resetPromoPayment;
		$scope.verifyEmployeePassPhrase = verifyEmployeePassPhrase;
		$scope.verifyPassWordEmployee = verifyPassWordEmployee;
		$scope.cancelTextCode = cancelTextCode;
		$scope.generateTextCode = generateTextCode;
		$scope.processVerification = processVerification;
		$scope.addSecondaryPhoneDetails = addSecondaryPhoneDetails;
		//Story 30445 - AWP, Kiosk: Add email verification edit box - start
		$scope.compareEmailVerify = compareEmailVerify;
		$scope.verifyEmail = true;
		$scope.newform = {};
		//Story 30445 -AWP, Kiosk: Add email verification edit box - end
		$scope.saveSecondaryPhoneDetails = saveSecondaryPhoneDetails;
		//$scope.showSameDayAgreementModal = showSameDayAgreementModal;
		$scope.submitBankingInfoForACH = submitBankingInfoForACH;
		$scope.submitMasterTextCode = submitMasterTextCode;
		$scope.publishOverrideData = publishOverrideData;
		//Story 33452 changes starts
		$scope.hideEmailAndStartAppln = false;
		$rootScope.isSLICustomer = false;
		$scope.custCreateData = {};
		$scope.applicationCheck = applicationCheck;
		$scope.proceedSaveApplication = proceedSaveApplication;
		$scope.confirmAndEditEmail = confirmAndEditEmail;
		$scope.categoryApprovalList = [];

		$scope.inventoryInfo = [];
		$scope.inventoryInfo.productCategories = [];
		var excludedCategories = [];
		$scope.approvalByCatList = [];
		$scope.submittingApplication = false;
		//$scope.getApprovalbyCat =  getApprovalbyCat;
		$scope.customerInfo.approvalByCatList = [];
		$scope.newEngagementWithCat = newEngagementWithCat;
		$scope.updateApprovalByCatList = updateApprovalByCatList;
		$scope.updateAutoFields = updateAutoFields;
		$scope.disableCatContinue = true;
		$scope.isApprovalByCatSelected = false;
		$scope.categoryCollected = false;
		//Story 33452 changes ends

		//PR flow new additions
		$scope.setPreviousAdd = setPreviousAdd;
		$scope.showPreviousAdd = false;
		$scope.updateCoIncome = updateCoIncome;
		$scope.updateCocustomerIncome = updateCocustomerIncome;
		$scope.validateLeaseYear = validateLeaseYear;
		$scope.validateCCSSN = validateCCSSN;
		$scope.stateValidation = stateValidation;//VAN-3209-Use customers zip code on agreement creation 
		$scope.isStateValid = true;//VAN-3209-Use customers zip code on agreement creation 
		//VAN-3402-Re-engagement with Code - AWP - Start
		$scope.searchSelectionForm = {};
		$scope.searchOptions = [{"labelEn": "Search by Approval ID", "labelEs": "Buscar por ID de aprobación", "value": "Id"}, {"labelEn": "Search by Name", "labelEs": "Buscar por nombre", "value": "Name"}];
		$scope.validateSelectedSrchOpt = validateSelectedSrchOpt;
		$scope.findCustApplication = findCustApplication;
		$scope.searchSelection = searchSelection;
		$scope.validateApprovalId = validateApprovalId;
		$scope.resetValidation = resetValidation;
		$scope.searchByIdForm = {};
		$scope.searchByIdForm.approvalId = '';
		$scope.showSubmit = false;
		$scope.showApprovalError = false;
		$scope.enableSearch = true;
		$scope.showSrchClose = false;
		$rootScope.isAppSrch=false;
		$scope.openConsentModal = openConsentModal;
		$scope.custApp = '';
		//VAN-3402-Re-engagement with Code - AWP - End
		//VAN-2844-Capture clients IPAddress for Virtual approvals and AWP-Starts
		$scope.ipaddress=null;
		
		getIpaddress();
		function getIpaddress()
        {                    
               $http.get("https://api.ipify.org/").then( function(ipdata){
                   $scope.ipaddress=ipdata.data;
               });
        }
		//VAN-2844-Capture clients IPAddress for Virtual approvals and AWP-Ends

		$scope.incomeEnums = [
			{
				type: 'EMPLOYED',
				label: {
	                en : en.application_select_empOpt1,
	                es : es.application_select_empOpt1
	            }
			},
			{
				type: 'SELFEMPLOYED',
				label: {
	                en : en.application_select_empOpt2,
	                es : es.application_select_empOpt2
	            }
			},
			{
				type: 'SOCIAL',
				label: {
	                en : en.application_select_empOpt3,
	                es : es.application_select_empOpt3
	            }
			},
			{
				type: 'RETIRED',
				label: {
	                en : en.application_select_empOpt4,
	                es : es.application_select_empOpt4
	            }
			},
			{
				type: 'PENSION',
				label: {
	                en : en.application_select_empOpt5,
	                es : es.application_select_empOpt5
	            }
			},
			{
				type: 'DISABILITY',
				label: {
	                en : en.application_select_empOpt6,
	                es : es.application_select_empOpt6
	            }
			},
		];

		$scope.payFrequencyEnums = [
			{
				type: 'MONTHLY',
				label: {
					en : en.application_select_payFreq1,
					es : es.application_select_payFreq1
				}
			},
			{
				type: 'TWICE-MONTHLY',
				label: {
					en : en.application_select_payFreq2,
					es : es.application_select_payFreq2
				}
			},
			{
				type: 'BI-WEEKLY',
				label: {
					en : en.application_select_payFreq3,
					es : es.application_select_payFreq3
				}
			},
			{
				type: 'WEEKLY',
				label: {
					en : en.application_select_payFreq4,
					es : es.application_select_payFreq4
				}
			}
		];


		// set default income source
		$scope.customerInfo.incomeSources.income = $scope.incomeEnums[0].type;
		var refType = ["PARENT", "SBLING", "GRANDP", "COUSIN", "ANTUNC", "NIENEP", "FRIEND", "COWORK", "OTHER"];   //AC22760 code changes                      		
		//save Data before navigating to AP dashboard or training library
		$scope.$on("saveAppData", function (event, path) {
			console.log("Broadcast Event: saveAppData");
			//check if secondary number is filled then secondary phone type is valid
			if ($scope.customerInfoForm.PhoneType2.$valid) {
				saveCustomerInfo().then(function () {
					if (path.indexOf("training") > -1) {
						$rootScope.loginTrainingLibrary();
					} else {
						$location.path("/dashboardAgreement");
					}
					return true;
				});
			}
			else {
				$scope.draftSaved = true;
				$scope.formSubmitted = false;
			}
		});

		/*
		Check if person data has been passed from the dashboard
		We'll use this to find their application data
		*/
		if ($routeParams.data) {
			var decoded, personData;
			decoded = decodeURIComponent($routeParams.data);
			decoded = window.atob(decoded);
			$scope.personData = JSON.parse(decoded);
			retrieveApplication($scope.personData);
			

		} else {
			// NEW CUSTOMER
			//Story 33452 changes starts
			if(SharedDataSvc.globalVars.isApplicationFromOrder){
				$scope.hideEmailAndStartAppln = true;
				SharedDataSvc.globalVars.isApplicationFromOrder = false;
				$rootScope.isSLICustomer = true;
				$rootScope.orderLink = true;//Defect 28266 - VAN: References getting wiped off in HT
			}
			$rootScope.isEnglishStore = $rootScope.selectedLanguage == "en" ? true : false;//VAN-510 - Electronic Consent moved to app flow
			//Story 33452 changes ends
			if($rootScope.orderLink && $rootScope.isSLICustomer){//VAN-3402-Re-engagement with Code - AWP 
				$scope.showSrchClose = false;
				var newCustomerModal = modalService.open({
					title: 'New Customer',
					templateUrl: 'partials/newCustomerModal.tpl.html',
					size: 'sm',
					backdrop: 'static',
					windowClass: 'newCustomerModal',
					scope: $scope
				});
				
				newCustomerModal.result.then(function (result) {
					/*VAN 719 - Make consent form required to read */
					newCustomerModalConsentCheck(result);
				});//VAN-510 - Electronic Consent moved to app flow - Ends
			}else{//VAN-3402-Re-engagement with Code - AWP - Start
				$scope.showSrchClose = true;
				searchSelection();
			}
			//VAN-3402-Re-engagement with Code - AWP - End
			$scope.orderSub = [];
		}
		/*VAN 719 - Make consent form required to read - Starts*/
		function newCustomerModalConsentCheck(result){
			var personData = {
				firstName: result.firstName,
				lastName: result.lastName,
				phone: result.primaryPhone,
				istcpaflag: false
			};
			$scope.personData = personData;
			console.log('[Application.newCustomerModal.result] New Person Check Result: ', result);
			$scope.customerInfoForm.firstName.$pristine = false;
			$scope.customerInfoForm.lastName.$pristine = false;
			$rootScope.createOrderConsent = result.createOrder;
			//VAN-510 - Electronic Consent moved to app flow - Starts
			modalService.processing('Processing');
			applicationService.getCustomerConsent(personData)
			.then(function (response) {
				$log.log('[Application.handleCustomerOutOfRadius] Successfully updated engagement');
				$scope.isConsentResponse = response.isCosentAgreed ;//my fix
				modalService.dismiss();
				if(response.status_code == "200" && (response.isCosentAgreed == "false" || response.isCosentAgreed == "null")){
					/*var newCustomerModal = modalService.open({
							title: 'New Customer',
							templateUrl: 'partials/newCustomerModal.tpl.html',
							size: 'sm',
							backdrop: 'static',
							windowClass: 'newCustomerModal',
							scope: $scope
						});
						$scope.isConsentAgreed = true;
						$scope.customerInfoModal.firstName = result.firstName;
						$scope.customerInfoModal.middleInitial = result.middleInitial;
						$scope.customerInfoModal.lastName = result.lastName;
						$scope.customerInfoModal.primaryPhone = result.primaryPhone;
						$scope.customerInfoModal.primaryPhoneType = result.primaryPhoneType;
						$scope.customerInfoModal.emailAddress = result.emailAddress;
						$scope.customerInfoModal.emailVerify = result.emailVerify;*/
					/*VAN 719 - Make consent form required to read - Starts*/
					$scope.myModalInstance = $modal.open({
						templateUrl: 'partials/modal_eAgreementNotice_StartApp.html',
						show: true,
						backdrop: 'static',
						scope: $scope
					});
					/*VAN 719 - Make consent form required to read - Ends*/
				}
				else if($rootScope.createOrderConsent){
					createOrderApp(personData);
				}
				else{
					startApplication(personData);
				}
			}, function (response) {
				$log.log('[Application.handleCustomerOutOfRadius] Updating engagement failed');
				modalService.dismiss();
			});
		}
		
		$scope.closeConsent =function(){
			var newCustomerModal = modalService.open({
				title: 'New Customer',
				templateUrl: 'partials/newCustomerModal.tpl.html',
				size: 'sm',
				backdrop: 'static',
				windowClass: 'newCustomerModal',
				scope: $scope
			});
			newCustomerModal.result.then(function (result) {
				newCustomerModalConsentCheck(result);
			});
		};
		/*VAN 719 - Make consent form required to read - Ends*/
		
		///////////////////////////////////////////////
		//  METHODS  //////////////////////////////////
		///////////////////////////////////////////////

		function init() {

			$rootScope.hideDashboardButton = true;
			$rootScope.hideDashboardAgreementButton = false;
			$rootScope.hideTrainingLibButton = false;
			$rootScope.isHybrid = $cookies.getObject('isHybrid');
			$scope.isSpanishStore = $cookies.getObject('isSpanishStore');
			
			/*VAN 491 - PR-AWP: ‘Please enter a valid zip code’ error message should be displayed when try to validate the Zip code with 00000*/
			$scope.prZipValidation = true;
			
			addYears();

			getStoreInfoFactory.getData()
				.then(function (response) {
					$scope.storeInfo = response;
					
					/*VAN 491 - PR-AWP: ‘Please enter a valid zip code’ error message should be displayed when try to validate the Zip code with 00000 - Starts*/
					$scope.isPRState = false;
					if(response.state=="PR"){
						$scope.isPRState = true;
					}
					/*VAN 491 - PR-AWP: ‘Please enter a valid zip code’ error message should be displayed when try to validate the Zip code with 00000 - Ends*/
					
					if (response.excludedCategories) {
						excludedCategories = response.excludedCategories;
					}
					$scope.customerPrivate.masterTextCode = response.masterCodeForOverride;
					SharedDataSvc.globalVars.storeSetup = response;
					if (response.vanStoreId) {
						$rootScope.storeNo = response.vanStoreId;
						$rootScope.state = response.state;
						$rootScope.isReferenceRequired = response.isReferenceRequired;
					}
					$rootScope.invoiceMethod = response.invoiceMethod;
					$scope.storeLatLng = { 'lat': $scope.storeInfo.latitude, 'lng': $scope.storeInfo.longitude };
					$scope.storeRadius = response.allowedRentalRadiusMiles;
					$scope.agreementAmount = response.minAgreementAmount;
					$scope.allowTextVerificationFlag = response.allowTextVerification;

					if ($scope.allowTextVerificationFlag) {
						$scope.customerInfoModal = {
							primaryPhoneType: "CELL"
						};
					}

					$scope.itinAllowed = response.itinAllowed;
					stateRestrictFactory.getStateRestrictions()
						.then(function (response) {
							$scope.stateRestrictions = response.stateRestrictions[0];
						});

					stateRestrictFactory.getAllStoreRestrictions($scope.storeInfo.state)
						.then(function (response) {
							$scope.restrictions = response;
						});
				});

				productCategoriesFactory.getData().then(function(response) {
				$scope.inventoryInfo = response;
				//console.log(builder.inventoryInfo);
				var productCategories = _.clone($scope.inventoryInfo.productCategories);
				// APPEND Children to their parent objects; basically re-map the JSON data
				_(productCategories).forEachRight(function(obj, index, collection) {
					if (obj.parent) {
						// find the parent object
						var parent = _.find(collection, {
							labelEn: obj.parent
						});
						// check if it already has a collection started
						if (!parent.items) {
							parent.items = [obj];
						} else {
							parent.items.push(obj);
						}
						// remove object from main collection, for organization
						_.pull(collection, obj);
					}
					if (excludedCategories.indexOf(obj.labelEn) > -1) {
						_.pull(collection, obj);
					}
				});

				/* Filtering the ProductCategories items as well i.e. on more level deep to filter out excluded categories present there */
				var productCat = productCategories;
				console.log("Prod Category before filtering", productCat);
				for(var z=0; z < productCategories.length; z++){

					if( _.isArray(productCategories[z].items) ){
						
						var filteredItem = [];
						for(var x = 0; x < productCategories[z].items.length; x++){
							
							if( excludedCategories.indexOf(productCategories[z].items[x].labelEn) == -1 ){
								filteredItem.push(productCategories[z].items[x]);
							}	
						}
						productCat[z].items = filteredItem;	
					}	
				}


				console.log("[Product Category after excluded categories filtering]", productCat);
				$scope.productList = productCat;
				console.log('product list --------'+ productCat);
				for(var z=0; z < productCat.length ; z++){
					var tempObj = {
						"isSelected": false,
						"category": productCat[z].labelEn
					}

					$scope.approvalByCatList.push(tempObj);	
				}

				console.log($scope.approvalByCatList);
				
			}); 

		}


		init();

		function addYears() {
			for (var i = 18; i < 100; i++) {
				$scope.birthYears.push(moment().year() - i);
			};

			for (var i = 0; i < 100; i++) {
				$scope.leaseYears.push(moment().year() + i);
			};

			for (var i = 0; i < 100; i++) {
				$scope.moveInYears.push(moment().year() - i);
			};

		}

		function getFullName(first, last) {
			if (first && last) {
				return first.toLowerCase() + ' ' + last.toLowerCase();
			}
			return undefined;
		}

		function getReferenceErrors(index) {
			return ['ref' + index + 'FirstName', 'ref' + index + 'LastName'];
		}

		function referenceNameSameAsCustomer(refName) {
			var custName = $scope.customerInfo.firstName + ' ' + $scope.customerInfo.lastName;
			if (refName && custName) {
				return refName.toLowerCase() === custName.toLowerCase() ? true : false;
			}
		}

		function referencePhoneSameAsCustomer(refPhone) {
			var custPhone = $scope.customerInfo.phone1.number;
			if (refPhone) {
				return refPhone === custPhone ? true : false;
			}
		}

		function clearSSN() {
			$scope.customerPrivate.ssn.ssn1 = '';
			$scope.customerPrivate.ssn.ssn2 = '';
			$scope.customerPrivate.ssn.ssn3 = '';
			//clear verify ssn
			$scope.customerPrivate.verifyssn.ssn1 = '';
			$scope.customerPrivate.verifyssn.ssn2 = '';
			$scope.customerPrivate.verifyssn.ssn3 = '';
		}

		function clearITIN() {
			if(!$scope.customerPrivate.itin){
				return false;
			}
			$scope.customerPrivate.itin.itin1 = '';
			$scope.customerPrivate.itin.itin2 = '';
			$scope.customerPrivate.itin.itin3 = '';
			//clear verify ssn
			$scope.customerPrivate.verifyitin.itin1 = '';
			$scope.customerPrivate.verifyitin.itin2 = '';
			$scope.customerPrivate.verifyitin.itin3 = '';
		}

		function clearSecondary(primaryPhoneType, secondaryPhoneNumber) {
			console.log("New Customer" + $scope.newCustomer + "phoneType" + primaryPhoneType);
			if ($scope.allowTextVerificationFlag) {
				if ($scope.newCustomer) {
					if (primaryPhoneType === 'HOME') {
						$scope.customerInfo.phone2 = {
							number: secondaryPhoneNumber,
							phoneType: "CELL"
						};
					} else {
						$scope.customerInfo.phone2 = {
							number: secondaryPhoneNumber,
							phoneType: ""
						}
					}
				} else if (primaryPhoneType === 'CELL' && SharedDataSvc.customerInfo.phones[1].areaCode === "000" && SharedDataSvc.customerInfo.phones[1].phoneType === "") {
					$scope.customerInfo.phone2 = {
						phoneType: ""
					}
				} else if (primaryPhoneType === 'HOME' && SharedDataSvc.customerInfo.phones[1].areaCode === "000" && SharedDataSvc.customerInfo.phones[1].phoneType === "") {
					$scope.customerInfo.phone2 = {
						phoneType: "CELL"
					};
				}
			}
		}

		function validateReferenceName(index) {
			$scope.ref = [{
				first: $scope.customerInfoForm.newRefs.ref1FirstName.$modelValue,
				last: $scope.customerInfoForm.newRefs.ref1LastName.$modelValue,
				full: getFullName($scope.customerInfoForm.newRefs.ref1FirstName.$modelValue, $scope.customerInfoForm.newRefs.ref1LastName.$modelValue)
			},
			{
				first: $scope.customerInfoForm.newRefs.ref2FirstName.$modelValue,
				last: $scope.customerInfoForm.newRefs.ref2LastName.$modelValue,
				full: getFullName($scope.customerInfoForm.newRefs.ref2FirstName.$modelValue, $scope.customerInfoForm.newRefs.ref2LastName.$modelValue)
			},
			{
				first: $scope.customerInfoForm.newRefs.ref3FirstName.$modelValue,
				last: $scope.customerInfoForm.newRefs.ref3LastName.$modelValue,
				full: getFullName($scope.customerInfoForm.newRefs.ref3FirstName.$modelValue, $scope.customerInfoForm.newRefs.ref3LastName.$modelValue)
			}];

			if($scope.customerInfoForm.newRefs.ref4FirstName && $scope.customerInfoForm.newRefs.ref4LastName){
				$scope.ref.push({
					first: $scope.customerInfoForm.newRefs.ref4FirstName.$modelValue,
					last: $scope.customerInfoForm.newRefs.ref4LastName.$modelValue,
					full: getFullName($scope.customerInfoForm.newRefs.ref4FirstName.$modelValue, $scope.customerInfoForm.newRefs.ref3LastName.$modelValue)
				});
			};

			var currentRef = _.at($scope.ref, index);
			var fullName = getFullName($scope.customerInfoForm.newRefs['ref' + index + 'FirstName'].$modelValue, $scope.customerInfoForm.newRefs['ref' + index + 'LastName'].$modelValue);
			var matches = _.pluck(_.where($scope.ref, { 'full': fullName }), 'full');
			var errorProp = 'ref' + index + 'NameSubmitted';
			var propNames = getReferenceErrors(index);
			var first = propNames[0];
			var last = propNames[1];

			matches = _.filter(matches, function (item) {
				if (!_.isUndefined(item)) return item;
			});

			var propFirstName = 'ref' + index + 'FirstName';
			var propLastName = 'ref' + index + 'LastName';

			if (matches.length > 1 || referenceNameSameAsCustomer(fullName)) {
				$scope.newRefs[first].$setValidity(first, false);
				$scope.newRefs[last].$setValidity(last, false);
				$scope[errorProp] = true;
			} else {
				$scope.newRefs[first].$setValidity(first, true);
				$scope.newRefs[last].$setValidity(last, true);
				$scope[errorProp] = false;
			}
		}

		// secondary phone number duplicate check
		function validateSecondaryPhone(index) {
			$scope.phoneNumbers = [
				{ 'number': $scope.customerInfoForm.phoneSecondary.$modelValue },
				{ 'number': $scope.customerInfoForm.newRefs.ref1Number.$modelValue },
				{ 'number': $scope.customerInfoForm.newRefs.ref2Number.$modelValue },
				{ 'number': $scope.customerInfoForm.newRefs.ref3Number.$modelValue },
				{ 'number': ($scope.customerInfoForm.newRefs.ref4Number ? $scope.customerInfoForm.newRefs.ref4Number.$modelValue : '')}
			];


			var currentNum = $scope.phoneNumbers[index - 1].number;
			var matches = _.pluck(_.where($scope.phoneNumbers, { 'number': currentNum }), 'number');
			var errorProp = 'phone' + index + 'NumberSubmitted';

			if (currentNum !== undefined) {
				if (currentNum.length === 10) {
					$scope.secondaryNoTypeRequired = true;
				} else {
					$scope.secondaryNoTypeRequired = false;
				}
			}

			matches = _.filter(matches, function (item) {
				if (!_.isUndefined(item)) return item;
			});

			console.log('PROP SUB', errorProp);

			if (matches.length > 1 || referencePhoneSameAsCustomer(currentNum)) {
				/*VAN-367 AWP - User is allowed to submit an application with same phone number in priamry and secondary phone number field - Starts*/
				$scope.customerInfoForm.$invalid = true;
				$scope[errorProp] = true;
				$scope.phone1NumberSubmitted = true ;
				/*VAN-367 AWP - User is allowed to submit an application with same phone number in priamry and secondary phone number field - Ends*/
			} else {
				$scope[errorProp] = false;
			}
		}

		function validateReferencePhone(index, event) {
			doPhoneValidation(event); // AC 10616 Changes
			$scope.refPhones = [
				{ 'number': $scope.customerInfoForm.newRefs.ref1Number.$modelValue },
				{ 'number': $scope.customerInfoForm.newRefs.ref2Number.$modelValue },
				{ 'number': $scope.customerInfoForm.newRefs.ref3Number.$modelValue }
			];
			if($scope.customerInfoForm.newRefs.ref4Number){
				$scope.refPhones.push({ 'number': $scope.customerInfoForm.newRefs.ref4Number.$modelValue });
			}

			var currentRef = $scope.refPhones[index - 1].number;
			var propName = 'ref' + index + 'Number';
			var matches = _.pluck(_.where($scope.refPhones, { 'number': currentRef }), 'number');
			var errorProp = 'ref' + index + 'NumberSubmitted';

			matches = _.filter(matches, function (item) {
				if (!_.isUndefined(item)) return item;
			});

			console.log('PROP NAME', propName);
			console.log('PROP SUB', errorProp);
			if (matches.length > 1 || referencePhoneSameAsCustomer(currentRef)) {
				$scope.customerInfoForm.newRefs[propName].$setValidity(propName, false);
				$scope[errorProp] = true;
			} else {
				$scope.customerInfoForm.newRefs[propName].$setValidity(propName, true);
				$scope[errorProp] = false;
			}
		}

		//Start AC-10616 - Code change to handle all zero phone number
		function doPhoneValidation(event) {
			var phoneNumber = event.target.value;
			var propName = event.target.name;
			var errorProp = event.target.name + 'Invalid';
			if (event.target.value == "(___) ___-____") {
				return false;
			}
			if ($scope.phoneNumberValidation.test(phoneNumber)) {
				$scope[errorProp] = false;
				if ($scope.customerInfoForm.newRefs && $scope.customerInfoForm.newRefs[propName]) {
					$scope.customerInfoForm.newRefs[propName].$setValidity(propName, true);
				}
				/*if($scope.newform.newCustomerCheckForm.primaryPhone){
					$scope.newform.newCustomerCheckForm.primaryPhone.$setValidity(propName, true);
				}*/
				if(propName == 'primaryPhone'){
					$scope.customerInfoForm.primaryPhone.$setValidity(propName,true);
				}

				if(propName == 'ccprimaryPhone'){
					$scope.customerInfoForm.coCustomerForm.ccprimaryPhone.$setValidity(propName, true);
				}

				if(propName == 'mortgageCompanyNum'){
					$scope.customerInfoForm.mortgageCompanyNum.$setValidity(propName, true);
				}

				if(propName == 'phoneSecondary'){
					$scope.customerInfoForm.phoneSecondary.$setValidity(propName, true);
				}
			} else {
				$scope[errorProp] = true;
				if ($scope.customerInfoForm.newRefs && $scope.customerInfoForm.newRefs[propName]) {
					$scope.customerInfoForm.newRefs[propName].$setValidity(propName, false);
					$scope.customerInfoForm.$invalid = true;
				}else{
					$scope.customerInfoForm.$invalid = true;
				}

				if(propName == 'primaryPhone'){
					$scope.customerInfoForm.primaryPhone.$setValidity(propName,false);
				}

				if(propName == 'ccprimaryPhone'){
					$scope.customerInfoForm.coCustomerForm.ccprimaryPhone.$setValidity(propName, false);
				}

				if(propName == 'mortgageCompanyNum'){
					$scope.customerInfoForm.mortgageCompanyNum.$setValidity(propName, false);
				}
				if(propName == 'phoneSecondary'){
					$scope.customerInfoForm.phoneSecondary.$setValidity(propName, false);
				}

				/*if($scope.newform.newCustomerCheckForm.primaryPhone){
					$scope.newform.newCustomerCheckForm.primaryPhone.$setValidity(propName, false);
					$scope.customerInfoForm.$invalid = true;
				}*/
			}
		}
		//End AC-10616 - Code change to handle all zero phone number

		function validateDOB(dateOfBirth) {
			console.log('[Validating Bday]');
			if (dateOfBirth.year && dateOfBirth.month && dateOfBirth.day) {
				var isValid;
				var isDateValidOnLeapYear; // VAN-2842 Changes
				if ((dateOfBirth.year >= $scope.dobvalid.year && dateOfBirth.month > $scope.dobvalid.month) || (dateOfBirth.year >= $scope.dobvalid.year && dateOfBirth.month == $scope.dobvalid.month && dateOfBirth.day > $scope.dobvalid.day)) {
					isValid = false;
				} else {
					isValid = true;
				}
				// VAN-2842 Changes Starts
				if (moment(dateOfBirth.month + "/" + dateOfBirth.day + "/" + dateOfBirth.year + "", 'MM/DD/YYYY', true).isValid()) {
					isDateValidOnLeapYear = true;
				} else {
					isDateValidOnLeapYear = false;
				}
				$scope.customerInfoForm.$setValidity('isDateValidOnLeapYear', isDateValidOnLeapYear);
				$scope.customerInfoForm.dateOfBirth.year.$setValidity('isDateValidOnLeapYear', isDateValidOnLeapYear);
				$scope.customerInfoForm.dateOfBirth.month.$setValidity('isDateValidOnLeapYear', isDateValidOnLeapYear);
				$scope.customerInfoForm.dateOfBirth.day.$setValidity('isDateValidOnLeapYear', isDateValidOnLeapYear);
				// VAN-2842 Changes Ends
				$scope.customerInfoForm.$setValidity('validAge', isValid);
				$scope.customerInfoForm.dateOfBirth.year.$setValidity('validAge', isValid);
				$scope.customerInfoForm.dateOfBirth.month.$setValidity('validAge', isValid);
				$scope.customerInfoForm.dateOfBirth.day.$setValidity('validAge', isValid);
			}
		}

		function zipValidation(zip) {
			//var pattern = /^([0-9]{5})(?:[-\s]*([0-9]{4}))?$/;
			/*VAN 491 - PR-AWP: ‘Please enter a valid zip code’ error message should be displayed when try to validate the Zip code with 00000 - Starts*/
			if($rootScope.state=="PR"){
				getStoreInfoFactory.getPRZipcodeData().then(function(response) {
					console.log("Response for zip code::"+response);
					if(response.zipCodes.length!=undefined){
						for(var z=0;z<response.zipCodes.length;z++){
							if("00"+(response.zipCodes[z])== zip){
								$scope.prZipValidation = true;
								$scope.customerInfoForm.zip.$setValidity('zip', true);
								break;
							}else{
								$scope.prZipValidation=false;
								$scope.customerInfoForm.zip.$setValidity('zip', false);
							}
						}
					}
				});
			}else{
				if ($scope.zipCodeVal.test(zip)) {//Defect 26474 code change
					/*VAN-850 -  Getting Error 700 while we provide Invalid zip code “00000”- Starts*/
					if($scope.customerInfo.address.zipCode != "00000"){
						isZipInStoreRadius(zip);
					}else{
						console.log('not valid zip - validate');
						$scope.isZipValid = false;
					}
					/*VAN-850 -  Getting Error 700 while we provide Invalid zip code “00000”- Ends*/ 
				} else {
					console.log('not valid zip - validate');
					$scope.isZipValid = false;
					console.log('FORM', $scope.customerInfoForm);
					$scope.customerInfoForm.zip.$setValidity('zip', false);
				}
			}
			/*VAN 491 - PR-AWP: ‘Please enter a valid zip code’ error message should be displayed when try to validate the Zip code with 00000 - Ends*/
		}

		function resetZipData() {
			$scope.customerInRadius = true;
			$scope.isZipValid = true;
			$scope.customerInfoForm.zip.$setValidity('zip', true);
		}

		function zipSubmitFalse(event) {
			if (event.keyCode === 13) {
				event.preventDefault();
				return false;
			}
		}

		function isZipInStoreRadius(zip, getFn) {
			getFn = getFn || function () { };
			getStoreInfoFactory.getLatLngFromZip(zip)
				.then(function (results) {
					console.log('ZIP TO LAT LNG', results);
			// Google API Implementation - Starts
			if (results.status == "OK") 
			{	
				var loc = results[0].geometry.location,
					customer = { 'lat': loc.lat(), 'lng': loc.lng() },
					// Google API Implementation - Ends
							distance = getStoreInfoFactory.getCustomerDistance($scope.storeLatLng, customer),
							isInAllowedRadius = getStoreInfoFactory.isCustomerInRadius($scope.storeInfo.allowedRentalRadiusMiles, distance),
							isCustomerInUS = getStoreInfoFactory.isCustomerInUS(results[0].address_components);

						console.log('Is in radius', isInAllowedRadius);
						console.log('Is in US', isCustomerInUS);
						console.log('distance', distance);
						$scope.customerDistance = Math.round(distance);

						if (isCustomerInUS && isInAllowedRadius) {
							$scope.customerInRadius = true;
							$scope.customerInfoForm.zip.$setValidity('zip', true);
							getFn("true");
						} else {
							$scope.customerInRadius = false;
							$scope.customerInfoForm.zip.$setValidity('zip', false);
							getFn("false");
						}

					} else {
						console.log('not valid zip');
						$scope.isZipValid = false;
						$scope.customerInfoForm.zip.$setValidity('zip', false);
						getFn("invalidZip");
					}
				}).
			catch(function (response) {
					// sending error to Raygun
					SharedDataSvc.reportRaygun(response);
				});
		}

		function checkState(state) {
			// console.log('test', state);
			var unsupportedStates = $scope.stateRestrictions.unsupportedStates.split(",") || '';
			if (unsupportedStates.indexOf(state) > -1) {
				$scope.invalidState = modalService.open({
					title: 'State Not Supported',
					templateUrl: 'partials/alert_stateUnsupported.tpl.html',
					backdrop: 'static',
					size: 'sm',
					scope: $scope
				});
			}
			if($rootScope.xpartnerLogin)//VAN-3228-AWP - same state rule apply to only XPARTNER and not XTEAM
				{
			stateValidation(state);//VAN-3209-Use customers zip code on agreement creation 
				}//VAN-3228-AWP - same state rule apply to only XPARTNER and not XTEAM
		}

		function navigateTo(target) {
			modalService.dismiss();
			$location.path(target);
		}

		function validateCCSSN() {
			if((!$scope.custAdditionalInfo.noCocustomer) && ($scope.coCustomerPrivate.ssn.ssn1 && $scope.coCustomerPrivate.ssn.ssn1 == $scope.customerPrivate.ssn.ssn1) && 
                ($scope.coCustomerPrivate.ssn.ssn2 && $scope.coCustomerPrivate.ssn.ssn2 == $scope.customerPrivate.ssn.ssn2) && 
                ($scope.coCustomerPrivate.ssn.ssn3 && $scope.coCustomerPrivate.ssn.ssn3 == $scope.customerPrivate.ssn.ssn3)){
				$scope.customerInfoForm.$invalid = true;
				$scope.saveSameSSNError = true;
			} else {
				$scope.saveSameSSNError = false;
			}
		}

		function saveDraft() {
			
			if((!$scope.custAdditionalInfo.noCocustomer) && 
				($scope.coCustomerPrivate.ssn.ssn1 && $scope.customerPrivate.ssn.ssn1 && 
					$scope.coCustomerPrivate.ssn.ssn1 == $scope.customerPrivate.ssn.ssn1) && 
                ($scope.coCustomerPrivate.ssn.ssn2 && $scope.customerPrivate.ssn.ssn2 && 
                	$scope.coCustomerPrivate.ssn.ssn2 == $scope.customerPrivate.ssn.ssn2) && 
                ($scope.coCustomerPrivate.ssn.ssn3 && $scope.customerPrivate.ssn.ssn3 && 
                	$scope.coCustomerPrivate.ssn.ssn3 == $scope.customerPrivate.ssn.ssn3)){
				$scope.customerInfoForm.$invalid = true;
				$scope.saveSameSSNError = true;
				$scope.scrollToTop("body");
				return false;
			} else {
				$scope.saveSameSSNError = false;
			}
			//check if secondary number is filled then secondary phone type is valid
			if (($scope.customerInfoForm.PhoneType2.$valid) && (!$scope.primaryPhoneInvalid)) {
				saveCustomerInfo()
					.then(function () {
						$location.path('dashboard');
					});
			}
			else {
				$scope.draftSaved = true;
				$scope.formSubmitted = false;
			}
		}

		function saveAndProcess() {
			$scope.formSubmitted = true;
			$scope.draftSaved = false;		
			// Defect 28142
			if ($scope.customerPrivate.ssn.ssnType == 'SSN' && 
				($scope.customerPrivate.ssn.ssn1 !== $scope.customerPrivate.verifyssn.ssn1
				|| $scope.customerPrivate
				.ssn.ssn2 !== $scope.customerPrivate.verifyssn.ssn2
				|| $scope.customerPrivate.ssn.ssn3 !== $scope.customerPrivate.verifyssn.ssn3)) {
				$scope.scrollToTop("body");
				return false;
			} else if($scope.customerPrivate.ssn.ssnType == 'ITIN' && 
				($scope.customerPrivate.itin.itin1 !== $scope.customerPrivate.verifyitin.itin1
				|| $scope.customerPrivate.itin.itin2 !== $scope.customerPrivate.verifyitin.itin2
				|| $scope.customerPrivate.itin.itin3 !== $scope.customerPrivate.verifyitin.itin3)){
				$scope.scrollToTop("body");
				return false;
			} 
			
			if((!$scope.custAdditionalInfo.noCocustomer) && ($scope.coCustomerPrivate.ssn.ssn1 && ($scope.coCustomerPrivate.ssn.ssn1 == $scope.customerPrivate.ssn.ssn1)) && 
                ($scope.coCustomerPrivate.ssn.ssn2 && ($scope.coCustomerPrivate.ssn.ssn2 == $scope.customerPrivate.ssn.ssn2)) && 
                ($scope.coCustomerPrivate.ssn.ssn3 && ($scope.coCustomerPrivate.ssn.ssn3 == $scope.customerPrivate.ssn.ssn3))){
				$scope.customerInfoForm.$invalid = true;
				$scope.scrollToTop("body");
				return false;
			}	
			proceedSaveApplication();	
		}

		function confirmAndEditEmail(){
		  var deferred = $q.defer();
		  $scope.emailAddress = $scope.customerInfo.email;

		    var confirmAndEditEmailModal = modalService.open({
		      title: "Customer Verification",
		      templateUrl: 'partials/modal_confirmAndEditEmail.html',
		      scope: $scope,
		      backdrop: 'static',
		      controller: 'confirmAndEditEmailCtrl'
		    });
		    confirmAndEditEmailModal.result.then(function(email) {
		      $scope.customerInfo.email = email;
		      $scope.customerInfo.emailVerify = email;
		      saveCustomerInfo().then(function (result) {
		        if(result == 'success') {
		          modalService.dismiss();
		          deferred.resolve(result);
		        }         
		      }, function (error) {
		        SharedDataSvc.reportRaygun(error);
		        deferred.reject(error);
		      });       
		    });
		  		      
		  return deferred.promise;
		};  

		function proceedSaveApplication(){
			if(!$scope.isSpanishStore) {
				var zipCode = $scope.customerInfo.address.zipCode ? $scope.customerInfo.address.zipCode.substring(0,5) : "";//Defect 26474 code changes
				isZipInStoreRadius(zipCode, function (status) {
					console.log(status);
					if (status === "true") {
						$scope.customerInRadius = true;
						$scope.isZipValid = true;
						saveAndProcessAppData();
					} else {
						if (status === "false") {
							$scope.customerInRadius = false;
							$scope.isZipValid = true;
							$scope.customerInfoForm.zip.$setValidity('zip', false);
						} else {
							$scope.customerInRadius = true;
							$scope.isZipValid = false;
							$scope.customerInfoForm.zip.$setValidity('zip', false);
						}
					}
				});
			} else {
				saveAndProcessAppData();
			}
		}

		function saveAndProcessAppData() {
				/*VAN-367 AWP - User is allowed to submit an application with same phone number in priamry and secondary phone number field - Starts*/
				if($scope.customerInfoForm.primaryPhone.$modelValue == $scope.customerInfoForm.phoneSecondary.$modelValue){
					$scope.phone1NumberSubmitted = true;
				}
				if ($scope.customerInfoForm.$valid && !$scope.phone1NumberSubmitted && $scope.isStateValid) {//VAN-3209-Use customers zip code on agreement creation
				/*VAN-367 AWP - User is allowed to submit an application with same phone number in priamry and secondary phone number field - Ends*/
				if($scope.submittingApplication) {
					/* VAN-340 changes */
					//$scope.confirmAndEditEmail().then(function(response) { 
						modalService.dismiss();
						$scope.submittingApplication = false;
					  	if ($scope.storeInfo.allowTextVerification) {
								$scope.showVerification = true;
								//$scope.customerPrivate.textCodeGenerateAttemptsNumber = 0;
								//saveCustomerInfo().then(confirmEmail);
								return false;
							} else {
								saveCustomerInfo().then(processCustomerApp);
							}				  
					/*}, function(error){
					    SharedDataSvc.reportRaygun(error);
					    return false;
					});*/
					/* VAN-340 changes */
				} /*else {
					saveCustomerInfo().then(processCustomerApp);	
				}*/
			} else {
				console.log('Application Invalid, Check It');
				var error = $scope.customerInfoForm.$error;
				var invalidFields = [];
				angular.forEach(error.required, function (field) {
					if (field.$invalid) {
						var fieldName = field.$name;
						invalidFields.push(fieldName);
					}
				});
				if(invalidFields[0] == 'newRefs' && !$scope.storeInfo.isReferenceRequired){ //Defect	28721	:AWP - Not able to submit application in IE due to reference ng-required check
					$scope.customerInfoForm.$invalid = false;
					$scope.customerInfoForm.$valid = true;
					if($scope.submittingApplication) {
						$scope.confirmAndEditEmail().then(function(response) {
							modalService.dismiss();
							$scope.submittingApplication = false;
							if ($scope.storeInfo.allowTextVerification) {
									$scope.showVerification = true;
									//$scope.customerPrivate.textCodeGenerateAttemptsNumber = 0;
									//saveCustomerInfo().then(confirmEmail);
									return false;
								} else {
									saveCustomerInfo().then(processCustomerApp);
								}				  
						}, function(error){
							SharedDataSvc.reportRaygun(error);
							return false;
						});
					} else {
						return true;
					}							
				}		
				$scope.scrollToTop("body"); // Added for scroll up VAN-10736
				console.log($scope.customerInfoForm);
				console.log("Invalid Fields: ", invalidFields);
			}
		}

		function masterCodeModal(){
			console.log('SharedDataSvc.cutomerInfoForMasterCode : ' + JSON.stringify(SharedDataSvc.cutomerInfoForMasterCode));
			if($scope.storeInfo.isManned && !$scope.storeInfo.hybridEnabled){
				showModal('confirmPasswordAnowOverride');
			}else{
				var verifyAssociatePassModal = modalService.open({
					title: 'Verify Associate Pass Modal',
					templateUrl: 'partials/modal_verifyAssociatePass.html',
					scope: $scope,
					backdrop: 'static'
				});
			}
			
		}

		function showModal(name, windowClass, size) {
	      windowClass = windowClass || '';
	      size =  size || '';
	      $scope.modal = modalService.open({
	        templateUrl: '../../../partials/modal_' + name + '.html',
	        backdrop: 'static',
	        windowClass: windowClass,
	        size: size,
	        scope: $scope
	      });
	    }

	    function verifyPassWordEmployee(pass){
	        $scope.noEmpPass = false;
	        $scope.errEmpPass = false;
	      	if (pass) {
	        	var auth = $rootScope.storeNo;
	        	if (auth === pass) {
	            	console.log('associate password passes');
	           	 $scope.modal.dismiss();
	           	  var masterCodeModal = modalService.open({
						title: 'Master Code Modal',
						templateUrl: 'partials/modal_confirmMasterCode.tpl.html',
						scope: $scope,
						backdrop: 'static'
					});
	       	 } else {
	         	 $scope.errEmpPass = true;
	         	 console.log('associate password failure');
	          }
	      	} else {
	       	 $scope.noEmpPass = true;
	      	}
      	}

		function verifyEmployeePassPhrase(val){
			if(val) {
	          showModal('Processing', 'processing-modal');
	          agreementFactory.validatePassPhrase(val, 'mastercode').then(function(response) {
	              console.log("Passphrase validated success", response);
	              $scope.associatePassFail = false;
	              SharedDataSvc.masterCodePassphraseUsed = val;
	              $scope.empPassPhrase = "";
	              var masterCodeModal = modalService.open({
						title: 'Master Code Modal',
						templateUrl: 'partials/modal_confirmMasterCode.tpl.html',
						scope: $scope,
						backdrop: 'static'
					});
	            }, function (response) {
	              console.log("Passphrase validated error", response);
	              $scope.empPassPhrase = "";
	              SharedDataSvc.validateMasterCodePassPhraseAttempts = SharedDataSvc.validateMasterCodePassPhraseAttempts + 1;
	              console.log('Validate Passphrase Attempts', SharedDataSvc.validateMasterCodePassPhraseAttempts);
	              if(SharedDataSvc.validateMasterCodePassPhraseAttempts === 3) {
	                SharedDataSvc.validateMasterCodePassPhraseAttempts = 0;
	                $scope.modal.dismiss();
	                $scope.invalidPassPhrase = false;
	                $scope.associatePassFail = false;
	                $scope.empPassPhrase = "";
	              } else {
	                $scope.errorMessage = response.data.errorData.status_message;
	                $scope.invalidPassPhrase = true;
	                $scope.associatePassFail = true;
	                showModal('verifyAssociatePass');
	              }                    
	          });
	        }else {
	          $scope.associatePassFail = true;
	          $scope.invalidPassPhrase = false;
	        }
		}

		function resetPromoPayment() {
	        $scope.invalidPassPhrase = false;
	        $scope.associatePassFail = false;
	        $scope.empPassPhrase = "";
	     }

		function processVerification() {
			$scope.customerPrivate.mastercode.employeeName = '';
			$scope.customerPrivate.mastercode.employeeId = '';
			$scope.customerPrivate.mastercode.comments = '';
			$scope.customerPrivate.mastercode.masterTextCode = '';
			$scope.customerPrivate.mastercode.mastertextCodeAttemptsNumber = 0;
			$scope.customerPrivate.mastercode.associatePass = '';
			$scope.customerPrivate.mastercode.noAssociatePass = true;
			$scope.customerPrivate.mastercode.invalidAssociatePass = false;


			saveAndProcess();
			if ($scope.customerInfoForm.$valid && !$scope.phone1NumberSubmitted) {
				saveCustomerInfo().then(function () {
					generateTextCode();
				});
			}
		}

		function addSecondaryPhoneDetails() {
			$scope.showAddSecondaryPhoneDetails = true;
			$scope.formSubmitted = false;
		}

		function saveSecondaryPhoneDetails() {
			saveAndProcess();
			if ($scope.customerInfoForm.$valid && !$scope.phone1NumberSubmitted) {
				$scope.showAddSecondaryPhoneDetails = false;
			}
		}

		// Start VAN - 10736 Added for scroll up
		$scope.scrollToTop = function (toHere) {
			$('html, body').animate({
				scrollTop: $(toHere).position().top
			}, 'fast');
		}
		// End

		function generateDays(newVal, oldVal) {
			// April, June, September, November
			var daysThirty = ['04', '06', '09', '11'];
			// February
			var daysTwentyNine = ['02'];
			var dayNum = 0;
			$scope.days = [];
			if (newVal == undefined) {
				return;
			} else if (daysThirty.indexOf(newVal) > -1) {
				dayNum = 30;
			} else if (daysTwentyNine.indexOf(newVal) > -1) {
				dayNum = 29;
			} else {
				dayNum = 31;
			}
			var day = 1;
			// Add days to array, with 0 based numbers before 10
			while (day < dayNum + 1) {
				if (day < 10) {
					day = '0' + day;
				} else {
					day += '';
				}
				$scope.days.push(day);
				day++;
			}
		}

		function generateDaysdob(newVal, oldVal) {
			// April, June, September, November
			var daysThirty = ['04', '06', '09', '11'];
			// February
			var daysTwentyNine = ['02'];
			var dayNum = 0;
			$scope.daysdob = [];
			if (newVal == undefined) {
				return;
			} else if (daysThirty.indexOf(newVal) > -1) {
				dayNum = 30;
			} else if (daysTwentyNine.indexOf(newVal) > -1) {
				dayNum = 29;
			} else {
				dayNum = 31;
			}
			var day = 1;
			// Add days to array, with 0 based numbers before 10
			while (day < dayNum + 1) {
				if (day < 10) {
					day = '0' + day;
				} else {
					day += '';
				}
				$scope.daysdob.push(day);
				day++;
			}
		}

		function generateDaysNextPay(newVal, oldVal) {
			// April, June, September, November
			var daysThirty = ['04', '06', '09', '11'];
			// February
			var daysTwentyNine = ['02'];
			var dayNum = 0;
			$scope.daysNextPay = [];
			if (newVal == undefined) {
				return;
			} else if (daysThirty.indexOf(newVal) > -1) {
				dayNum = 30;
			} else if (daysTwentyNine.indexOf(newVal) > -1) {
				dayNum = 29;
			} else {
				dayNum = 31;
			}
			var day = 1;
			// Add days to array, with 0 based numbers before 10
			while (day < dayNum + 1) {
				if (day < 10) {
					day = '0' + day;
				} else {
					day += '';
				}
				$scope.daysNextPay.push(day);
				day++;
			}
		}

		function validateLeaseYear(){
			var months;
			var currentTime = new Date();
			var currentMonth = currentTime.getMonth() + 1;
			var curryear = currentTime.getFullYear();
			if(Number($scope.custAdditionalInfo.leaseEndDate.month) < currentMonth && $scope.leaseYears[0] == curryear){
				$scope.leaseYears.shift();
			}else if(Number($scope.custAdditionalInfo.leaseEndDate.month) >= currentMonth && $scope.leaseYears[0] != curryear){
				$scope.leaseYears.unshift(curryear);
			}
		}


		function setPreviousAdd(){
			var months;
			var currentTime = new Date();
			var currentMonth = currentTime.getMonth() + 1;
			var curryear = currentTime.getFullYear();
			if(Number($scope.custAdditionalInfo.moveInDate.month) > currentMonth && $scope.moveInYears[0] == curryear){
				$scope.moveInYears.shift();
			}else if(Number($scope.custAdditionalInfo.moveInDate.month) <= currentMonth && $scope.moveInYears[0] != curryear){
				$scope.moveInYears.unshift(curryear);
			}

			if($scope.custAdditionalInfo.moveInDate.month && $scope.custAdditionalInfo.moveInDate.year){
				
	        	var enteredDay = currentTime.getDate();
	        	var enteredMonth= $scope.custAdditionalInfo.moveInDate.month;
	        	var enteredYear = $scope.custAdditionalInfo.moveInDate.year;

				var enteredDateFormat = enteredYear+'/'+enteredMonth+'/'+enteredDay;
				var enDate = new Date(enteredDateFormat);

				
	    		months = (currentTime.getFullYear() - enDate.getFullYear()) * 12;
	    		months -= enDate.getMonth() + 1;
	    		months += currentTime.getMonth();
	    		if(months > 12 ){
	    			$scope.showPreviousAdd = false;
	    		}else{
	    			$scope.showPreviousAdd = true;
	    		}
			}
		}

		function generateKeys() {
			SharedDataSvc.generatedkeys = SharedDataSvc.generatekeys();
			$scope.my_keys = SharedDataSvc.generatedkeys;
			$scope.my_keys[0].replace(/(-----(\w+) RSA PRIVATE KEY-----|\r\n|\n|\r)/gm, '');
			$scope.my_keys[1].replace(/(-----(\w+) PUBLIC KEY-----|\r\n|\n|\r)/gm, '');
			SharedDataSvc.cleankeys = $scope.my_keys;
			SharedDataSvc.commonHeadersENCRYPT['Public-Key'] = toString($scope.my_keys[1]);
		}
		
		//VAN-510 - Electronic Consent moved to app flow - Starts
//		Call the Start Application flow
		function createOrderApp(personData){
			createOrder = true;
			$rootScope.createOrderFlow=true;
			if($scope.isConsentResponse != "null" && $scope.isConsentResponse != undefined && $scope.isConsentResponse == "false"){
				updateCustomerConstent(personData);
			}
			if($scope.isConsentResponse == "null" || $scope.isConsentResponse == "true"){
				retrieveApplication(personData);
			}
		}
		
		function startApplication(personData){
			$rootScope.startAppFlow = true;
			if($scope.isConsentResponse != "null" && $scope.isConsentResponse != undefined && $scope.isConsentResponse == "false"){
				updateCustomerConstent(personData);
			}
			if($scope.isConsentResponse == "null" || $scope.isConsentResponse == "true"){
				retrieveApplication(personData);}
			
			
		}

		function updateCustomerConstent(personData){
			//call to update remote signing flag update engagement call
			//VAN-3402-Re-engagement with Code - AWP - Start
			var updateData;
			if($rootScope.isAppSrch && $scope.custApp!=''){
				updateData = {
						firstName: $scope.custApp.firstName,
						lastName: $scope.custApp.lastName,
						phoneNumber: $scope.custApp.phones[0].areaCode+$scope.custApp.phones[0].number,
						isTcpaConstentFlg: $scope.personData.istcpaflag //VAN 4120-Update the disclaimer in AWP - SLI/HT originated agreement
				};
			}else{
				updateData = {
						firstName: personData.firstName,
						lastName: personData.lastName,
						phoneNumber: personData.phone,
						isTcpaConstentFlg: personData.istcpaflag //VAN 4120-Update the disclaimer in AWP - SLI/HT originated agreement
				};
			}//VAN-3402-Re-engagement with Code - AWP - End
			modalService.processing('Processing');
			applicationService.updateCustomerConsent(updateData)
			.then(function(response) {
				console.log('Successfully updated engagement with isConsentAgreed flag to true');
				modalService.dismiss();
				if(response.status == '200' ){
					//VAN-3402-Re-engagement with Code - AWP - Start
					if($rootScope.isAppSrch){
						getApplicationSuccess($scope.custApp);
					}else{
						retrieveApplication(personData);
					}//VAN-3402-Re-engagement with Code - AWP - End
				}
			}, function(response){
				console.log('Updating engagement with isConsentAgreed flag to true failed');
				modalService.dismiss();
			});
		}

		//Show electronic Consent at Start Application Flow
		function newCustomerElectronicConsent(){
			console.log("Electronic consent at start application");
			$scope.myModalInstance = $modal.open({
				templateUrl: 'partials/modal_eAgreementNotice_StartApp.html',
				show: true,
				backdrop: 'static',

				resolve: {
					myModalInstance: function () {
						return $scope.myModalInstance;
					}
				}
			});
			$scope.modalClose = function (){
				$scope.myModalInstance.close();
			}}

		//Checkbox enable/disable for electronic consent
		$scope.eConsentAgreefnc = function () {
			if ($scope.eConsentAgree) {
				return true;
			} else {
				return false;
			}
		};
		//VAN-510 - Electronic Consent moved to app flow - Ends

		function retrieveApplication(personData) {
			console.log("[Application.retrieveApplication] personData:-- ", personData);

			// engagement scenario from agreement page when radius is out of region
			if (personData.hasEngagementFromAgreement) {
				var existingCustSSNModal = modalService.open({
					title: 'Customer Verification',
					templateUrl: 'partials/modal_custExistsSsnVerify.tpl.html',
					scope: $scope,
					backdrop: 'static'
				});
				existingCustSSNModal.result
					.then(function (data) {
						personData.ssn = data.ssn1 + data.ssn2 + data.ssn3;
						modalService.processing(modalTitle);
						applicationService.getApplication(personData)
							.then(callApplicationEngagement, getApplicationFail);
						return;
					});
				return;
			}

			SharedDataSvc.globalVars.engagementId = "000000"; //story 26289 code changes
			var modalTitle = (personData.ssn && !$scope.itinFlag) ? 'Verifying SSN' : 'Checking Customer';
			modalService.processing(modalTitle);
			applicationService.getApplication(personData)
				.then(checkCatApprovalSuccess, getApplicationFail);
		}


		/*function checkCatApprovalSSN(response){
			if(response.VanEngagement.applicationStatus === "InProgress" && response.VanEngagement.orderStatus.toLocaleLowerCase() === "notstarted"){
		    var selectCatApprovalSSNModal = modalService.open({
		      title: "Approval by Category",
		      templateUrl: 'partials/modal_selectCatApprovalSSN.html',
		      scope: $scope,
		      backdrop: 'static',
		      controller: ''
		    });
		    selectCatApprovalSSNModal.result.then(function() {
		    	$scope.isApprovalByCatSelected = true;
		    	formatCatApprovalList();
		    	$scope.isApprovalByCatSelected = true;
		        callApplicationEngagement(respose);   
		    });

		  }else{
		  	callApplicationEngagement(respose);
		  }
				
		}*/

		function callApplicationEngagement(response) {
			console.log("[Application.getApplicationSuccess] response: ", response);
			var customerData = response;
			var hasEngagement = _(customerData).has('VANEngagementId');
			//AC 26204 code changes starts
			if (customerData.personalReferences && customerData.personalReferences.length > 0) {
				for (var i = 0; i < customerData.personalReferences.length; i++) {										
					if (refType.indexOf(customerData.personalReferences[i].personalReferenceType) < 0) {
						customerData.personalReferences[i].personalReferenceType = "";
					}					
			}
			}
			//AC 26204 code changes ends
			if (hasEngagement) {
				handleEngagement(customerData);
			} else {
				createNewEngagement(customerData);
			}



		}

		// format Date
		function formatDate(date) {
			return moment(date, 'YYYY-MM-DD').format('YYYY-MM-DD');
		}

		//show expiration notification modal
		function showExpirationNotification(customerData, dateDiff, path, engagementId) {
			$scope.customerFirstName = customerData.firstName;
			$scope.approvalAmount = customerData.approvalAmount;
			$scope.approvalExpiryDays = dateDiff;
			var vanEngagementId = customerData.VANEngagementId || engagementId;
			var expirationNotify = modalService.open({
				title: 'Approval Expiration Notification',
				templateUrl: 'partials/alert_expirationNotification.tpl.html',
				backdrop: 'static',
				windowClass: 'approved-modal',
				scope: $scope
			});
			expirationNotify.result
				.then(function () {
					if (path === "dashboard") {
						navigateTo('dashboard/' + vanEngagementId);
					} else {
						navigateTo('ordersummary/' + vanEngagementId);
					}
					return;
				});
		}

		// Approval Amount Check
		function approvalAmountCheck(VANEngagementID) {
			var approvedAmountModal = modalService.open({
				title: 'Application Approved For Amount Less than $300',
				templateUrl: 'partials/alert_belowMin.tpl.html',
				backdrop: 'static',
				scope: $scope
			});
			approvedAmountModal.result.then(function (data) {
				navigateTo('dashboard/' + VANEngagementID);
			});
		}

		function createEngagementData(customerData, hasEngagement) {
			$scope.engagementDetails = {};
			var engagementProps = [
				'engagementId',
				'orderStatus',
				'deliveryStatus',
				'paymentStatus',
				'salesStatus',
				'agreementStatus',
				'applicationStatus',
				'orderOriginator'
			];
			for (var i = 0; i < engagementProps.length; i++) {
				if (hasEngagement) {
					$scope.engagementDetails[engagementProps[i]] = customerData.VanEngagement[engagementProps[i]];
				} else {
					$scope.engagementDetails[engagementProps[i]] = customerData[engagementProps[i]];
				}
			}
			if (hasEngagement) {
				$scope.engagementDetails['customerId'] = customerData.VanEngagement.customer.customerId;
				$scope.engagementDetails['customerHtID'] = customerData.VanEngagement.customerHTId;
				$scope.engagementDetails['customerHtStoreId'] = customerData.VanEngagement.customerHTStoreId;
				$scope.engagementDetails['vanStoreId'] = customerData.VanEngagement.vanStore.storeId;
			} else {
				$scope.engagementDetails['customerId'] = customerData.customerId;
				$scope.engagementDetails['customerHtID'] = customerData.customerHtID;
				$scope.engagementDetails['customerHtStoreId'] = customerData.customerHtStoreId;
				$scope.engagementDetails['vanStoreId'] = customerData.vanStoreId;
			}

			console.log($scope.engagementDetails);
		}

		// show out of radius modal
		function outOfRadiusModal() {
			var outOfRadius = modalService.open({
				title: 'Out of Radius',
				templateUrl: 'partials/modal_outsideRadiusAppCheck.html',
				backdrop: 'static',
				scope: $scope
			});
		}

		// show ITIN disabled modal
		function itinDisabledModal() {
			var itinDisabled = modalService.open({
				title: 'ITIN Disabled Store',
				templateUrl: 'partials/modal_itinDisabled.html',
				backdrop: 'static',
				scope: $scope
			});
		}

		// out of radius exit click handling
		function handleCustomerOutOfRadius() {
			$scope.engagementDetails.applicationStatus = 'OutOfArea';
			updateEngagementItemFactory.putData($scope.engagementDetails)
				.then(function (response) {
					$log.log('[Application.handleCustomerOutOfRadius] Successfully updated engagement');
					navigateTo('dashboard/' + $scope.engagementDetails.engagementId);
				}, function (response) {
					$log.log('[Application.handleCustomerOutOfRadius] Updating engagement failed');
					modalService.dismiss();
				});
		}

		// out of radius update application handling
		function navigateToApplication() {
			console.log($scope.customerInfo);
			$log.log($scope.engagementDetails);
			customerDataFactory.findByEngagementId($scope.engagementDetails.engagementId)
				.then(function (response) {
					$log.log('[Application.getCustomer] customer', response);
					var person = {
						firstName: response.customer.fName,
						lastName: response.customer.lName,
						phone: response.customer.phNum,
						ssn: $scope.ssnNumber
					};
					applicationService.getApplication(person)
						.then(callApplicationEngagement, getApplicationFail);

				}).catch(function (response) {
					$log.log('[Application.getCustomer] could not get customer data', response);
				});
		}

		//fetch and validate zip code
		function fetchAndValidateZipCode(customerData) {
			var zipCode = "";
			resetZipData();
			angular.forEach(customerData.address, function (address) {
				if (address.zipCode && address.zipCode !== "") {
					zipCode = address.zipCode;
				}
			});
			zipCode = zipCode.substring(0,5);//Defect 26474 code change
			if(zipCode) {
				zipValidation(zipCode);
			}
		}

		function showSameDayAgreementModal() {
			console.log('SAME DAY AGREEMENT');
			var processingModal = modalService.open({
				scope: $scope,
				windowClass: '',
				templateUrl: 'partials/modal_sameDayAgree.html',
				backdrop: 'static'
			});
		}

		//show associate review modal
		function showAssociateReviewModal(engagementId, modalName) {
			var assocrvwModalUnmanned = modalService.open({
				templateUrl: 'partials/alert_' + modalName + '.tpl.html',
				windowClass: 'modal-small',
				backdrop: 'static',
				scope: $scope
			});
			assocrvwModalUnmanned.result.then(function () {
				navigateTo('dashboard/' + engagementId);
			});
		}

		//story 25996 changes starts
		//show decline agreement modal
		function showAgreementDeclineModal(engagement) {
			var customerData;
			customerDataFactory.findByEngagementId(engagement.engagementId)
				.then(function (response) {
					customerData = response;
					$scope.customerEngagementInfo = {};
					console.log('success retrieving the customer details ', customerData);
					var engagementProps = [
						'engagementId',
						'orderStatus',
						'deliveryStatus',
						'paymentStatus',
						'salesStatus',
						'agreementStatus',
						'applicationStatus',
						'orderOriginator'
					];
					// cherry pick the needed properties for a proper engagement
					for (var i = 0; i < engagementProps.length; i++) {
						$scope.customerEngagementInfo[engagementProps[i]] = response[engagementProps[i]];
					}
					$scope.customerEngagementInfo['customerId'] = response.customer.customerId;
					$scope.customerEngagementInfo['customerHtID'] = response.customerHTId;
					$scope.customerEngagementInfo['customerHtStoreId'] = response.customerHTStoreId;
					$scope.customerEngagementInfo['vanStoreId'] = response.vanStore.storeId;
					var declineModal = modalService.open({
						templateUrl: 'partials/modal_agreementDeclineAndReEditOrder.html',
						scope: $scope,
						backdrop: 'static'//AC 25996 updated code changes
					});

				}).catch(function (response) {
					console.log('could not get customer data', response);
				});


		}
		//Story 29237 code changes
		function declineAgreement() {
			showProcessingModal("Decline Agreement");
			var engagement = $scope.customerEngagementInfo;
			engagement.agreementStatus = 'Voided';
			updateEngagementItemFactory.putData(engagement)
				.then(function (response) {
					console.log('Successfully updated engagement with agreement status declined');
					modalService.dismiss();
					navigateTo('ordersummary/' + $scope.customerEngagementInfo.engagementId);
				}, function (response) {
					console.log('Updating engagement for agreement status voided failed');
					modalService.dismiss();
				});
		}
		//Story 29237 code changes ends
		function cancelAgreementDecline() {
			navigateTo('dashboard/' + $scope.customerEngagementInfo.engagementId);
		}
		//story 25996 changes ends

		//Story 30445 -AWP, Kiosk: Add email verification edit box - start	
		function compareEmailVerify(event, newvalue) {
			if ($scope.customerInfoForm.emailAddressVerify.$invalid || ($scope.newform.newCustomerCheckForm && $scope.newform.newCustomerCheckForm.emailAddressVerify && $scope.newform.newCustomerCheckForm.emailAddressVerify.$invalid)) {
				$scope.verifyEmail = true;
				$scope.customerInfoForm.emailAddressVerify.$setValidity('emailAddressVerify', true);
			}
			if ($scope.customerInfoForm.emailAddress.$valid && $scope.customerInfoForm.emailAddressVerify.$valid) {
				if ($scope.customerInfo.email) {
					//$scope.verifyEmail = newvalue === $scope.customerInfo.email ? true : false;
					if (newvalue.toUpperCase() === $scope.customerInfo.email.toUpperCase()) {
						$scope.verifyEmail = true;
						$scope.customerInfoForm.emailAddressVerify.$setValidity('emailAddressVerify', true);
					} else {
						$scope.verifyEmail = false;
						$scope.customerInfoForm.emailAddressVerify.$setValidity('emailAddressVerify', false);
					}
				}
			} else if ($scope.newform.newCustomerCheckForm && $scope.newform.newCustomerCheckForm.emailAddressVerify && $scope.newform.newCustomerCheckForm.emailAddressVerify.$valid) {
				if ($scope.customerInfoModal.emailAddress) {
					$scope.verifyEmail = newvalue.toUpperCase() === $scope.customerInfoModal.emailAddress.toUpperCase() ? true : false;
				}
			}
		}
		//Story 30445 -AWP, Kiosk: Add email verification edit box - end

		function checkCatApprovalSuccess(response){
			var skipCatSelect = false
			if(typeof response.status_code != 'undefined'){
				if(response.status_code === 'ENG-E100-303'){
					skipCatSelect = true
				}
			} 

			if(typeof response.overallApprovalStatus != 'undefined'){
				if(response.overallApprovalStatus === "ACCEPT"){
					skipCatSelect = true;
				}
			}


			if($scope.storeInfo.isProductTypeSelection && !skipCatSelect && typeof response.VanEngagement != 'undefined'){
				if(response.VanEngagement.applicationStatus === "InProgress" && response.VanEngagement.orderStatus.toLocaleLowerCase() === "notstarted"){
					$scope.isApprovalByCatSelected = true;
						if($scope.approvalByCatList.length > 1){
							var selectCatApprovalAppSuccessModal = modalService.open({
						      title: "Approval by Category",
						      templateUrl: 'partials/modal_selectCatApprovalAppSuccess.html',
						      scope: $scope,
						      backdrop: 'static',
						      controller: ''
						    });
						    selectCatApprovalAppSuccessModal.result.then(function() {
						    	formatCatApprovalList();
						    	//$scope.categoryCollected = true;
						        getApplicationSuccess(response);    
						    });
						}else{
							getApplicationSuccess(response);
						}
			  	}else{
			  	getApplicationSuccess(response);
			  	}
			}else{
				getApplicationSuccess(response);
			}
				
		}

		

		function getApplicationSuccess(response) {
			console.log("[Application.getApplicationSuccess] response: ", response);
			//var zipCode = "";
			SharedDataSvc.globalVars.engagementId = response.VANEngagementId //Story 26289 code changes
			var customerData = response;
			var hasEngagement = _(customerData).has('VANEngagementId');
			var expirationDate = formatDate(customerData.approvalExpiryDate);
			var todayDate = formatDate(datesFactory.getCurrentDate());
			var dateDiff = moment(expirationDate).diff(todayDate, 'days');
			//VAN-297 - AWP/KIOSK Same day PIF customers not getting approval - Starts
			var agreementData = _(customerData).has('pifAgreement');
			if(agreementData){
			var inactiveDate = formatDate(customerData.pifAgreement.inactiveDate);}
			//VAN-297 - AWP/KIOSK Same day PIF customers not getting approval - Ends
			
			//AC22760  code changes starts
			if (customerData.personalReferences && customerData.personalReferences.length > 0) {
				for (var i = 0; i < customerData.personalReferences.length; i++) {
					if (refType.indexOf(customerData.personalReferences[i].personalReferenceType) < 0) {
						customerData.personalReferences[i].personalReferenceType = "";
					}
				}
			}
			//AC22760 code changes ends	

			// Customer intends to create an order
			if (response.status_code === 'ENG-E100-303') {
				if (response.itinFlag && !$rootScope.itinAllowed) {
					$scope.storePhoneNumber = SharedDataSvc.globalVars.storeSetup.vipPhoneNumber; //AC-28614 Changes 
					console.log('Store Is ITIN disabled.');
					itinDisabledModal();
					return;
				}
				provideSSN();
				$scope.itinFlag = response.itinFlag;
				if (hasEngagement) {
					resetZipData();
					//Defect 26474 code changes
					if(!$scope.isSpanishStore) {
						var zipCode = (customerData.VanEngagement && customerData.VanEngagement.customer.addressZipcode) ? customerData.VanEngagement.customer.addressZipcode.substring(0,5):"";
						if(zipCode) {
							zipValidation(zipCode);
						}
					}
					createEngagementData(customerData, hasEngagement);					
				}
				return;
			}

			if ($scope.storeInfo.achVerification && response.VanEngagement && response.VanEngagement.applicationStatus === "InProgress") {
				var customerInfo = {
					"customerId": response.VANCustomerID,
					"engagementId": response.VANEngagementId,
					"customerFirstName": response.firstName,
					"customerLastName": response.lastName,
					"customerPrimaryPhone": response.phones[0].areaCode + response.phones[0].number
				}
				//provideBankingInfoForACH(customerInfo);
			}

			/* Doing out of radius check before missing info check
			 * because customer overall status should be updated here itself to be out of radius.
			 * Otherwise, it will show its previous status on the out of radius store.
			 */
			if (hasEngagement) {
				//for SLI customer out of radius issue - AC 26765
				if(customerData.VanEngagement && customerData.VanEngagement.applicationStatus == 'Declined' && customerData.overallApprovalStatus == "DECLINE"){
					$scope.declinedMessage = 'We are not able to approve your application at this time. An explanatory letter has been emailed to the customer.';
					var appDeclinedModal = modalService.open({
						title: 'Application Declined',
						templateUrl: 'partials/alert_applicationDeclined.tpl.html',
						backdrop: 'static',
						scope: $scope
					});
					appDeclinedModal.result.then(function () {
						$location.path('dashboard/' + customerData.VANEngagementId);
					});
					return;
				} 
				// out of radius check
				if (!$scope.customerInRadius && $scope.isZipValid) {
					outOfRadiusModal(customerData);
					return;
				}
			}/* else if(customerData.overallApprovalStatus == "DECLINE"){
				$scope.declinedMessage = 'We are not able to approve your application at this time. An explanatory letter has been emailed to the customer.';
				var appDeclinedModal = modalService.open({
					title: 'Application Declined',
					templateUrl: 'partials/alert_applicationDeclined.tpl.html',
					backdrop: 'static',
					scope: $scope
				});
				appDeclinedModal.result.then(function () {
					$location.path('dashboard');
				});
				return;
			}*/
			if($rootScope.xpartnerLogin)//VAN-3228-AWP - same state rule apply to only XPARTNER and not XTEAM
				{
			//VAN-3209-Use customers zip code on agreement creation - Starts
			if(customerData.address && customerData.address.length > 0){
				if(customerData.address[0].state !=  $scope.storeInfo.state){
					if(customerData.VanEngagement != undefined){
						if(customerData.VanEngagement.applicationStatus == 'InProgress'){
							$scope.isStateValid = false;
						}else if(customerData.VanEngagement && customerData.VanEngagement.applicationStatus != 'Declined'){
							$scope.invalidState = modalService.open({
								title: 'State is invalid',
								templateUrl: 'partials/alert_invalidStateError.tpl.html',
								backdrop: 'static',
								size: 'sm',
								scope: $scope
							});
							return;
						}
					}else{
						$scope.invalidState = modalService.open({
							title: 'State is invalid',
							templateUrl: 'partials/alert_invalidStateError.tpl.html',
							backdrop: 'static',
							size: 'sm',
							scope: $scope
						});
						return;
					}
				}
			}
			//VAN-3209-Use customers zip code on agreement creation - Ends
				}//VAN-3228-AWP - same state rule apply to only XPARTNER and not XTEAM
			SharedDataSvc.checkMissingInfo(response, function (isDataGood) {

				console.log("[Is data good]", isDataGood);

				if (isDataGood) {
					//VAN-736 - PRB0042956 - AWP- New agreement is getting created next day after declining the order for an active agreement in HT - Starts
					if(createOrder){
						if(customerData.agreement !== null && customerData.agreement !== undefined && customerData.agreement.agreementStatus === 'ACTIVE'){
							console.log('open active agreement modal at order flow');
							var wrongvendor = modalService.open({
								show: true,
								templateUrl: 'partials/alert_activeAgreementErrorAtOrder.tpl.html',
								backdrop: 'static'         
							});
							navigateTo('dashboard/');
							return;
						}
					}
					//VAN-736 - PRB0042956 - AWP- New agreement is getting created next day after declining the order for an active agreement in HT - Ends

					if (hasEngagement) {
						// minimum approval amount check
						if (parseInt(customerData.approvalAmount) < parseInt(customerData.minimumApprovalAmount) && customerData.VanEngagement.applicationStatus.toLocaleLowerCase() === "accepted"
							&& customerData.pifAgreement !== undefined && customerData.pifAgreement.inactiveCode != 'CHARGEOFF' && inactiveDate != todayDate){//VAN-3541-PRB0043608 - PIF customer not getting the correct approval amount when reengaging on same day
							approvalAmountCheck(customerData.VANEngagementId);
							return;
						}

						// approval expiration notification
						if (customerData.VanEngagement.applicationStatus.toLocaleLowerCase() === "accepted" && customerData.VanEngagement.orderStatus.toLocaleLowerCase() === "notstarted" && dateDiff < 8) {
							if (createOrder) {
								showExpirationNotification(customerData, dateDiff, "order");
							} else {
								showExpirationNotification(customerData, dateDiff, "dashboard");
							}
							return;
						}
						// showModal for unmanned store associate review call assistance details
						if (!$scope.storeInfo.isManned && customerData.VanEngagement.applicationStatus.toLocaleLowerCase() === "assocrvw") {
							$scope.servicePhoneNumber = SharedDataSvc.globalVars.storeSetup.vipPhoneNumber; //AC-28614 Changes 
							showAssociateReviewModal(customerData.VANEngagementId, "appAssociateReviewUnmanned");
							return;
						}
						// showModal for manned store to find associate to review application
						if ($scope.storeInfo.isManned && customerData.VanEngagement.applicationStatus.toLocaleLowerCase() === "assocrvw") {
							showAssociateReviewModal(customerData.VANEngagementId, "appAssociateReviewManned");
							return;
						}

						// HT approved customer re-engagement in PR store - redirection to agreement for signing agreements
						if(customerData.VanEngagement !== undefined && (customerData.VanEngagement.applicationStatus && customerData.VanEngagement.applicationStatus.toLocaleLowerCase() === "accepted") && (customerData.VanEngagement.orderStatus && customerData.VanEngagement.orderStatus.toLocaleLowerCase() === "confirmed") && (customerData.VanEngagement.agreementStatus && customerData.VanEngagement.agreementStatus.toLocaleLowerCase() === "pending") && (customerData.VanEngagement.agreementOriginator && customerData.VanEngagement.agreementOriginator.toLocaleLowerCase() === "hightouch")) {
								/**if($scope.storeInfo.isDigitalSigningEnabled) {
									$location.path('digitalAgreement/' + customerData.VANEngagementId);
								} else {
									$location.path('agreement/' + customerData.VANEngagementId);
								}**/
								$location.path('dashboard/' + customerData.VANEngagementId);
								return;
						}

					}

					if (createOrder) {
						if (hasEngagement) {
							//story 25996 changes starts
							if (customerData.VanEngagement && ((customerData.VanEngagement.agreementStatus !== 'Declined' && customerData.VanEngagement.agreementStatus !== 'Voided') && customerData.VanEngagement.orderStatus === 'Confirmed' && (customerData.VanEngagement.agreementHTId != null && customerData.VanEngagement.agreementHTId != undefined) && (customerData.VanEngagement.applicationStatus.toLocaleLowerCase() !== 'declined')) && (customerData.VanEngagement.applicationStatus !== 'InProgress')) {//AC 25996 & AC 14458 updated code changes
								console.log("condition for agreement status and agreement id check");
								showAgreementDeclineModal(customerData.VanEngagement);
								return;
							} else {
								navigateTo('ordersummary/' + customerData.VANEngagementId);
							}
							//story 25996 changes ends
						} else {
							// HT approved customer re-engagement in PR store - redirection to agreement for signing agreements
							if(customerData.VanEngagement !== undefined && (customerData.VanEngagement.applicationStatus && customerData.VanEngagement.applicationStatus.toLocaleLowerCase() === "accepted") && (customerData.VanEngagement.orderStatus && customerData.VanEngagement.orderStatus.toLocaleLowerCase() === "confirmed") && (customerData.VanEngagement.agreementStatus && customerData.VanEngagement.agreementStatus.toLocaleLowerCase() === "pending") && (customerData.VanEngagement.agreementOriginator && customerData.VanEngagement.agreementOriginator.toLocaleLowerCase() === "hightouch")) {
									/**if($scope.storeInfo.isDigitalSigningEnabled) {
										$location.path('digitalAgreement/' + customerData.VANEngagementId);
									} else {
										$location.path('agreement/' + customerData.VANEngagementId);
									}**/
									$location.path('dashboard/' + customerData.VANEngagementId);
									return;
							}
							if(!$scope.isSpanishStore) {
								fetchAndValidateZipCode(customerData);
							}
							/*if(response.social){
								$scope.customerInfoModal.social = response.social;
							}*/

							/* Added condition here to go with full data of customer in case of re-engagement 
							 If you get ssn then it was engagement created earlier with complete application*/
							if (customerData.social) {
								createFullEngagement(customerData, function (returnData) {
									console.log('[Application.createFullEngagement().response]', response);
									var VANEngagementId = returnData.VANEngagementId;
									updateEngagementItemFactory.getData(VANEngagementId).then(function (response) {
										var engagementItemData = response;
										console.log('[Engagement Item Details]', engagementItemData);
										createEngagementData(engagementItemData, hasEngagement);
										// minimum approval amount check
										if (parseInt(customerData.approvalAmount) < parseInt(customerData.minimumApprovalAmount) && engagementItemData.applicationStatus.toLocaleLowerCase() === "accepted") {
											approvalAmountCheck(VANEngagementId);
											return;
										}
										// out of radius check
										else if (!$scope.customerInRadius && $scope.isZipValid) {
											outOfRadiusModal();
											return;
										}
										// approval expiration notification
										else if (engagementItemData.applicationStatus.toLocaleLowerCase() === "accepted" && engagementItemData.orderStatus.toLocaleLowerCase() === "notstarted" && dateDiff < 8) {
											showExpirationNotification(customerData, dateDiff, "order", VANEngagementId);
											return;
										}
										// showModal for unmanned store associate review call assistance details
										else if (!$scope.storeInfo.isManned && engagementItemData.applicationStatus.toLocaleLowerCase() === "assocrvw") {
											$scope.servicePhoneNumber = SharedDataSvc.globalVars.storeSetup.vipPhoneNumber; //AC-28614 Changes  
											showAssociateReviewModal(VANEngagementId, "appAssociateReviewUnmanned");
											return;
										}
										// showModal for manned store to find associate to review application
										else if ($scope.storeInfo.isManned && engagementItemData.applicationStatus.toLocaleLowerCase() === "assocrvw") {
											showAssociateReviewModal(VANEngagementId, "appAssociateReviewManned");
											return;
										}
										else {
											//story 25996 changes starts
											if (customerData.VanEngagement && ((customerData.VanEngagement.agreementStatus !== 'Declined' && customerData.VanEngagement.agreementStatus !== 'Voided') && customerData.VanEngagement.orderStatus === 'Confirmed' && (customerData.VanEngagement.agreementHTId != null && customerData.VanEngagement.agreementHTId != undefined) && (engagementItemData.applicationStatus.toLocaleLowerCase() !== 'declined'))) {//AC 25996 updated code changes
												console.log("condition for agreement status and agreement id check");
												//VAN-736 - PRB0042956 - AWP- New agreement is getting created next day after declining the order for an active agreement in HT - Starts
												if(customerData.agreement !== null && customerData.agreement !== undefined && customerData.agreement.agreementStatus === 'ACTIVE'){
													console.log('open active agreement modal at order flow');
													var wrongvendor = modalService.open({
														show: true,
														templateUrl: 'partials/alert_activeAgreementErrorAtOrder.tpl.html',
														backdrop: 'static'         
													});
													navigateTo('dashboard/' + customerData.VanEngagement.engagementId);
												}
												else
												{
													showAgreementDeclineModal(customerData.VanEngagement);
												}
												//VAN-736 - PRB0042956 - AWP- New agreement is getting created next day after declining the order for an active agreement in HT - Ends
												return;
											} else {
												navigateTo('ordersummary/' + engagementItemData.engagementId);
											}
											//story 25996 changes ends
										}
									}, function (response) {
										// error
										console.log('[Engagement Item] failed getting engagement data', response);
									});
								});
							} else {
								createNewEngagement(customerData, function (engagementData) {
									SharedDataSvc.globalVars.engagementId = engagementData.VANEngagementId; //story 26289 code changes reviewed by Theenmathi
									navigateTo('ordersummary/' + engagementData.VANEngagementId);
								});
							}
						}
						return;
					}

					// Customer has existing approval amount
					if (customerData.approvalAmount > 0 || customerData.overallApprovalStatus == 'DECLINE') { //Story 29310 Changes
						$scope.declinedMessage = 'We are not able to approve your application at this time. An explanatory letter has been emailed to the customer.';
						if (hasEngagement) {
							//Story 29310 Changes Start	
							if (customerData.overallApprovalStatus == 'DECLINE') {
								var appDeclinedModal = modalService.open({
									title: 'Application Declined',
									templateUrl: 'partials/alert_applicationDeclined.tpl.html',
									backdrop: 'static',
									scope: $scope
								});
								appDeclinedModal.result
									.then(function () {
										$location.path('dashboard/' + customerData.VANEngagementId);
									});
							} else {
								navigateTo('dashboard/' + customerData.VANEngagementId);
							}
							//Story 29310 Changes End
						} 
						//VAN-297 - AWP/KIOSK Same day PIF customers not getting approval - Starts
						else if(agreementData && customerData.pifAgreement !== undefined && customerData.pifAgreement.agreementStatus == 'INACTIVE' && customerData.pifAgreement.inactiveCode == 'CHARGEOFF' /*&& inactiveDate == todayDate*/)//Commented as part of VAN-2606-Hit DE for any recent PIF, paid in full
							{
								createNewEngagement(customerData);
								return;
							}
						//VAN-297 - AWP/KIOSK Same day PIF customers not getting approval - Ends
						else {
							if(!$scope.isSpanishStore){
								fetchAndValidateZipCode(customerData);
							}
							createFullEngagement(customerData, function (response) {
								console.log('[Application.createFullEngagement().response]', response);
								var VANEngagementId = response.VANEngagementId;
								updateEngagementItemFactory.getData(VANEngagementId).then(function (response) {
									var engagementItemData = response;
									console.log('[Engagement Item Details]', engagementItemData);
									createEngagementData(engagementItemData, hasEngagement);
									// minimum approval amount check
									if (parseInt(customerData.approvalAmount) < parseInt(customerData.minimumApprovalAmount) && engagementItemData.applicationStatus.toLocaleLowerCase() === "accepted") {
										approvalAmountCheck(VANEngagementId);
										return;
									}
									// out of radius check
									else if (!$scope.customerInRadius && $scope.isZipValid) {
										outOfRadiusModal();
										return;
									}
									// approval expiration notification
									else if (engagementItemData.applicationStatus.toLocaleLowerCase() === "accepted" && engagementItemData.orderStatus.toLocaleLowerCase() === "notstarted" && dateDiff < 8) {
										showExpirationNotification(customerData, dateDiff, "dashboard", VANEngagementId);
										return;
									}
									// showModal for unmanned store associate review call assistance details
									else if (!$scope.storeInfo.isManned && engagementItemData.applicationStatus.toLocaleLowerCase() === "assocrvw") {
										$scope.servicePhoneNumber = SharedDataSvc.globalVars.storeSetup.vipPhoneNumber; //AC-28614 Changes 
										showAssociateReviewModal(VANEngagementId, "appAssociateReviewUnmanned");
										return;
									}
									// showModal for manned store to find associate to review application
									else if ($scope.storeInfo.isManned && engagementItemData.applicationStatus.toLocaleLowerCase() === "assocrvw") {
										showAssociateReviewModal(VANEngagementId, "appAssociateReviewManned");
										return;
									}
									else {
										//Story 29310 Changes Start
										//navigateTo('dashboard/' + engagementItemData.VANEngagementId);
										if (customerData.overallApprovalStatus == 'DECLINE') {
											var appDeclinedModal = modalService.open({
												title: 'Application Declined',
												templateUrl: 'partials/alert_applicationDeclined.tpl.html',
												backdrop: 'static',
												scope: $scope
											});
											appDeclinedModal.result
												.then(function () {
													$location.path('dashboard/' + VANEngagementId);
												});
										} else {
											navigateTo('dashboard/' + VANEngagementId);
										}//Story 29310 Changes End
									}
								}, function (response) {
									// error
									console.log('[Engagement Item] failed getting engagement data', response);
								});
							});
						}
						return;
					}

					if (hasEngagement) {
						handleEngagement(customerData);
					} else {
						createNewEngagement(customerData);
					}

				} else {
//					$rootScope.kountSessionId = null;//VAN-3128-KOUNT implementation VA/AWP
					modalService.dismiss();
					var person = {
						firstName: customerData.firstName,
						lastName: customerData.lastName,
						phone: customerData.phones[0].areaCode + customerData.phones[0].number,
						ssn: customerData.social
					};
					var personData = window.btoa(JSON.stringify(person));
					$location.path('getmissinginfo/').search({ data: personData });
				}


			});

		}

		function getApplicationFail(response, personData) {
			console.log('[Application.getApplicationFail] response.status: ' + response.status, response);
			// Customer with SSN entered
			if (response.status === 303) {
				provideSSN();
			}
			
			if (response.status === 400 && response.data.errorData.status_message.indexOf('A completed agreement already exists') > -1) {
				showSameDayAgreementModal();
			}
			// should remove the 500 check once session manager is fixed
			if (response.status === 404 || response.status === 500) {
				if (response.data.errorData.status_details === 'No Customers Match Search Criteria') {
					modalService.dismiss();
					$scope.newCustomer = true;
					// console.log($scope.customerInfoModal);
					if (createOrder) {
						createNewEngagement($scope.customerInfoModal, function (engagementData) {
							SharedDataSvc.globalVars.engagementId = engagementData.VANEngagementId; //story 26289 code changes reviewed by Theenmathi
							//Story 33452 changes starts
							var person = {
									firstName: $scope.customerInfoModal.firstName,
									lastName: $scope.customerInfoModal.lastName,
									phone: $scope.customerInfoModal.primaryPhone,
									disableHT: true
								};
							applicationService.getApplication(person)
							.then(function(response){
								SharedDataSvc.globalVars.order.currentEngagement = response;
								if(response.VanEngagement && response.VanEngagement.applicationStatus == 'Accepted'){
									$scope.custCreateData = person;
									$scope.custIdNotAvail = modalService.open({
										scope: $scope,
										templateUrl: 'partials/alert_custHtIdNotAvailable.tpl.html',
										backdrop: 'static'
									});
								} else {
									navigateTo('ordersummary/' + engagementData.VANEngagementId);
								}
							},function(response){
								console.log('error occured in find call'+response);
							});
							//Story 33452 changes ends
//							navigateTo('ordersummary/' + engagementData.VANEngagementId);
						});
						return;
					}

					if($scope.approvalByCatList.length > 1 && $scope.storeInfo.isProductTypeSelection){
						showModal("selectCategoryApproval");
					}else{
						$scope.isApprovalByCatSelected = true;
						createNewEngagement($scope.customerInfoModal);
					}

					
				}
				if (response.data.errorData.status_details === "SSN Supplied Not Found") {
					$scope.ssnInvalid = true;
					provideSSN();
				}
			}
		}

		function updateAutoFields(){
			if($scope.custAdditionalInfo.auto.noCar == true){
				$scope.custAdditionalInfo.auto.make = "";
				$scope.custAdditionalInfo.auto.model = "";
				$scope.custAdditionalInfo.auto.tag = "";
				$scope.custAdditionalInfo.auto.color = "";
				$scope.custAdditionalInfo.auto.amount = "";
			}
		}

		function updateApprovalByCatList(){
			for(var z=0; z < $scope.approvalByCatList.length ; z++){
				var count = false;
					if($scope.approvalByCatList[z].isSelected == true){
						count = true;
						break;
					}	
			}
			if(count){
				$scope.disableCatContinue = false;
			}else{
				$scope.disableCatContinue = true;
			}

		}

		function formatCatApprovalList(){
			for(var z=0; z < $scope.approvalByCatList.length ; z++){
					if($scope.approvalByCatList[z].isSelected == true){
						$scope.approvalByCatList[z].isSelected = true;
					}else{
						$scope.approvalByCatList[z].isSelected = false;
					}
			}

			$scope.customerInfo.approvalByCatList = $scope.approvalByCatList;
		}

		function newEngagementWithCat(){
			//formatCatApprovalList();
			$scope.isApprovalByCatSelected = true;
			createNewEngagement($scope.customerInfoModal);

		}
		
		//Story 33452 changes starts
		function applicationCheck(){
			modalService.dismiss();
			var customerData = $scope.custCreateData;
			customerData.phone1 = {};
			customerData.phone1.number = $scope.custCreateData.phone;
			fillInitalApplicationData(customerData);
		}
		
		function fillInitalApplicationData(custData){
			$scope.customerInfo.firstName = custData.firstName;
			$scope.customerInfo.lastName = custData.lastName;
			$scope.customerInfo.phone1 = {};
			$scope.customerInfo.phone1.number = custData.phone1.number;
			$scope.customerInfo.phone1.phoneType = $scope.customerInfo.primaryPhoneType;
			if($scope.customerInfo.emailAddress){
				$scope.customerInfo.email=$scope.customerInfo.emailAddress;
			}
			$scope.verifyEmailInOrder = true;
			SharedDataSvc.customerInfo = $scope.customerInfo;
		}
		//Story 33452 changes ends

		function handleEngagement(customerData) {
			// application | order | agreements | payment | delivery
			var engagementOrderContent;
			var engagement = customerData.VanEngagement;
			var engagementStatus = [];
			var statusDictionary = {
				null: 0,
				'NotStarted': 0,
				'Pending': 0,
				'InProgress': 0,
				'Accepted': 1,
				'Confirmed': 1,
				'Complete': 1,
				'Declined': 2
			};

			engagementStatus = [
				statusDictionary[engagement.applicationStatus],
				statusDictionary[engagement.orderStatus],
				statusDictionary[engagement.agreementStatus],
				statusDictionary[engagement.paymentStatus],
				statusDictionary[engagement.deliveryStatus]
			];
			engagementStatus = engagementStatus.join('');

			if (engagement.orderContent && engagement.orderContent.length > 0) {
				engagementOrderContent = JSON.parse(engagement.orderContent);
				_.forEach(engagementOrderContent.orderItems, function (orderItem) {
					existingOrderTotal += orderItem.itemPrice;
				});
				console.log('[Application.handleEngagement existingOrderTotal] ' + existingOrderTotal);
			}


			if (engagementStatus = '00000') {
				modalService.dismiss();
				SharedDataSvc.globalVars.order.currentEngagement = customerData;
				$rootScope.$broadcast('applicationDataUpdated', customerData);
				return;
			}
			$location.path('dashboard');
			/* Code for handling redirection after re-engagment
			switch (engagementStatus) {
				// Application not complete
				case '00000':
					modalService.dismiss();
					SharedDataSvc.globalVars.order.currentEngagement = response.data;
					$rootScope.$broadcast('applicationDataUpdated', response.data);
					break;
				// Application complete & order not complete
				case '10000':
					$location.path('/ordersummary/'+engagement.engagementId);
					break;
				// Application & Order Complete
				case '11000':
					$location.path('/agreement/'+engagement.engagementId);
					break;
				// App, Order & Agreements Complete
				case '11100':
					$location.path('/epay/'+engagement.engagementId);
				default:
					break;
			}
			// Application,
			*/
		}

		function showProcessingModal(title) {
			$scope.viewLoading = true;
			$scope.modalTitle = title || 'Processing';
			$scope.processingModal = modalService.open({
				scope: $scope,
				windowClass: 'processing-modal',
				templateUrl: 'partials/modal_Processing.html',
				size: 'sm',
				backdrop: 'static'
			});
		}

		function hideProcessingModal() {
			$scope.processingModal.dismiss();
			$scope.modalTitle = undefined;
			$scope.viewLoading = false;
		}

		function createFullEngagement(customerData, callback) {
			console.log("[create full engagement customer data]", customerData);

			callback = callback || function () { };
			var fullCreateData = {};
			var parameters = [
				'firstName',
				'lastName',
				'middleInitial',
				'email',
				'itinFlag',
				'social',
				'languagePreference',
				'customerType',
				'address',
				'driversLicense',
				'incomeSources',
				'personalReferences'
			];
			// Copy all the properties listed above
			for (var i = 0; i < parameters.length; i++) {
				fullCreateData[parameters[i]] = _.clone(customerData[parameters[i]], true);
			}
			// remove address type, in case it's been entered before somehow
			for (var i = 0; i < fullCreateData.address.length; i++) {
				delete fullCreateData.address[i].addressType;
			}
			fullCreateData.phones = [];
			for (var i = 0; i < customerData.phones.length; i++) {
				if (customerData.phones[i].areaCode !== '000') {
					fullCreateData.phones[i] = _.clone(customerData.phones[i], true);
				}
				// console.log(fullCreateData.phones[i].areaCode);
			}

			if (fullCreateData.phones) {
				if (fullCreateData.phones[0]) {
					fullCreateData.phones[0].phoneType = fullCreateData.phones[0].phoneType || null;
				}

				if (fullCreateData.phones[1]) {
					fullCreateData.phones[1].phoneType = fullCreateData.phones[1].phoneType || null;
				}
				//VAN-849 - AWP- Not able to reengage customer created in HT (store A)to different store(storeB ) in AWP -- Start
				if (fullCreateData.phones[2]) {
					fullCreateData.phones[2].phoneType = fullCreateData.phones[2].phoneType || null;
				}
				//VAN-849 - AWP- Not able to reengage customer created in HT (store A)to different store(storeB ) in AWP -- End
			}


			fullCreateData.primaryPhoneNumber = fullCreateData.phones[0].areaCode + fullCreateData.phones[0].number;
			fullCreateData.customerId = customerData.VANCustomerID;
			fullCreateData.orderOriginator = "AWP";
			//fullCreateData.suppressDbCreate="true;
			if (customerData.personalReferences && customerData.personalReferences.length > 0) {
				for (var i = 0; i < customerData.personalReferences.length; i++) {
					fullCreateData.personalReferences[i].firstName = customerData.personalReferences[i].firstName === '' ? null : customerData.personalReferences[i].firstName;
					fullCreateData.personalReferences[i].middleInitial = customerData.personalReferences[i].middleInitial === '' ? null : customerData.personalReferences[i].middleInitial;
					fullCreateData.personalReferences[i].lastName = customerData.personalReferences[i].lastName === '' ? null : customerData.personalReferences[i].lastName;
					fullCreateData.personalReferences[i].personalReferenceType = customerData.personalReferences[i].personalReferenceType === '' ? null : customerData.personalReferences[i].personalReferenceType;
					fullCreateData.personalReferences[i].phone = customerData.personalReferences[i].phone.areaCode + customerData.personalReferences[i].phone.number === '' ? null : customerData.personalReferences[i].phone;
				}
			}

			console.log('[Application.createFullEngagement().fullCreateData]', fullCreateData);

			getSpecificApplication.searchHT(fullCreateData)
				.then(
				callback,
				function (response) {
					console.log('[Application.createFullEngagement] HT PUT ERROR: #', response);
					if (response.status === 400 && response.data.errorData.status_message.indexOf('A completed agreement already exists') > -1) {
						showSameDayAgreementModal();
					}
				}
				);
		}

		function createNewEngagement(customerData, callback) {
			var customerPhone;
			console.log('[Application.createNewEngagement] ', customerData);

			/* This script below will populate the application page fields for
			 * save drafted customer re-engaging on other store.
			  It reaches here because
			 * on another store there is no engagement present for it.
			 */
			//PRB0042346 - Application stuck inprocess due to different phone number - Starts
			if($rootScope.applicationLink == true && customerData.firstName == undefined){
				console.log("alert popup for undefined engagementdata");
				var wrongvendor = modalService.open({
					show: true,
					templateUrl: 'partials/alert_openNewexistingCustomer.tpl.html',
					backdrop: 'static'         
				});    
				navigateTo('dashboard');
			}
			else {//PRB0042346 - Application stuck inprocess due to different phone number - Ends
				$rootScope.$broadcast('applicationDataUpdated', customerData);

				if(!$scope.isSpanishStore){
					fetchAndValidateZipCode(customerData);
				}
				// out of radius check
				if (!$scope.customerInRadius && $scope.isZipValid) {
					outOfRadiusModal(customerData);
					return;
				}

				showProcessingModal();
				if (_.isArray(customerData.phones)) {
					customerPhone = customerData.phones[0];
				} else {
					customerPhone = {
						areaCode: customerData.primaryPhone.substr(0, 3),
						number: customerData.primaryPhone.substr(3, 10),
						phoneType: customerData.primaryPhoneType || null
					};
				}

				if (customerData.social) {
					var createData = {
						phones: [customerPhone],
						firstName: customerData.firstName,
						lastName: customerData.lastName,
						middleInitial: customerData.middleInitial,
						email: customerData.email || customerData.emailAddress,
						primaryPhoneNumber: customerPhone.areaCode + customerPhone.number,
						languagePreference: "ENGLISH",
						orderOriginator: "AWP",
						social: customerData.social

					};

				} else {
					var createData = {
						phones: [customerPhone],
						firstName: customerData.firstName,
						lastName: customerData.lastName,
						middleInitial: customerData.middleInitial,
						email: customerData.email || customerData.emailAddress,
						primaryPhoneNumber: customerPhone.areaCode + customerPhone.number,
						languagePreference: "ENGLISH",
						orderOriginator: "AWP",
						isConsentAgreed : $scope.isConsentResponse == "null" ? true : "",//VAN-510 - Electronic Consent moved to app flow 	
						isTcpaConstentFlg : $scope.isConsentResponse == "null" ? $scope.personData.istcpaflag : "" //VAN 4119-Update the disclaimer in AWP
					};
				}



				callback = callback || function (data) {
					modalService.dismiss();
					console.log('[Application.createNewEngagement] CREATED ID', data.customerId);
					$scope.customerId = data.customerId;
					SharedDataSvc.globalVars.order.currentEngagement.VANCustomerID = data.customerId;
					SharedDataSvc.globalVars.order.currentEngagement.VANEngagementId = data.VANEngagementId;

					$scope.customerInfo.firstName = createData.firstName;
					$scope.customerInfo.middleName = createData.middleInitial;
					$scope.customerInfo.lastName = createData.lastName;
					$scope.customerInfo.email = createData.email;
					$scope.customerInfo.emailVerify = createData.email;//Story 30445 -AWP, Kiosk: Add email verification edit box
					$scope.customerInfo.phone1 = {
						number: createData.primaryPhoneNumber,
						phoneType: createData.phones[0].phoneType || null
					};
					if (createData.phones[0].phoneType === "HOME" && $scope.allowTextVerificationFlag) {
						$scope.customerInfo.phone2 = {
							phoneType: "CELL"
						}
					}
					/*
					if ($scope.storeInfo.achVerification) {
						var customerInfo = {
							"customerId": data.customerId,
							"engagementId": data.VANEngagementId,
							"customerFirstName": $scope.customerInfo.firstName,
							"customerLastName": $scope.customerInfo.lastName,
							"customerPrimaryPhone": createData.primaryPhoneNumber
						}
						provideBankingInfoForACH(customerInfo);
					}*/

				};

				getSpecificApplication.searchHT(createData)
					.then(
					callback,
					function (data) {
						console.log('[Application.createNewEngagement] HT PUT ERROR: #' + data);
					}
					);
			}//PRB0042346 - Application stuck inprocess due to different phone number
		}

		function cancelCustomerEntry() {
			var cancelCustSSNModal;
			// open modal only once
			cancelCustSSNModal = modalService.open({
				title: 'Existing Customer Verification',
				templateUrl: 'partials/confirm_discardAppChanges.tpl.html',
				backdrop: 'static',
				scope: $scope
			});
		}

		function updateCoIncome(){
			if($scope.custAdditionalInfo.noCocustomer){
				$scope.custAdditionalInfo.noCocustomerIncome = true;
				$scope.coCustomerInfo.firstName = "";
				$scope.coCustomerInfo.lastName = "";
				$scope.coCustomerInfo.middleName =  "";
				$scope.coCustomerInfo.relation = "";
				$scope.coCustomerInfo.phone1.number = ""; 
				$scope.coCustomerInfo.phone1.phoneType = "";	
				$scope.coCustomerInfo.driversLicense.id = "";
				$scope.coCustomerInfo.driversLicense.issuer = "";
				$scope.coCustomerInfo.driversLicense.idType = "";
				$scope.coCustomerInfo.incomeSources.income = "";
			}else{
				$scope.custAdditionalInfo.noCocustomerIncome = false;
			}
		}

		function updateCocustomerIncome() {
			if($scope.custAdditionalInfo.noCocustomerIncome){
				$scope.coCustomerInfo.incomeSources.income = "";
			}
		}

		function saveCustomerInfo() {
			var deferred = $q.defer();

			modalService.processing('Saving Application');

			var employerAreaCode = '000',
				employerPhone = '0000000',
				monthlyIncome = '',
				incomeSourceName = '',
				bestCallTime = '',
				customerId = SharedDataSvc.globalVars.order.currentEngagement.VANCustomerID || SharedDataSvc.globalVars.order.currentEngagement.customerId,
				engagementId = SharedDataSvc.globalVars.order.currentEngagement.VANEngagementId || $routeParams.engagementId,
				ccEmployerAreaCode = '000',
				ccEmployerPhone = '0000000',
				ccMonthlyIncome = '',
				ccIncomeSourceName = '',
				cctakeHomePay = '';

			SharedDataSvc.customerInfo = $scope.customerInfo;
			SharedDataSvc.customerPrivate = $scope.customerPrivate;

			if (typeof $scope.customerInfo.incomeSources.sourcePhone !== "undefined") {
				employerAreaCode = $scope.customerInfo.incomeSources.sourcePhone.number.substr(0, 3);
				employerPhone = $scope.customerInfo.incomeSources.sourcePhone.number.substr(3, 10);
			}

			

			if ($scope.customerInfo.incomeSources.income) {
				switch ($scope.customerInfo.incomeSources.income) {
					case 'EMPLOYED':
						incomeSourceName = $scope.customerInfo.incomeSources.sourceName || '';
						break;
					case 'SELFEMPLOYED':
						incomeSourceName = $scope.customerInfo.incomeSources.sourceName || '';
						break;
					default:
						incomeSourceName = $scope.customerInfo.incomeSources.income;
				}
			}

		


			if ($scope.customerInfo.incomeSources.monthlyIncome) {
				monthlyIncome = $scope.customerInfo.incomeSources.monthlyIncome.replace('$', '');
				console.log('monthlyIncome ::: ' + monthlyIncome);
			}


			if ($scope.coCustomerInfo.incomeSources.takeHomePay) {
				cctakeHomePay = $scope.coCustomerInfo.incomeSources.takeHomePay.replace('$', '');
				console.log('takehomepay ::: ' + monthlyIncome);
			}

			/*if (_($scope.customerInfo.phone1).has('bestCallTime')) {
				if ($scope.customerInfo.phone1.bestCallTime.Morning === 'Yes') {
					bestCallTime += 'M';
				}
				if ($scope.customerInfo.phone1.bestCallTime.Afternoon === 'Yes') {
					if (bestCallTime.length > 0) bestCallTime += ', ';
					bestCallTime += 'A';
				}
				if ($scope.customerInfo.phone1.bestCallTime.Evening === 'Yes') {
					if (bestCallTime.length > 0) bestCallTime += ', ';
					bestCallTime += 'E';
				}
			}*/

			var incType = $scope.customerInfo.incomeSources.income;

			
			// Check if listed keys exist in form; for saving form properly
			if(!$scope.custAdditionalInfo.noCocustomer){
				if ($scope.coCustomerPrivate.ssn && _.every(['ssn1', 'ssn2', 'ssn3'], _.partial(_.has, $scope.coCustomerPrivate.ssn))) {
					var SSN = $scope.coCustomerPrivate.ssn;
					if (SSN.ssn1.length === 3 && SSN.ssn2.length === 2 && SSN.ssn3.length === 4 ) {
						var ccSocial = $scope.coCustomerPrivate.ssn.ssn1 + $scope.coCustomerPrivate.ssn.ssn2 + $scope.coCustomerPrivate.ssn.ssn3;
					}
				}
			}else{
				var ccSocial = "";
			}
			

				var mortgageName,mortgagePhone,avgUtilities,leaseEnd, leaseTypeRent,apartmentComplex, makeandmodel,custPayment,homeType;
				if($scope.isSpanishStore){
						var currYear = (new Date()).getFullYear();
									// co customer income information number
						if (typeof $scope.coCustomerInfo.incomeSources.sourcePhone !== "undefined") {
							ccEmployerAreaCode = $scope.coCustomerInfo.incomeSources.sourcePhone.number.substr(0, 3);
							ccEmployerPhone = $scope.coCustomerInfo.incomeSources.sourcePhone.number.substr(3, 10);
						}

						// co customer income information income type
						if ($scope.coCustomerInfo.incomeSources.income) {
							switch ($scope.coCustomerInfo.incomeSources.income) {
								case 'EMPLOYED':
									ccIncomeSourceName = $scope.coCustomerInfo.incomeSources.sourceName || '';
									break;
								case 'SELFEMPLOYED':
									ccIncomeSourceName = $scope.coCustomerInfo.incomeSources.sourceName || '';
									break;
								default:
									ccIncomeSourceName = $scope.coCustomerInfo.incomeSources.income;
							}
						}

						if($scope.customerInfo.address != undefined){
							homeType = $scope.customerInfo.address.homeType;
						}

						if(!$scope.custAdditionalInfo.noCocustomer){
							if ($scope.coCustomerInfo.dob && _.every(['month', 'day', 'year'], _.partial(_.has, $scope.coCustomerInfo.dob))) {
								var CCdob = $scope.coCustomerInfo.dob.year + $scope.coCustomerInfo.dob.month + $scope.coCustomerInfo.dob.day;
							}
						}else{
							var CCdob = "";
						}
						

						if ($scope.coCustomerInfo.incomeSources.nextPayDate && _.every(['month', 'day'], _.partial(_.has, $scope.coCustomerInfo.incomeSources.nextPayDate))) {
							var CCnextPayDate =  currYear + $scope.coCustomerInfo.incomeSources.nextPayDate.month + $scope.coCustomerInfo.incomeSources.nextPayDate.day;
						}

						if ($scope.customerInfo.incomeSources.nextPayDate && _.every(['month', 'day'], _.partial(_.has, $scope.customerInfo.incomeSources.nextPayDate))) {
							var nextPayDate = currYear +  $scope.customerInfo.incomeSources.nextPayDate.month + $scope.customerInfo.incomeSources.nextPayDate.day;
						}

						var ccMoveInDate =  $scope.custAdditionalInfo.moveInDate.year + $scope.custAdditionalInfo.moveInDate.month + "01" ;

					if($scope.custAdditionalInfo.leaseEndDate.month && $scope.custAdditionalInfo.leaseEndDate.year){
						leaseEnd =  $scope.custAdditionalInfo.leaseEndDate.year + $scope.custAdditionalInfo.leaseEndDate.month + "01";
					}

					if($scope.custAdditionalInfo.auto.make && $scope.custAdditionalInfo.auto.model){
						makeandmodel = $scope.custAdditionalInfo.auto.make + "-" + $scope.custAdditionalInfo.auto.model;
					}else{
						makeandmodel = "";
					}
					

					if($scope.customerInfo.address){
						if($scope.customerInfo.address.homeType === 'RENT'){
							leaseTypeRent = $scope.custAdditionalInfo.rent.leaseTypeRent;
							apartmentComplex = $scope.custAdditionalInfo.rent.apmtComplex;

							mortgageName = $scope.custAdditionalInfo.ownRentInfo.mortgageCompanyRent;
							mortgagePhone = $scope.custAdditionalInfo.ownRentInfo.mortgageCompanyNumRent;
							avgUtilities = $scope.custAdditionalInfo.ownRentInfo.avgUtilitiesRent;
							custPayment = $scope.custAdditionalInfo.ownRentInfo.custPaymentAmountRent;
						}

						if($scope.customerInfo.address.homeType === 'OWN'){
							mortgageName = $scope.custAdditionalInfo.ownRentInfo.mortgageCompanyOwn;
							mortgagePhone = $scope.custAdditionalInfo.ownRentInfo.mortgageCompanyNumOwn;
							avgUtilities = $scope.custAdditionalInfo.ownRentInfo.avgUtilitiesOwn;
							custPayment = $scope.custAdditionalInfo.ownRentInfo.custPaymentAmountOwn;
							
						}
					}
					
			}
			

			var mData = {
				lastName: $scope.customerInfo.lastName,
				firstName: $scope.customerInfo.firstName,
				middleInitial: $scope.customerInfo.middleName,
				customerId: customerId,
				engagementId: engagementId,
				email: $scope.customerInfo.email,
				orderOriginator: "AWP",
				referer: "",
				//residenceType: $scope.customerInfo.address.homeType,
				residenceType: "OWN",
				primaryPhoneNumber: $scope.customerInfo.phone1.number,
				phones: [{
					areaCode: $scope.customerInfo.phone1.number.substr(0, 3),
					number: $scope.customerInfo.phone1.number.substr(3, 10),
					extension: '',
					phoneType: $scope.customerInfo.phone1.phoneType,
					bestCallTime: bestCallTime,
					contactName: '',
					phoneSet: "CUSTPHN1"
				}],
				languagePreference: "ENGLISH",
				address: [{
					addrLine1: $scope.customerInfo.address !== undefined ? $scope.customerInfo.address.addrLine1 : "",
					//Changes added for AC13229
					addrLine2: "",
					unit: $scope.customerInfo.address !== undefined ? $scope.customerInfo.address.addrLine2 : "",
					city: $scope.customerInfo.address !== undefined ? $scope.customerInfo.address.city : "",
					state: $scope.customerInfo.address !== undefined ? $scope.customerInfo.address.state : "",
					zipCode: $scope.customerInfo.address !== undefined ? $scope.customerInfo.address.zipCode : "",			
					

				}],
				driversLicense: {
					id: $scope.customerInfo.driversLicense !== undefined ? $scope.customerInfo.driversLicense.id : "",
					issuer: $scope.customerInfo.driversLicense !== undefined && ($scope.customerInfo.driversLicense.idType == 1 || $scope.customerInfo.driversLicense.idType == 2) ? $scope.customerInfo.driversLicense.issuer : "",
					idType: $scope.customerInfo.driversLicense !== undefined ? $scope.customerInfo.driversLicense.idType : "1"
				},
				incomeSources: [{
					incomeSourceSet: "INCSRC1",
					sourcePhone: {
						phoneType: 'HOME',
						areaCode: employerAreaCode,
						number: employerPhone,
						extension: '',
						bestCallTime: 'xxx'
					},
					sourceStartDate: '',
					incomeType: incType,
					sourceName: incomeSourceName,
					monthlyIncome: monthlyIncome,
					sourceAddress : {
						addrLine1 : $scope.customerInfo.incomeSources.employerAddress
					},
					paydayFrequency : $scope.customerInfo.incomeSources.payFrequency,
					sourceDepartment : $scope.customerInfo.incomeSources.deptTitle,
					workHours : $scope.customerInfo.incomeSources.workHours,
					supervisorName :$scope.customerInfo.incomeSources.supervisorName,
					bestPayDate : $scope.customerInfo.incomeSources.bestPayDate,
					nextPayday : nextPayDate !== undefined ? nextPayDate : ""
				}]		
			};

			$scope.customerName = mData.firstName + ' ' + mData.lastName;

		 	if($scope.isSpanishStore){
		 		mData.customerType = "REFERENCE";
		 		mData.additionalInfo = {
		 			isCoCustomerIncomeSourceProvided : $scope.custAdditionalInfo.noCocustomerIncome !== undefined ? !$scope.custAdditionalInfo.noCocustomerIncome : "",
					previousAddress : [{
						addrLine1 : $scope.custAdditionalInfo.previousAddress.addrLine1 !== undefined ? $scope.custAdditionalInfo.previousAddress.addrLine1 : "",
						landLordName : $scope.custAdditionalInfo.previousAddress.mortgageName !== undefined ? $scope.custAdditionalInfo.previousAddress.mortgageName : "",
						landLordNumber : $scope.custAdditionalInfo.previousAddress.mortgagePhone !== undefined ? $scope.custAdditionalInfo.previousAddress.mortgagePhone : ""
					}],
					autoMakeAndModel : makeandmodel !== undefined ? makeandmodel :"",
					tag : $scope.custAdditionalInfo.auto.tag !== undefined ? $scope.custAdditionalInfo.auto.tag : "",
					color : $scope.custAdditionalInfo.auto.color !== undefined ? $scope.custAdditionalInfo.auto.color : "",
					carPaymentAmount : $scope.custAdditionalInfo.auto.amount !== undefined ? $scope.custAdditionalInfo.auto.amount : "",
					averageMonthlyUtilities : avgUtilities !== undefined ? avgUtilities :"",
					isCarDetailsProvided: !$scope.custAdditionalInfo.auto.noCar,
					customerPaymentAmount : custPayment !== undefined ? custPayment : "",
					
				}

				mData.coCustomerInfo = {
					firstName : $scope.coCustomerInfo.firstName !== undefined ? $scope.coCustomerInfo.firstName : "",
					lastName : $scope.coCustomerInfo.lastName !== undefined ? $scope.coCustomerInfo.lastName : "",
					//middleInitial : $scope.coCustomerInfo.middleName !== undefined ? $scope.coCustomerInfo.middleName: "",
					birthDate : CCdob !== undefined ? CCdob : "",
					//custInfoType : 'NAME2',
					relationshipType : $scope.coCustomerInfo.relation !== undefined ? $scope.coCustomerInfo.relation: "",
					phoneNumber : $scope.coCustomerInfo.phone1.number !== undefined ? $scope.coCustomerInfo.phone1.number : "", 
					phoneType : $scope.coCustomerInfo.phone1.phoneType !== undefined ? $scope.coCustomerInfo.phone1.phoneType : "",
					//social : ccSocial
					//driversLicense: {
						//id: $scope.coCustomerInfo.driversLicense !== undefined ? $scope.coCustomerInfo.driversLicense.id : "",
						//issuer: $scope.coCustomerInfo.driversLicense !== undefined ? $scope.coCustomerInfo.driversLicense.issuer : "",
						//idType: $scope.coCustomerInfo.driversLicense !== undefined ? $scope.coCustomerInfo.driversLicense.idType : "1"
					//}
				}

				if($scope.customerInfo){
				 	if($scope.customerInfo.coCustomerInfo) {
						if(!$scope.customerInfo.coCustomerInfo.social){
							mData.coCustomerInfo.social = ccSocial;
						} else if($scope.customerInfo.coCustomerInfo.social !== $scope.coCustomerPrivate.ssn.ssn3) {
							mData.coCustomerInfo.social = ccSocial;
						}
					} else {
						mData.coCustomerInfo.social = ccSocial;
					}
				}
				

				mData.isCoCustomerInfoProvided = $scope.custAdditionalInfo.noCocustomer !== undefined ? !$scope.custAdditionalInfo.noCocustomer : "";
				
		 		
		 		mData.address[0].leaseType =  leaseTypeRent !== undefined ? leaseTypeRent : "" ;
				mData.address[0].landLordName =  mortgageName !== undefined ? mortgageName : "";
				mData.address[0].landLordNumber =  mortgagePhone !== undefined ? mortgagePhone : "";
				mData.address[0].apartmentComplex =  apartmentComplex !== undefined ? apartmentComplex :"";
				mData.address[0].moveInDate =  ccMoveInDate;
				mData.address[0].leaseEndDate =  leaseEnd !== undefined ? leaseEnd : "";
				mData.address[0].isRenewingLease =  homeType !== undefined ? homeType: "";
				mData.address[0].addressType = "HOME";
		 		//mData.additionalInfo.customerPaymentAmount = $scope.customerInfo.ownRentInfo.custPaymentAmount !== undefined ? $scope.customerInfo.ownRentInfo.custPaymentAmount : "";
		 	
		 		if(!$scope.custAdditionalInfo.noCocustomerIncome && $scope.coCustomerInfo.incomeSources.income){
		 			mData.additionalInfo.coCustomerIncomeSource = [{
		 				incomeType : $scope.coCustomerInfo.incomeSources.income,
						sourceName : ccIncomeSourceName,
						monthlyIncome : cctakeHomePay !== undefined ? cctakeHomePay : "",
						paydayFrequency :$scope.coCustomerInfo.incomeSources.payFrequency !== undefined ? $scope.coCustomerInfo.incomeSources.payFrequency: "",
						sourcePhone : null,
						sourceAddress : null,
						sourceDepartment : null,
						supervisorName : null,
						workHours : null,
						nextPayday : null,
						sourceStartDate : ''
		 			}];
		 		}
		 		if($scope.coCustomerInfo.incomeSources.income === $scope.incomeEnums[1].type && !$scope.custAdditionalInfo.noCocustomerIncome){
						
						if($scope.coCustomerInfo.incomeSources && $scope.coCustomerInfo.incomeSources.length > 0){
							$scope.coCustomerInfo.incomeSources[0].sourceStartDate = '';
						} else {
							$scope.coCustomerInfo.incomeSources = [];
							$scope.coCustomerInfo.incomeSources[0] = {
								sourceStartDate : ''
							};
						}
						
						mData.additionalInfo.coCustomerIncomeSource[0].sourceStartDate = '';
				} 
				if (!$scope.custAdditionalInfo.noCocustomerIncome && $scope.coCustomerInfo.incomeSources.sourceStartDate !== '') {
						mData.additionalInfo.coCustomerIncomeSource = [{
							sourcePhone: {
								phoneType: 'HOME',
								areaCode: ccEmployerAreaCode,
								number: ccEmployerPhone,
									extension: '',
									bestCallTime: 'xxx'
							},
							sourceAddress : {
								addrLine1 : $scope.coCustomerInfo.incomeSources.employerAddress !== undefined ? $scope.coCustomerInfo.incomeSources.employerAddress : "",
							},
							incomeType : $scope.coCustomerInfo.incomeSources.income,
							sourceName : ccIncomeSourceName,
							monthlyIncome : cctakeHomePay !== undefined ? cctakeHomePay : "",
							sourceDepartment : $scope.coCustomerInfo.incomeSources.deptTitle !== undefined ? $scope.coCustomerInfo.incomeSources.deptTitle: "",
							supervisorName : $scope.coCustomerInfo.incomeSources.supervisorName !== undefined ? $scope.coCustomerInfo.incomeSources.supervisorName : "",
							workHours : $scope.customerInfo.incomeSources.workHours !== undefined ? $scope.customerInfo.incomeSources.workHours: ""	,
							nextPayday : CCnextPayDate	!== undefined ? CCnextPayDate : "",
							paydayFrequency :$scope.coCustomerInfo.incomeSources.payFrequency !== undefined ? $scope.coCustomerInfo.incomeSources.payFrequency: "",
							sourceStartDate : moment().subtract('months', $scope.customerInfo.incomeSources.sourceStartDate).format("YYYYMMDD")
						}];
				}

		 	}

			// Check if listed keys exist in form; for saving form properly
			if ($scope.customerPrivate.ssn && _.every(['ssn1', 'ssn2', 'ssn3'], _.partial(_.has, $scope.customerPrivate.ssn))) {
				var SSN = $scope.customerPrivate.ssn;
				if (SSN.ssn1.length === 3 && SSN.ssn2.length === 2 && SSN.ssn3.length === 4) {
					mData.social = $scope.customerPrivate.ssn.ssn1 + $scope.customerPrivate.ssn.ssn2 + $scope.customerPrivate.ssn.ssn3;
				}
			}

			if ($scope.customerPrivate.ssn.ssnType == 'ITIN') {
				mData.itinFlag = true;		
				if ($scope.customerPrivate.itin && _.every(['itin1', 'itin2', 'itin3'], _.partial(_.has, $scope.customerPrivate.itin))) {
					var itin = $scope.customerPrivate.itin;
					if (itin.itin1.length === 3 && itin.itin2.length === 2 && itin.itin3.length === 4) {
						mData.social = $scope.customerPrivate.itin.itin1 + $scope.customerPrivate.itin.itin2 + $scope.customerPrivate.itin.itin3;
					}
				}		
			} else {
				mData.itinFlag = false;
			}

			if ($scope.customerPrivate.dob && _.every(['month', 'day', 'year'], _.partial(_.has, $scope.customerPrivate.dob))) {
				mData.driversLicense.birthDate = $scope.customerPrivate.dob.year + $scope.customerPrivate.dob.month + $scope.customerPrivate.dob.day;
			}
			
			//if($scope.storeInfo.isReferenceRequired === true) {
				if($scope.isSpanishStore){
					var referenceCount = 4;
				}else{
					var referenceCount = 3;
				}
			//}

			var referencesArray = [];
			for (var i = 0; i < referenceCount; i++) {
				var formReference = $scope['ref' + (i + 1)],reference,set;
				if(formReference){
						if(i == 3 && !formReference.number){
						formReference.number = $scope.customerInfoForm.newRefs.ref4Number.$modelValue;
					}
					//refNum = $scope.refPhones[i].number;
					set = "PERREF" + (i + 1);
					if (_(formReference).has('personalReferenceType') && formReference.personalReferenceType !== '') {
						reference = {
							lastName: formReference.lastName,
							firstName: formReference.firstName,
							personalReferenceType: formReference.personalReferenceType,
							address: [{
								addrLine1: formReference.address,
								addressType: "HOME"
							}],
							personalReferenceSet : set,
							phone: {
								areaCode: formReference.number.substr(0, 3),
								number: formReference.number.substr(3, 10),
								//areaCode: refNum.substr(0,3),
								//number : refNum.substr(3,10),
								phoneType: formReference.phoneType,
								bestCallTime: ''
							}
						};
						referencesArray.push(reference);
					}
				}
			}
			mData.personalReferences = referencesArray;

			if (_($scope.customerInfo).has('phone2')
				&& _($scope.customerInfo.phone2).has('number')
				&& $scope.customerInfo.phone2.number.length > 9) {
				var phone2 = {
					areaCode: $scope.customerInfo.phone2.number.substr(0, 3),
					number: $scope.customerInfo.phone2.number.substr(3, 10),
					extension: '',
					phoneType: $scope.customerInfo.phone2.phoneType,
					contactName: '',
					phoneSet: "CUSTPHN2"
				};
				mData.phones.push(phone2);
			}

			if($scope.customerInfo.incomeSources.income == $scope.incomeEnums[1].type){
				$scope.customerInfo.incomeSources.sourceStartDate =  '';
				mData.incomeSources[0].sourceStartDate = '';
			} 
			if ($scope.customerInfo.incomeSources.sourceStartDate !== '') {
				mData.incomeSources[0].sourceStartDate = moment().subtract('months', $scope.customerInfo.incomeSources.sourceStartDate).format("YYYYMMDD");
			} 

			
			console.log("create requesr-------> " + mData);

			// save to /customer
			applicationService.saveApplication(mData)
				.then(
				function (response) {
					deferred.resolve('success');
				},
				function (error) {
					// sending error to Raygun
					SharedDataSvc.reportRaygun(error);
					if (error.data.errorData.status_message === 'SSN invalid') {
						modalService.error(error.data.errorData.status_message);
					}
					deferred.reject(error);
				}
				);

			return deferred.promise;
		}

		function processCustomerApp() {
			showProcessingModal('Processing Application');
			SharedDataSvc.hasPAmodalRun = true;
			var incomeSources;
			//generateKeys();

			if($scope.storeInfo.isReferenceRequired !== false) {
				// re-map referenceInfo
				// ref1, ref2, ref3
				var custRefsScope = angular.element(document.getElementById('newCustReferences')).scope();
				var phone1ex1 = custRefsScope.ref1.number.replace(/\D+/g, "");
				var phone2ex1 = custRefsScope.ref2.number.replace(/\D+/g, "");
				var phone3ex1 = custRefsScope.ref3.number.replace(/\D+/g, "");

				$scope.customerInfo.personalReferences = [{
					firstName: custRefsScope.ref1.firstName, //"John",
					lastName: custRefsScope.ref1.lastName, //"Smith",
					phone: phone1ex1, //"513-123-5432",
					relationship: custRefsScope.ref1.personalReferenceType //"PARENT"
				}, {
					firstName: custRefsScope.ref2.firstName, //"John",
					lastName: custRefsScope.ref2.lastName, //"Smith",
					phone: phone2ex1, //"513-123-5432",
					relationship: custRefsScope.ref2.personalReferenceType //"PARENT"
				}, {
					firstName: custRefsScope.ref3.firstName, //"John",
					lastName: custRefsScope.ref3.lastName, //"Smith",
					phone: phone3ex1, //"513-123-5432",
					relationship: custRefsScope.ref3.personalReferenceType //"PARENT"
				}];
			}

			//re-map incomeSources
			var newCustEmpInfoScope = angular.element(document.getElementById('iSourcesNew')).scope();

			var incomeSourceNumber = $scope.customerInfo.incomeSources.sourcePhone ? $scope.customerInfo.incomeSources.sourcePhone.number : "",
				incomeSourceName = $scope.customerInfo.incomeSources.sourceName || "",
				monthlyIncome = $scope.customerInfo.incomeSources.monthlyIncome || 0;

			var incType = $scope.customerInfo.incomeSources.income;
			//AC 26654 code changes
			if ($scope.customerInfo.incomeSources.income) {
				switch ($scope.customerInfo.incomeSources.income) {
					case 'EMPLOYED':
						incomeSourceName = $scope.customerInfo.incomeSources.sourceName || '';
						break;
					case 'SELFEMPLOYED':
						incomeSourceName = $scope.customerInfo.incomeSources.sourceName || '';
						break;
					default:
						incomeSourceName = '';
						incomeSourceNumber = '';
				}
			}

			var incomeSources = [{
				incomeType: incType,
				employer: incomeSourceName, // "Deloitte",
				employerAddress: "",
				employerCity: "",
				employerState: "",
				employerZip: "",
				employerDept: "",
				monthlySalary: monthlyIncome,
				employerPhone: incomeSourceNumber.replace(/\D+/g, "")
			}];
			console.log("customerInfo", $scope.customerInfo);

			// get manned/unmanned status
			var appFlowType = '';
			if (SharedDataSvc.globalVars.storeSetup.isManned === false) {
				appFlowType = 'UNMANNED';
			} else {
				appFlowType = 'MANNED';
			}


			// map all the disparate data into customerApp
			var customerId = SharedDataSvc.globalVars.order.currentEngagement.VANCustomerID || SharedDataSvc.globalVars.order.currentEngagement.customerId;
			var engagementId = SharedDataSvc.globalVars.order.currentEngagement.VANEngagementId || SharedDataSvc.globalVars.order.currentEngagement.engagementId;


			SharedDataSvc.globalVars.ApplicationSave = {
				sessionId: '123456',
				salesResourceId: '000001', // salesResoureceId changed to salesResourceId as per Service team requirement
				customerId: customerId,
				engagementId: engagementId,
				orderOriginator: "AWP",
				approvalFlowType: appFlowType,
				bankingInfo: {
					routingNumber: "",
					accountNumber: ""
				},
				idInfo: {
					idIssuingState: $scope.customerInfo.driversLicense.issuer,
					idNumber: $scope.customerInfo.driversLicense.id,
					idType: $scope.customerInfo.driversLicense.idType,
					//Defect 28124 - AWP - ITIN is not getting passed for AWP 
					//idSSN: $scope.customerPrivate.ssn.ssn1 + $scope.customerPrivate.ssn.ssn2 + $scope.customerPrivate.ssn.ssn3 || 
					//$scope.customerPrivate.itin.itin1 + $scope.customerPrivate.itin.itin2 + $scope.customerPrivate.itin.itin3, 
					idSSN: $scope.customerPrivate.ssn.ssnType == 'SSN'? ($scope.customerPrivate.ssn.ssn1 + $scope.customerPrivate.ssn.ssn2 + $scope.customerPrivate.ssn.ssn3):($scope.customerPrivate.itin.itin1 + $scope.customerPrivate.itin.itin2 + $scope.customerPrivate.itin.itin3),
					//					idDOB: $scope.customerPrivate.dob.month + $scope.customerPrivate.dob.day + $scope.customerPrivate.dob.year,
					idDOB: $scope.customerPrivate.dob.year + $scope.customerPrivate.dob.month + $scope.customerPrivate.dob.day,
					idLastName: $scope.customerInfo.lastName,
					idFirstName: $scope.customerInfo.firstName,
					// leaving out 'middle name/initial'
					idMiddleName: $scope.customerInfo.middleName,
					idPhone: $scope.customerInfo.phone1.number.replace(/\D+/g, ""),
					//AC24574  Code changes starts
					idPrimaryPhoneType: $scope.customerInfo.phone1.phoneType,
					idSecondaryPhone: $scope.customerInfo.phone2 && $scope.customerInfo.phone2.number ? $scope.customerInfo.phone2.number.replace(/\D+/g, "") : "",
					idSecondaryPhoneType: $scope.customerInfo.phone2 && $scope.customerInfo.phone2.phoneType ? $scope.customerInfo.phone2.phoneType : "",
					//AC24574  Code change ends
					// idPhone: "310-9030945" ,
					idAddress1: $scope.customerInfo.address.addrLine1,
					idAddress2: $scope.customerInfo.address.addrLine2,
					idCity: $scope.customerInfo.address.city,
					idState: $scope.customerInfo.address.state,
					idZip: $scope.customerInfo.address.zipCode.replace(/\D+/g, ""),
					idEmail: $scope.customerInfo.email
				},
				employmentInfo: incomeSources[0],
//				kountSessionId: $rootScope.kountSessionId,//VAN-3128-KOUNT implementation VA/AWP
				ipAddress:$scope.ipaddress//VAN-2844-Capture clients IPAddress for Virtual approvals and AWP
			};

			if($scope.storeInfo.isReferenceRequired !== false) {
				SharedDataSvc.globalVars.ApplicationSave.referenceInfo = [{
					firstName: $scope.customerInfo.personalReferences[0].firstName, //"John",
					lastName: $scope.customerInfo.personalReferences[0].lastName, //"Smith",
					phone: $scope.customerInfo.personalReferences[0].phone, //"513-123-5432",
					relationship: $scope.customerInfo.personalReferences[0].relationship //"PARENT"
				}, {
					firstName: $scope.customerInfo.personalReferences[1].firstName, //"John",
					lastName: $scope.customerInfo.personalReferences[1].lastName, //"Smith",
					phone: $scope.customerInfo.personalReferences[1].phone, //"513-123-5432",
					relationship: $scope.customerInfo.personalReferences[1].relationship
				}, {
					firstName: $scope.customerInfo.personalReferences[2].firstName, //"John",
					lastName: $scope.customerInfo.personalReferences[2].lastName, //"Smith",
					phone: $scope.customerInfo.personalReferences[2].phone, //"513-123-5432",
					relationship: $scope.customerInfo.personalReferences[2].relationship
				}]
			};


			//sending validated ACH info
			/*if ($scope.storeInfo.achVerification && $scope.customerInfoForACH) {
				SharedDataSvc.globalVars.ApplicationSave.bankingInfo.routingNumber = $scope.customerInfoForACH.bankingInfo.routingNumber;
				SharedDataSvc.globalVars.ApplicationSave.bankingInfo.accountNumber = $scope.customerInfoForACH.bankingInfo.accountNumber;
			}*/

			if($scope.storeInfo.achVerification && $scope.bankingInfoFormForACH) {
				SharedDataSvc.globalVars.ApplicationSave.bankingInfo.routingNumber = $scope.bankingInfoData.routingNumber;
				SharedDataSvc.globalVars.ApplicationSave.bankingInfo.accountNumber = $scope.bankingInfoData.accountNumber;
				$scope.challenge.routingNumber = $scope.bankingInfoData.routingNumber;
				$scope.challenge.accountNumber = $scope.bankingInfoData.accountNumber;
			}

			if($scope.isApprovalByCatSelected){
				var catList = []
				
				if($scope.approvalByCatList.length > 1){
					for(var z=0; z < $scope.approvalByCatList.length ; z++){
						var tempObj = {
							"isSelected": $scope.approvalByCatList[z].isSelected,
							"category": $scope.approvalByCatList[z].category
						}
						catList.push(tempObj);	
					}
				}else{
					var tempObj = {
						"isSelected": true,
						"category": $scope.approvalByCatList[0].category
					}
					catList.push(tempObj);
				}
				

				SharedDataSvc.globalVars.ApplicationSave.productCategories = catList;
			}

			// Sending data as object instead of JSON
			//var mDataObject = angular.toJson(SharedDataSvc.globalVars.ApplicationSave);
			//console.log(mDataObject);
			var mData = SharedDataSvc.globalVars.ApplicationSave;


			// POST into HT

			applicationService.processApp(mData)
				.then(
				function (response) {
					applicationResultAction(response);
				},
				function (error) {
					// sending error to Raygun
					SharedDataSvc.reportRaygun(error);
					console.log(error);
				}
				);

		}

		function provideSSN() {
			var existingCustSSNModal = modalService.open({
				title: 'Customer Verification',
				templateUrl: 'partials/modal_custExistsSsnVerify.tpl.html',
				scope: $scope,
				backdrop: 'static'
			});
			existingCustSSNModal.result
				.then(function (data) {
					var person = $scope.personData;
					$scope.ssnNumber = data.ssn1 + data.ssn2 + data.ssn3;
					person.ssn = data.ssn1 + data.ssn2 + data.ssn3;
					retrieveApplication(person);
				});
		}

		function confirmEmail() {
			var confirmEmailModal = modalService.open({
				title: 'Confirm Email',
				templateUrl: 'partials/modal_confirmEmail.tpl.html',
				scope: $scope,
				backdrop: 'static'
			});
			confirmEmailModal.result
				.then(function (data) {
					$scope.customerPrivate.textCodeGenerateAttemptsNumber = 0;
					generateTextCode();
				});
		}

		function generateTextCode() {
			if ($scope.customerPrivate.textCodeGenerateAttemptsNumber >= 3) {
				var errorTextCodeModal = modalService.open({
					title: 'Error Confirm Email',
					templateUrl: 'partials/modal_confirmEmailError.html',
					scope: $scope,
					backdrop: 'static'
				});
				return;
			}

			$scope.customerPrivate.textCode = '';
			var mData = {
				"toNumber": [],
				"languagePreference": "ENGLISH",
				"type": '',
				"emailId": ''
			}

			if ($scope.customerInfo.phone1 && $scope.customerInfo.phone1.number != undefined &&
				($scope.customerPrivate.verificationType == 'PText' ||
					$scope.customerPrivate.verificationType == 'PVoice')) {
				mData.toNumber.push($scope.customerInfo.phone1.number);
			} else if ($scope.customerInfo.phone2 && $scope.customerInfo.phone2.number != undefined &&
				($scope.customerPrivate.verificationType == 'SText' ||
					$scope.customerPrivate.verificationType == 'SVoice')) {
				mData.toNumber.push($scope.customerInfo.phone2.number);
			}

			if ($scope.customerPrivate.verificationType == 'PText' || $scope.customerPrivate.verificationType == 'SText') {
				mData.type = 'TEXT';
			}
			else if ($scope.customerPrivate.verificationType == 'PVoice' || $scope.customerPrivate.verificationType == 'SVoice') {
				mData.type = 'VOICE';
			} else {
				mData.type = 'EMAIL';
			}

			mData.emailId = $scope.customerInfo.email;


			applicationService.validatePhone(mData).then(
				function (response) {
					$scope.textCode = response;
					$scope.customerPrivate.textCodeGenerateAttemptsNumber += 1;
					var submitTextCodeModal = modalService.open({
						title: 'Confirm Email',
						templateUrl: 'partials/modal_confirmEmailTextCode.tpl.html',
						scope: $scope,
						backdrop: 'static'
					});
				},
				// Error
				function (error) {
					// sending error to Raygun
					SharedDataSvc.reportRaygun(error);
					console.log(error);
				});
		}

		function publishOverrideData(data){
			var Data = {
				"customerId": data.customerId,
				"engagementId": data.engagementId,
				"storeId": data.storeId,
				"overrideType": data.overrideType,
				"associateName": data.associateName,
				"associateId": data.associateId,
				"associatePassword": data.associatePassword,
				"comments": data.comments,
				"agreementNumber": data.agreementNumber
			}

			var obj = {};
			obj.message = {};
			obj.message.override = [];
			obj.message.override[0] = Data;
			
			applicationService.publishOverrideDataMasterCode(obj).then(
				function (response) {
					console.log('publishOverrideDataMasterCode - response' + JSON.stringify(mData));
				},
				// Error
				function (error) {
					// sending error to Raygun
					SharedDataSvc.reportRaygun(error);
					console.log(error);
				});
		}

		function cancelTextCode() {
			$scope.showVerficationWarning = true;
		}

		function submitTextCode() {
			$scope.customerPrivate.textCodeError = false;
			$scope.customerPrivate.textCodeAttemptsNumber += 1;
			if ($scope.textCode == $scope.customerPrivate.textCode && $scope.customerPrivate.textCodeAttemptsNumber <= 3) {
				modalService.dismiss();
				processCustomerApp();
			} else if ($scope.customerPrivate.textCodeAttemptsNumber == 3) {
				$scope.customerPrivate.textCodeAttemptsNumber = 0;
				$scope.customerPrivate.textCode = '';
				if ($scope.customerPrivate.textCodeGenerateAttemptsNumber >= 3) {
					var errorTextCodeModal = modalService.open({
						title: 'Error Confirm Email',
						templateUrl: 'partials/modal_confirmEmailError.html',
						scope: $scope,
						backdrop: 'static'
					});
				} else {
					modalService.dismiss();
					$scope.showVerficationWarning = true;
					/*var confirmEmailModalTooManyPinAttempts = modalService.open({
						title: 'Confirm Email',
						templateUrl: 'partials/modal_confirmEmailTooManyPinAttempts.tpl.html',
						scope: $scope,
						backdrop: 'static'
					});*/
				}
			}
			else {
				$scope.customerPrivate.textCodeError = true;
				$scope.customerPrivate.textCode = '';
			}
		}

		function submitMasterTextCode(){
				var mData = {
					"customerId": SharedDataSvc.cutomerInfoForMasterCode.customerId,
					"engagementId": SharedDataSvc.cutomerInfoForMasterCode.VANEngagementId,
					"storeId": $scope.storeNo,
					"overrideType": 1,
					"associateName": $scope.customerPrivate.masterCode.employeeName,
					"associateId": $scope.customerPrivate.masterCode.employeeId,
					"associatePassword": SharedDataSvc.masterCodePassphraseUsed,
					"comments": $scope.customerPrivate.masterCode.comments,
					"agreementNumber": ''
				}

				publishOverrideData(mData);
				modalService.dismiss();
				processCustomerApp();
		}

		function provideBankingInfo() {
			if (SharedDataSvc.hasBImodalRun === false) {
				$scope.bankingAppModal = modalService.open({
					title: 'Provide Banking Info',
					templateUrl: 'partials/modal_appBankingInfo.tpl.html',
					backdrop: 'static',
					scope: $scope
				});
				$scope.bankingAppModal.result.then(submitBankingInfo);
				SharedDataSvc.hasBImodalRun = true;
			}
		}

		function handleSaveAndExit() {
			var handleSaveAndExitModal = modalService.open({
				title: 'Provide Banking Info',
				templateUrl: 'partials/modal_saveAndExit.html',
				backdrop: 'static',
				scope: $scope,
				windowClass: 'save-exit-modal'
			});

			handleSaveAndExitModal.result.then(function() {
				console.log("Customer Name", $scope.customerName);
				SharedDataSvc.searchValue = $scope.customerName;
				navigateTo('dashboard');
			});
		}

		function submitBankingInfo(bankingInfo) {
			modalService.processing('Checking Banking Information');
			// grab both numbers and throw into existing ApplicationSave
			SharedDataSvc.globalVars.ApplicationSave.bankingInfo.routingNumber = bankingInfo.routingNumber;
			SharedDataSvc.globalVars.ApplicationSave.bankingInfo.accountNumber = bankingInfo.accountNumber;

			// Sending data as object instead of JSON
			//var sData = angular.toJson(SharedDataSvc.globalVars.ApplicationSave);
			var sData = SharedDataSvc.globalVars.ApplicationSave;
			// return;


			applicationService.processApp(sData).then(
				// Success
				function (response) {
					applicationResultAction(response);
				},
				// Error
				function (error) {
					// sending error to Raygun
					SharedDataSvc.reportRaygun(error);
					console.log(error);
				}
			);
		}

		// How to react based on response from the application
		function applicationResultAction(responseData) {
			$scope.errorInValidateBI = false;
			$scope.errorInValidateBIMsg = undefined;
			var resultString = responseData.response;
			hideProcessingModal();
			console.log(responseData);
			// test for 'retry' response
			// if 'retry', then re-submit WITH banking info
			if (resultString === 'BANKINGRETRY' || resultString === 'RETRY') {
				// RETRY scenario
				SharedDataSvc.hasBImodalRun = false;
				SharedDataSvc.hasPAmodalRun = false;
				$scope.errorInValidateBI = true;
				$scope.errorInValidateBIMsg = responseData.decisionDetails;
				provideBankingInfo();
			} else if (resultString === 'APPROVE' || resultString === 'Approve' || resultString === 'APPROVE_BANK') {//VAN-3284-Prequalify customer for Charge After
				// APPROVE scenario
				$scope.challenge.approvedAmt = parseInt(responseData.amount);
				$scope.challenge.originalApprovedAmt = parseInt(responseData.originalApprovalAmount);
				$scope.challenge.outstandingAmt = parseInt(responseData.outstandingBalance);
				SharedDataSvc.globalVars.order.currentEngagement.approvalAmount = parseInt(responseData.amount);


				SharedDataSvc.globalVars.order.currentEngagement.applicationStatus = 'Accepted';
				if (typeof $scope.bankingAppModal !== "undefined") {
					$scope.bankingAppModal.dismiss();
				}


				$scope.engagementId = SharedDataSvc.globalVars.order.currentEngagement.VANEngagementId || SharedDataSvc.globalVars.order.currentEngagement.engagementId;


				if ((parseInt(responseData.amount) < parseInt($scope.agreementAmount)) && !$scope.isSpanishStore) {
					$scope.storePhoneNumber = SharedDataSvc.globalVars.storeSetup.vipPhoneNumber; //AC-28614 Changes
					modalService.open({
						title: 'Approval amount is less than minimum agreement amount',
						templateUrl: 'partials/modal_approvedBelowMin.tpl.html',
						backdrop: 'static',
						windowClass: 'approved-modal',
						scope: $scope
					});
				} else {
					// fire-off the APPROVED modal and fill it with info
					var approvedAmountModal = modalService.open({
						title: 'Application Approved',
						templateUrl: 'partials/alert_approvedForAmount.tpl.html',
						backdrop: 'static',
						windowClass: 'approved-modal',
						scope: $scope
					});
					approvedAmountModal.result
						.then(function () {
							//Story 33452 - AWP take customer to missing info at agreement starts
							if($rootScope.isSLICustomer){
								navigateTo('ordersummary/' + SharedDataSvc.globalVars.currentSLICustEngId);
							} else {
								$location.path('dashboard');
							}
							//Story 33452 - AWP take customer to missing info at agreement ends
						});
				}
				/*  Removed this check to fix defect 25955
				if( parseInt(responseData.amount) >= parseInt(responseData.minimumApprovalAmount) ) {
					
				} */
			} else if (resultString === 'DECLINE') {
				// DECLINE
				if (typeof $scope.bankingAppModal !== "undefined") {
					$scope.bankingAppModal.dismiss();
				}
				$scope.declinedMessage = "We are not able to approve your application at this time. An explanatory letter will be sent to your e-mail address within 7 to 10 business days.";//Story 29310 Changes
				var appDeclinedModal = modalService.open({
					title: 'Application Declined',
					templateUrl: 'partials/alert_applicationDeclined.tpl.html',
					backdrop: 'static',
					scope: $scope
				});
				appDeclinedModal.result
					.then(function () {
						$location.path('dashboard');
					});

			} else if (resultString === 'ERROR') {
				// ERROR
				console.log('An Error occurred.');
			} else if (resultString === 'MANUAL_INTERVENTION') {
				// MANUAL_INTERVENTION
				if (responseData.decisionDetails === "Unable to retrieve telecheck score.") {
					$scope.storePhoneNumber = SharedDataSvc.globalVars.storeSetup.vipPhoneNumber; //AC-28614 Changes 
					modalService.open({
						templateUrl: 'partials/alert_appManualIntervention.tpl.html',
						backdrop: 'static',
						scope: $scope
					});
				} else {
					modalService.open({
						templateUrl: 'partials/alert_appAssociateReview.tpl.html',
						windowClass: 'modal-small',
						backdrop: 'static',
						scope: $scope
					});
				}
			}
		}

		function fillApplicationData(event, data) {
			$scope.customerInfo = jQuery.extend(true, {}, data);
			//$scope.customerInfo = data;
			//SharedDataSvc.customerInfo = data;
			var engagementData, customer;
			resetZipData();//Defect 26474 code change

			if ($routeParams.engagementId) {
				console.log(data);
				engagementData = data.VanEngagement;
				customer = engagementData.customer;

				var newCustomerInfo = {
					firstName: customer.fName,
					lastName: customer.lName,
					middleName: data.middleInitial,
					phone1: {
						number: customer.phNum
					},
					address: {
						state: customer.state,
						addrLine1: customer.addressLine1,
						//addrLine2: customer.addressLine2,
						addrLine2: customer.unit,
						city: customer.addressCity,
						zipCode: (customer.addressZipcode && customer.addressZipcode !== "00000") ? customer.addressZipcode.substring(0,5) : "",//Defect 26474 code change
					},
					email: customer.email,
					emailVerify: customer.email//Story 30445 -AWP, Kiosk: Add email verification edit box
				};
				$scope.customerInfo = _.merge($scope.customerInfo, newCustomerInfo);
				SharedDataSvc.customerInfo = $scope.customerInfo;
			}

			//filling field inputs
			$scope.customerInfoForm.firstName.$pristine = false;
			$scope.customerInfoForm.lastName.$pristine = false;

			//Story 30445 code changes starts
			if (data.email) {
				$scope.customerInfo.emailVerify = data.email;
			}
			//Story 30445 code changes ends
			if (data.address && data.address[0]) {

				$scope.customerInfo.address = {
					addrLine1: data.address[0].addrLine1,
					addrLine2: data.address[0].addrLine2,
					city: data.address[0].city,
					state: data.address[0].state,
					zipCode: (data.address[0].zipCode && data.address[0].zipCode !== "00000") ? data.address[0].zipCode.substring(0,5) : "" // Defect 26474 code change

				};

			}

			if (data.incomeSources && data.incomeSources.income) {
				$scope.customerInfo.monthlyIncome = data.incomeSources.income;
			}
			if (!$scope.customerInfo.monthlyIncome) {
				$scope.customerInfoForm.iSources.monthlyIncome.$pristine = true;
			} else {
				$scope.customerInfoForm.iSources.monthlyIncome.$pristine = false;
			}

			// dob
			if (data.driversLicense) {
				if (data.driversLicense.birthdate !== '') {
					var birthdate = data.driversLicense.birthDate;
					var dobYear = birthdate.substr(0, 4);
					var dobMonth = birthdate.substr(4, 2);
					var dobDay = birthdate.substr(6, 2);
					$scope.customerPrivate.dob = {
						month: dobMonth,
						day: dobDay,
						year: dobYear
					};
				}
			} else {
				$scope.customerInfo.driversLicense = {
					idType : "1"
				};
			}

			// SSN splicing
			if (data.social) {
				var fullSSN = data.social;

				if(data.itinFlag) {
					$scope.customerPrivate.ssn.ssnType = 'ITIN';
					$scope.customerPrivate.ssn.ssnTypeDisable = true;

					$scope.customerPrivate.itin = {};
					$scope.customerPrivate.itin.itin1 = fullSSN.slice(0, 3);
					$scope.customerPrivate.itin.itin2 = fullSSN.slice(3, 5);
					$scope.customerPrivate.itin.itin3 = fullSSN.slice(5, 9);

					$scope.customerPrivate.verifyitin = {};
					$scope.customerPrivate.verifyitin.itin1 = fullSSN.slice(0, 3);
					$scope.customerPrivate.verifyitin.itin2 = fullSSN.slice(3, 5);
					$scope.customerPrivate.verifyitin.itin3 = fullSSN.slice(5, 9);
				} else {
					$scope.customerPrivate.ssn.ssnType = 'SSN';
					$scope.customerPrivate.ssn.ssnTypeDisable = true;
					
					$scope.customerPrivate.ssn.ssn1 = fullSSN.slice(0, 3);
					$scope.customerPrivate.ssn.ssn2 = fullSSN.slice(3, 5);
					$scope.customerPrivate.ssn.ssn3 = fullSSN.slice(5, 9);

					$scope.customerPrivate.verifyssn.ssn1 = fullSSN.slice(0, 3);
					$scope.customerPrivate.verifyssn.ssn2 = fullSSN.slice(3, 5);
					$scope.customerPrivate.verifyssn.ssn3 = fullSSN.slice(5, 9);
				}
			} else if(data.itinFlag && !data.social){
				$scope.customerPrivate.ssn.ssnType = 'ITIN';
			}

			if ($scope.storeInfo.isReferenceRequired !== false && data.personalReferences && data.personalReferences.length > 0) {
				var refLength;
				if($scope.isSpanishStore){
					refLength = 4;
				}else{
					refLength = 3;
				}
				for (var i = 0; i < refLength; i++) {
					var refNum = i + 1;
					var reference = {};
					reference.firstName = data.personalReferences[i].firstName;
					reference.lastName = data.personalReferences[i].lastName;
					reference.personalReferenceType = data.personalReferences[i].personalReferenceType;
					reference.address = data.personalReferences[i].address[0].addrLine1;
					reference.number = data.personalReferences[i].phone.areaCode + data.personalReferences[i].phone.number;
					reference.phoneType = data.personalReferences[i].phone.phoneType;
					$scope['ref' + refNum] = reference;
					$scope.customerInfoForm.newRefs['ref' + refNum + 'FirstName'].$pristine = false;
					$scope.customerInfoForm.newRefs['ref' + refNum + 'LastName'].$pristine = false;
					$scope['ref' + refNum + 'NumberInvalid'] = false;
				}
			}


			if (data.phones) {
				if (data.phones[0].number.length > 0) {
					$scope.customerInfo.phone1 = {
						number: data.phones[0].areaCode + data.phones[0].number,
						phoneType: data.phones[0].phoneType || null,
						bestCallTime: {
							Morning: false,
							Afternoon: false,
							Evening: false
						}
					};

					/*if($scope.customerInfo.phone1.phoneType == 'CELL' || $scope.customerInfo.phone1.phoneType == 'WORK'){
						$timeout(function(){
							document.getElementById('workPhone').checked =true;
						},1000);
					} else if($scope.customerInfo.phone1.phoneType == 'HOME') {
						$timeout(function(){
							document.getElementById('homePhone').checked =true;
						},1000);
					}
					/*if (data.phones[0].bestCallTime) {
						var times = data.phones[0].bestCallTime.split(",");
						console.log("[Best call Times]", times);
						for (var x = 0; x < times.length; x++) {
							times[x] = times[x].trim();

							console.log(times[x]);

							if (times[x] == "M") {
								$scope.customerInfo.phone1.bestCallTime.Morning = "Yes";
							} else if (times[x] == "A") {
								$scope.customerInfo.phone1.bestCallTime.Afternoon = "Yes";
							} else if (times[x] == "E") {
								$scope.customerInfo.phone1.bestCallTime.Evening = "Yes";
							}
						}
					}*/
				}
				if (data.phones.length > 1 && data.phones[1].areaCode !== "000") {
					$scope.customerInfo.phone2 = {
						number: data.phones[1].areaCode + data.phones[1].number,
						phoneType: data.phones[1].phoneType || null
					};
				} else if (data.phones.length > 1 && data.phones[1].areaCode === "000" && data.phones[0].phoneType.toUpperCase() === "HOME" && $scope.allowTextVerificationFlag) {
					$scope.customerInfo.phone2 = {
						phoneType: "CELL"
					};
				}
			}

			if (data.incomeSources && data.incomeSources[0].incomeType !== null) {
				var incomeSource = data.incomeSources[0];
				$scope.customerInfo.incomeSources = {
					income: incomeSource.incomeType,
					monthlyIncome: incomeSource.monthlyIncome !== "000000" ? incomeSource.monthlyIncome : "",
					sourceName: incomeSource.sourceName !== "n/a" ? incomeSource.sourceName : "",
					sourcePhone: {
						number: (incomeSource.sourcePhone.areaCode !== "000" ? incomeSource.sourcePhone.areaCode : "") + (incomeSource.sourcePhone.number !== "0000000" ? incomeSource.sourcePhone.number : "")
					}
				};
				if (incomeSource.sourceStartDate !== "") {
					var startDateSelection;
					var monthThresholds = ['5', '11', '23', '30'];
					var startDate = moment(incomeSource.sourceStartDate, 'YYYYMMDD');
					var monthsSinceStart = moment().diff(startDate, 'months');
					for (var i = 0; i < monthThresholds.length; i++) {
						if (monthsSinceStart <= monthThresholds[i] && monthsSinceStart) {
							startDateSelection = monthThresholds[i];
							break;
						}
					}
					if (monthsSinceStart >= monthThresholds[monthThresholds.length - 1]) {
						startDateSelection = monthThresholds[monthThresholds.length - 1];
					}
					$scope.customerInfo.incomeSources.sourceStartDate = startDateSelection;
				}
			} else {
				console.log($scope.customerInfo);
				$scope.customerInfo.incomeSources = {};
				$scope.customerInfo.incomeSources.income = $scope.incomeEnums[0].type;
				//$scope.coCustomerInfo.incomeSources.income = $scope.incomeEnums[0].type;
				//$scope.customerInfo.phone2 = {};
			}

			//PR fields
			if($scope.isSpanishStore){
				if( data.isCoCustomerInfoProvided === undefined || data.isCoCustomerInfoProvided === true || data.isCoCustomerInfoProvided === null ){
					$scope.custAdditionalInfo.noCocustomer = false;
					//$scope.custAdditionalInfo.noCocustomerIncome = true;
				}else {
					$scope.custAdditionalInfo.noCocustomer = true;
					//$scope.custAdditionalInfo.noCocustomerIncome = true;
				}
				

				if(data.additionalInfo){
					if(data.additionalInfo.isCoCustomerIncomeSourceProvided === undefined || data.additionalInfo.isCoCustomerIncomeSourceProvided === true || data.additionalInfo.isCoCustomerIncomeSourceProvided === null) {
						$scope.custAdditionalInfo.noCocustomerIncome = false;
					} else {
						$scope.custAdditionalInfo.noCocustomerIncome = true;
					}
				
					if(data.additionalInfo.isCarDetailsProvided){
						if(data.additionalInfo.autoMakeAndModel){
							var model = data.additionalInfo.autoMakeAndModel.split('-');
							$scope.custAdditionalInfo.auto.make = model[0];
							$scope.custAdditionalInfo.auto.model = model[1];
						}
						if(data.additionalInfo.tag){
							$scope.custAdditionalInfo.auto.tag = data.additionalInfo.tag;
						}
						if(data.additionalInfo.carPaymentAmount){
							$scope.custAdditionalInfo.auto.amount = data.additionalInfo.carPaymentAmount;
						}
						if(data.additionalInfo.color){
							$scope.custAdditionalInfo.auto.color = data.additionalInfo.color;
						}
					}else{
						$scope.custAdditionalInfo.auto.noCar = true;
					}
					
					
					if(data.additionalInfo.previousAddress.addrLine1){
						$scope.custAdditionalInfo.previousAddress.addrLine1 = data.additionalInfo.previousAddress.addrLine1;
					}
					if(data.additionalInfo.previousAddress.landLordName){
						$scope.custAdditionalInfo.previousAddress.mortgageName = data.additionalInfo.previousAddress.landLordName;
					}

					if(data.additionalInfo.previousAddress.landLordNumber){
						$scope.custAdditionalInfo.previousAddress.mortgagePhone = data.additionalInfo.previousAddress.landLordNumber;
					}

					if(data.additionalInfo.previousAddress){
						$scope.showPreviousAdd = true;
						if(data.additionalInfo.previousAddress[0].addrLine1){
							$scope.custAdditionalInfo.previousAddress.addrLine1	 = data.additionalInfo.previousAddress[0].addrLine1;
						}
					
						if(data.additionalInfo.previousAddress[0].landLordName){
							$scope.custAdditionalInfo.previousAddress.mortgageName = data.additionalInfo.previousAddress[0].landLordName;
						}

						if(data.additionalInfo.previousAddress[0].landLordNumber){
							$scope.custAdditionalInfo.previousAddress.mortgagePhone = data.additionalInfo.previousAddress[0].landLordNumber;
						}
					}

					

					if(data.additionalInfo.coCustomerIncomeSource){
						//$scope.custAdditionalInfo.noCocustomerIncome =  false;
						var coCustIncomeInfo = data.additionalInfo.coCustomerIncomeSource[0];
						if(coCustIncomeInfo.sourcePhone){
							if (coCustIncomeInfo.sourcePhone.number > 1 && coCustIncomeInfo.sourcePhone.areaCode !== "000") {
								$scope.coCustomerInfo.incomeSources.sourcePhone = {
									number: coCustIncomeInfo.sourcePhone.areaCode + coCustIncomeInfo.sourcePhone.number
								}
								//$scope.coCustomerInfo.incomeSources.sourcePhone.number = coCustIncomeInfo.sourcePhone.areaCode + coCustIncomeInfo.sourcePhone.number;
							} 		
						}

						if (coCustIncomeInfo.sourceStartDate !== "") {
							var startDateSelection;
							var monthThresholds = ['5', '11', '23', '30'];
							var startDate = moment(coCustIncomeInfo.sourceStartDate, 'YYYYMMDD');
							var monthsSinceStart = moment().diff(startDate, 'months');
							for (var i = 0; i < monthThresholds.length; i++) {
								if (monthsSinceStart <= monthThresholds[i] && monthsSinceStart) {
									startDateSelection = monthThresholds[i];
									break;
								}
							}
							if (monthsSinceStart >= monthThresholds[monthThresholds.length - 1]) {
								startDateSelection = monthThresholds[monthThresholds.length - 1];
							}
							$scope.coCustomerInfo.incomeSources.sourceStartDate = startDateSelection;
						}

						if(coCustIncomeInfo.sourceAddress){
							$scope.coCustomerInfo.incomeSources.employerAddress  = coCustIncomeInfo.sourceAddress.addrLine1;
						}
						if(coCustIncomeInfo.sourceName){
							$scope.coCustomerInfo.incomeSources.sourceName = coCustIncomeInfo.sourceName;
						}

						//if(coCustIncomeInfo.incomeType && !$scope.custAdditionalInfo.noCocustomerIncome){
							$scope.coCustomerInfo.incomeSources.income  = coCustIncomeInfo.incomeType;
						//}

						if(coCustIncomeInfo.monthlyIncome){
							$scope.coCustomerInfo.incomeSources.takeHomePay = coCustIncomeInfo.monthlyIncome;
						}
						if(coCustIncomeInfo.sourceDepartment){
							$scope.coCustomerInfo.incomeSources.deptTitle = coCustIncomeInfo.sourceDepartment;
						}
						if(coCustIncomeInfo.supervisorName){
							$scope.coCustomerInfo.incomeSources.supervisorName = coCustIncomeInfo.supervisorName;
						}
						if(coCustIncomeInfo.workHours){
							$scope.coCustomerInfo.incomeSources.workHours = coCustIncomeInfo.workHours;
						}

						/*if(coCustIncomeInfo.bestPayDate){
							$scope.coCustomerInfo.incomeSources.bestPayDate = coCustIncomeInfo.bestPayDate;
						}*/

						if(coCustIncomeInfo.nextPayday){
							var npd = coCustIncomeInfo.nextPayday;
							var ndpYear = npd.substr(0, 4);
							var ndpMonth = npd.substr(4, 2);
							var ndpDay = npd.substr(6,2);
							$scope.coCustomerInfo.incomeSources.nextPayDate = {
								month : ndpMonth,
								day : ndpDay
							}
						}
						if(coCustIncomeInfo.paydayFrequency){
							$scope.coCustomerInfo.incomeSources.payFrequency = coCustIncomeInfo.paydayFrequency; 
						}


					}

				}
					/* income info customer */
					if(data.incomeSources){
						if(data.incomeSources[0].sourceAddress){
							$scope.customerInfo.incomeSources.employerAddress = data.incomeSources[0].sourceAddress.addrLine1;
						}
		
						if(data.incomeSources[0].department){					
								$scope.customerInfo.incomeSources.deptTitle = data.incomeSources[0].department;
						}
						if(data.incomeSources[0].supervisorName){
							$scope.customerInfo.incomeSources.supervisorName = data.incomeSources[0].supervisorName;
						}
						if(data.incomeSources[0].workHours){
							$scope.customerInfo.incomeSources.workHours = data.incomeSources[0].workHours;
						}
						if(data.incomeSources[0].bestPayDate){
							$scope.customerInfo.incomeSources.bestPayDate = data.incomeSources[0].bestPayDate;
						}

						if(data.incomeSources[0].nextPayday){
							var npd = data.incomeSources[0].nextPayday;
							var ndpYear = npd.substr(0, 4);
							var ndpMonth = npd.substr(4, 2);
							var ndpDay = npd.substr(6,2);
							$scope.customerInfo.incomeSources.nextPayDate = {
								month : ndpMonth,
								day : ndpDay
							}
						}
						if(data.incomeSources[0].paydayFrequency){
							$scope.customerInfo.incomeSources.payFrequency = data.incomeSources[0].paydayFrequency; 
						}
					}
					



				if(data.address){
					if(data.address[0].moveInDate){
							var mid = data.address[0].moveInDate;
							var moveInYear = mid.substr(0, 4);
							var moveInMonth = mid.substr(4, 2);
							$scope.custAdditionalInfo.moveInDate.year = moveInYear;
							$scope.custAdditionalInfo.moveInDate.month = moveInMonth;
							$scope.setPreviousAdd();
					}
				}
					
				if(data.address){
					if(data.address[0].isRenewingLease){
							$scope.customerInfo.address.homeType = data.address[0].isRenewingLease;
					}

					if(data.address[0].isRenewingLease == "RENT"){
						if(data.address[0].landLordName){
							$scope.custAdditionalInfo.ownRentInfo.mortgageCompanyRent = data.address[0].landLordName;
						}
						if(data.address[0].landLordNumber){
							$scope.custAdditionalInfo.ownRentInfo.mortgageCompanyNumRent = data.address[0].landLordNumber;
						}
						
						if(data.additionalInfo.customerPaymentAmount){
							$scope.custAdditionalInfo.ownRentInfo.custPaymentAmountRent = data.additionalInfo.customerPaymentAmount;
						}

						if(data.additionalInfo.averageMonthlyUtilities){
							$scope.custAdditionalInfo.ownRentInfo.avgUtilitiesRent = data.additionalInfo.averageMonthlyUtilities;
						}

					}

					if(data.address[0].isRenewingLease == "OWN"){
						if(data.address[0].landLordName){
							$scope.custAdditionalInfo.ownRentInfo.mortgageCompanyOwn = data.address[0].landLordName;
						}
						if(data.address[0].landLordNumber){
							$scope.custAdditionalInfo.ownRentInfo.mortgageCompanyNumOwn = data.address[0].landLordNumber;
						}
						if(data.additionalInfo.customerPaymentAmount){
							$scope.custAdditionalInfo.ownRentInfo.custPaymentAmountOwn = data.additionalInfo.customerPaymentAmount;
						}

						if(data.additionalInfo.averageMonthlyUtilities){
							$scope.custAdditionalInfo.ownRentInfo.avgUtilitiesOwn = data.additionalInfo.averageMonthlyUtilities;
						}
					}
					
					if(data.address[0].leaseType){
						$scope.custAdditionalInfo.rent.leaseTypeRent = data.address[0].leaseType;
					}
					if(data.address[0].apartmentComplex){
						$scope.custAdditionalInfo.rent.apmtComplex = data.address[0].apartmentComplex;
					}
					

					if(data.address[0].leaseEndDate){
							var led = data.address[0].leaseEndDate;
							var leaseEndYear = led.substr(0, 4);
							var leaseEndMonth = led.substr(4, 2);
							$scope.custAdditionalInfo.leaseEndDate.year = leaseEndYear;
							$scope.custAdditionalInfo.leaseEndDate.month = leaseEndMonth;
					}
				
				}
				/* co customer 	info*/
				if(data.coCustomerInfo){
						if(data.coCustomerInfo.relationshipType){
						$scope.coCustomerInfo.relation = data.coCustomerInfo.relationshipType;
					}
					if(data.coCustomerInfo.firstName){
						$scope.coCustomerInfo.firstName = data.coCustomerInfo.firstName;
					}
					if(data.coCustomerInfo.lastName){
						$scope.coCustomerInfo.lastName = data.coCustomerInfo.lastName;
					}
					if(data.coCustomerInfo.middleInitial){
						$scope.coCustomerInfo.middleName = data.coCustomerInfo.middleInitial;
					}

					if (data.coCustomerInfo.birthDate) {
						var bdate = data.coCustomerInfo.birthDate;
						var dYear = bdate.substr(0, 4);
						var dMonth = bdate.substr(4, 2);
						var dDay = bdate.substr(6, 2);
						$scope.coCustomerInfo.dob = {
							month: dMonth,
							day: dDay,
							year: dYear
						};
					}

					if(data.coCustomerInfo.phoneNumber){
						$scope.coCustomerInfo.phone1.number = data.coCustomerInfo.phoneNumber;
					}

					if(data.coCustomerInfo.phoneType){
						$scope.coCustomerInfo.phone1.phoneType = data.coCustomerInfo.phoneType;
					}

					if(data.coCustomerInfo.social){
						$scope.coCustomerPrivate.ssn.ssn1 = "***";
						$scope.coCustomerPrivate.ssn.ssn2 = "**";
						$scope.coCustomerPrivate.ssn.ssn3 = data.coCustomerInfo.social;
					}
				}
				


				/* co customer 	info*/
				
			}
			// income length date-calculations
			// var incomeLength = $scope.customerInfo.incomeSources.sourceStartDate;
			// incomeLength = incomeLength[2]+incomeLength[1]+incomeLength[0];
			// var currDate = new Date();
			// $scope.then = moment(incomeLength,'YYYYMMDD');
			// $scope.now = moment();
			// var diff = $scope.then.from($scope.now);
			//console.log('diff='+diff);
		}
		
		function provideBankingInfoForACH(customerInfo) {
			$scope.tryAgainCounter = 0;
			$scope.tryAgainMsg = false;
			SharedDataSvc.customerInfoForACH = customerInfo;
			$scope.provideBankingInfoForACHModal = modalService.open({
				title: 'Customer Verification',
				templateUrl: 'partials/modal_appBankingInfoForACH.tpl.html',
				scope: $scope,
				backdrop: 'static'
			});
		}

		function submitBankingInfoForACH(data) {
			$scope.tryAgainMsg = false;
			SharedDataSvc.customerInfoForACH.bankingInfo = {
				routingNumber: data.routingNumber,
				accountNumber: data.accountNumber,
				accountType: data.accountType ? 'Savings' : 'Checking'
			};
			SharedDataSvc.customerInfoForACH.billToaddress = {
				stateProvince: data.address.state,
				address1: data.address.addrLine1,
				//addrLine2: data.address.addrLine2 ? data.address.addrLine2 : "",
				city: data.address.city,
				postalCode: data.address.zipCode !== "00000" ? data.address.zipCode : ""
			};
			applicationService.validateBankingInfo(SharedDataSvc.customerInfoForACH).then(
				function (response) {
					$scope.provideBankingInfoForACHModal.dismiss();
					modalService.processing('Checking Banking Information');
					$timeout(function () {
						modalService.dismiss();
					}, 1200);
					console.log(SharedDataSvc.customerInfoForACH);
					$scope.customerInfoForACH = SharedDataSvc.customerInfoForACH;

					if ($scope.customerInfo.address) {
						/*$scope.customerInfo.address.addrLine1 = SharedDataSvc.customerInfoForACH.billToaddress.address1;
						$scope.customerInfo.address.state = SharedDataSvc.customerInfoForACH.billToaddress.stateProvince;
						$scope.customerInfo.address.city = SharedDataSvc.customerInfoForACH.billToaddress.city;
						$scope.customerInfo.address.zipCode = SharedDataSvc.customerInfoForACH.billToaddress.postalCode;*/
					} else {
						$scope.customerInfo.address = {
							addrLine1: SharedDataSvc.customerInfoForACH.billToaddress.address1,
							state: SharedDataSvc.customerInfoForACH.billToaddress.stateProvince,
							city: SharedDataSvc.customerInfoForACH.billToaddress.city,
							zipCode: SharedDataSvc.customerInfoForACH.billToaddress.postalCode
						};
					}

				},
				function (error) {
					console.log(error);
					if (error.data.errorData.status_code == "ENG-A1012-400" && $scope.tryAgainCounter < 3) {
						$scope.tryAgainCounter++;
						$scope.tryAgainMsg = true;
						return false;
					} else {
						$scope.provideBankingInfoForACHModal.dismiss();
						navigateTo('dashboard');
					}
				}
			);
		}
		//VAN-3209-Use customers zip code on agreement creation - Starts
		function stateValidation(state){
			if(state == $scope.storeInfo.state){
				$scope.isStateValid = true;
			}else{
				$scope.isStateValid = false;
			}
		}
		//VAN-3209-Use customers zip code on agreement creation - Ends
		
		////////////////////////////////////////////////
		//  WATCHERS  //////////////////////
		////////////////////////////////////////////////

		$scope.$watch('customerPrivate.dob.month', generateDays);
		$scope.$watch('coCustomerInfo.dob.month', generateDaysdob);

		$scope.$watch('customerInfo.incomeSources.nextPayDate.month', generateDaysNextPay);

		////////////////////////////////////////////////
		//  BROADCAST LISTENERS   //////////////////////
		////////////////////////////////////////////////

		$scope.$on("applicationDataUpdated", fillApplicationData);
		//VAN-3128-KOUNT implementation VA/AWP - Starts
		/*var kountUrl = null;
		$rootScope.kountSessionId = null;
		if(window.location.hostname !== undefined && kountUrl == null){
			if(window.location.hostname === 'awp.acceptancenow.com'){
				kountUrl = "https://ssl.kaptcha.com/collect/sdk?m=171110"; 
			}
			else{
				kountUrl = "https://tst.kaptcha.com/collect/sdk?m=171110";  
			}
		}
		console.log("kountUrl - "+kountUrl);
		var body = document.createElement('body');
		body.setAttribute('class', 'kaxsdc');
		body.setAttribute('data-event', 'load');
		document.body.appendChild(body);
		var script = document.createElement('script');
		script.src = kountUrl;
		document.head.appendChild(script);
		script.onload = function() {
			try{
				var client = new ka.ClientSDK();
				client.setupCallback(
						{
							'collect-end':
								function(params) {
								console.log("Fires when collection has ended");
								if(params != null &&  Object.keys(params).length>1){
									var MercSessId = params['MercSessId'];
									console.log("MercSessId- "+MercSessId);
									if(MercSessId && MercSessId != null){
										console.log("kountSessionId Generated");
										$rootScope.kountSessionId = MercSessId;
									}
								}
							},

							'collect-begin':
								function(params) {
								console.log("Fires when Collection has started.");
							}
						}
				);
				client.autoLoadEvents(); 
			} catch (e) {
				if (e instanceof ReferenceError) {
					console.log("Error: "+e.name+" "+ e.message);
					$rootScope.kountSessionId = 'Error: '+e.message;

				} else {
					console.log("Error: "+e.name+" "+ e.message);
					$rootScope.kountSessionId = 'Error: '+e.message;
				}
			}

		};

		script.onerror = function() {
			console.log("Error at OnLoad");
			$rootScope.kountSessionId = 'Error: On load of Kount Service';
		};*/
		//VAN-3128-KOUNT implementation VA/AWP - End
		
		//VAN-3402-Re-engagement with Code - AWP - Start
		function validateSelectedSrchOpt(value){
			if(value=='Id' || value=='Name'){
				$scope.showSubmit = true;
			}else{
				$scope.showSubmit = false;
			}
		}

		function findCustApplication(){
			var findCustById = modalService.open({
				title: 'Search By ID',
				templateUrl: 'partials/modal_searchApplicationByApprovalId.tpl.html',
				size: 'sm',
				backdrop: 'static',
				windowClass: 'modal_searchApplicationByApprovalId',
				scope: $scope
			});
			findCustById.result.then(function (result) {
				console.log('Entered ApprovalID: ',result.approvalId);
				modalService.processing('Processing');
				applicationService.searchApplicationByID(result.approvalId)
				.then(function (response) {
					$log.log('Found the customer successfully.');
					modalService.dismiss();
					if(response!=null){
						$rootScope.isAppSrch=true;
						$scope.custApp=response;
						if(response.VanEngagement!=null && response.VanEngagement.customer!=null && response.VanEngagement.customer.isConsentAgreed==null){
							openConsentModal($scope.custApp);
						}else{
							getApplicationSuccess($scope.custApp);
						}
					}
				},function (response) {
					$log.log('Failed while searching application by approvalId');
					modalService.dismiss();
					navigateTo('dashboard/');
				});
			});
		}

		function searchSelection(){
			var selectSearchOption = modalService.open({
				title: 'Select Search Option',
				templateUrl: 'partials/modal_selectSearchOption.tpl.html',
				size: 'sm',
				backdrop: 'static',
				windowClass: 'modal_selectSearchOption',
				scope: $scope
			});
			selectSearchOption.result.then(function (result) {
				if(result!=null && result!=undefined){
					if(result.selectedOption.value == 'Id'){
						findCustApplication();
					}else if(result.selectedOption.value == 'Name'){
						$scope.closeConsent();
					}
				}
			});
		}

		function validateApprovalId(value){
			if(!$scope.idValidation.test(value)){
				$scope.showApprovalError = true;
				$scope.enableSearch = true;
			}else{
				$scope.showApprovalError = false;
				$scope.enableSearch = false;
			}
		}

		function resetValidation(){
			$scope.searchSelectionForm.selectedOption='';
			$scope.showSubmit = false;
			$scope.showApprovalError = false;
			$scope.enableSearch = true;
			$rootScope.isAppSrch=false;
			$scope.searchByIdForm.approvalId = '';
		}

		function openConsentModal(response){
			$scope.eAgreement = $modal.open({
				templateUrl: 'partials/modal_eAgreementNotice_StartApp.html',
				show: true,
				backdrop: 'static',
				scope: $scope
			});
		} 
		//VAN-3402-Re-engagement with Code - AWP - End
	} // end Application controller
})();
