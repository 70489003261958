

///////////////////////////////////////////
//////////////////////////////////////////
///   DASHBOARD AGREEMENT CONTROLLER   //
////////////////////////////////////////
///////////////////////////////////////
(function(){

	'use strict';

	angular
	.module('app.dashboardAgreement')
	.controller('DashboardAgreement', DashboardAgreement);

	/* @ngInject */
	function DashboardAgreement(
		$scope,
		$rootScope,
		$location,
		$route,
		$idle,
		$routeParams,
		$log,
		$sce,
		$cookies,
		modalService,
		authentication,
		SharedDataSvc,
		agreementFactory,
		getStoreInfoFactory,
		getTrainingLibraryFactory,
		getInitialDashboard
		) {

		/*jshint validthis: true */
		var dashboardAgreement = this;

		// DEFINE VARS
		dashboardAgreement.loadingAgreements = true;
		dashboardAgreement.invalidAgreementPin = false;
		dashboardAgreement.duplicateInvoiceId = false;//Story - 28842 code change
		dashboardAgreement.disputeAmountLimitExceed = false;
		dashboardAgreement.disputeAmountMaxPrice = 9999.99;

		//Feature 9467 - AWP-Services: Paginate Invoice Dashboard - Starts
		dashboardAgreement.dashboardAgreementItems = SharedDataSvc.dashboardAgreementItems?SharedDataSvc.dashboardAgreementItems:'5';
		//Feature 9467 - AWP-Services: Paginate Invoice Dashboard - Ends
		dashboardAgreement.order = 'Date';
		dashboardAgreement.searchIcon = 'Plus';
 		dashboardAgreement.sortingOrder = '';
		dashboardAgreement.acknowledgementDetails = {};
		dashboardAgreement.disputeDetails = {};
		dashboardAgreement.disputeReasons;
		dashboardAgreement.agreementInventoryItems = null;
		dashboardAgreement.agreementCount;
		dashboardAgreement.initialDashboardData;
		dashboardAgreement.searchIconElement;
		$scope.nameValidation = /^[a-zA-Z\s\-\']+$/; // Added for name validation 2542 & 2543. It will allows only letters

		dashboardAgreement.navigateTo = navigateTo;
		dashboardAgreement.showModal = showModal;
		dashboardAgreement.showAcknowledgeAgreementBtn = showAcknowledgeAgreementBtn;
		
		//Feature 9467 - AWP-Services: Paginate Invoice Dashboard - Starts
		dashboardAgreement.showitemsperpage = showitemsperpage;
		//Feature 9467 - AWP-Services: Paginate Invoice Dashboard - Ends
		
		dashboardAgreement.showdisputetBtn = showdisputetBtn;
		//9464 -Disable/hide dispute button in invoice dashboard  code changes starts
		dashboardAgreement.disableDispute = disableDispute;
		//9464 -Disable/hide dispute button in invoice dashboard  code changes ends
		dashboardAgreement.acknowledgeAgreement = acknowledgeAgreement;
		dashboardAgreement.confirmAcknowledgementItems = confirmAcknowledgementItems;
		dashboardAgreement.disputeAgreement = disputeAgreement;
		dashboardAgreement.confirmDispute = confirmDispute;
		dashboardAgreement.agreementPinValidate = agreementPinValidate;
		dashboardAgreement.summaryStatusSortFn = summaryStatusSortFn;
		dashboardAgreement.summaryStatusForSortingFn = summaryStatusForSortingFn;
		dashboardAgreement.onInputChangeDisable = onInputChangeDisable;
		dashboardAgreement.customSearch = customSearch;
		dashboardAgreement.resetSearchResults = resetSearchResults;
		dashboardAgreement.resetFormData = resetFormData;
		dashboardAgreement.showDeliveryModal = showDeliveryModal;
		dashboardAgreement.deliveryStatusVerbiage = deliveryStatusVerbiage;
		dashboardAgreement.setDeliveryStatusClass = setDeliveryStatusClass;
		//dashboardAgreement.checkDisputeAmount = checkDisputeAmount;
		//9464 -Disable/hide dispute button in invoice dashboard  code changes -AC Defect- 2091 starts
		var passphrase = (SharedDataSvc.base64.decode($cookies.getObject('PP'))).substring(5);
		//9464 -Disable/hide dispute button in invoice dashboard  code changes -AC Defect- 2091 ends
		
		var itemStatus = {
			application: {
				notStarted: 'Pending',
				inProgress: 'InProgress',
				declined: 'Declined',
				accepted: 'Accepted',
				manualIntervention: 'Manual',
				outOfServiceArea: 'OutOfArea',
				associateReview: 'AssocRvw'
			},
			order: {
				notStarted: 'NotStarted',
				inProgress: 'InProgress',
				pendingConfirm: 'Pending',
				confirmed: 'Confirmed'
			},
			agreement: {
				notStarted: 'Pending',
				inProgress: 'InProgress',
				declined: 'Declined',
				accepted: 'Accepted',
				voided: 'Voided'  //AC 25996 updated code changes
			},
			payment: {
				pendingConfirm: 'Pending',
				inProgress: 'InProgress',
				complete: 'Complete'
			},
			delivery: {
				notStarted: 'NotStarted',
				pendingPayment: 'Pending',
				scheduled: 'Scheduled',
				pickup: 'Pickup',
				confirmed: 'Confirmed',
				readyToSchedule: 'ReadySched'
			},
			sales: {
				pending: 'Pending',
				ready: 'Ready',
				submitted: 'Submitted'
			}
		};

		//PUBLIC VARIABLES
		$scope.isSearched = false;
		$scope.searchError = false;
		$scope.master = {};
		$scope.searchedValue = "Search";
		$scope.years = [];
		$scope.disputeDetailsData = {
			invoiceId: null
		};
		$scope.agreementAcknowledgementDetails = {
			invoiceNumber: null
		};
		$scope.acknowledge = {};//Story - 28842 code change
		$scope.dispute = {};//Story - 28842 code change
		
		// PUBLIC FUNCTIONS
		// Feature 9467 - AWP-Services: Paginate Invoice Dashboard - Activity code changes -Starts
		$scope.sortByClick = function (selectedData) {
			SharedDataSvc.order=selectedData;
			dashboardAgreement.order=SharedDataSvc.order;
			if(dashboardAgreement.order == 'Name') {
				dashboardAgreement.order = 'Name';
				dashboardAgreement.sortingOrder = ['lastName', 'firstName', '-modifiedDate'];
			} if(dashboardAgreement.order == 'Date') {
				dashboardAgreement.sortingOrder = ['-modifiedDate'];
			}	else if(dashboardAgreement.order == 'Activity'){
				dashboardAgreement.order = 'Activity';
				dashboardAgreement.sortingOrder = [dashboardAgreement.summaryStatusSortFn, '-modifiedDate'];
			}
		}
		// Feature 9467 - AWP-Services: Paginate Invoice Dashboard - Activity code changes -Ends
		
		$scope.toggleSearchIcon = function () {
			dashboardAgreement.searchIconElement = angular.element("span.search-button");
			if(dashboardAgreement.searchIconElement.hasClass("glyphicon-minus")) {
				dashboardAgreement.searchIconElement.removeClass("glyphicon-minus active");
				dashboardAgreement.searchIcon = "Plus";
				$scope.searchedValue = "Search";
			} else {
				dashboardAgreement.searchIconElement.addClass("glyphicon-minus active");
				dashboardAgreement.searchIcon = "Minus";
				$scope.searchedValue = "Search only one criteria at a time";
			}
		}

		init();
		function init()
		{
			$rootScope.hideDashboardButton = false;
			$rootScope.hideTrainingLibButton = false;
			$rootScope.hideDashboardAgreementButton = true;
			$rootScope.storeNo = SharedDataSvc.vanStoreId;
			$scope.master = angular.copy($scope.searchParametersValue);

			//Feature 9467 - AWP-Services: Paginate Invoice Dashboard - Starts
			$scope.currentPage = 1;
			$scope.pageSize = 5;
			//Feature 9467 - AWP-Services: Paginate Invoice Dashboard - Ends
			
			addYears();
			agreementFactory.retrieveAgreementsDashboard()
			.then(function(data) {
				dashboardAgreement.loadingAgreements = false;
				dashboardAgreement.dashboardAgreementData = JSON.parse(data);
				//dashboardAgreement.dashboardAgreementData =data;
				$scope.dashboardAgreement.dashboardAgreementData = angular.forEach(dashboardAgreement.dashboardAgreementData, function(value, key) {
					var customerFullName = value.firstName + ' ' + value.lastName;
					$scope.dashboardAgreement.dashboardAgreementData[key].customerFullName = customerFullName;
				});
				console.log('AGREEMENT DASHBOARD DATA', dashboardAgreement.dashboardAgreementData);
				dashboardAgreement.initialDashboardData = dashboardAgreement.dashboardAgreementData;
			}, function(data) {
				$log.log('error getting agreement dashboard data', data);
				dashboardAgreement.loadingAgreements = false;
			});

			getStoreInfoFactory.getData()
				.then(function(response){
					$rootScope.invoiceMethod = response.invoiceMethod;
					//9464 - Dispute/hide disable button in invoice dashboard code changes starts
					$rootScope.isBarcodeEnabled = response.isBarcodeEnabled;
					//9464 - Dispute/hide disable button in invoice dashboard code changes ends
					dashboardAgreement.storeInvoiceMethod = response.invoiceMethod;//AC32101 code changes
			});
			
			//Feature 7855 Code changes starts
			if(SharedDataSvc.operationMode && SharedDataSvc.operationMode == "MANUALPAYABLE") {
				$rootScope.hideMenuButtons = false;
				$rootScope.hideDashboardButton = true;
				$rootScope.hideTrainingLibButton = true;
				}	
			//Feature 7855 Code changes ends

			agreementFactory.getDisputeReasons().then(function(response) {
				dashboardAgreement.disputeReasons = response.disputeReasonList;
				console.log(dashboardAgreement.disputeReasons);
			});

			getTrainingLibraryFactory.getData().then(function(response){
				console.log('New Docs', getTrainingLibraryFactory.getNewDocuments(response));
				$rootScope.trainingCount = getTrainingLibraryFactory.getNewDocuments(response);
			});

			$scope.sortByClick(dashboardAgreement.order);
			
			//Feature 9467 - AWP-Services: Paginate Invoice Dashboard - Starts
			$scope.sortByItems = function(selectedValue){
				if(selectedValue){
				SharedDataSvc.dashboardAgreementItems=selectedValue;
				dashboardAgreement.dashboardAgreementItems=selectedValue;
				$scope.pageSize=selectedValue;
				return dashboardAgreement.dashboardAgreementItems;
				}
			};
			$scope.sortByItems(SharedDataSvc.dashboardAgreementItems);
			//Feature 9467 - AWP-Services: Paginate Invoice Dashboard - Ends

		}

		function addYears() {
			for (var i = 0; i < 50; i++) {
				$scope.years.push(moment().year() - i);
			};
		}

		function navigateTo(target, engagement) {
			engagement = engagement || {};
			if (engagement.engagementId) {
				storeCurrentEngagement(engagement.engagementId);
			}else{
			//AC 32101 code changes starts
				SharedDataSvc.globalVars.order.currentEngagement = engagement;
				SharedDataSvc.globalVars.order.currentEngagement.vanStoreId = SharedDataSvc.vanStoreId;
				SharedDataSvc.globalVars.invoiceAndDeliveryModal = true;
				//AC 32101 code changes ends
			}

			if (target === 'delivery') {
				showDeliveryModal(engagement);
				return;
			}else {
				modalService.dismiss();
				$location.path(target);
			}
		}

		function storeCurrentEngagement(itemID) {
			console.log("[dashboard.storeCurrentEngagement] itemID: " + itemID);
			var selectedEngagement = _.find(dashboardAgreement.dashboardAgreementData, {
				engagementId: itemID
			});
			console.log("[dashboard.storeCurrentEngagement] selectedEngagement: ", selectedEngagement);
			SharedDataSvc.globalVars.order.currentEngagement = selectedEngagement;
			SharedDataSvc.globalVars.order.currentEngagementId = itemID;
			SharedDataSvc.globalVars.order.currentEngagement.vanStoreId = SharedDataSvc.vanStoreId;
			//console.log('539_sds currEng = '+angular.toJson(SharedDataSvc.globalVars.order.currentEngagement));
		};

		//handle modal as per delivery or pickup
		function showDeliveryModal(engagement) {
			SharedDataSvc.deliveryTempId = engagement.engagementId;
			dashboardAgreement.deliveryTempId = engagement.engagementId;
			SharedDataSvc.invoiceDeliveryData.engagement = engagement;
			dashboardAgreement.myDeliveryModal = modalService.open(deliveryModalOptions(engagement, "ForInvoiceDashboard"));

			dashboardAgreement.myDeliveryModal
					.result.then(
					function (result) {
						//console.log('[Delivery Modal complete]', result);
						if (result && result.status === 'confirm') {
						//AC 32101 code changes starts
							if(engagement.engagementId){
								showDeliveryConfirmation(result.data);
								console.log('[Schedule Delivery Address Check Modal] success');
							} else {
								showModalDeliveryPass('scheduleDeliveryPinCheck');
								console.log('[Schedule delivery pin check modal] success');
							}
							//AC 32101 code changes ends
						} else if(result && result.status === 'pickup') {
							showModalDeliveryPass('scheduleDeliveryPinCheck');
							console.log('[Schedule Pickup Modal Pass Check] success:', result.status);
						}
					},
					function (result) {
						console.log('[Delivery Modal dismiss]');
					}
			)
		}

		function deliveryModalOptions(engagement, deliveryPart) {
			deliveryPart = deliveryPart || '';
			return {
				templateUrl: 'partials/modal_invoiceAndDelivery'+deliveryPart+'.html',
				controller: 'delivery',
				controllerAs: 'delivery',
				scope: $scope
			}
		}

		//address confirm modal for schedule delivery
		function showDeliveryConfirmation(engagement) {
			var deliveryConfirmModal = modalService.open(deliveryModalOptions(engagement, 'Confirm'));
			deliveryConfirmModal
					.result.then(
					function (result) {
						if (result && result.status === 'confirm') {
							showModalDeliveryPass('scheduleDeliveryPinCheck');
							console.log('[Schedule Delivery Modal Pass Check] success:', result.status);
						}
					},
					// dismissed
					function (result) {
						if (result && result.status === 'declined') {
							modalService.open({
								templateUrl: 'partials/modal_invoiceAndDeliveryFailed.html',
								scope: $scope
							});
						}
					}
			)
		}

		//show schedule delivery associate pass check modal
		function showModalDeliveryPass(name, windowClass) {
			windowClass = windowClass || '';
			modalService.open({
				templateUrl: '/partials/modal_' + name + '.html',
				backdrop: 'static',
				windowClass: windowClass,
				controller: 'delivery',
				controllerAs: 'delivery',
				scope: $scope
			});
		}

		function deliveryStatusVerbiage(status, date) {
			var deliveryStatus = itemStatus.delivery;
			switch(status) {
				case deliveryStatus.notStarted:
					return 'Not Scheduled';
				case deliveryStatus.pendingPayment:
					return 'Pending Payment Completion';
				case deliveryStatus.scheduled:
					return 'Scheduled for ' + moment(date).format('MM/DD/YY');
				case deliveryStatus.pickup:
					return 'Pickup on ' + moment(date).format('MM/DD/YY');
				case deliveryStatus.confirmed:
					return 'Delivery Confirmed';
				case deliveryStatus.readyToSchedule:
					return 'Ready to Schedule';
				default:
					return 'Error retrieving status'
			}
		}

		function setDeliveryStatusClass(status) {
			var deliveryStatus = itemStatus.delivery;
			switch(status) {
				case deliveryStatus.notStarted:
				case deliveryStatus.pendingPayment:
					return "process-standby";
				case deliveryStatus.pickup:
				case deliveryStatus.scheduled:
					return "process-yellow itemNoEdit";
				case deliveryStatus.readyToSchedule:
					return "process-problem";
				case deliveryStatus.confirmed:
					return "process-complete itemNoEdit";
				default:
			}
		}

		function scheduleDeliveryPinCheck(pin){

		}

		function showModal(name, windowClass) {
			windowClass = windowClass || '';

			dashboardAgreement.modal = modalService.open({
				templateUrl: '../../../partials/modal_' + name + '.html',
				backdrop: 'static',
				windowClass: windowClass,
				scope: $scope
			});
		}

		function showAcknowledgeAgreementBtn (engagement) {
			if(engagement.customerAgreementStatus.toLowerCase() === 'pending' && $rootScope.invoiceMethod.toLowerCase() === "manualpayable") {
				return true;
			}
			return false;
		}
		
		//Feature 9467 - AWP-Services: Paginate Invoice Dashboard - Starts
		function showitemsperpage(){
			if(dashboardAgreement.dashboardAgreementData!=undefined && dashboardAgreement.dashboardAgreementData.length<5){
				return false;
			}
			return true;
		}
		//Feature 9467 - AWP-Services: Paginate Invoice Dashboard - Ends
		
        function showdisputetBtn (engagement) {
			//9464 - Dispute/hide disable button in invoice dashboard code changes starts
        	if(engagement.customerAgreementStatus.toLowerCase() === 'payment sent' || engagement.customerAgreementStatus.toLowerCase() === 'payment processing'){
		    	if(passphrase == 'xteam' && $rootScope.isBarcodeEnabled == true){
		    		return false;
		    	}else{
		    		return true;
		    	}
			}
        	return false;
			//9464 - Dispute/hide disable button in invoice dashboard code changes ends
		}
		function acknowledgeAgreement(engagementData) {
			/*console.log("[Engagement Data]", engagementData);
			console.log("[Engagement Data]", engagementData.agreementId);*/
			showModal('Processing', 'processing-modal');
			agreementFactory.agreementInventory(engagementData.agreementId)
			.then(function(data) {
				dashboardAgreement.agreementInventoryItems = JSON.parse(data);
				showModal("agreementItems","dashboard-agreement");
				console.log('AGREEMENT INVENTORY ITEMS', dashboardAgreement.agreementInventoryItems);
				$scope.agreementAcknowledgementDetails.invoiceNumber = dashboardAgreement.agreementInventoryItems.invoiceId;
				$scope.disputeDetailsData.invoiceId = dashboardAgreement.agreementInventoryItems.invoiceId;
			}, function(data) {
				$log.log('error getting agreement inventory itmes', data);
			});
		}
        //9464 - Disable/hide dispute button in invoice dashboard code changes starts
		function disableDispute() {
			if(passphrase == 'xteam' && $rootScope.isBarcodeEnabled == true) {
				return false;
			}
			return true;
		}
		 //9464 - Disable/hide dispute button in invoice dashboard code changes ends
		
		function agreementPinValidate(data){
			var pin = data.agreementPin;
			var auth = SharedDataSvc.base64.decode($cookies.getObject('store_first_name'));
			if(auth === pin) {
				dashboardAgreement.invalidAgreementPin = false;
				if(data.invoiceNumber !== undefined){
					//Story - 28842 Code changes start
					//showModal('Processing', 'processing-modal');
					$scope.acknowledge.acknowledgeAgreementDetails.invoiceNumber.$setValidity('invoiceNumber', false);	
					//Story - 28842 Code changes end 
					dashboardAgreement.confirmAcknowledgementItems(data);
				} else{
					//Story - 28842 Code changes start
					//showModal('Processing', 'processing-modal');
					$scope.dispute.disputeDetails.invoiceId.$setValidity('invoiceId', false);	
					//Story - 28842 Code changes end 
					dashboardAgreement.confirmDispute(data);
				}
			} else {
				console.log('invalid associate pin', dashboardAgreement.invalidAgreementPin);
				dashboardAgreement.invalidAgreementPin = true;
			}
		}

		function confirmAcknowledgementItems(data){
			console.log('[Current Agreement Inventory Details]', dashboardAgreement.agreementInventoryItems);
			console.log('[Acknowledgement Details Form Data]', data);

			dashboardAgreement.acknowledgementDetails['agreementId'] = dashboardAgreement.agreementInventoryItems.agreementId;
			dashboardAgreement.acknowledgementDetails['invoiceId'] = data.invoiceNumber;
			dashboardAgreement.acknowledgementDetails['salesPerson'] = data.salesPerson;
			dashboardAgreement.acknowledgementDetails['payableStatus'] = 'CONFIRMED';
			dashboardAgreement.acknowledgementDetails['disputeReason'] = null;
			dashboardAgreement.acknowledgementDetails['disputeComment'] = null;
			dashboardAgreement.disputeDetails['disputedAmount'] = null;
			console.log('[Update Agreement Acknowledgement Object]', dashboardAgreement.acknowledgementDetails);

			agreementFactory.updateAgreement(dashboardAgreement.acknowledgementDetails)
			.then(function(respone){
				console.log('[Agreement Acknowledgement Successfully Updated]', respone);
				//Story - 28842 Code changes start
				$scope.acknowledge.acknowledgeAgreementDetails.invoiceNumber.$setValidity('invoiceNumber', true);
				if (respone.status_message === "Duplicate Invoice id found"	&& respone.status_details === "ERROR") {
					dashboardAgreement.duplicateInvoiceId = true;
				} else {
					$route.reload();
					}
				//Story - 28842 Code changes end
			},
			function(respone){
				$log.log('Error Updating Agreement Acknowledgement Details', respone);
			});
						
		}

		function disputeAgreement(data) {
			console.log(data);
			showModal("agreementDisputeDetails");
			$scope.disputeDetailsData.invoiceId = data.invoiceId;
			dashboardAgreement.agreementInventoryItems = data;
		}

		/*function checkDisputeAmount (val) {
			if(val.indexOf('$') > -1){
				val = val.split('$')[1];
			}
			if(val > dashboardAgreement.disputeAmountMaxPrice) {
				dashboardAgreement.disputeAmountLimitExceed = true;
				$scope.dispute.disputeDetails.disputeAmount.$setValidity('disputeAmount', false);
				return false;
			}else{
				dashboardAgreement.disputeAmountLimitExceed = false;
				$scope.dispute.disputeDetails.disputeAmount.$setValidity('disputeAmount', true);
			}
		}
*/
		function confirmDispute(data){
			console.log('[Current Agreement Dispute Reason]', dashboardAgreement.agreementInventoryItems);
			console.log('[Dispute Details Form Data]', data);

			//AC 24784 code changes - removed indexof check
			/*if(data.disputeAmount.indexOf('$') > -1){
				data.disputeAmount = data.disputeAmount.split('$')[1];
			}*/

			dashboardAgreement.disputeDetails['agreementId'] = dashboardAgreement.agreementInventoryItems.agreementId;
			dashboardAgreement.disputeDetails['invoiceId'] = data.invoiceId;;
			dashboardAgreement.disputeDetails['salesPerson'] = null;
			dashboardAgreement.disputeDetails['payableStatus'] = 'DISPUTED';
			dashboardAgreement.disputeDetails['disputeReason'] = data.disputeReason.disputeOption;
			dashboardAgreement.disputeDetails['disputeComment'] = data.disputeComments;
			dashboardAgreement.disputeDetails['disputedAmount'] = data.disputeAmount;
			console.log('[Agreement Acknowledgement Dispute Object]', dashboardAgreement.disputeDetails);

			agreementFactory.updateAgreement(dashboardAgreement.disputeDetails)
			.then(function(respone){
				console.log('[Agreement Acknowledgement Disputed]', respone);
				//Story - 28842 Code changes start
				$scope.dispute.disputeDetails.invoiceId.$setValidity('invoiceId', true);
				if (respone.status_message === "Duplicate Invoice id found"	&& respone.status_details === "ERROR") {
					dashboardAgreement.duplicateInvoiceId = true;
				} else {
					$route.reload();
					}
				//Story - 28842 Code changes end
			},
			function(respone){
				$log.log('Error Agreement Acknowledgement Disputed', respone);
			});			
		}

		function resetFormData() {
			$scope.disputeDetailsData = {};
			$scope.agreementAcknowledgementDetails = {};
			dashboardAgreement.duplicateInvoiceId = false;//Story - 28842 code change
		}	

		function summaryStatusSortFn(item) {
			var status = summaryStatusForSortingFn(item);
			var sortMap = {
				'Pending': 1,
				'Payment Processing': 2,
				'Payment Sent': 3,
				'Dispute Claimed': 4,
				//Feature - 8023 code change starts
				'Conflict':5,
				'Inactive':6
				//Feature - 8023 code change end 
			}
			return sortMap[status];
		}

		
		function summaryStatusForSortingFn(engagement) {
			if (engagement.customerAgreementStatus.toUpperCase() === "PENDING") {
				return 'Pending';
			}else if (engagement.customerAgreementStatus.toUpperCase() === "PAYMENT PROCESSING") {
				return 'Payment Processing';
			} else if (engagement.customerAgreementStatus.toUpperCase() === "PAYMENT SENT") {
				return 'Payment Sent';
			} else if (engagement.customerAgreementStatus.toUpperCase() === "DISPUTE CLAIMED") {
				return 'Dispute Claimed';
			//Feature - 8023 code change starts
			} else if (engagement.customerAgreementStatus.toUpperCase() === "CONFLICT") {
				return 'Conflict';
			} else if (engagement.customerAgreementStatus.toUpperCase() === "INACTIVE") {
				return 'Inactive';
			//Feature - 8023 code change end
			}
		}

		function generateDays(newVal, oldVal) {
			// April, June, September, November
			var daysThirty = ['04', '06', '09', '11'];
			// February
			var daysTwentyNine = ['02'];
			var dayNum = 0;
			$scope.days = [];
			if (newVal == undefined) {
				return;
			} else if (daysThirty.indexOf(newVal) > -1) {
				dayNum = 30;
			} else if (daysTwentyNine.indexOf(newVal) > -1) {
				dayNum = 29;
			} else {
				dayNum = 31;
			}
			var day = 1;
			// Add days to array, with 0 based numbers before 10
			while (day < dayNum + 1) {
				if (day < 10) {
					day = '0' + day;
				} else {
					day += '';
				}
				$scope.days.push(day);
				day++;
			}
		}

		function searchSuccess () {
			$scope.isSearched = true;
			$scope.searchError = false;
			dashboardAgreement.searchIconElement.removeClass("glyphicon-minus active");
			dashboardAgreement.searchIcon = "Plus";
		}

		function onInputChangeDisable(input, inputText) {
			if(inputText !== undefined  && inputText.length <= 0) {
				return false;
			}
			console.log('Input ID', input);
			$scope.searchParameters.$pristine = false;
			if(input === 'agreementDate') {
				angular.element(".search-parameter input").prop('disabled', true);
				$scope.searchParameters.dateOfBirth.month.$dirty = true;

			}else {
				angular.element(".search-parameter input, .search-parameter select").prop('disabled', true);
				angular.element("#"+ input).prop('disabled', false).focus();
			}		
		}

		function resetSearchResults(action) {
			$scope.dashboardAgreement.dashboardAgreementData = dashboardAgreement.initialDashboardData;
			$scope.searchParametersValue = angular.copy($scope.master);
			$scope.isSearched = false;
			$scope.searchError = false;
			dashboardAgreement.agreementCount = 0;
			if(action !== "reset"){
				$scope.searchedValue = "Search";
				$route.reload();
			}
			$scope.searchParameters.$pristine = true;
			$scope.searchParameters.dateOfBirth.month.$dirty = false;
			angular.element(".search-container .search-box").prop('disabled', true);
			angular.element(".search-parameter input, .search-parameter select").prop('disabled', false);
			angular.element(".search-parameter select[name='day']").prop('disabled', true);
		}


		function customSearch(searchParams) {
			console.log(searchParams);
			
			// Service based search for serach parameter as Agreement Number 
			if(searchParams.agreementNo !== undefined && searchParams.agreementNo !== "") {
				showModal('Processing', 'processing-modal');//VAN-2993-Merchant payable - single OKTA login for 47 stores , to see all agreements in dashboard and confirm
				$scope.searchedValue = searchParams.agreementNo;
				searchSuccess();
				agreementFactory.searchAgreement('agreementNumber', searchParams.agreementNo)
				.then(function(data) {
					modalService.dismiss();//VAN-2993-Merchant payable - single OKTA login for 47 stores , to see all agreements in dashboard and confirm
					$scope.dashboardAgreement.dashboardAgreementData = JSON.parse(data);
					console.log('Search Result for Agreement Number', $scope.dashboardAgreement.dashboardAgreementData);
					if($scope.dashboardAgreement.dashboardAgreementData !== null) {
						dashboardAgreement.agreementCount = $scope.dashboardAgreement.dashboardAgreementData.length;
					} else {
						dashboardAgreement.agreementCount = 0;
					}				
				}, function(data) {
					modalService.dismiss();//VAN-2993-Merchant payable - single OKTA login for 47 stores , to see all agreements in dashboard and confirm
					$scope.dashboardAgreement.dashboardAgreementData = "";
					$log.log('Error Getting Search Result For Agreement Number', data);
				});
			}

			// Service based search for serach parameter as Reference/Invoice Number 
			else if (searchParams.referenceNo !== undefined && searchParams.referenceNo !== "") {
				showModal('Processing', 'processing-modal');//VAN-2993-Merchant payable - single OKTA login for 47 stores , to see all agreements in dashboard and confirm
				$scope.searchedValue = searchParams.referenceNo;
				searchSuccess();
				agreementFactory.searchAgreement('invoice', searchParams.referenceNo)
				.then(function(data) {
					modalService.dismiss();//VAN-2993-Merchant payable - single OKTA login for 47 stores , to see all agreements in dashboard and confirm
					$scope.dashboardAgreement.dashboardAgreementData = JSON.parse(data);
					console.log('Search Result for Reference Number', $scope.dashboardAgreement.dashboardAgreementData);
					if($scope.dashboardAgreement.dashboardAgreementData !== null) {
						dashboardAgreement.agreementCount = $scope.dashboardAgreement.dashboardAgreementData.length;
					} else {
						dashboardAgreement.agreementCount = 0;
					}
				}, function(data) {
					modalService.dismiss();//VAN-2993-Merchant payable - single OKTA login for 47 stores , to see all agreements in dashboard and confirm
					$scope.dashboardAgreement.dashboardAgreementData = "";
					$log.log('Error Getting Search Result For Reference Number', data);
				});
			}

			// FE based search as per Customer Name
			else if(searchParams.name !== undefined && searchParams.name !== "") {
				searchSuccess();
				$scope.searchedValue = searchParams.name;
				var output = [];
				angular.forEach(dashboardAgreement.initialDashboardData, function(data) {
					if(data.customerFullName.toUpperCase().indexOf(searchParams.name.toUpperCase()) > -1) {
						output.push(data);
					}
				});
				$scope.dashboardAgreement.dashboardAgreementData = output;
				dashboardAgreement.agreementCount = output.length;
			}

			// FE based search as per Agreement Date
			else if(searchParams.dob !== undefined && searchParams.dob !== "") {
				searchSuccess();
				console.log(searchParams.dob);
				var date = searchParams.dob.month + '/' + searchParams.dob.day + '/' + searchParams.dob.year;
				var formattedDate = moment(date).format('MM/DD/YY');
				$scope.searchedValue = formattedDate;
				var output = [];
				angular.forEach(dashboardAgreement.initialDashboardData, function(data) {
					if(data.agreementOpenDate.indexOf(formattedDate) > -1) {
						output.push(data);
					}
				});
				$scope.dashboardAgreement.dashboardAgreementData = output;
				dashboardAgreement.agreementCount = output.length;
			}

			else {
				$scope.isSearched = false;
				$scope.searchError = true;
				dashboardAgreement.searchIconElement.addClass("glyphicon-minus active");
				dashboardAgreement.searchIcon = "Minus";
			}
		}

		////////////////////////////////////////////////
		//  WATCHERS  //////////////////////
		////////////////////////////////////////////////

		$scope.$watch('searchParametersValue.dob.month', generateDays);
	}
})();
