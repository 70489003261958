// This controller follows the angular-styleguide
// https://github.com/johnpapa/angularjs-styleguide
(function () {
  'use strict';

  angular
    .module('myApp')
    .controller('delivery', Delivery);

  Delivery.$inject = ['$scope',
                      '$rootScope',
                       '$log',
                       '$routeParams',
                       '$q',
                       '$sce',
                       '$modal',
                       '$location',
                       '$cookies',
                       'SharedDataSvc',
                       'base64',
                       'modalService',
                       'getStoreInfoFactory',
                       'updateEngagementItemFactory',
                       'customerDataFactory',
                       'dashboardServices',
                       'agreementFactory'//AC 32101 code changes
                       ];

  function Delivery($scope,
                    $rootScope,
                     $log,
                     $routeParams,
                     $q,
                     $sce,
                     $modal,
                     $location,
                     $cookies,
                     SharedDataSvc,
                     base64,
                     modalService,
                     getStoreInfoFactory,
                     updateEngagementItemFactory,
                     customerDataFactory,
                     dashboardServices,
                     agreementFactory//AC 32101 code changes
                    ) {
    /*jshint validthis: true */
    var vm = this;

    // set scope variable for processing modal
    $scope.title = 'Processing';
    $scope.modalTitle = 'Processing';

    $scope.isBarcodeEnabled = false;

    // Variables
    vm.customerData = SharedDataSvc.globalVars.order.currentEngagement;
    vm.engagement;
    vm.modal;
    vm.type;
    vm.invoiceNumber = "";
    vm.associate = "";
    vm.storeInfo;
    vm.dt;
    vm.engageSpec = SharedDataSvc.deliveryData;
    vm.errors = {
      date: false,
      name: false,
      type: false,
      invoice: false
    };
    vm.empPass;
    vm.noAssociatePass = false;
    vm.invalidAssociatePass = false;

    vm.empPin;
    vm.noAssociatePin = false;
    vm.invalidAssociatePin = false;
    vm.immediateInvoicing = true;
    vm.noInvoiceAndName = false;

    vm.processingModal;

    // Controller Functions
    vm.navigateTo = navigateTo;
    vm.showModal = showModal;
    vm.validate = validate;
    vm.updateEngagement = updateEngagement;
    vm.updateEngagementFromInvoiceDashoard = updateEngagementFromInvoiceDashoard;
    vm.setDeliveryType = setDeliveryType;
    vm.scheduleDeliveryPassCheck = scheduleDeliveryPassCheck;
    vm.scheduleDeliveryPinCheck = scheduleDeliveryPinCheck;
    vm.generateVccDetails = generateVccDetails;

    // Initialize Controller
    init();

    function init() {
      // console.log('errors', vm.errors);
      console.log('[delivery.init] engagement', vm.customerData);
      console.log('[delivery.init] current engagement', SharedDataSvc.globalVars.order.currentEngagement);

      //AC 31704
      $rootScope.englishInstructions = SharedDataSvc.globalVars.storeSetup.checkOutInstructions ? SharedDataSvc.globalVars.storeSetup.checkOutInstructions.englishInstructions : '';

//AC 32101 code changes
      if(vm.customerData.engagementId){
    	  
      updateEngagementItemFactory.getData(vm.customerData.engagementId)
        .then(function (response) {
          vm.engagement = response;
          SharedDataSvc.currentEngagementData = response;
          delete vm.engagement.status;
          $log.log('[delivery.init] engagement', vm.engagement);
          
          if((vm.engagement.invoiceNum) && (vm.engagement.salesAssociateID)){
          	if( (vm.engagement.invoiceNum != "") && (vm.engagement.salesAssociateID != "")){
	          	vm.invoiceNumber = vm.engagement.invoiceNum;
	          	vm.associate = vm.engagement.salesAssociateID;
	        }
          }
          
            getStoreInfoFactory.getData()
                .then(function(response) {
                  console.log('[delivery.init] STORE INFO', response);
                  vm.storeInfo = response;
                  $rootScope.vccPrint = response.vccPrintAWP;
                  vm.immediateInvoicing = response.immediateInvoicing;
                  $scope.isBarcodeEnabled = response.isBarcodeEnabled;
                  if(vm.storeInfo.invoiceAtOrderconfirmation == 1){
                    vm.invoiceNumber = vm.engagement.invoiceNum;
                    vm.associate = vm.engagement.salesAssociateID;
                  }
                });
        }, function(response) {
          console.log('[delivery.init] failed getting engagement');
        });
      } else {//AC 32101 code changes starts
    	  if(SharedDataSvc.globalVars.invoiceAndDeliveryModal){
    		  showProcessingModal();
    	  }
    	  vm.engagement = SharedDataSvc.globalVars.order.currentEngagement;
    	  agreementFactory.searchAgreement('agreementNumber', vm.customerData.agreementNumber)
			.then(function(data) {
				if(vm.processingModal)
				vm.processingModal.dismiss();
				var response = JSON.parse(data);
				 if((response[0].invoiceId) && (response[0].salesPerson)){
			          	if( (response[0].invoiceId != "") && (response[0].salesPerson != "")){
				          	vm.invoiceNumber = response[0].invoiceId;
				          	vm.associate = response[0].salesPerson;
				        }
			          }
				 
				 vm.engagement.invoiceNum = vm.invoiceNumber;
	              vm.engagement.salesAssociateID = vm.associate;
		    	  getStoreInfoFactory.getData()
		          .then(function(response) {
		            console.log('[delivery.init] STORE INFO', response);
		            vm.storeInfo = response;
		            vm.immediateInvoicing = response.immediateInvoicing;
		            $scope.isBarcodeEnabled = response.isBarcodeEnabled;
		            if(vm.storeInfo.invoiceAtOrderconfirmation == 1){
		              vm.invoiceNumber = vm.engagement.invoiceNum;
		              vm.associate = vm.engagement.salesAssociateID;
		            }
		            
		          });
		    	  SharedDataSvc.globalVars.invoiceAndDeliveryModal = false;
			});
    	 
      }//AC 32101 code changes ends
    }

    function navigateTo(target) {
      //vm.modal.hide();
      $location.path(target);
    };

    function showModal(name, windowClass) {
      windowClass = windowClass || '';
      vm.modal = modalService.open({
        templateUrl: '/partials/modal_' + name + '.html',
        backdrop: 'static',
        windowClass: windowClass,
        controller: 'delivery',
        controllerAs: 'delivery',
        scope: $scope
      }).result.then(function(result){ 
        console.log(result) 
      }, function(result){ 
        if (result === 'Failure') {
          modalService.open({
            templateUrl: 'partials/modal_invoiceAndDeliveryFailed.html'
          });
        }
      });
    }

    // schedule delivery associate password check
    function scheduleDeliveryPassCheck(pass) {
      if (pass) {
        vm.noAssociatePass = false;
        var auth = SharedDataSvc.base64.decode($cookies.getObject('store_nick_name'));
        if(auth === pass) {
          vm.empPass = '';
          vm.invalidAssociatePass = false;
          vm.noAssociatePass = false;
          updateEngagement();
        } else {
          console.log('invalid associate pin', vm.invalidAssociatePass);
          vm.invalidAssociatePass = true;
        }
      }else{
        console.log('no associate pin', vm.noAssociatePass);
        vm.invalidAssociatePass = false;
        vm.noAssociatePass = true;
      }
    }

    // schedule delivery associate password check
    function scheduleDeliveryPinCheck(pin) {
      if (pin) {
        vm.noAssociatePin = false;
        var auth = SharedDataSvc.base64.decode($cookies.getObject('store_first_name'));
        if(auth === pin) {
          vm.empPass = '';
          vm.invalidAssociatePin = false;
          vm.noAssociatePin = false;
          updateEngagementFromInvoiceDashoard();
        } else {
          console.log('invalid associate pin', vm.invalidAssociatePin);
          vm.invalidAssociatePin = true;
        }
      }else{
        console.log('no associate pin', vm.noAssociatePin);
        vm.invalidAssociatePin = false;
        vm.noAssociatePin = true;
      }
    }

    function validate(context) {
      var errors;

      // Reset Errors
      vm.errors.invoice = false;
      vm.errors.name = false;
      vm.errors.date = false;
      vm.errors.type = false;

      vm.noInvoiceAndName = false;

      vm.engageSpec.applicationStatus = vm.engagement.applicationStatus;
      vm.engageSpec.customerId = vm.engagement.customerId;
      vm.engageSpec.orderStatus = vm.engagement.orderStatus;
      vm.engageSpec.salesStatus = vm.engagement.salesStatus;
      vm.engageSpec.paymentStatus = vm.engagement.paymentStatus;
      vm.engageSpec.agreementStatus = vm.engagement.agreementStatus;
      vm.engageSpec.vanStoreId = SharedDataSvc.vanStoreId;
      vm.engageSpec.customerHtID = vm.engagement.customerHtID;
      vm.engageSpec.engagementId = vm.engagement.engagementId;
      vm.engageSpec.orderContent = vm.engagement.orderContent;

      if(context == "noInvoiceAndName") {
        vm.noInvoiceAndName = true;
      }

      if(vm.storeInfo.invoiceAtOrderconfirmation != 1) {
        vm.errors.invoice = false;
        vm.errors.name = false;
        if(context != "noInvoiceAndName"){
          if(context == "invoiceDashboard"){
            if(!vm.engagement.invoiceNum){
              vm.errors.invoice = true;
            }
            if(!vm.engagement.salesAssociateID){
              vm.errors.name = true;
            }
          } else {
            if(!vm.invoiceNumber){
              vm.errors.invoice = true;
            }
            if(!vm.associate){
              vm.errors.name = true;
            }
          }
        }       
      }
      
      if(!SharedDataSvc.invoiceDeliveryData.date) {
        vm.errors.date = true;
      }
      if(!vm.type) {
        vm.errors.type = true;
      }

      errors = _.contains(vm.errors, true);

      if(!errors) {
        console.log('[delivery.validate] all fields pass');
        // SharedDataSvc.invoiceDeliveryData.date = vm.dt;
        if(vm.invoiceNumber && (context != "invoiceDashboard")){
          SharedDataSvc.invoiceDeliveryData.invoiceNum = vm.invoiceNumber;
        }else{
          SharedDataSvc.invoiceDeliveryData.invoiceNum = vm.engagement.invoiceNum;
        }

        if(vm.associate && (context != "invoiceDashboard")){
          SharedDataSvc.invoiceDeliveryData.associateId = vm.associate;
        }else{
          SharedDataSvc.invoiceDeliveryData.associateId = vm.engagement.salesAssociateID;
        }

        submitDelivery(vm.noInvoiceAndName);
      }
    }

    function setDeliveryType() {
      console.log('[delivery.setDeliveryType] DELIVERY TYPE', vm.type);
      SharedDataSvc.invoiceDeliveryData.type = vm.type;
    }

    function addDecimalToInitialPayment(amount) {
      var len = amount.length,
          decIndex = len - 2,
          array = amount.split('');

      array[decIndex] = '.' + array[decIndex];
      amount = array.join('');
      return amount;
    }

    function setDeliveryInfo() {
      vm.engagement.estimatedDeliveryDate = moment(SharedDataSvc.invoiceDeliveryData.date).format('YYYY-MM-DD') + ' 00:00';
      SharedDataSvc.invoiceDeliveryData.deliveryDate = vm.engagement.estimatedDeliveryDate;
      console.log('[delivery.setDeliveryInfo] SET DELIVERY', SharedDataSvc.invoiceDeliveryData.type);
      vm.engagement.deliveryStatus = "Scheduled";
      SharedDataSvc.invoiceDeliveryData.deliveryType = "Scheduled";
      if(SharedDataSvc.invoiceDeliveryData.type === 'pickup') {
        console.log('[delivery.setDeliveryInfo] set as pickup');
        vm.engagement.deliveryStatus = "Pickup";
        SharedDataSvc.invoiceDeliveryData.deliveryType = "Pickup";
      } else {
        vm.engagement.deliveryStatus = "Scheduled";
        SharedDataSvc.invoiceDeliveryData.deliveryType = "Scheduled";
      }
      if(vm.immediateInvoicing || !vm.noInvoiceAndName) {
        vm.engagement.salesStatus = "Submitted";
        vm.engagement.invoiceNum = SharedDataSvc.invoiceDeliveryData.invoiceNum;
        vm.engagement.salesAssociateID = SharedDataSvc.invoiceDeliveryData.associateId;  
      }  
    }

    function showProcessingModal () {
        $rootScope.modalTitle = 'Processing';
        vm.processingModal = modalService.open({
          templateUrl: '/partials/modal_Processing.html',
          title: $scope.title,
          backdrop: 'static',
          windowClass: 'processing-modal',
          show: true
        });
    }

    function generateVccDetails(engagementID) {
      console.log("EngagementID for VCC generation", engagementID);
      showProcessingModal();
      dashboardServices.getCreditCardDetails(engagementID)
        .then(function(response){
          console.log('Generate credit card details success', response);

          var pdfBlob = SharedDataSvc.createPdfBlob(response.vccInvoicePdf);
          if (navigator.msSaveOrOpenBlob) {
            navigator.msSaveOrOpenBlob(pdfBlob);
            vm.processingModal.dismiss();
            navigateTo('dashboard');
          } else {
            var objectURL = URL.createObjectURL(pdfBlob);
            $rootScope.securePdf = $sce.trustAsResourceUrl(objectURL);
          }
          if($rootScope.securePdf) {
            //vm.processingModal.dismiss();
            var vccPrintDetailModal = modalService.open({
              templateUrl: '/partials/modal_vccPrintReceipt.html',
              backdrop: 'static',
              controller: 'delivery',
              controllerAs: 'delivery',
              show:true
            });
            vccPrintDetailModal.result.then(function(){
              SharedDataSvc.clearPrintSection();
              SharedDataSvc.globalVars.navigateToDashboard = false;
              navigateTo('dashboard');
            });
          }

        },function(response){
          console.log('Generate credit card details failure',response);
          if(response.data.errorData.status_code === 'ENG-V116-404') {
            //vm.processingModal.dismiss();
            $rootScope.modalMessage = response.data.errorData.status_message;
            var errorModal = modalService.open({
              templateUrl: '/partials/modal_vccPrintReceiptError.html',
              backdrop: 'static',
              controller: 'delivery',
              controllerAs: 'delivery',
              show:true
            });
            errorModal.result.then(function(){
              navigateTo('dashboard');
              SharedDataSvc.globalVars.navigateToDashboard = false;
            });          
          } else if(response.data.errorData.status_code === 'ENG-V101-500') {
              SharedDataSvc.vccBarcodeGenerateAttempts = SharedDataSvc.vccBarcodeGenerateAttempts + 1;
              console.log('BARCODE GENERATION ATTEMPTS', SharedDataSvc.vccBarcodeGenerateAttempts);
              if(SharedDataSvc.vccBarcodeGenerateAttempts === 3) {
                SharedDataSvc.vccBarcodeGenerateAttempts = 0;
                navigateTo('dashboard');
              } else {
                $rootScope.modalMessage = response.data.errorData.status_message;
                var retryBarcodeGeneration = modalService.open({
                  templateUrl: '/partials/modal_vccPrintReceiptError.html',
                  backdrop: 'static',
                  controller: 'delivery',
                  controllerAs: 'delivery',
                  show:true
                });
                retryBarcodeGeneration.result.then(function(){
                  // close modal
                  var vccDetails = modalService.open({
                    templateUrl: '/partials/alert_scheduleDeliveryConfirmation.html',
                    backdrop: 'static',
                    controller: 'delivery',
                    controllerAs: 'delivery',
                    show:true
                  });
                });
              }
          }
        });
    }

    function updateEngagement() { 
      showProcessingModal();
      setDeliveryInfo();
      console.log('[delivery.updateEngagement] UPDATED ENGAGE', vm.engagement);
      delete vm.engagement.status;
      delete vm.engagement['status'];
      if(vm.engagement.engagementId){//AC 32101 code changes
    	  updateEngagementItemFactory.putData(vm.engagement)
          .then(function(response) {
            console.log('[delivery.updateEngagement] successfully updated engagement', response);
            SharedDataSvc.globalVars.order.currentEngagement.deliveryStatus = SharedDataSvc.invoiceDeliveryData.deliveryType;
            if(vm.immediateInvoicing) {
              SharedDataSvc.globalVars.order.currentEngagement.salesStatus = "Submitted";
            }
            if(response.estimatedDeliveryDate) {
              SharedDataSvc.globalVars.order.currentEngagement.estimatedDeliveryDate = response.estimatedDeliveryDate;
            } else {
              SharedDataSvc.globalVars.order.currentEngagement.estimatedDeliveryDate = SharedDataSvc.invoiceDeliveryData.deliveryDate;
            }
            //AC 32805 code changes starts
            if(response.invoiceNum){
            	SharedDataSvc.globalVars.order.currentEngagement.invoiceId = response.invoiceNum;
            } else {
              SharedDataSvc.globalVars.order.currentEngagement.invoiceId = SharedDataSvc.invoiceDeliveryData.invoiceNum;
            }
            //AC 32805 code changes ends
            vm.processingModal.dismiss();
            if(SharedDataSvc.globalVars.navigateToDashboard) {
              //vm.processingModal.dismiss();
              $rootScope.customerName = SharedDataSvc.globalVars.order.currentEngagement.customerFirstName;
              $rootScope.initialAmountPaid = SharedDataSvc.initialPaymentAmount;
              $rootScope.servicePhoneNumber = SharedDataSvc.globalVars.storeSetup.vipPhoneNumber;
              var vccDetails = modalService.open({
                templateUrl: '/partials/alert_scheduleDeliveryConfirmation.html',
                backdrop: 'static',
                controller: 'delivery',
                controllerAs: 'delivery',
                show:true
              });
              vccDetails.result.then(function() {
                navigateTo('dashboard');
              });
            } else if(!vm.immediateInvoicing && vm.noInvoiceAndName) {
              navigateTo('dashboard');
            }
          }, function(response) {
            showModal(failedModal, 'delivery-modal');
            console.log('[delivery.updateEngagement] failed updating engagement delivery info', response);
          });
      } else {//AC 32101 code changes starts
    	  var oldEngagement = new Object();
          oldEngagement.deliveryStatus = vm.engagement.deliveryStatus;
          oldEngagement.invoiceId = vm.engagement.invoiceNum;
          oldEngagement.estimatedDeliveryDate = vm.engagement.estimatedDeliveryDate;
          oldEngagement.salesPerson = vm.engagement.salesAssociateID;
          oldEngagement.agreementId = vm.customerData.agreementId;
          
          
    	  agreementFactory.updateAgreement(oldEngagement)
			.then(function(respone){
				if(vm.immediateInvoicing) {
		              SharedDataSvc.globalVars.order.currentEngagement.salesStatus = "Submitted";
		            }
		            if(response.estimatedDeliveryDate) {
		              SharedDataSvc.globalVars.order.currentEngagement.estimatedDeliveryDate = response.estimatedDeliveryDate;
		            } else {
		              SharedDataSvc.globalVars.order.currentEngagement.estimatedDeliveryDate = SharedDataSvc.invoiceDeliveryData.deliveryDate;
		            }
		            //AC 32805 code changes starts
		            if(response.invoiceId){
		            	SharedDataSvc.globalVars.order.currentEngagement.invoiceId = response.invoiceId;
		            } else {
		              SharedDataSvc.globalVars.order.currentEngagement.invoiceId = SharedDataSvc.invoiceDeliveryData.invoiceNum;
		            }
		            //AC 32805 code changes ends
		            vm.processingModal.dismiss();
		            if(SharedDataSvc.globalVars.navigateToDashboard){
			              $location.path('dashboard');
			            }
			}, function(response) {
	            showModal(failedModal, 'delivery-modal');
	            console.log('[delivery.updateEngagement] failed updating engagement delivery info', response);
	          });
      }//AC 32101 code changes ends
      
    }

    function updateEngagementFromInvoiceDashoard() {
      showModal('Processing', 'processing-modal');
      setDeliveryInfo();
      console.log('[delivery.updateEngagement] UPDATED ENGAGE', vm.engagement);
      delete vm.engagement.status;
      delete vm.engagement['status'];

      var oldEngagement = new Object();
      oldEngagement.deliveryStatus = vm.engagement.deliveryStatus;
      oldEngagement.customerId = vm.engagement.customerId;
      oldEngagement.engagementId = vm.engagement.engagementId;
      oldEngagement.invoiceNum = vm.engagement.invoiceNum;
      oldEngagement.estimatedDeliveryDate = vm.engagement.estimatedDeliveryDate;
      oldEngagement.salesAssociateID = vm.engagement.salesAssociateID;
      oldEngagement.salesStatus = vm.engagement.salesStatus;
      oldEngagement.vanStoreId = vm.engagement.vanStoreId;

      if(vm.engagement.engagementId){//AC 32101 code changes
    	  updateEngagementItemFactory.putData(oldEngagement)
          .then(function(response) {
            console.log('[delivery.updateEngagement] successfully updated engagement', response);
            SharedDataSvc.globalVars.order.currentEngagement.deliveryStatus = SharedDataSvc.invoiceDeliveryData.deliveryType;
            SharedDataSvc.globalVars.order.currentEngagement.salesStatus = "Submitted";
            if(response.estimatedDeliveryDate) {
              SharedDataSvc.globalVars.order.currentEngagement.estimatedDeliveryDate = response.estimatedDeliveryDate;
            } else {
              SharedDataSvc.globalVars.order.currentEngagement.estimatedDeliveryDate = SharedDataSvc.invoiceDeliveryData.deliveryDate;
            }
            //AC 32805 code changes starts
            if(response.invoiceNum){
            	SharedDataSvc.globalVars.order.currentEngagement.invoiceId = response.invoiceNum;
            } else {
              SharedDataSvc.globalVars.order.currentEngagement.invoiceId = SharedDataSvc.invoiceDeliveryData.invoiceNum;
            }
            //AC 32805 code changes ends
            modalService.dismiss();
            if(SharedDataSvc.globalVars.navigateToDashboard){
              $location.path('dashboard');
            }
          }, function(response) {
            showModal(failedModal, 'delivery-modal');
            console.log('[delivery.updateEngagement] failed updating engagement delivery info', response);
          });  
      }else{//AC 32101 code changes starts
    	  var oldEngagement = new Object();
          oldEngagement.deliveryStatus = vm.engagement.deliveryStatus;
          oldEngagement.invoiceId = vm.engagement.invoiceNum;
          oldEngagement.estimatedDeliveryDate =moment(vm.engagement.estimatedDeliveryDate).format('YYYY-MM-DD'); 
          oldEngagement.salesPerson = vm.engagement.salesAssociateID;
          oldEngagement.agreementId = vm.customerData.agreementId;
          
    	  agreementFactory.updateAgreement(oldEngagement)
			.then(function(response){
				//vm.processingModal.dismiss();
				modalService.dismiss();
				if(response.estimatedDeliveryDate) {
		              SharedDataSvc.globalVars.order.currentEngagement.estimatedDeliveryDate = response.estimatedDeliveryDate;
		            } else {
		              SharedDataSvc.globalVars.order.currentEngagement.estimatedDeliveryDate = SharedDataSvc.invoiceDeliveryData.deliveryDate;
		            }
				//AC 32805 code changes starts
				if(response.invoiceId){
	            	SharedDataSvc.globalVars.order.currentEngagement.invoiceId = response.invoiceId;
	            } else {
	              SharedDataSvc.globalVars.order.currentEngagement.invoiceId = SharedDataSvc.invoiceDeliveryData.invoiceNum;
	            }
	            //AC 32805 code changes ends
		            
		            if(SharedDataSvc.globalVars.navigateToDashboard){
		              $location.path('dashboard');
		            }
			}, function(response) {
	            showModal(failedModal, 'delivery-modal');
	            console.log('[delivery.updateEngagement] failed updating engagement delivery info', response);
	          });
      }
     //AC 32101 code changes ends
    }

    function deliveryModalOptions(engagement, deliveryPart) {
      deliveryPart = deliveryPart || '';
      return {
        templateUrl: 'partials/modal_invoiceAndDelivery' + deliveryPart + '.html',
        backdrop: 'static',
        controller: 'delivery',
        controllerAs: 'delivery',
        show: true
      }
    }

    function showDeliveryConfirmation() {      
      var deliveryConfirmModal = modalService.open(deliveryModalOptions(vm.customerData, 'Confirm'));
      deliveryConfirmModal.result.then(function (result) {
        if (result && result.status === 'confirm') {
          updateEngagement();
          console.log('[Schedule Delivery Modal Pass Check] Success:', result.status);
        }
      },
        //dismiss
        function (result) {
          if (result && result.status === 'declined') {
            var deliveryConfirmModalError = modalService.open({
              templateUrl: 'partials/modal_invoiceAndDeliveryFailed.html'
            });
            deliveryConfirmModalError.result.then(function(result) {
              //close
              navigateTo('dashboard');
            });
          }
        });
    }

    function submitDelivery(noInvoice) {
      if(vm.type == 'pickup') {
        console.log('[delivery.submitDelivery] pick submit');
        if(!vm.immediateInvoicing && noInvoice) {
          updateEngagement();
        }        
        $scope.$close({ status: 'pickup'});
      } else {
        console.log('[delivery.submitDelivery] show delivery modal');
        	//$scope.$close({ status: 'confirm', data: vm.customerData });
        	if(!vm.immediateInvoicing && noInvoice) {
        		showDeliveryConfirmation();
        	}
        	/*VAN-421 - PR-AWP: Address info is missing on Invoice and Delivery Popup - starts*/
        	if(vm.customerData!=undefined && vm.customerData!=null){
        		if(vm.customerData.customerAddress1!=null && vm.customerData.customerCity!=null
        				&& vm.customerData.customerState!=null && vm.customerData.customerZipCode!=null){
        			$scope.$close({ status: 'confirm', data: vm.customerData });
        		}
        		else{
        			var scheduleDelivery = modalService.open({
        				templateUrl: '/partials/modal_scheduleDeliveryPass.html',
        				backdrop: 'static',
        				controller: 'delivery',
        				controllerAs: 'delivery',
        				show:true
        			});
        		}
        	}
        	/*VAN-421 - PR-AWP: Address info is missing on Invoice and Delivery Popup - Ends*/
      }
    }
  }

})();
