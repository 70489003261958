// application (exiting)
myApp.factory('applicationService', function ($http, $q, SharedDataSvc, API_DOMAIN, $cookies, PROXY_ACTION_URL, authentication, ServiceAgent) {
	return {
		saveApplication : function (mData) {
			var deferred = $q.defer();
			var headers = SharedDataSvc.commonHeadersJSON;
			headers.endTarget = 'rentacentervrto/rest/customer/create';
			headers.sessionToken = authentication.getCurrentSessionId();
			headers.orderOriginator = 'AWP';
			//var newCrypt = SharedDataSvc.encrypt(mData, SharedDataSvc.globalVars.storeSetup.serverPublicRSAKey);
			
			var entryPointFromCookie = $cookies.getObject('AwpEntryPoint');
			if(typeof entryPointFromCookie !== "undefined"){
				mData.entryPointName = entryPointFromCookie;
				console.log("[entryPointName in create customer call from application service]", entryPointFromCookie);
			}
			
			console.log("[mData on application service call]",mData);
			
			ServiceAgent.httpCall(
				PROXY_ACTION_URL,
				'POST',
				headers,
				mData,
				true
		    ).then(function (response) {
		    	SharedDataSvc.cutomerInfoForMasterCode = response;
		    	console.log("[applicationService.saveApplication] success response: ", response);
		    	deferred.resolve(response);
		    },
		    function (response) {
		    	console.log("[applicationService.saveApplication] fail ", response);
		      	deferred.reject(response);
		    });
			return deferred.promise;
		},
		/*VAN-249 VAN- Configurable Manual Intervention Response - Starts*/
		getApplicationByEngagement: function (engagement) {
			console.log("[applicationService.getApplicationByEngagement] EngagementDetails: ", engagement);
			var deferred = $q.defer();
			var params = '&lastName=' + engagement.customerLastName;
				params += '&firstName=' + engagement.customerFirstName;
				params += '&areaCode=' + engagement.customerPhoneNum.substr(0,3);
				params += '&phoneNumber=' + engagement.customerPhoneNum.substr(3,10);
				params += '&engagementId=' + engagement.engagementId;
				
			params += '&suppressDbCreate=true';
			var encryptParams = SharedDataSvc.encrypt(params, SharedDataSvc.globalVars.storeSetup.serverPublicRSAKey);//ServiceAgent.encryptData(params);
			var headers = {
				'endTarget': 'rentacentervrto/rest/customer/find',
				'sessionToken' : authentication.getCurrentSessionId(),
				'params': encryptParams,
				'Access-Control-Allow-Origin':'endTarget,sessionToken,Encrypt-Mode,RAC-KIOSK-LOCATION-ID,Public-Key,params,RAC-CORRELATION-ID'//Story 26289 code changes
			};
			var url = PROXY_ACTION_URL;
			var doEncrypt = true;
			ServiceAgent.httpCall(
				url,
				'POST',
				headers,
				null,
				doEncrypt
				// false
			).then(function (response) {
				console.log("[applicationService.getApplication] success: ", response);
				var successData = (typeof response !== "object") ? JSON.parse(response) : response;
	    		deferred.resolve(successData);
			},
			function (response) {
				console.log("[applicationService.getApplication] fail ", response);
				deferred.reject(response);
			});

			return deferred.promise;
		},
		/*VAN-249 VAN- Configurable Manual Intervention Response - Ends*/
		getApplication: function (personDetails) {
			console.log("[applicationService.getApplication] personDetails: ", personDetails);
			var deferred = $q.defer();
			var params = '&lastName=' + personDetails.lastName;
				params += '&firstName=' + personDetails.firstName;
				params += '&areaCode=' + personDetails.phone.substr(0,3);
				params += '&phoneNumber=' + personDetails.phone.substr(3,10);
				//AC14937 code change starts
				if (personDetails.engagementId)	{
					params += '&engagementId=' + personDetails.engagementId;
				}
				//AC14937 code change ends
			if (personDetails.ssn) {
				params += '&ssn=' + personDetails.ssn;
			}
			//Story 33452 changes starts
			if(personDetails.disableHT){
				params += '&disableHT=' + personDetails.disableHT;
			}
			//Story 33452 changes ends
			params += '&suppressDbCreate=true';
			var encryptParams = SharedDataSvc.encrypt(params, SharedDataSvc.globalVars.storeSetup.serverPublicRSAKey);//ServiceAgent.encryptData(params);
			var headers = {
				'endTarget': 'rentacentervrto/rest/customer/find',
				'sessionToken' : authentication.getCurrentSessionId(),
				'params': encryptParams,
				'Access-Control-Allow-Origin':'endTarget,sessionToken,Encrypt-Mode,RAC-KIOSK-LOCATION-ID,Public-Key,params,RAC-CORRELATION-ID'//Story 26289 code changes
			};
			var url = PROXY_ACTION_URL;
			var doEncrypt = true;
			ServiceAgent.httpCall(
				url,
				'POST',
				headers,
				null,
				doEncrypt
				// false
			).then(function (response) {
				var successData = (typeof response !== "object") ? JSON.parse(response) : response;
	    		deferred.resolve(successData);
			},
			function (response) {
				console.log("[applicationService.getApplication] fail ", response);
				//TODO: remove this when proxy is functioning properly
				//response.data.status_details = 'No Customers Match Search Criteria';
				deferred.reject(response);
			});

			return deferred.promise;
		},
		getApplicationByAgreementId: function (agreementId) {
			console.log("[applicationService.getApplicationByAgreementId] agreementId:", agreementId);
			var deferred = $q.defer();
			var params = '&agreementHTId=' + agreementId
			           + '&disableHT=true'
			           + '&suppressDbCreate=true';
			console.log('params:', params);
			var encryptParams = SharedDataSvc.encrypt(params, SharedDataSvc.globalVars.storeSetup.serverPublicRSAKey);//ServiceAgent.encryptData(params);
			var headers = {
				'endTarget': 'rentacentervrto/rest/customer/find',
				'sessionToken' : authentication.getCurrentSessionId(),
				'params': encryptParams,
				'Access-Control-Allow-Origin':'endTarget,sessionToken,Encrypt-Mode,RAC-KIOSK-LOCATION-ID,Public-Key,params,RAC-CORRELATION-ID'//Story 26289 code changes
			};
			var url = PROXY_ACTION_URL;
			var doEncrypt = true;
			ServiceAgent.httpCall(
				url,
				'POST',
				headers,
				null,
				doEncrypt
				// false
			).then(function (response) {
				console.log("[applicationService.getApplicationByAgreementId] success: ", response);
				var successData = (typeof response !== "object") ? JSON.parse(response) : response;
				deferred.resolve(successData);
			},
			function (response) {
				console.log("[applicationService.getApplicationByAgreementId] fail ", response);
				//TODO: remove this when proxy is functioning properly
				//response.data.status_details = 'No Customers Match Search Criteria';
				deferred.reject(response);
			});

			return deferred.promise;
		},
		processApp : function (mData) {
			console.log("[applicationService.processApp] mData: ", mData);
			var deferred = $q.defer();
			// sending object instead of encrypted JOSN
			//var encryptParams = SharedDataSvc.encrypt(mData, SharedDataSvc.globalVars.storeSetup.serverPublicRSAKey);
			//Feature 8275 added client originator and client source id
			var headers = {
				'endTarget': 'rentacentervrto/rest/approval',
				'sessionToken' : authentication.getCurrentSessionId(),
				'Public-Key': SharedDataSvc.globalVars.storeSetup.serverPublicRSAKey,
				'Access-Control-Allow-Origin':'endTarget,sessionToken,Encrypt-Mode,RAC-KIOSK-LOCATION-ID,Public-Key,RAC-CORRELATION-ID,CLIENT-SOURCE-ID,CLIENT-ORIGINATOR',
				'CLIENT-SOURCE-ID':'3',
				'CLIENT-ORIGINATOR':'15'
			};
			var url = PROXY_ACTION_URL;
			ServiceAgent.httpCall(
				url,
				'POST',
				headers,
				mData,
				true //encrypt mode changed to true as per Service team requirement
		    ).then(function (response) {
			console.log("[applicationService.processApp] success response: ", response);
			deferred.resolve(response);
		    },
		    function (response) {
			console.log("[applicationService.processApp] fail ", response);
			deferred.reject(response);
		    });

		    return deferred.promise;
		},
		processApp : function (mData) {
			console.log("[applicationService.processApp] mData: ", mData);
			var deferred = $q.defer();
			// sending object instead of encrypted JOSN
			//var encryptParams = SharedDataSvc.encrypt(mData, SharedDataSvc.globalVars.storeSetup.serverPublicRSAKey);
			//Feature 8275 added client originator and client source id
			var headers = {
				'endTarget': 'rentacentervrto/rest/approval',
				'sessionToken' : authentication.getCurrentSessionId(),
				'Public-Key': SharedDataSvc.globalVars.storeSetup.serverPublicRSAKey,
				'Access-Control-Allow-Origin':'endTarget,sessionToken,Encrypt-Mode,RAC-KIOSK-LOCATION-ID,Public-Key,RAC-CORRELATION-ID,CLIENT-SOURCE-ID,CLIENT-ORIGINATOR',
				'CLIENT-SOURCE-ID':'3',
				'CLIENT-ORIGINATOR':'15'
			};
			var url = PROXY_ACTION_URL;
			ServiceAgent.httpCall(
				url,
				'POST',
				headers,
				mData,
				true //encrypt mode changed to true as per Service team requirement
		    ).then(function (response) {
		    	console.log("[applicationService.processApp] success response: ", response);
		    	deferred.resolve(response);
		    },
		    function (response) {
		    	console.log("[applicationService.processApp] fail ", response);
		      	deferred.reject(response);
		    });

		    return deferred.promise;
		},
		getNewCustId : function(eData){
			console.log('[applicationService.getNewCustId] 123eData: ' + angular.toJson(eData));
			var deferred = $q.defer();
			var headers = SharedDataSvc.commonHeadersJSON;
			headers.endTarget = 'rentacentervrto/rest/db/customerReference';
			headers.sessionToken = authentication.getCurrentSessionId();
			//var newCrypt = SharedDataSvc.encrypt(mData, SharedDataSvc.globalVars.storeSetup.serverPublicRSAKey);
			return $http({
				method: 'POST',
				url: PROXY_ACTION_URL,
				headers: headers,
				data: eData
			}).then(function(response){
				console.log('[applicationService.getNewCustId] successs: ', response);
				deferred.resolve(response.data);
			}).catch(function (response) {
				console.log('[applicationService.getNewCustId] error: ', response);
				deferred.reject(response.data);
			});;
			return deferred.promise;
		},
		validatePhone : function(Data){
			console.log('[applicationService.validatePhone]: ' + angular.toJson(Data));
			var deferred = $q.defer();

			var params = '&toNumber=' + Data.toNumber;
			params += '&languagePreference=' + Data.languagePreference;
			var encryptParams = SharedDataSvc.encrypt(params, SharedDataSvc.globalVars.storeSetup.serverPublicRSAKey);
			var doEncrypt = false;
			var headers = {
				'endTarget': 'rentacentervrto/rest/customer/validatePhone',
				'sessionToken' : authentication.getCurrentSessionId(),
				'params': encryptParams,
				'Public-Key': SharedDataSvc.globalVars.storeSetup.serverPublicRSAKey
			};
			var url = PROXY_ACTION_URL;
			ServiceAgent.httpCall(
					url,
					'POST',
					headers,
					Data,
					doEncrypt
					// false
			).then(function (response) {
						console.log("[applicationService.validatePhone] success: ", response);
						var successData = response;
						deferred.resolve(successData);
					},
					function (response) {
						console.log("[applicationService.validatePhone] fail ", response);
						//TODO: remove this when proxy is functioning properly
						//response.data.status_details = 'No Customers Match Search Criteria';
						deferred.reject(response);
					});

			return deferred.promise;
		},
		publishOverrideDataMasterCode : function(Data){
			console.log('[applicationService.publishOverrideDataMasterCode]: ' + angular.toJson(Data));
			var deferred = $q.defer();
			var doEncrypt = false;

			var headers = {
				'endTarget': 'rentacentervrto/rest/application/publishEvent',
				'sessionToken' : authentication.getCurrentSessionId(),
				'Event-Type' : 'Bus-Event',
				'Event-SubType' : 'OVERRIDE',
				'Public-Key': SharedDataSvc.globalVars.storeSetup.serverPublicRSAKey,
				'Access-Control-Allow-Headers':'OPERATION-MODE,POS-TYPE,Event-Type,Event-SubType'
			};
			
			var url = PROXY_ACTION_URL;
			ServiceAgent.httpCall(
					url,
					'POST',
					headers,
					Data,
					doEncrypt
					// false
			).then(function (response) {
						console.log("[applicationService.publishOverrideDataMasterCode] success: ", response);
						var successData = response;
						deferred.resolve(successData);
					},
					function (response) {
						console.log("[applicationService.publishOverrideDataMasterCode] fail ", response);
						//TODO: remove this when proxy is functioning properly
						//response.data.status_details = 'No Customers Match Search Criteria';
						deferred.reject(response);
					});

			return deferred.promise;
		},
		validateBankingInfo : function(Data){
			console.log('[applicationService.validateBankingInfo]: ' + angular.toJson(Data));
			var deferred = $q.defer();

			var doEncrypt = false;
			var headers = SharedDataSvc.commonHeadersJSON;
			headers.endTarget = 'rentacentervrto/rest/payment/achPaymentVerification';
			headers.sessionToken = authentication.getCurrentSessionId();
			var url = PROXY_ACTION_URL;

			ServiceAgent.httpCall(
					url,
					'POST',
					headers,
					Data,
					doEncrypt
					// false
			).then(function (response) {
					console.log('[applicationService.validateBankingInfo] successs: ', response);
					deferred.resolve(response);
				},
				function (response) {
					console.log('[applicationService.validateBankingInfo] error: ', response);
					deferred.reject(response);
				});

			return deferred.promise;
		},
		updateCustomer : function(data){
			var deferred = $q.defer();
			var headers = SharedDataSvc.commonHeadersJSON;
			headers.endTarget = 'rentacentervrto/rest/customer/update';
			headers.sessionToken = authentication.getCurrentSessionId();
			headers.orderOriginator = 'AWP';
			//var newCrypt = SharedDataSvc.encrypt(mData, SharedDataSvc.globalVars.storeSetup.serverPublicRSAKey);
			/*
			var entryPointFromCookie = $cookies.getObject('AwpEntryPoint');
			if(typeof entryPointFromCookie !== "undefined"){
				data.entryPointName = entryPointFromCookie;
			}*/			
			console.log("[mData on application service call]",data);
			
			ServiceAgent.httpCall(
				PROXY_ACTION_URL,
				'POST',
				headers,
				data,
				true
		    ).then(function (response) {
		    	SharedDataSvc.cutomerInfoForMasterCode = response;
		    	console.log("[applicationService.updateCustomer] success response: ", response);
		    	deferred.resolve(response);
		    },
		    function (response) {
		    	console.log("[applicationService.updateCustomer] fail ", response);
		      	deferred.reject(response);
		    });
			return deferred.promise;
		},//VAN-510 - Electronic Consent moved to app flow - Ends
		getCustomerConsent: function (personDetails) {
			console.log("[applicationService.getCustomerConsent] personDetails: ", personDetails);
			var deferred = $q.defer();
			var params = '&lastName=' + personDetails.lastName;
				params += '&firstName=' + personDetails.firstName;
				params += '&phoneNumber=' + personDetails.phone;
			params += '&suppressDbCreate=true';
			var encryptParams = SharedDataSvc.encrypt(params, SharedDataSvc.globalVars.storeSetup.serverPublicRSAKey);//ServiceAgent.encryptData(params);
			var headers = {
				'endTarget': 'rentacentervrto/rest/customer/getCustomerConsent',
				'sessionToken' : authentication.getCurrentSessionId(),
				'params': encryptParams,
				'Access-Control-Allow-Origin':'endTarget,sessionToken,Encrypt-Mode,RAC-KIOSK-LOCATION-ID,Public-Key,params,RAC-CORRELATION-ID'//Story 26289 code changes
			};
			var url = PROXY_ACTION_URL;
			var doEncrypt = true;
			ServiceAgent.httpCall(
				url,
				'POST',
				headers,
				null,
				doEncrypt
				// false
			).then(function (response) {
				console.log("[applicationService.getCustomerConsent] success: ", response);
				var successData = (typeof response !== "object") ? JSON.parse(response) : response;
	    		deferred.resolve(successData);
			},
			function (response) {
				console.log("[applicationService.getCustomerConsent] fail ", response);
				//TODO: remove this when proxy is functioning properly
				//response.data.status_details = 'No Customers Match Search Criteria';
				deferred.reject(response);
			});

			return deferred.promise;
		},
		updateCustomerConsent : function(data){
			var deferred = $q.defer(); 
			var headers = SharedDataSvc.commonHeadersJSON;
			headers.endTarget = 'rentacentervrto/rest/customer/updateCustomerConsent';
			headers.sessionToken = authentication.getCurrentSessionId();
			headers.orderOriginator = 'AWP';
			//var newCrypt = SharedDataSvc.encrypt(mData, SharedDataSvc.globalVars.storeSetup.serverPublicRSAKey);
			/*
			var entryPointFromCookie = $cookies.getObject('AwpEntryPoint');
			if(typeof entryPointFromCookie !== "undefined"){
				data.entryPointName = entryPointFromCookie;
			}*/			
			console.log("[mData on application service call]",data);
			
			ServiceAgent.httpCall(
				PROXY_ACTION_URL,
				'POST',
				headers,
				data,
				true
		    ).then(function (response) {
		    	SharedDataSvc.cutomerInfoForMasterCode = response;
		    	console.log("[applicationService.updateCustomerConsent] success response: ", response);
		    	deferred.resolve(response);
		    },
		    function (response) {
		    	console.log("[applicationService.updateCustomerConsent] fail ", response);
		      	deferred.reject(response);
		    });
			return deferred.promise;
		},
		//VAN-510 - Electronic Consent moved to app flow - Ends
		
		//VAN-3402-Re-engagement with Code - AWP - Start
		searchApplicationByID: function (data) {
			console.log("[applicationService.searchApplicationByID] data: ", data);
			var deferred = $q.defer();
			SharedDataSvc.searchApp = true;
			var params = '&approvalId=' + data;
			params += '&suppressDbCreate=true';
			var encryptParams = SharedDataSvc.encrypt(params, SharedDataSvc.globalVars.storeSetup.serverPublicRSAKey);
			var headers = {
					'endTarget': 'rentacentervrto/rest/customer/searchApplicationByID',
					'sessionToken' : authentication.getCurrentSessionId(),
					'params': encryptParams,
					'Access-Control-Allow-Origin':'endTarget,sessionToken,Encrypt-Mode,RAC-KIOSK-LOCATION-ID,Public-Key,params,RAC-CORRELATION-ID'
			};
			var url = PROXY_ACTION_URL;
			var doEncrypt = true;
			ServiceAgent.httpCall(
					url,
					'POST',
					headers,
					null,
					doEncrypt
					// false
			).then(function (response) {
				console.log("[applicationService.searchApplicationByID] success: ", response);
				var successData = (typeof response !== "object") ? JSON.parse(response) : response;
				deferred.resolve(successData);
			},
			function (response) {
				console.log("[applicationService.searchApplicationByID] fail ", response);
				deferred.reject(response);
			});

			return deferred.promise;
		}
		//VAN-3402-Re-engagement with Code - AWP - End
	};
});
