// The Agreement controller follows the angular-styleguide
// https://github.com/johnpapa/angularjs-styleguide
(function() {
	'use strict';

	angular.module('myApp').controller('Agreement', Agreement);

	Agreement.$inject = ['$scope', '$log', '$rootScope', '$routeParams', '$q', '$translate', 'modalService', '$location', 'agreementFactory', 'getStoreInfoFactory', 'customerDataFactory', 'orderEstimatePutFactory', 'datesFactory', 'paymentFactory', 'updateEngagementItemFactory', 'productFactory', 'SharedDataSvc', 'base64', 'stateRestrictFactory', 'applicationService', '$cookies'];
	function Agreement($scope, $log, $rootScope, $routeParams, $q, $translate, modalService, $location, agreementFactory, getStoreInfoFactory, customerDataFactory, orderEstimatePutFactory, datesFactory, paymentFactory, updateEngagementItemFactory, productFactory, SharedDataSvc, base64, stateRestrictFactory, applicationService, $cookies) {
		/*jshint validthis: true */
		var vm = this,
		    keys;
		//AC-28614 Changes removed MANNED_STORE_PHONE and UNMANNED_STORE_PHONE
		// set scope variable for processing modal
		$scope.modalTitle = 'Processing';

		// Models
		vm.customer = {};
		vm.engagement;
		vm.agreementData;
		vm.storeInfo;
		vm.stateRestrictions;
		vm.restrictions;
		vm.agreements = [{
			name : {
				en : en.agreement_header_RPA,
				es : es.agreement_header_RPA
			},
			hidden : false,
			agree : undefined,
			pdf : undefined,
			viewed : false,
			// type: 'Kw7N2pTLkRsh202k2wKUdeAbHsosOoIcruqDUqo3idnw2YBrw2mFAAJ2dWQVGef4Y'
			type : 'RA',
			eSign : false,
			signature : null
		}, {
			name : {
				en : en.agreement_header_SA,
				es : es.agreement_header_SA
			},
			hidden : false,
			agree : undefined,
			pdf : undefined,
			viewed : false,
			type : undefined,
			eSign : false,
			signature : null
		}, {
			name : {
				en : en.agreement_header_RAA,
				es : es.agreement_header_RAA
			},
			hidden : false,
			agree : undefined,
			pdf : undefined,
			viewed : false,
			type : 'ACK',
			eSign : false,
			signature : null
		}, {
			name : {
				en : en.agreement_header_EPOA,
				es : es.agreement_header_EPOA
			},
			hidden : false,
			agree : undefined,
			pdf : undefined,
			viewed : false,
			type : 'EPO90',
			eSign : false,
			signature : null
		}, {
			name : {
				en : en.agreement_header_LDW,
				es : es.agreement_header_LDW
			},
			hidden : false,
			agree : undefined,
			pdf : undefined,
			viewed : false,
			type : 'LDW',
			eSign : false,
			signature : null
		}, {
			name : {
				en : en.agreement_header_BPA,
				es : es.agreement_header_BPA
			},
			hidden : false,
			agree : undefined,
			pdf : undefined,
			viewed : false,
			type : 'CLB',
			eSign : false,
			signature : null
		}, {
			name : {
				en : en.agreement_header_AA,
				es : es.agreement_header_AA
			},
			hidden : false,
			agree : undefined,
			pdf : undefined,
			viewed : false,
			type : 'ARB',
			eSign : false,
			signature : null
		}, {
			name : {
				en : en.agreement_header_WA,
				es : es.agreement_header_WA
			},
			hidden : false,
			agree : undefined,
			pdf : undefined,
			viewed : false,
			type : 'FEE',
			eSign : false,
			signature : null
		}, {
			name : {
				en : en.agreement_header_DFA,
				es : es.agreement_header_DFA
			},
			hidden : false,
			agree : undefined,
			pdf : undefined,
			viewed : false,
			type : 'dlf',
			eSign : false,
			signature : null
		}];

		vm.agreementsSpanish = [{
			name : "Acuerdo de Alquiler con opción a compra",

		}, {
			name : "Acuerdo suplementario (s)",
		}, {
			name : "Reconocimiento del contrato de alquiler",

		}, {
			name : "Enmienda de opción de compra temprana",

		}, {
			name : "Acuerdo de exención de daños de responsabilidad",

		}, {
			name : "Acuerdo de Benefits Plus",

		}, {
			name : "Acuerdo de Arbitraje",

		}, {
			name : "Contrato de garantía",
		}, {
			name : "Acuerdo de entrega",
		}];

		vm.suppAgreementsName = {

			"CAM" : "Camcorder HD Supplement acknowledgement",
			"COP" : "Computer Rental Supplement acknowledgement",
			"CRB" : "Crib Waiver acknowledgement",
			"CYC" : "E-Cycle acknowledgement",
			"GAS" : "Gas Dryer Waiver acknowledgement",
			"LCD" : "Large Screen TV Supplement acknowledgement",
			"RAN" : "Range Waiver acknowledgement",
			"SOC" : "Summary of Cost acknowledgement",
			"VID" : "Electronic Device Rental Supplement acknowledgement"

		};

		vm.suppAgreementsSpanishName = {

			"CAM" : "Videocámara HD suplementaria",
			"COP" : "Arrendamiento de computadora suplementario",
			"CRB" : "Crib Waiver",
			"CYC" : "Reconocimiento  de  E-Cycle",
			"GAS" : "Reconocimiento  de Secadora de gas",
			"LCD" : "Reconocimiento  de TV de pantalla grande",
			"RAN" : "Reconocimiento  de renuncia  de Cocina económica",
			"SOC" : "Reconocimiento  de Resumen de Costo",
			"VID" : "Reconocimiento  de Dispositivo electronico"

		};

		vm.orderData = [_.clone(SharedDataSvc.globalVars.agreementOrder), _.clone(SharedDataSvc.globalVars.agreementOrder), _.clone(SharedDataSvc.globalVars.agreementOrder)];

		vm.orderDataForAdjustablePayment = _.clone(SharedDataSvc.globalVars.agreementOrder);

		// Variables
		vm.quote;
		vm.firstPayment = 0;
		vm.showPaymentOptionsModal = false;
		vm.paymentInfo = [];
		vm.showConsentAgree = false;
		vm.quoteDates = datesFactory.quoteDates;
		vm.quotes = agreementFactory.quotes;
		vm.engagementId = $routeParams.engagementId;
		vm.pdfLinks = [];
		vm.unsignedAgreement;
		vm.activeAgreementIndex = 0;
		vm.activeAgreement = vm.agreements[vm.activeAgreementIndex];
		vm.modal;
		vm.orderContent;
		vm.orderArray = [];
		vm.declineBpModalShown = false;
		vm.languagePreference = true;
		vm.hideBp;
		vm.customerInRadius = true;
		vm.isZipValid = true;
		vm.storeLatLng;
		vm.customerDistance;
		vm.storeRadius;
		vm.customerInStoreRadius;
		vm.term1 = false;
		vm.term2 = false;
		vm.term3 = false;
		vm.term4 = false;
		vm.term5 = false;
		vm.term6 = false;
		vm.term7 = false;
		vm.isAnyUnchecked = true;
		vm.inputInitials = "";
		vm.employeeId;
		vm.employeePinError;
		vm.pinAttempts = 0;
		vm.initialPaymentStatus = false;
		vm.initialPaymentErrorClass = "";
		vm.noEmpPin = false;
		vm.processingFee;
		vm.isEPOAmendmentEnabled = false;
		vm.suppCheck = [];
		vm.isAllSuppChecked = false;
		vm.showSupp = false;
		vm.showWarrantyAgreement = false;
		vm.showDelWarrantyAgreement = false;
		vm.adjustableDateSelection = "";
		vm.ipMinDate = "";
		vm.ipMaxDate = "";
		vm.arrAdjustablePayment = "";
		vm.isAdjustablePayment = false;
		vm.quoteGotAsPerSelection = "";
		vm.prodCat;
		$scope.isSpanishStore;

		// Controller Functions
		vm.submitAgreements = submitAgreements;
		vm.createAgreement = createAgreement;
		vm.signAgreement = signAgreement;
		vm.declineAgreement = declineAgreement;
		vm.openAgreementPdf = openAgreementPdf;
		vm.navigateTo = navigateTo;
		vm.signLdw = signLdw;
		vm.showModal = showModal;
		vm.setFirstPaymentDate = setFirstPaymentDate;
		vm.handleRentalAgreement = handleRentalAgreement;
		vm.handleRentalAgreementDecline = handleRentalAgreementDecline;
		vm.handleRentalAcknowledgement = handleRentalAcknowledgement;
		vm.handleLdwDecline = handleLdwDecline;
		vm.handleEsvAgreement = handleEsvAgreement;
		vm.handleEsvDelAgreement = handleEsvDelAgreement;
		vm.handleBpDecline = handleBpDecline;
		vm.handleEpoDecline = handleEpoDecline;
		vm.handleSupAgreement = handleSupAgreement;
		vm.handleSupAgreementDecline = handleSupAgreementDecline;
		vm.handleSkipBp = handleSkipBp;
		vm.getFirstPaymentOptions = getFirstPaymentOptions;
		vm.setLang = setLang;
		vm.declineRental = declineRental;
		vm.setAgreementSignature = setAgreementSignature;
		vm.declineConsent = declineConsent;
		vm.hideBPWhenLDWisDeclined = hideBPWhenLDWisDeclined;
		vm.handleCustomerOutOfRadius = handleCustomerOutOfRadius;
		vm.navigateToApplication = navigateToApplication;
		vm.declineBp = declineBp;
		vm.declineBpNew = declineBpNew;
		vm.doIfChecked = doIfChecked;
		vm.doIfSuppChecked = doIfSuppChecked;
		vm.handleInitialsStateCheck = handleInitialsStateCheck;
		vm.setEmployeeId = setEmployeeId;
		vm.checkStore = checkStore;
		vm.validateAssociatePasswordCash = validateAssociatePasswordCash;
		vm.checkAssociatePin = checkAssociatePin;
		vm.createInventoryList = createInventoryList;
		vm.isSkipButtonEnable = false;
		vm.agreementHTID = "";
		//AC 25996 code changes
		//AC13303 code changes starts
		vm.voidAgreement = voidAgreement;
		vm.agreementStatus = "";
		//AC13303 code changes ends
		vm.dateSelectionDone = dateSelectionDone;
		vm.setOrderDateAsPerSelection = setOrderDateAsPerSelection;
		vm.openfirstPaymentSelectionCalendar = openfirstPaymentSelectionCalendar;
		//AC24649 Code changes starts
		vm.agreementCodeVerify = agreementCodeVerify;
		vm.reselectNextPaymentError = reselectNextPaymentError;
		$scope.showVerification = true;
		//AC24649 Code changes ends
		//7573 feature
		$scope.verifyAgreement = {};
		$scope.verifyAgreement.textCode = '';
		$scope.verifyAgreement.textCodeAttemptsNumber = 0;
		$scope.verifyAgreement.textCodeError = false;
		$scope.verifyAgreement.agrVerificationNumber = '';
		$scope.submitTextCode = submitTextCode;

		vm.getRAInitials = null;
		vm.setHTML = setHTML;
		vm.cancelAndNavigate = cancelAndNavigate;
		$scope.navigateTo = navigateTo;
		
		//VAN-672 - Low Down Initial Payment should be displayed on RA - Starts
		$scope.isPromoSelected= false;
		vm.selectedPromo;
		vm.showPaymentInitial = showPaymentInitial;
		vm.showInitialPaymentOptions = showInitialPaymentOptions;
		//VAN-672 - Low Down Initial Payment should be displayed on RA - Ends
		
		init();

		function init() {

			$rootScope.hideDashboardButton = true;
			$rootScope.hideDashboardAgreementButton = false;
			$rootScope.hideTrainingLibButton = false;
			$scope.isSpanishStore = $cookies.getObject('isSpanishStore');

			//setting processing fee
			//vm.processingFee = SharedDataSvc.globalVars.estimateReturn.processingFee;

			var currentDate = datesFactory.getCurrentDate(),
			    quoteDates = datesFactory.setQuoteDates(currentDate),
			    findData = {
				'firstName' : null,
				'lastName' : null,
				'phone' : null
			},
			    engagement;
			console.log("quoteDates", quoteDates);
			vm.quoteDates = quoteDates;
			setOrderDates(quoteDates);

			// Default language to English
			SharedDataSvc.globalVars.agreementPrePut.customer.languagePreference = 'ENGLISH';

			// reset inventory
			SharedDataSvc.globalVars.agreementPrePut.inventoryList = [];

			console.log('[Agreement.init] OD', vm.orderData);

			showModal('Processing', 'processing-modal');

			getStoreInfoFactory.getData().then(function(response) {
				$log.log('[Agreement.init] STORE INFO', response);
				vm.storeInfo = response;
				SharedDataSvc.globalVars.storeSetup = response;
				if(response.vanStoreId) {
					$rootScope.storeNo = response.vanStoreId;
 					$rootScope.state = response.state;
 				}
				$rootScope.invoiceMethod = response.invoiceMethod;
				setSkipButtonVisibility(vm.storeInfo.state);
				vm.isEPOAmendmentEnabled = vm.storeInfo.isEPOAmendmentEnabled;

				vm.arrAdjustablePayment = vm.storeInfo.adjustableInitPayment.split(",");
				vm.isAdjustablePayment = parseInt(vm.arrAdjustablePayment[0]);

				stateRestrictFactory.getStateRestrictions().then(function (response) {
					vm.stateRestrictions = response.stateRestrictions[0];
					vm.getRAInitials = _.find(vm.stateRestrictions.allowRAInitials, {'state': vm.storeInfo.state}); 
				});

				stateRestrictFactory.getAllStoreRestrictions(vm.storeInfo.state).then(function(response) {
					vm.restrictions = response;
					vm.storeLatLng = {
						'lat' : vm.storeInfo.latitude,
						'lng' : vm.storeInfo.longitude
					};
					vm.storeRadius = response.allowedRentalRadiusMiles;
					storeExclusions();
					$log.log('[Agreement.init] STORE RESTRICTIONS', response);
				});
			});

			customerDataFactory.findByEngagementId(vm.engagementId).then(function(response) {
				$log.log('[Agreement.init] customerDataFactory.findByEngagementId response: ', response);
				vm.orderContent = (response.orderContent !== null && response.orderContent !== '') ? JSON.parse(response.orderContent) : null;
				vm.engagement = response;

				$log.log('[Agreement.init] engagement Order', vm.orderContent);
				$log.log('[Agreement.init] engagement', vm.engagement);

				vm.customer.customerFirstName = findData.firstName = vm.engagement.customer.fName;
				vm.customer.customerLastName = findData.lastName = vm.engagement.customer.lName;
				findData.phone = vm.engagement.customer.phNum;
				findData.engagementId = vm.engagement.engagementId;//AC14937 code changes added

				vm.engagement = {};
				var engagementProps = ['engagementId', 'orderStatus', 'deliveryStatus', 'paymentStatus', 'salesStatus', 'agreementStatus', 'applicationStatus', 'orderOriginator'];
				// cherry pick the needed properties for a proper engagement
				for (var i = 0; i < engagementProps.length; i++) {
					vm.engagement[engagementProps[i]] = response[engagementProps[i]];
				}
				vm.engagement['customerId'] = response.customer.customerId;
				vm.engagement['customerHtID'] = response.customerHTId;
				vm.engagement['customerHtStoreId'] = response.customerHTStoreId;
				vm.engagement['vanStoreId'] = response.vanStore.storeId;
				console.log(vm.engagement);

				applicationService.getApplication(findData).then(function(response) {
					console.log('[Agreement.init] applicationService.getApplication - Find customer success', response);
					vm.customerFind = ( typeof response !== "object") ? JSON.parse(response) : response;
					//VAN-672 - Low Down Initial Payment should be displayed on RA
					vm.promoSelectedAtAgreement = (vm.customerFind.VanEngagement.promoSelectedAtAgreement !== null && vm.customerFind.VanEngagement.promoSelectedAtAgreement !== '') ? JSON.parse(vm.customerFind.VanEngagement.promoSelectedAtAgreement) : null;
					if(vm.customerFind.VanEngagement.customer && vm.customerFind.VanEngagement.customer.langOption && vm.customerFind.VanEngagement.customer.langOption.trim() === "ENGLISH"){
						vm.languagePreference = true;
						SharedDataSvc.globalVars.agreementPrePut.customer.languagePreference = 'ENGLISH';
					} else {
						vm.languagePreference = false;
						SharedDataSvc.globalVars.agreementPrePut.customer.languagePreference = 'SPANISH';
					}

					if (!response.VanEngagement) {
						console.log('[Agreement.init] applicationService.getApplication - Find customer fail', response);
						showModal('customerNotFound');
					} else {
						console.log('[Agreement.init] applicationService.getApplication - Find customer error', response);
						/* Setting the agreement HTID from findCustomer response */
						vm.agreementHTID = response.VanEngagement.agreementHTId;
						//INCTEC0330947 code changes
						isSameDayAgreement();
						//Defect 26474 code changes starts
						var zip = vm.customerFind.VanEngagement.customer.addressZipcode;
						var zipCode = zip;
						if((zip.length > 5) && (zip.indexOf("-") < 1)) {
							zipCode = zip.substr(0,5) + '-' + zip.substr(5);
						}
						//Defect 26474 code changes ends
						if($scope.isSpanishStore) {
							processAgreementData();
						} else {
							isZipInStoreRadius(zipCode).then(function(response) {
								console.log('[Agreement.init] applicationService.getApplication isZipInStoreRadius ', response);
								if (response) {
									processAgreementData();
								} else {
									showModal('outsideRadius');
								}
							});
						}
					}

				}, function(response) {
					//modalService.dismiss();
					vm.customerFind = response.data.errorData;

					if (!response.data.errorData.VanEngagement) {
						console.log('[Agreement.init] applicationService.getApplication - Find customer fail', response);
						if(response.data.errorData.status_code == "ENG-A4001-400"){
							showModal('agreementAlreadyExist');
						}else{
							showModal('customerNotFound');
						}
					} else {
						console.log('[Agreement.init] applicationService.getApplication - Find customer error', response);
						vm.agreementHTID = response.data.errorData.VanEngagement.agreementHTId;
						//INCTEC0330947 code changes
						isSameDayAgreement();
						//Defect 26474 code changes starts
						var zip = vm.customerFind.VanEngagement.customer.addressZipcode;
						var zipCode = zip;
						if((zip.length > 5) && (zip.indexOf("-") < 1)) {
							zipCode = zip.substr(0,5) + '-' + zip.substr(5);
						}
						//Defect 26474 code changes ends
						if($scope.isSpanishStore) {
							processAgreementData();
						} else {
							isZipInStoreRadius(zipCode).then(function(response) {
								if (response) {
									processAgreementData();
								} else {
									showModal('outsideRadius');
								}
							});
						}
					}
				});

			}).catch(function(response) {
				$log.log('[Agreement.init] could not get customer data', response);
			});

			//get processing fee
			orderEstimatePutFactory.getProcessingFee().then(function(processingFee) {
				vm.processingFee = processingFee;
			});

			//get product category list
			productFactory.getProductCategories().then(function(response) {
				vm.prodCat = response.productCategories;
			});
		}

		function doIfChecked() {
			vm.isAnyUnchecked = !(vm.term1 && vm.term2 && vm.term3 && vm.term4 && vm.term5 && vm.term6 && vm.term7);
		}

		function doIfSuppChecked() {
			//	console.log("[Supp Check]", vm.suppCheck);
			var suppAgreeLen = vm.agreements[1].type.length;
			if (vm.agreements[1].type.indexOf("CYC") > -1) {
				suppAgreeLen = suppAgreeLen - 1;
			}
			var currSuppCheckLen = vm.suppCheck.length;

			if ((suppAgreeLen === currSuppCheckLen)) {
				vm.isAllSuppChecked = true;
				for (var z = 0; z < suppAgreeLen; z++) {
					if (vm.suppCheck[z] == false || vm.suppCheck[z] == undefined) {
						vm.isAllSuppChecked = false;
					}
				}
			} else {
				vm.isAllSuppChecked = false;
			}

			//console.log(vm.isAllSuppChecked);
		}

		function setLang(currentLang) {
			if (!currentLang) {
				SharedDataSvc.globalVars.agreementPrePut.customer.languagePreference = 'SPANISH';
			} else {
				SharedDataSvc.globalVars.agreementPrePut.customer.languagePreference = 'ENGLISH';
			}
			console.log('set lang', SharedDataSvc.globalVars.agreementPrePut.customer.languagePreference);
		}

		function storeExclusions() {
			var bp = _.where(vm.agreements, {
				type : 'CLB'
			}),
			    ldw = _.where(vm.agreements, {
				type : 'LDW'
			});

			// console.log('skipBp', vm.restrictions.skipBp);
			// console.log('skipldw', vm.restrictions.skipLdw);

			if (vm.restrictions.skipBp) {
				delete vm.agreements[5];
				//Have changed here
				vm.hideBp = true;
			}

			if (vm.restrictions.skipLdw) {
				delete vm.agreements[4];
				//Have changed here
			}

			if (!vm.isEPOAmendmentEnabled) {
				delete vm.agreements[3];
				//Have changed here
			}

			console.log('[Agreement.storeExclusions] agreements after exclusions: ', vm.agreements);
		}

		function navigateTo(target) {
			modalService.dismiss();
			$location.path(target);
		};

		function handleCustomerOutOfRadius() {
			showModal($translate.instant("common_label_processing"), 'processing-modal');
			vm.engagement.applicationStatus = 'OutOfArea';
			updateEngagementItemFactory.putData(vm.engagement).then(function(response) {
				$log.log('[Agreement.handleCustomerOutOfRadius] Successfully updated engagement');
				navigateTo('dashboard');
			}, function(response) {
				$log.log('[Agreement.handleCustomerOutOfRadius] Updating engagement failed');
				modalService.dismiss();
			});
		}

		function navigateToApplication() {
			$log.log(vm.engagement);
			customerDataFactory.findByEngagementId(vm.engagement.engagementId).then(function(response) {
				$log.log('[Agreement.getCustomer] customer', response);
				var person = {
					firstName : response.customer.fName,
					lastName : response.customer.lName,
					phone : response.customer.phNum,
					hasEngagementFromAgreement : true
				};
				var personData = window.btoa(JSON.stringify(person));
				$location.path('application/' + vm.engagement.engagementId).search({
					data : personData
				});
			}).catch(function(response) {
				$log.log('[Agreement.getCustomer] could not get customer data', response);
			});
		}

		function dateSelectionDone() {
			vm.showModal($translate.instant("common_label_processing"), 'processing-modal');
			vm.setOrderDateAsPerSelection(vm.adjustableDateSelection);

			/*var paymentModal = modalService.open({ //changes for 9619, VAN-365:VAN: Same payment option for all on same agreement put on hold
				scope: $scope,
				templateUrl: 'partials/modal_paymentOptions.html',
				backdrop: 'static',
				windowClass: 'paymentOptionsModal'
			});
			paymentModal.result.then(function(){
				vm.orderDataForAdjustablePayment.paymentOption = vm.paymentOption;*/
				agreementFactory.setQuotesAsPerSelection(vm.orderDataForAdjustablePayment).then(function(response) {
					console.log('[agreementFactory.setQuotesAsPerSelection] success', response);
					vm.quoteGotAsPerSelection = response;

					vm.showModal('showInitialPaymentInfo', 'initial-payment-info-modal');

				}, function(response) {
					console.log('[agreementFactory.setQuotesAsPerSelection] fail', response);
					modalService.dismiss();
				});
			/*});	*/	
		}

		function getFirstPaymentOptions() {

			console.log('[Agreement.getFirstPaymentOptions] PRE QUOTE DATA', vm.orderContent);

			if (vm.orderContent.orderItems.length > 0) {
				showModal($translate.instant("common_label_processing"), 'processing-modal');

				_.forEach(vm.orderContent.orderItems, function(order, i) {
					productFactory.getProductInfo(vm.orderContent.orderItems[i]).then(function(response) {
						$log.log('product info ' + i, response);
						vm.orderArray.push(response);

						if (i === vm.orderContent.orderItems.length - 1) {

							if (vm.isAdjustablePayment) {
								setOrderDataForAdjustablePayment();
								if (vm.orderDataForAdjustablePayment.feeArray && vm.orderDataForAdjustablePayment.feeArray.length > 1) {
									if (vm.orderContent.deliveryFeeOneTime) {
										vm.orderDataForAdjustablePayment.feeArray[1].feeAcrossTerm = false;
									} else {
										vm.orderDataForAdjustablePayment.feeArray[1].feeAcrossTerm = true;
									}
								}
							} else {
								setOrderData();
								_.forEach(vm.orderData, function(orderData) {
									if (orderData.feeArray && orderData.feeArray.length > 1) {
										if (vm.orderContent.deliveryFeeOneTime) {
											orderData.feeArray[1].feeAcrossTerm = false;
										} else {
											orderData.feeArray[1].feeAcrossTerm = true;
										}

									}
								});
							}

							if (vm.isAdjustablePayment) {
								var start = parseInt(vm.arrAdjustablePayment[1]);
								var end = parseInt(vm.arrAdjustablePayment[2]);
								console.log(vm.arrAdjustablePayment);
								
								datesFactory.getDateByDaysFromNow(start).then(function(response) {
									vm.ipMinDate = response;
									vm.adjustableDateSelection = response;
									datesFactory.getDateByDaysFromNow(end).then(function(responseData) {
										vm.ipMaxDate = responseData;
										vm.openfirstPaymentSelectionCalendar();
									});
								});

							} else {
								agreementFactory.setQuotes(vm.orderData).then(function() {
									vm.quotes = agreementFactory.quotes;
									vm.showModal('firstPayment', 'first-payment-options-modal');

									// $log.log('Quotes', agreementFactory.quotes);
									// $log.log('quote dates', qouteDates);
									$log.log('[Agreement.getFirstPaymentOptions] store info', vm.storeInfo);
								});
							}
						}
					});
				});

			} else {
				console.log("There is nothing in orderItems.");
			}
		}

		function openfirstPaymentSelectionCalendar() {
			vm.showModal('adjustablePayment', 'first-adjustable-payment-modal');
		}

		function handleRentalAgreement() {
			if (!vm.agreements[0].viewed) {
				showModal('viewPdfBeforeSigning');
			} else {
				showModal('eAgreementNotice');
			}
		}

		function handleRentalAgreementDecline() {
			if (!vm.agreements[0].viewed) {
				showModal('viewPdfBeforeSigning');
			} else {
				showModal('declineRental');
			}
		}

		function handleSupAgreement() {
			if (!vm.agreements[1].viewed) {
				showModal('viewPdfBeforeSigning');
			} else {
				showModal('signSupAgree');
			}
		}

		function handleSupAgreementDecline() {
			if (!vm.agreements[1].viewed) {
				showModal('viewPdfBeforeSigning');
			} else {
				showModal('declineSupp');
			}
		}

		function handleLdwDecline(index) {
			if (!vm.agreements[4].viewed) {//Have changed here
				showModal('viewPdfBeforeSigning');
			} else {
				vm.agreements[index].eSign = false;
				showModal('declineLdw');
			}
		}

		function handleEsvAgreement() {
			if (!vm.agreements[7].viewed) {
				showModal('viewPdfBeforeSigning');
			} else {
				showModal('declineEsv');
			}
		}

		function handleEsvDelAgreement() {
			if (!vm.agreements[8].viewed) {
				showModal('viewPdfBeforeSigning');
			} else {
				showModal('declineEsvDel');
			}
		}

		function handleSkipBp() {
			if (!vm.agreements[4].viewed) {
				showModal('viewPdfBeforeSigning');
			} else {
				vm.declineBpNew();
			}
		}

		function handleBpDecline() {
			if (!vm.agreements[5].viewed) {//Have changed here
				showModal('viewPdfBeforeSigning');
			} else {
				vm.declineBpNew();
			}
		}

		function handleRentalAcknowledgement() {
			if (!vm.agreements[2].viewed) {//Added new function here
				showModal('viewPdfBeforeSigning');
			} else {
				showModal('agreementAcknowledge');
			}

		}

		function handleEpoDecline() {
			if (!vm.agreements[3].viewed) {//Added new function here
				showModal('viewPdfBeforeSigning');
			} else {
				showModal('declineEpo');
			}
		}

		function handleInitialsStateCheck() {
			if(vm.getRAInitials){
				vm.initialInstructions = vm.getRAInitials.initialInstructions;
				showModal('agreementRAInitials');
			}
			/*if (vm.storeInfo.state == "FL") {
				showModal('agreementInitials');
			}*/ 
			else {
				showModal('eSignAgreement');
			}
		}

		function setSkipButtonVisibility(state) {
			if (state == "FL" || state == "CA") {
				vm.isSkipButtonEnable = true;
			}
		}

		function showModal(name, windowClass) {
			windowClass = windowClass || '';
			modalService.open({
				templateUrl : '../../../partials/modal_' + name + '.html',
				backdrop : 'static',
				windowClass : windowClass,
				scope : $scope
			});
		}

		function signLdw() {
			if (!vm.agreements[4].agree) {//Have changed me
				showModal('declineLdw', 'decline-ldw-modal');
			}
		}

		function showCorrectOrder(activeAgreementIndex) {
			if (vm.agreements[5] && vm.agreements[4]) {//Have changed here
				if (vm.activeAgreementIndex === 5 && !vm.agreements[4].agree && !vm.bpNotContingentOnLDW) {//Have changed here
					// console.log('LDW has been declined');
					vm.unsignedAgreement = $rootScope.selectedLanguage == 'es' ? vm.agreements[4].name.es : vm.agreements[4].name.en;
					//Have changed here
					showModal('signedAgreementsOrder', 'unsigned-agreements-modal');
					return false;
				} else if (vm.activeAgreementIndex === 6 && !vm.agreements[5].agree && !vm.bpNotContingentOnLDW && vm.agreements[4].agree !== 'DECLINED') {//Have changed here
					// console.log('BP is not signed');
					if (!vm.agreements[4].agree) {//Have changed here
						vm.unsignedAgreement = $rootScope.selectedLanguage == 'es' ? vm.agreements[4].name.es : vm.agreements[4].name.en;
						//Have changed here
					} else {
						vm.unsignedAgreement = $rootScope.selectedLanguage == 'es' ? vm.agreements[5].name.es : vm.agreements[5].name.en;
						//Have changed here
					}

					showModal('signedAgreementsOrder', 'unsigned-agreements-modal');
					return false;
				} else {
					return true;
				}
			} else {
				return true;
			}

		}

		function signAgreement(index) {
			console.log('index', index);

			if (showCorrectOrder(index)) {
				if (vm.agreements[vm.activeAgreementIndex].agree === 'SIGNED') {
					// vm.agreements[vm.activeAgreementIndex].agree = undefined;
				} else {
					if (hasAgreementBeenViewed(index)) {
						/* The line below will reset the esign pop up check box always to none */
						vm.agreements[index].eSign = false;
						showModal('eSignAgreement');
						// vm.agreements[vm.activeAgreementIndex].agree = 'SIGNED';
					} else {
						showModal('viewPdfBeforeSigning');
					}
				}
			}

		}

		function hideBPWhenLDWisDeclined(index) {
			console.log(vm.restrictions);
			if (!vm.restrictions.bpNotContingentOnLdw && index === 4) {//Have changed here
				if (vm.agreements[4].agree === 'DECLINED') {//Have changed here
					console.log('HIDE BP');
					vm.hideBp = true;
				} else {
					console.log('DONT HIDE BP');
					vm.hideBp = false;
				}
			}
		}

		function declineRental() {
			//Story 29237 code changes starts
			showModal($translate.instant("common_label_processing"), 'processing-modal');
			vm.engagement.agreementStatus = 'Declined';
			updateEngagementItemFactory.putData(vm.engagement)
		          .then(function(response) {
				$log.log('Successfully updated engagement');
				modalService.dismiss();
				navigateTo('dashboard');
			}, function(response) {
				$log.log('Updating engagement failed');
				modalService.dismiss();
			});
		}

		//Start - AC-13303 - to update agreementStatus in VAN
		function voidAgreement() {
			showModal($translate.instant("common_label_processing"), 'processing-modal');
			vm.engagement.agreementStatus = 'Voided';
			updateEngagementItemFactory.putData(vm.engagement).then(function(response) {
				$log.log('Successfully updated engagement with agreement status voided');
				modalService.dismiss();
				navigateTo('dashboard');
			}, function(response) {
				$log.log('Updating engagement for agreement status voided failed');
				modalService.dismiss();
			});
		}

		//End - AC-13303 - to update agreementStatus in VAN

		function declineBpNew() {
			if (vm.storeInfo.state == "FL") {
				showModal('declineBenefitsPlus');
			} else {
				showModal('benefitPlusDeclineConfirm');
			}
		}

		function declineBp(index) {
			console.log('DECLINE BP', vm.restrictions.dontSignDeclineBp);
			if (vm.restrictions.dontSignDeclineBp) {
				showModal('declineBenefitsPlus');
			} else {
				/* The line below will reset the esign pop up check box always to none */
				vm.agreements[vm.activeAgreementIndex].eSign = false;
				showModal('eDeclineAgreement');
			}
		}

		function declineAgreement(index) {
			var sign = $translate.instant('agreement_label_declineDesc') +'\n';
			sign += vm.customer.customerFirstName + ' ' + vm.customer.customerLastName + '\n';
			sign += datesFactory.getCurrentDate();

			vm.agreements[index].signature = sign;

			// console.log('decline', index);
			if (vm.agreements[index].agree === 'DECLINE') {
				if (vm.agreements[index].agree !== 1) {
					vm.agreements[index].agree = 'DECLINED';
					// setAgreementSignature('DECLINE');
				} else {
					showModal('declineLdw');
				}
				// Unsign BP if LDW is declined
				if (vm.agreements[index] === 4) {//Have changed here
					declineAgreement(5);
					//Have changed here
				}
			} else {
				vm.agreements[index].agree = 'DECLINED';
				// setAgreementSignature('DECLINE');
			}
		}

		function getCustomer(engagement) {
			$log.log('[Agreement.getCustomer] engagement: ', engagement);
			customerDataFactory.findByEngagementId(vm.engagementId).then(function(response) {
				customer = response;
				vm.customer = response;
				$log.log('[Agreement.getCustomer] customer', customer);
			}).catch(function(response) {
				$log.log('[Agreement.getCustomer] could not get customer data', response);
			});
		}

		function setOrderDates(paymentDates) {
			var requestDate = datesFactory.getCurrentDate();
			console.log('payment dates', paymentDates);
			_.forEach(paymentDates, function(paymentDate, i) {
				vm.orderData[i].requestDte = requestDate;
				vm.orderData[i].nextRenewDte = paymentDate.date;
			});
		}

		function setOrderDateAsPerSelection(selectedDate) {
			var requestDate = datesFactory.getCurrentDate();
			console.log('selectedDate', selectedDate);

			vm.orderDataForAdjustablePayment.requestDte = requestDate;
			vm.orderDataForAdjustablePayment.nextRenewDte = moment(selectedDate).format('YYYY-MM-DD HH:MM:SS');

		}

		function generateKeys() {
			SharedDataSvc.generatedkeys = SharedDataSvc.generatekeys();
			var my_keys = SharedDataSvc.generatedkeys;
			// clean the BS out of the keys
			var cleanKeys = my_keys[0].replace('-----BEGIN RSA PRIVATE KEY-----\n', '');
			my_keys[0] = cleanKeys.replace('\n-----END RSA PRIVATE KEY-----', '');
			// $scope.my_keys[0] = cleanKeys.replace('=', '');
			var cleanKeys3 = my_keys[1].replace('-----BEGIN PUBLIC KEY-----\n', '');
			my_keys[1] = cleanKeys3.replace('\n-----END PUBLIC KEY-----', '');
			SharedDataSvc.cleankeys = my_keys;
			// SharedDataSvc.agreementHeadersENCRYPT['Public-Key'] = my_keys[1];
			// $scope.my_keys[0] = cleanKeys.replace('=', '');
		}

		function formatItemPrice(price) {
			var formattedPrice;
			if (String(price).indexOf('.') > -1) {
				price = String(price).split('.');
				if (price[1].length === 2) {
					formattedPrice = Number(price[0] + price[1]);
				} else {
					formattedPrice = Number(price[0] + price[1] + '0');
				}
			} else {
				formattedPrice = Number(price + '00');
			}
			return formattedPrice;
		}

		//checking if Associate password in case of max associate pin attempts
		function validateAssociatePasswordCash(pass) {
			var auth = SharedDataSvc.base64.decode($cookies.getObject('store_nick_name'));
			if (auth === pass) {
				vm.employeePinError = false;
				vm.employeeId = '';
				showModal('agreementEmployeePin');
			} else {
				vm.invalidAssociatePass = true;
			}
		}

		//asking for Associate PIN if it manned store
		function checkStore() {
			/*if (vm.storeInfo.isManned == true && (!$cookies.getObject('isHybrid'))) {
				showModal('agreementEmployeePin');
			} else {
				setFirstPaymentDate();
				createAgreement();
			}*/
			//VAN-672 - Low Down Initial Payment should be displayed on RA - Starts
			if(vm.storeInfo.lowInitialPaymentEnabled && vm.storeInfo.noCalcPromos && vm.selectedPromo === 'AutoPayRequired') {
				showPaymentInitial(vm.selectedPromo);
			}
			else{
				setFirstPaymentDate();
				createAgreement();
			}
			//VAN-672 - Low Down Initial Payment should be displayed on RA - Ends
		}
		
		//AC24649 Code changes starts
		function agreementCodeVerify() {
			if((!vm.storeInfo.isManned && vm.storeInfo.agreementVerification) ||
					($cookies.getObject('isHybrid') && vm.storeInfo.agreementVerification)){
			console.log('call agreement verfication modal');
			showModal($translate.instant("common_label_processing"), 'processing-modal');
			$scope.verifyAgreement.agrVerificationNumber = vm.storeInfo.agrVerificationNumber;
			agreementFactory.agreementVerification(vm.engagementId).then(
					function (response) {
						modalService.dismiss();
						$scope.textCode = response;
						var submitTextCodeModal = modalService.open({
							title: 'Confirm Email',
							templateUrl: 'partials/modal_confirmAgreementVerification.tpl.html',
							scope: $scope,
							backdrop: 'static'
						});
					},
					// Error
					function (error) {
						// sending error to Raygun
						SharedDataSvc.reportRaygun(error);
						console.log(error);
					});
			} 
		}
		
		function reselectNextPaymentError() {
			modalService.open({
				templateUrl : 'partials/modal_confirmInitialPaymentInfo.html',
				backdrop : 'static',
				scope : $scope
			});
		}
		//AC24649 Code changes ends
		
		//checking for Associate PIN if entered or not
		function checkAssociatePin(pin) {
			if (pin) {
				setFirstPaymentDate();
				createAgreement();
			} else {
				$scope.noEmpPin = true;
				console.log('no associate pin', vm.noEmpPin);
			}
		}

		function setFirstPaymentDate() {
			if (vm.isAdjustablePayment) {
				var selectedDate = moment(vm.adjustableDateSelection).format('YYYYMMDD');
				SharedDataSvc.globalVars.agreementPrePut.initialPaymentDate = selectedDate;
				console.log('[Agreement.setFirstPaymentDate] SET FIRST DATE', selectedDate);
			} else {
				var selectedDate = moment(vm.quoteDates[vm.firstPayment].date).format('YYYYMMDD');
				SharedDataSvc.globalVars.agreementPrePut.initialPaymentDate = selectedDate;
				console.log('[Agreement.setFirstPaymentDate] SET FIRST DATE', selectedDate);
			}
		}

		//sending Associate PIN through post
		function setEmployeeId(employeeId) {
			SharedDataSvc.globalVars.agreementPrePut.empin = employeeId;
		}

		function processAgreementData() {
			//modalService.dismiss();
			if (vm.isAdjustablePayment) {
				setCustomerOrderDataForAdjustablePayment(vm.customerFind);
			} else {
				setCustomerOrderData(vm.customerFind);
			}

			//AC 25996 code changes
			if (isSameDayAgreement() && (vm.agreementHTID !== undefined && vm.agreementHTID !== null)) {
				console.log('[Agreement.init] applicationService.getApplication - Is same day agreements');
				handleSameDayAgreements();
			}
			//VAN-735 - PRB0042975- AWP - Application creating new agreement for an active customer re-engaging next day - Starts
			else if (!isSameDayAgreement()&& vm.customerFind.VanEngagement != undefined && vm.customerFind.agreement !== undefined  && vm.agreementHTID !== undefined && vm.agreementHTID !== null && vm.customerFind.agreement.agreementStatus === 'ACTIVE' && vm.customerFind.VanEngagement.agreementStatus === 'Pending'){
				console.log("Agreement is Active");
				modalService.dismiss();
			}
			//VAN-735 - PRB0042975- AWP - Application creating new agreement for an active customer re-engaging next day - Ends 
			else {
				showModal('langOption', 'modal-lang-option');
			}
		}
		
		function showSameDayAgreementModal(){
			console.log('SAME DAY AGREEMENT');		
			var processingModal = modalService.open({
				scope: $scope,
				windowClass: '',
				templateUrl: 'partials/modal_sameDayAgree.html',
				backdrop: 'static'
			});
		}

		function createAgreement() {
			console.log("[Agreement.createAgreement] ");
			showModal($translate.instant("common_label_processing"), 'processing-modal');
			var agreementData = SharedDataSvc.globalVars.agreementPrePut,
			    prodCat,
			    extendedWarranty = {
				"racBrand" : "ACP",
				"itemModelNumber" : "ExtSvc",
				"racModelNumber" : "ESVPLAN-1",
				"depreciableLifeInMonths" : 24,
				"racRateCode" : "ESV396",
				"itemOriginalCost" : formatItemPrice(vm.orderContent.extendedServiceAmount)//AC21269 code change
			};

			// Set agreement data to send before creating the agreement
			console.log("[Agreement.createAgreement] vm: ", vm);
			agreementData.customer['id'] = vm.engagement.customerId;
			agreementData.customer.engagementId = Number(vm.engagementId);
			agreementData.customer.languagePreference = SharedDataSvc.globalVars.agreementPrePut.customer.languagePreference;
			agreementData.deliveryFee = formatItemPrice(vm.orderContent.deliveryFee);//AC23321 code change 
			agreementData.processingFee = (vm.processingFee * 100);
			agreementData.emailUnsignedAgreements = false;

			console.log('[Agreement.createAgreement] Order Items', vm.orderContent.orderItems);

			productFactory.getProductCategories().then(function(response) {
				prodCat = response.productCategories;

				console.log('[Agreement.createAgreement] order items len', vm.orderContent.orderItems.length);
				_.forEach(vm.orderContent.orderItems, function(item, index) {
					var orderItem = {
						"racBrand" : "ACP", // 'ESV' for extended
						"itemModelNumber" : "", // 'ExtSvc' for extended
						"racModelNumber" : "",
						"depreciableLifeInMonths" : 24,
						"itemOptions" : "",
						"itemOriginalCost" : 0
					};

					orderItem.depreciableLifeInMonths = 24;
					orderItem.itemModelNumber = "";
					orderItem.itemOptions = productFactory.buildItemOptions(item);
					orderItem.itemOriginalCost = formatItemPrice(item.itemPrice);
					orderItem.racBrand = "ACP";
					orderItem.racModelNumber = productFactory.getRacModelNumber(prodCat, item, $rootScope.selectedLanguage);//VAN-737 - PRB0042979 - AWP - Adding RAnges/Electric giving error in AWP on order screen - Added the selectedlanguage
					console.log('[Agreement.createAgreement] AUGMENTED ITEM', orderItem);
					agreementData.inventoryList.push(orderItem);
				});

				if (vm.orderContent.extendedServiceAmount > 0) {
					agreementData.inventoryList.push(extendedWarranty);
				}
				//VAN-672 - Low Down Initial Payment should be displayed on RA - Starts
				if(vm.storeInfo.lowInitialPaymentEnabled && vm.storeInfo.noCalcPromos) {
					if(vm.selectedPromo === 'AutoPayRequired'){
						agreementData.selectedPromoOptions = vm.storeInfo.noCalcPromos[0];
					}
					else {
						agreementData.selectedPromoOptions = vm.storeInfo.noCalcPromos[1];
					}
				}
				//VAN-672 - Low Down Initial Payment should be displayed on RA - Ends
				$log.log('[Agreement.createAgreement] PRE CREATE AGREEMENT', agreementData);

				agreementFactory.createAgreement(agreementData, keys).then(function(response) {
					response = JSON.parse(response);
					$log.log('[Agreement.createAgreement] AGREEMENT DATA', response);
					vm.agreementData = response;
					if((!vm.storeInfo.isManned && vm.storeInfo.agreementVerification) ||
							($cookies.getObject('isHybrid') && vm.storeInfo.agreementVerification)){
						console.log('call agreement verfication modal');
						$scope.verifyAgreement.agrVerificationNumber = vm.storeInfo.agrVerificationNumber;
						agreementFactory.agreementVerification(vm.engagementId).then(
								function (response) {
									modalService.dismiss();
									$scope.textCode = response;
									var submitTextCodeModal = modalService.open({
										title: 'Confirm Email',
										templateUrl: 'partials/modal_confirmAgreementVerification.tpl.html',
										scope: $scope,
										backdrop: 'static'
									});
								},
								// Error
								function (error) {
									// sending error to Raygun
									SharedDataSvc.reportRaygun(error);
									console.log(error);
								});
					}else{
						modalService.dismiss();
						//showModal('checkCustomerId', 'check-customer-modal'); Commenting for Story 29360 changes - by TCS
					}
					setPdfData(response.documentsLst);
				}).catch(function(response) {
					modalService.dismiss();
					// reset inventory
					SharedDataSvc.globalVars.agreementPrePut.inventoryList = [];
					
					/* Same day agreement scenario check */
					if (response.status === 400 && response.data.errorData.status_message.indexOf('A completed agreement already exists') > -1) {
						showSameDayAgreementModal();
						return;
					}

					//time out scenario, record Locked scenario 409 and brand model not found scenario
					if (response.status === 502 || response.data.errorData.status_code === "ENG-A4001-409" || response.data.errorData.status_message === $translate.instant("agreement_label_errorModelNotFound")) {

						/* Showing modal only for one scenario. Rest all are handled in service-agent.js */
						if (response.data.errorData.status_message === $translate.instant("agreement_label_errorModelNotFound")) {
							showModal('agreementError');
						}
						$log.log('[Agreement.createAgreement] could not create agreements', response);
					}else {
						$log.log('Error processing create agreement', response);
						modalService.dismiss();
						//var status_details = JSON.parse(response.data.errorData.status_details);
						console.log('CREATE AGREEMENT RESPONSE', response);
						//console.log('STATUS', status_details);
						//error handling when PIN is incorrect
						if (response.data.errorData.status_message.indexOf('Employee pin is not valid') > -1) {
							vm.pinAttempts = vm.pinAttempts + 1;
							vm.initialPaymentStatus = false;
							vm.initialPaymentErrorClass = '';
							vm.employeeId = '';
							console.log('ATTEMPTS', vm.pinAttempts);
							if (vm.pinAttempts === 3) {
								vm.pinAttempts = 0;
								vm.invalidAssociatePass = false;
								showModal('tooManyPinAttemptsAgreement');
							} else {
								vm.employeePinError = true;
								showModal('agreementEmployeePin');
							}
						} else {
							vm.initialPaymentStatus = true;
							vm.initialPaymentErrorClass = 'alert-danger';
							vm.initialPaymentMessage = 'Payment Failed';
						}
					}
				});
			});
		}

		function submitTextCode(){
			$scope.verifyAgreement.textCodeError = false;
			$scope.verifyAgreement.textCodeAttemptsNumber += 1;
			if($scope.textCode == $scope.verifyAgreement.textCode && $scope.verifyAgreement.textCodeAttemptsNumber <= 3){
				modalService.dismiss();
				//showModal('checkCustomerId', 'check-customer-modal'); Commenting for Story 29360 changes - by TCS
			}else if($scope.verifyAgreement.textCodeAttemptsNumber == 3){
				$scope.verifyAgreement.textCodeAttemptsNumber = 0;
				$scope.verifyAgreement.textCode = '';
				navigateTo('dashboard');
			}
			else{
				$scope.verifyAgreement.textCodeError = true;
				$scope.verifyAgreement.textCode = '';
			}
		}

		function handleBpForAllAgreements() {
			if (vm.agreementData && vm.agreementData.documentsLst[6]) {
				$log.log('new agreements');
				vm.hideBp = false;
			} else {
				$log.log('old agreements');
				vm.hideBp = true;
			}
		}

		function isAllAgreementsSigned() {

			if (vm.agreements[0].agree === "SIGNED" && vm.agreements[2].agree === "SIGNED" && vm.agreements[6].agree === "SIGNED") {

				//If EPO is there
				if (vm.agreements[3]) {

					if (!(vm.agreements[3].agree === "SIGNED" || vm.agreements[3].agree === "DECLINED")) {
						return false;
					}
				}

				//If Supplementary is there
				if (vm.agreements[1].pdf.length > 0) {
					if (vm.agreements[1].agree !== "SIGNED") {
						return false;
					}
				}

				// If Warranty Agreement is there
				if (vm.agreements[7]) {
					if (!(vm.agreements[7].agree === 'SIGNED' || vm.agreements[7].agree === 'DECLINED')) {
						return false;
					}
				}

				// If Delivery fee Agreement is there
				if (vm.agreements[8]) {
					if (!(vm.agreements[8].agree === 'SIGNED' || vm.agreements[8].agree === 'DECLINED')) {
						return false;
					}
				}

				//If LDW and BP is there
				if (vm.agreements[4] && vm.agreements[5]) {

					//If LDW is signed
					if (vm.agreements[4].agree === "SIGNED") {

						//If BP is signed or declined
						if (vm.agreements[5].agree === "SIGNED" || vm.agreements[5].agree === "DECLINED") {
							return true;
						} else {
							return false;
						}

					} else {

						//If LDW is declined
						if (vm.agreements[4].agree === "DECLINED") {
							return true;
						} else {
							return false;
						}

					}

				} else {

					//If LDW and BP is not there
					if (!vm.agreements[4] && !vm.agreements[5]) {
						return true;
					} else {

						// If LDW is there and BP is not there
						if (vm.agreements[4] && !vm.agreements[5]) {

							//If LDW is either signed or declined
							if (vm.agreements[4].agree == 'SIGNED' || vm.agreements[4].agree == 'DECLINED') {
								return true;
							} else {
								return false;
							}

						} else {

							//If BP is either signed or declined
							if (vm.agreements[5].agree == 'SIGNED' || vm.agreements[5].agree == 'DECLINED') {
								return true;
							} else {
								return false;
							}

						}
					}
				}

			} else {

				return false;

			}

		}

		function submitAgreements() {
			console.log('AGREEMENTS', vm.agreements);
			var agree;
			// TODO - add check for states that do not include all agreements
			/*if(!vm.agreements[2] && !vm.agreements[3]) {					//Have changed here
			 agree = ['SIGNED', 'SIGNED'];
			 } else {
			 agree = _.pluck(vm.agreements, 'agree');
			 }*/

			if (isAllAgreementsSigned()) {
				showModal($translate.instant("common_label_processing"), 'processing-modal');
				// $log.log('all agreements signed');
				var promise = setCalcPaymentSpec();
				setSignatureData();

				promise.then(function() {

					console.log('Signature Data', SharedDataSvc.globalVars.signatureTemplate);
					console.log('PAYMENT SPEC', SharedDataSvc.globalVars.paymentCalcSetup);

					paymentFactory.pdfSign(SharedDataSvc.globalVars.signatureTemplate).then(function(response) {
						console.log("Pdf signer service response", response);

						paymentFactory.calculatePayment(SharedDataSvc.globalVars.paymentCalcSetup).then(function(response) {
							$log.log('AGREEMENTS COMPLETE', response);
							setAgreementStatus('Accepted');

							//AC 25996 code changes starts
							//if( !isSameDayAgreement()){ //On Sameday, we can have multiple agreements if order is edited. Commenting this condition because reading AgreementHTId from customerFind was overwriting agreementHTID column in db with previous agreement value.
							if (vm.agreementData !== undefined) {
								vm.engagement.agreementHtId = vm.agreementData.agreement.raNumber;
							} else {
								vm.engagement.agreementHtId = vm.customerFind.VanEngagement.agreementHTId;
							}
							//AC 25996 code changes ends

							console.log('Update engagement', vm.engagement);
							updateEngagementItemFactory.putData(vm.engagement).then(function(response) {
								$log.log('Successfully updated engagement');
								modalService.dismiss();
								navigateTo('dashboard');
							}, function(response) {
								$log.log('Updating engagement failed');
								// sending error to Raygun
								SharedDataSvc.reportRaygun(response);
								modalService.dismiss();
							});

							/* Resetting the globalVars for signature and paymentCalcSetup below */
							SharedDataSvc.globalVars.signatureTemplate = {
								"customerId" : null,
								"engagementId" : null,
								"language" : "english",
								"signatures" : []
							};

							SharedDataSvc.globalVars.paymentCalcSetup = {
								"customerEngagementId" : null,
								"paymentToDate" : "20150301", // initial payment date
								"id" : null, // ht store id
								"customerId" : null,
								"clubSwitch" : "N",
								"waiverSwitch" : "DECLINE",
								"hoCustId" : null, // customer ht id
								"agreementNumber" : null
							};

						}, function(response) {
							$log.log('Submit agreements failed');

							console.log("[CalculatePayment Service Failed here]", response);
							//AC13233 & AC12058 code changes start - on submit agreement
							if (response.status === 400 && response.data.errorData.status_details.indexOf('T2238') > -1) {
								$rootScope.modalMessage = $translate.instant("agreement_label_errorInUpdate")+"[789]";
								//14120 message change as per business.
								vm.modal = modalService.open({
									templateUrl : '../../../partials/alert_serviceError.tpl.html',
									backdrop : 'static',
									scope : $scope
								});
							} else {
								showModal('agreementError');
							}
							//AC13233 & AC12058 code changes end

							// sending error to Raygun
							SharedDataSvc.reportRaygun(response);
						});

					}, function(response) {

						console.log("[Pdf Signer Service failed]", response);
						showModal('agreementError');

					});
				});

			} else {
				$log.log('unsigned agreements');
				modalService.dismiss();

				showModal('unsignedAgreements', 'unsigned-agreements-modal');
			}
		}

		function setPdfData(documentsLst) {
			// console.log('set PDF data');
			vm.hideBp = true;
			vm.showWarrantyAgreement = false;
			vm.showDelWarrantyAgreement = false;

			vm.agreements[1].pdf = [];
			vm.agreements[1].type = [];

			_.forEach(documentsLst, function(agreement) {
				// console.log('DOCUMENTSLIST', agreement);
				if (agreement.documentType === 'RA') {
					vm.agreements[0].pdf = agreement.data;
				} else if (agreement.documentType === 'ACK') {
					vm.agreements[2].pdf = agreement.data;
					// Added a new line
				} else if (agreement.documentType === 'EPO90') {
					vm.agreements[3].pdf = agreement.data;
					// Added a new line
				} else if (agreement.documentType === 'LDW') {
					vm.agreements[4].pdf = agreement.data;
					//Have changed here
				} else if (agreement.documentType === 'ARB') {
					vm.agreements[6].pdf = agreement.data;
					//Have changed here
				} else if (agreement.documentType === 'FEE') {
					vm.showWarrantyAgreement = true;
					vm.agreements[7].pdf = agreement.data;
					//Have changed here
				} else if (agreement.documentType === 'dlf') {
					vm.showDelWarrantyAgreement = true;
					vm.agreements[8].pdf = agreement.data;
					//Have changed here
				} else if (agreement.documentType === 'CLB') {
					vm.hideBp = false;
					vm.agreements[5].pdf = agreement.data;
					//Have changed here
				} else if (agreement.documentType === 'CAM' || agreement.documentType === 'COP' || agreement.documentType === 'CRB' || agreement.documentType === 'CYC' || agreement.documentType === 'GAS' || agreement.documentType === 'LCD' || agreement.documentType === 'RAN' || agreement.documentType === 'SOC' || agreement.documentType === 'VID') {
					vm.agreements[1].pdf.push(agreement.data);
					vm.agreements[1].type.push(agreement.documentType);
					vm.showSupp = true;
				}

			});

			if (vm.agreements[7].pdf == undefined) {
				delete vm.agreements[7];
			}

			if (vm.agreements[8].pdf == undefined) {
				delete vm.agreements[8];
			}

			console.log("Supplementry types", vm.agreements[1].type);

			if (vm.hideBp && vm.agreements[5]) {
				vm.agreements[5].agree = 'SIGNED';
				//Have changed here
				vm.agreements[5].viewed = true;
				//Have changed here
				console.log('Agreements', vm.agreements);
			}
			console.log('hide BP', vm.hideBp);
		}

		function setAgreementViewed(index) {
			vm.agreements[index].viewed = true;
		}

		function setAgreementUnviewed(index) {
			vm.agreements[index].viewed = false;
		}

		function hasAgreementBeenViewed(index) {
			return vm.agreements[index].viewed;
		}

		function openAgreementPdf(index) {
			setAgreementViewed(index);

			if (index == 1) {

				var supPdfArr = [];
				for (var x = 0; x < vm.agreements[index].pdf.length; x++) {
					var pdfBlob = SharedDataSvc.createPdfBlob(vm.agreements[index].pdf[x]);
					supPdfArr.push(pdfBlob);

					if (navigator.msSaveOrOpenBlob) {
						navigator.msSaveOrOpenBlob(pdfBlob);
						var returnVal = true;
					} else {
						var objectURL = window.URL.createObjectURL(pdfBlob);
						var returnVal = window.open(objectURL, "_blank");
					}

				}

				if (!returnVal) {
					setAgreementUnviewed(index);
					alert("Disable the pop up blocker for this site and click 'view agreements' link again, to unblock and view all the Supplemental agreements");
				} else {
					setAgreementViewed(index);
				}

			} else {
				var pdfBlob = SharedDataSvc.createPdfBlob(vm.agreements[index].pdf);
				if (navigator.msSaveOrOpenBlob) {
					navigator.msSaveOrOpenBlob(pdfBlob);
				} else {
					var objectURL = window.URL.createObjectURL(pdfBlob);
					window.open(objectURL);
				}
			}

		}

		function setAgreementSignature(status) {
			
			if(SharedDataSvc.globalVars.agreementPrePut.customer.languagePreference == "ENGLISH"){
				var sign = 'Signed via click-through AcceptanceNOW web portal.\n';
				sign += vm.customer.customerFirstName + ' ' + vm.customer.customerLastName + '\n';
				sign += datesFactory.getCurrentDate();
			}else{
				var sign = 'Firmado vía virtual a través del sitio web AcceptanceNow.\n';
				sign += vm.customer.customerFirstName + ' ' + vm.customer.customerLastName + '\n';
				sign += datesFactory.getCurrentDate();
			}
			
			

			console.log("Intials Entered is", vm.inputInitials);
			console.log('ACTIVE AGREE', vm.agreements[vm.activeAgreementIndex].agree);
			vm.agreements[vm.activeAgreementIndex].agree = status;
			vm.agreements[vm.activeAgreementIndex].signature = sign;

			console.log('signature set', vm.activeAgreementIndex);
			console.log('AGREEMENTS', vm.agreements);
			//AC13194 Code changes start
			if (vm.agreements[5] && vm.agreements[5].pdf !== undefined && vm.agreements[5].pdf !== null) {
				hideBPWhenLDWisDeclined(vm.activeAgreementIndex);
			}
			//AC13194 Code changes end
		}

		function setSignatureData() {

			var signatureData = SharedDataSvc.globalVars.signatureTemplate;

			signatureData.customerId = vm.engagement.customerId;
			signatureData.engagementId = Number(vm.engagementId);
			signatureData.language = SharedDataSvc.globalVars.agreementPrePut.customer.languagePreference.toLowerCase();
			//	signatureData.pdfProfileName = vm.storeInfo.state;

			if (vm.agreements[1] && vm.agreements[1].agree === "SIGNED") {

				signatureData.signatures = [];
				var typeLen = vm.agreements[1].type.length;

				for (var b = 0; b < typeLen; b++) {
					var signData = {
						"docType" : vm.agreements[1].type[b],
						"signature" : vm.agreements[1].signature
					};

					signatureData.signatures.push(signData);
				}

			}
			console.log("[Supplementary agreement signatures]", signatureData.signatures);

			if (vm.agreements[2] && vm.agreements[2].agree === "SIGNED") {
				var ackSignObj = {
					"signature" : vm.agreements[2].signature,
					"docType" : vm.agreements[2].type
				};

				signatureData.signatures.push(ackSignObj);
			}

			if (vm.agreements[4] && vm.agreements[4].agree === 'SIGNED') {//Have changed here

				var ldwSignObj = {
					"formSwitch" : true,
					"signature" : vm.agreements[4].signature,
					"docType" : vm.agreements[4].type
				};

			} else {

				if (vm.agreements[4]) {
					var ldwSignObj = {
						"formSwitch" : false,
						"signature" : vm.agreements[4].signature,
						"docType" : vm.agreements[4].type
					};

				}

			}
			if (ldwSignObj !== undefined) {
				signatureData.signatures.push(ldwSignObj);
			}

			if (vm.agreements[5] && vm.agreements[5].agree === 'SIGNED') {//Have changed here

				var bPlusObj = {
					"formSwitch" : true,
					"frequency" : "MONTHLY",
					"signature" : "",
					"docType" : vm.agreements[5].type
				};

			} else {

				if (vm.agreements[5]) {

					var bPlusObj = {
						"formSwitch" : false,
						"frequency" : "MONTHLY",
						"signature" : "",
						"docType" : vm.agreements[5].type
					};

				}

			}

			if (!vm.hideBp && vm.agreements[5]) {

				bPlusObj.signature = vm.agreements[5].signature;

			} else {

				if (vm.agreements[5]) {

					vm.agreements[5].agree = "";
					//Have changed here
					bPlusObj = undefined;

				}

			}
			if (bPlusObj !== undefined) {
				signatureData.signatures.push(bPlusObj);
			}

			if (vm.agreements[3] && vm.isEPOAmendmentEnabled) {

				var epoSignObj = {

					"signature" : vm.agreements[3].signature,
					"docType" : vm.agreements[3].type

				};

			}
			if (epoSignObj !== undefined) {
				signatureData.signatures.push(epoSignObj);
			}

			var rentalAgreeObj = {
				"signature" : vm.agreements[0].signature,
				"docType" : vm.agreements[0].type,
				"initial" : vm.inputInitials
			};
			signatureData.signatures.push(rentalAgreeObj);

			var arbSignObj = {
				"signature" : vm.agreements[6].signature,
				"docType" : vm.agreements[6].type

			};
			signatureData.signatures.push(arbSignObj);

			//ESV Agreement
			if (vm.agreements[7] && vm.agreements[7].agree === "SIGNED") {
				var esvSignObj = {
					"signature" : vm.agreements[7].signature,
					"docType" : vm.agreements[7].type
				};
				signatureData.signatures.push(esvSignObj);
			}

			//ESV DEL Agreement
			if (vm.agreements[8] && vm.agreements[8].agree === "SIGNED") {
				var esvSignObj = {
					"signature" : vm.agreements[8].signature,
					"docType" : vm.agreements[8].type
				};
				signatureData.signatures.push(esvSignObj);
			}

			SharedDataSvc.globalVars.signatureTemplate = signatureData;

		}

		// TODO - need to add processing loading modal
		function setCalcPaymentSpec() {
			console.log('set calc spec');

			var deferred = $q.defer();

			var paymentSpec = SharedDataSvc.globalVars.paymentCalcSetup;

			setFirstPaymentDate();

			paymentSpec.customerEngagementId = Number(vm.engagementId);
			paymentSpec.id = vm.engagement.customerHtStoreId;

			paymentSpec.customerId = vm.engagement.customerId;
			paymentSpec.clubSwitch = 'N';
			paymentSpec.waiverSwitch = 'DECLINE';
			paymentSpec.hoCustId = vm.customer.customerHtId;

			if (vm.agreementData !== undefined) {//AC 25996 code changes
				paymentSpec.agreementNumber = vm.agreementData.agreement.raNumber;
				paymentSpec.paymentToDate = SharedDataSvc.globalVars.agreementPrePut.initialPaymentDate;
			} else {
				paymentSpec.agreementNumber = vm.customerFind.VanEngagement.agreementHTId;
				paymentSpec.paymentToDate = vm.customerFind.VanEngagement.nextPaymentDate;
			}

			// console.log('waiver switch', vm.agreements[1].agree);
			// console.log('club switch', vm.agreements[2].agree);

			if (vm.agreements[4] && vm.agreements[4].agree === 'SIGNED') {//Have changed here
				paymentSpec.waiverSwitch = 'ACCEPT';
			} else {
				paymentSpec.waiverSwitch = 'DECLINE';
			}

			if (vm.agreements[5] && vm.agreements[5].agree === 'SIGNED' && !vm.hideBp) {//Have changed here
				paymentSpec.clubSwitch = 'Y';
			} else if (vm.agreements[5] && vm.agreements[5].agree === 'DECLINED') {
				paymentSpec.clubSwitch = 'N';
			} else {
				paymentSpec.clubSwitch = 'N';
			}

			SharedDataSvc.globalVars.paymentCalcSetup = paymentSpec;

			if (SharedDataSvc.globalVars.paymentCalcSetup && SharedDataSvc.globalVars.agreementPrePut.initialPaymentDate) {
				deferred.resolve("PaymentCalcSetup and initialPaymentDate has been set");
			} else {
				deferred.reject("PaymentCalcSetup or intialPaymentDate is not set yet");
			}

			return deferred.promise;

		}

		function setAgreementStatus(status) {
			vm.engagement.agreementStatus = status;
		}

		function declineConsent() {
			showModal('Processing', 'processing-modal');
			setAgreementStatus('Declined');
			console.log('decline consent', vm.engagement);
			updateEngagementItemFactory.putData(vm.engagement).then(function(response) {
				$log.log('Successfully updated engagement');
				modalService.dismiss();
				navigateTo('dashboard');
			}, function(response) {
				$log.log('Updating engagement failed');
			});
		}
		
		function updateEngagement() {
			updateEngagementItemFactory.putData(vm.engagement).then(function(response) {
				$log.log('Successfully updated engagement');
			}, function(response) {
				$log.log('Updating engagement failed');
			});
		}

		function formatAgreementDate(date) {
			var year = String(date).substring(0, 4),
			    month = String(date).substring(4, 6),
			    day = String(date).substring(6, 8);

			// 20140825
			console.log('formatted date', month + '-' + day + '-' + year);
			return month + '-' + day + '-' + year;
		}

		function isSameDayAgreement() {
			var today = datesFactory.getCurrentDate(),
			    agreementsExist = vm.customerFind.agreement ? true : false,
			    agreementDate = vm.customerFind.agreement ? formatAgreementDate(vm.customerFind.agreement.agreementDate) : false,
			    isSameDay = moment(agreementDate).isSame(today, 'day') ? true : false;
			// Set line below to today to check for same day agreements
			// isSameDay = moment('4-27-2015').isSame(today, 'day') ? true : false;

			return isSameDay;
		}

		function handleSameDayAgreements() {
			vm.agreementDataForClauseUse = vm.customerFind.agreement;
			if (vm.customerFind.agreement && isSameDayAgreement() && (vm.agreementHTID !== undefined && vm.agreementHTId !== null)) {//AC 25996 code changes
				//Start - AC13303 - Error 700 on submit agreements
				if (vm.customerFind.agreement && (vm.customerFind.agreement.agreementStatus === "INACTIVE")) {
					vm.agreementStatus = vm.customerFind.agreement.agreementStatus;
					var servicePhoneNumber = SharedDataSvc.globalVars.storeSetup.vipPhoneNumber;
					//AC-28614 Changes
					//Commented out the Active check since customers will be allowed to docusign after payment complete in HT
//					if (vm.customerFind.agreement.agreementStatus === "ACTIVE") {
//						$rootScope.modalMessage = $translate.instant('agreement_label_errorReceiveAgreement') + servicePhoneNumber + '. [99]';
//					} else {
						$rootScope.modalMessage = $translate.instant('agreement_label_errorDeclineAgreement') + ' [44]';
//					}
					modalService.open({
						templateUrl : 'partials/modal_agreementStatusNotinPending.html',
						backdrop : 'static',
						scope : $scope
					});
					return false;
				} else {
					//End - AC13303 - Error 700 on submit agreements
					var agreements = vm.customerFind.agreement;
					vm.agreements[1].pdf = [];
					vm.agreements[1].type = [];

					console.log("[Same Day agreements variable]", agreements);

					for (var key in agreements) {

						if (key === 'UnsignedCAM' || key === 'UnsignedCOP' || key === 'UnsignedCRB' || key === 'CYC' || key === 'UnsignedGAS' || key === 'UnsignedLCD' || key === 'UnsignedRAN' || key === 'UnsignedSOC' || key === 'UnsignedVID') {

							vm.agreements[1].pdf.push(agreements[key]);
							var keyForSigning = key.replace("Unsigned", "");
							vm.agreements[1].type.push(keyForSigning);
							vm.showSupp = true;

						}

					}
					//  vm.agreements[1].pdf = 'data:application/pdf;base64,' + escape(agreements.UnsignedSAC);
					vm.agreements[2].pdf = agreements.UnsignedRAAck;
					//Have changed here
					vm.agreements[0].pdf = agreements.UnsignedRA;

					if (agreements.EpoAmendment) {
						vm.agreements[3].pdf = agreements.EpoAmendment;
						//Have changed here
					}

					if (agreements.UnsignedLDW) {
						vm.agreements[4].pdf = agreements.UnsignedLDW;
						//Have changed here
					}

					if (agreements.CLB) {
						vm.agreements[5].pdf = agreements.CLB;
						//Have changed here
					}

					vm.agreements[6].pdf = agreements.UnsignedARB;
					//Added a new line here

					if (agreements.UnsignedFEE) {
						vm.agreements[7].pdf = agreements.UnsignedFEE;
						vm.showWarrantyAgreement = true;
					} else {
						delete vm.agreements[7];
					}

					if (agreements.UnsignedDLF) {
						vm.agreements[8].pdf = agreements.UnsignedDLF;
						vm.showDelWarrantyAgreement = true;
					} else {
						delete vm.agreements[8];
					}
				} //AC13303 - Error 700 on submit agreements
				//AC24649 Code changes starts
				if(vm.isAdjustablePayment){
					if(vm.customerFind.VanEngagement.customer && vm.customerFind.VanEngagement.customer.langOption && vm.customerFind.VanEngagement.customer.langOption.trim() === "ENGLISH"){
						vm.languagePreference = true;
					} else {
						vm.languagePreference = false;
					}
					var requestDate = datesFactory.getCurrentDate();				
					vm.orderDataForAdjustablePayment.requestDte = requestDate;
					vm.orderDataForAdjustablePayment.nextRenewDte = moment(vm.customerFind.VanEngagement.nextPaymentDate,'YYYYMMDD',true).format("YYYY-MM-DD HH:mm:ss");
					//vm.orderDataForAdjustablePayment.nextRenewDte = "2016-09-16 23:59:59";
					setOrderDataForAdjustablePayment();
					agreementFactory.setQuotesAsPerSelection(vm.orderDataForAdjustablePayment).then(function(response) {
						console.log('[agreementFactory.setQuotesAsPerSelection] success', response);
						vm.quoteGotAsPerSelection = response;
						vm.adjustableDateSelection = moment(vm.customerFind.VanEngagement.nextPaymentDate,'YYYYMMDD',true).format("MMM DD, YYYY");
						vm.showModal('samedayInitialPaymentInfo', 'initial-payment-info-modal');
					}, function(response) {
						console.log('[agreementFactory.setQuotesAsPerSelection] fail', response);
					});
					
				}else {
				
					agreementCodeVerify();
				}//AC24649 Code changes ends
			}

		}

		function setOrderData() {
			var orderItems = [];
			_.forEach(vm.orderContent.orderItems, function(item, i) {
				var orderItem = {};
				// orderItem.unitType = vm.orderArray[i].unitType;
				// orderItem.subType = vm.orderArray[i].subType;
				orderItem.itemId = '1';
				orderItem.price = item.itemPrice;
				orderItem.itemDesc = item.itemDescription;
				orderItem.qty = item.quantity;

				orderItems.push(orderItem);
			});
			vm.orderData[0].quoteItem = orderItems;
			vm.orderData[1].quoteItem = orderItems;
			vm.orderData[2].quoteItem = orderItems;

			vm.orderData[0].inventoryList = vm.createInventoryList();
			vm.orderData[1].inventoryList = vm.createInventoryList();
			vm.orderData[2].inventoryList = vm.createInventoryList();

			vm.orderData[0].engagementId = vm.engagement.engagementId;			
			vm.orderData[1].engagementId = vm.engagement.engagementId;			
			vm.orderData[2].engagementId = vm.engagement.engagementId;

			console.log("vm.orderDate after setOrderData", vm.orderData);
			// SharedDataSvc.globalVars.orderEstimatePut.quoteItem
		}

		function setOrderDataForAdjustablePayment() {
			var orderItems = [];
			_.forEach(vm.orderContent.orderItems, function(item, i) {
				var orderItem = {};
				// orderItem.unitType = vm.orderArray[i].unitType;
				// orderItem.subType = vm.orderArray[i].subType;
				orderItem.itemId = '1';
				orderItem.price = item.itemPrice;
				orderItem.itemDesc = item.itemDescription;
				orderItem.qty = item.quantity;

				orderItems.push(orderItem);
			});

			vm.orderDataForAdjustablePayment.quoteItem = orderItems;
			vm.orderDataForAdjustablePayment.inventoryList = vm.createInventoryList();
			vm.orderDataForAdjustablePayment.engagementId = vm.engagement.engagementId;
			console.log("orderDataForAdjustablePayment after setOrderData", vm.orderDataForAdjustablePayment);

		}

		function isZipInStoreRadius(zip) {
			console.log('CUSTOMER ZIP', zip);
			var deferred = $q.defer();
			// Google API Implementation - Starts
			getStoreInfoFactory.getLatLngFromZip(zip).then(function(results) {
			if (results.status == "OK") 
			{	
				var loc = results[0].geometry.location,
					customer = { 'lat': loc.lat(), 'lng': loc.lng() },
					// Google API Implementation - Ends
					    distance = getStoreInfoFactory.getCustomerDistance(vm.storeLatLng, customer),
					    isInAllowedRadius = getStoreInfoFactory.isCustomerInRadius(vm.storeInfo.allowedRentalRadiusMiles, distance),
					    isCustomerInUS = getStoreInfoFactory.isCustomerInUS(results[0].address_components);

					console.log('Is in radius', isInAllowedRadius);
					// console.log('Is in US', isCustomerInUS);
					console.log('distance', distance);
					vm.customerDistance = Math.round(distance);

					if (isCustomerInUS && isInAllowedRadius) {
						vm.customerInStoreRadius = true;
						deferred.resolve(true);
					} else {
						vm.customerInStoreRadius = false;
						deferred.resolve(false);
					}
				} else {
					vm.isZipValid = false;
					deferred.resolve(false);
				}
			});
			return deferred.promise;
		}

		function setCustomerOrderData(customer) {
			vm.orderData[0].customer.firstName = vm.customerFind.VanEngagement.customer.fName;
			vm.orderData[0].customer.lastName = vm.customerFind.VanEngagement.customer.lName;
			vm.orderData[0].orderIdentifier = vm.orderContent.orderIdentifier;
			//vm.orderData[0].deliveryAmt = vm.orderContent.deliveryFee;
			//vm.orderData[0].extendService = vm.orderContent.extendedServiceAmount;
			vm.orderData[0].feeArray = [{
				"feeAcrossTerm" : true,
				"feeAmount" : vm.orderContent.extendedServiceAmount,
				"feeType" : "ESV"
			}, {
				"feeAcrossTerm" : true,
				"feeAmount" : vm.orderContent.deliveryFee,
				"feeType" : "Delivery"
			}];
			vm.orderData[0].employeeName = 'AWP';
			vm.orderData[0].store = vm.storeInfo.vanStoreId;

			vm.orderData[1].customer.firstName = vm.customerFind.VanEngagement.customer.fName;
			vm.orderData[1].customer.lastName = vm.customerFind.VanEngagement.customer.lName;
			vm.orderData[1].orderIdentifier = vm.orderContent.orderIdentifier;
			//vm.orderData[1].deliveryAmt = vm.orderContent.deliveryFee;
			//vm.orderData[1].extendService = vm.orderContent.extendedServiceAmount;
			vm.orderData[1].feeArray = [{
				"feeAcrossTerm" : true,
				"feeAmount" : vm.orderContent.extendedServiceAmount,
				"feeType" : "ESV"
			}, {
				"feeAcrossTerm" : true,
				"feeAmount" : vm.orderContent.deliveryFee,
				"feeType" : "Delivery"
			}];
			vm.orderData[1].employeeName = 'AWP';
			vm.orderData[1].store = vm.storeInfo.vanStoreId;

			vm.orderData[2].customer.firstName = vm.customerFind.VanEngagement.customer.fName;
			vm.orderData[2].customer.lastName = vm.customerFind.VanEngagement.customer.lName;
			vm.orderData[2].orderIdentifier = vm.orderContent.orderIdentifier;
			//vm.orderData[2].deliveryAmt = vm.orderContent.deliveryFee;
			//vm.orderData[2].extendService = vm.orderContent.extendedServiceAmount;
			vm.orderData[2].feeArray = [{
				"feeAcrossTerm" : true,
				"feeAmount" : vm.orderContent.extendedServiceAmount,
				"feeType" : "ESV"
			}, {
				"feeAcrossTerm" : true,
				"feeAmount" : vm.orderContent.deliveryFee,
				"feeType" : "Delivery"
			}];
			vm.orderData[2].employeeName = 'AWP';
			vm.orderData[2].store = vm.storeInfo.vanStoreId;
		}

		function setCustomerOrderDataForAdjustablePayment(customer) {
			vm.orderDataForAdjustablePayment.customer.firstName = vm.customerFind.VanEngagement.customer.fName;
			vm.orderDataForAdjustablePayment.customer.lastName = vm.customerFind.VanEngagement.customer.lName;
			vm.orderDataForAdjustablePayment.orderIdentifier = vm.orderContent.orderIdentifier;
			//vm.orderData[0].deliveryAmt = vm.orderContent.deliveryFee;
			//vm.orderData[0].extendService = vm.orderContent.extendedServiceAmount;
			vm.orderDataForAdjustablePayment.feeArray = [{
				"feeAcrossTerm" : true,
				"feeAmount" : vm.orderContent.extendedServiceAmount,
				"feeType" : "ESV"
			}, {
				"feeAcrossTerm" : true,
				"feeAmount" : vm.orderContent.deliveryFee,
				"feeType" : "Delivery"
			}];
			vm.orderDataForAdjustablePayment.employeeName = 'AWP';
			vm.orderDataForAdjustablePayment.store = vm.storeInfo.vanStoreId;
		}

		function createInventoryList() {
			var inventoryList = [];

			console.log('[Agreement.createInventoryList] order items len', vm.orderContent.orderItems.length);
			_.forEach(vm.orderContent.orderItems, function(item, index) {
				var orderItem = {
					"racBrand" : "ACP", // 'ESV' for extended
					"itemModelNumber" : "", // 'ExtSvc' for extended
					"racModelNumber" : "",
					"depreciableLifeInMonths" : 24,
					"itemOptions" : "",
					"itemOriginalCost" : 0
				};

				orderItem.racBrand = "ACP";
				orderItem.itemModelNumber = "";
				orderItem.racModelNumber = productFactory.getRacModelNumber(vm.prodCat, item, $rootScope.selectedLanguage);//VAN-737 - PRB0042979 - AWP - Adding RAnges/Electric giving error in AWP on order screen - Added the selectedlanguage
				orderItem.depreciableLifeInMonths = 24;
				orderItem.itemOptions = productFactory.buildItemOptions(item);
				orderItem.itemOriginalCost = SharedDataSvc.formatItemPrice(item.itemPrice);
				console.log('[Agreement.createInventoryList] AUGMENTED ITEM', orderItem);
				inventoryList.push(orderItem);

				if(vm.orderContent.extendedServiceAmount > 0) {
					var extendedWarranty = {
						"racBrand" : "ACP",
						"itemModelNumber" : "ExtSvc",
						"racModelNumber" : "ESVPLAN-1",
						"depreciableLifeInMonths" : 24,
						"racRateCode" : "ESV396",
						"itemOriginalCost" : SharedDataSvc.formatItemPrice(vm.orderContent.extendedServiceAmount)
					};
					inventoryList.push(extendedWarranty);
				}
			});

			return inventoryList;
		}

		function setHTML(template) {
			$scope.agreementInfo = vm.agreementData ? vm.agreementData.agreement : vm.agreementDataForClauseUse;
			var str = template.replace(/{{/g, '{{agreementInfo.');
			var filteredStr = str.replace(/}}/g, ' | decimalFilter:2 }}');
			return filteredStr;
		}

		function cancelAndNavigate(){
			if(vm.storeInfo.isDigitalSigningEnabled && (vm.isHTCustomer || (vm.hideBp && vm.restrictions.skipLdw))) {
				navigateTo('dashboard');
			}  else {
				modalService.dismiss();
			}
		}
		
		//VAN-672 - Low Down Initial Payment should be displayed on RA - Starts
		function showPaymentInitial(){
			$rootScope.selectedLang = SharedDataSvc.globalVars.agreementPrePut.customer.languagePreference === 'ENGLISH' ? true : false;
			if(vm.selectedPromo === 'AutoPayRequired'){
				$scope.autoPayRequired = modalService.open({
					title: 'Open AutopayRequired popup for $49 Promo',
					templateUrl: 'partials/modal_showAutopayRequired.tpl.html',
					backdrop: 'static',
					size: 'sm',
					scope: $scope
				});
			}
			else
			{
				checkStore();
			}
		}
		function showInitialPaymentOptions(){
			vm.showModal('showInitialPaymentInfo', 'initial-payment-info-modal');
		}
		//VAN-672 - Low Down Initial Payment should be displayed on RA - Ends
	}

})();
