///////////////////////////////////////////
//////////////////////////////////////////
///   LOGIN CONTROLLER   ////////////////
////////////////////////////////////////
///////////////////////////////////////
(
	function() {

		'use strict';

		angular.module('app.login').factory('MemoryCache', function($cacheFactory) {
			return $cacheFactory('memoryCache');
		}).controller('Login', Login);

		/* @ngInject */
		function Login($scope, $rootScope, MemoryCache, $http, $location, authentication, SharedDataSvc, $timeout, $cookies, getStoreInfoFactory, modalService) {
			/*jshint validthis: true */
			var login = this;

			///////////

			login.showLoginError = false;
			login.showReset = false;
			login.loginError = '';
			login.viewLoading = false;
			login.globalmessage = SharedDataSvc.globalmessage;
			login.globalmessageShow = SharedDataSvc.globalmessageShow;
			login.enter = enter;
			login.showMPError = false;//Added Feature 7855 code changes
			$rootScope.xpartnerLogin = false;//VAN-3201-Enable remote signing option for AWP generated agreements- xpartner for retailers
			//login.truePassphrase = "";

			/**
			 * TODO:
			 * - Hook up to actual authentication service
			 */
			function enter() {
				console.log("passphrase converted to dot", login.passphrase);
				if (!login.passphrase) {
					console.log("[Login.enter] No user or pass entered. passphrase: " + login.passphrase);
					login.showLoginError = true;
					return;
				}
				login.showLoginError = false;
				login.showMPError = false;//Added Feature 7855 code changes
				$scope.viewLoading = true;
				//9464 - Dispute/hide disable button in invoice dashboard code changes starts
				$rootScope.passphrase = login.passphrase;
				//9464 - Dispute/hide disable button in invoice dashboard code changes ends
				authentication.loginUser(login.passphrase).then(function success(data) {
					console.log("[Login.enter] success: ", data);
					MemoryCache.removeAll();
					//VAN-3201-Enable remote signing option for AWP generated agreements- xpartner for retailers - Starts
					var passPhrase = login.passphrase.toLowerCase();
					//if(passPhrase.includes("xpartner")){
					if(passPhrase.indexOf("xpartner")!=-1){	//AWP Application not working in IE
						$rootScope.xpartnerLogin = true;
					}//VAN-3201-Enable remote signing option for AWP generated agreements- xpartner for retailers - Ends
					getStoreInfoFactory.getData(login.passphrase).then(function(data) {
						//VAN-3201-Enable remote signing option for AWP generated agreements- xpartner for retailers - Starts
						if($rootScope.xpartnerLogin && data.isManned){
							data.isManned = false;
							data.operationMode = 'UNMANNED';
							data.achVerification = true;
							data.allowAch = false;
							data.isReferenceRequired = false;
						}//VAN-3201-Enable remote signing option for AWP generated agreements- xpartner for retailers - Ends
						$rootScope.storeInfo = data;
						var lang = $rootScope.selectedLanguage ? $rootScope.selectedLanguage : $rootScope.languageSettings[1].id;
						if(data.state == 'PR'){
							if($cookies.getObject('isSpanishStore') && $rootScope.selectedLanguage != $rootScope.languageSettings[1].id){
								lang = $rootScope.selectedLanguage;
							} else {
								lang = $rootScope.languageSettings[1].id;
							}
							$rootScope.showLanguageSettings = true;
							$cookies.putObject('isSpanishStore', true);
							$rootScope.changeLocale(lang);
						} else {
							$rootScope.showLanguageSettings = false;
							$cookies.putObject('setSpanishStore', false);
							$rootScope.changeLocale(lang);
						}
						if(data.posType){
							$cookies.putObject('POS-TYPE', data.posType);
						} else {
							$cookies.putObject('POS-TYPE', null);
						}
						if(data.operationMode){
							
							$cookies.putObject('operationMode', data.operationMode);
							
							$rootScope.helpdeskNo = data.vipPhoneNumber;
							if(data.isManned && data.operationMode == 'HYBRID'){
								$rootScope.isHybridMode = true;
								$rootScope.isMannedMode = false;
							} else if (data.isManned && data.operationMode == 'MANNED'){
								$rootScope.isHybridMode = false;
								$rootScope.isMannedMode = true;
							} else {
								$rootScope.isHybridMode = false;
								$rootScope.isMannedMode = false;
							}

							$cookies.putObject('isHybridMode', $rootScope.isHybridMode);
							$cookies.putObject('isMannedMode', $rootScope.isMannedMode);
							$cookies.putObject('helpdeskNo', $rootScope.helpdeskNo);
							
							if(data.operationMode == "HYBRID"){
								$cookies.putObject('isHybrid', true);	
								SharedDataSvc.globalVars.storeSetup = data;
								console.log("[Login.enter] store setup: ", data);
								$cookies.putObject('auth_timeout', null);
								modalService.lock(false);
								$scope.viewLoading = false;
								$location.path('/dashboard');
								//Feature 7855 Code changes starts
							}else if(data.operationMode == "MANUALPAYABLENOTALLOWED"){
								login.showMPError = true;
								authentication.resetAll();
							}else if(data.operationMode == "MANUALPAYABLE"){
								$cookies.putObject('isHybrid', false);
								SharedDataSvc.globalVars.storeSetup = data;
								console.log("[Login.enter] store setup: ", data);
								$cookies.putObject('auth_timeout', null);
								modalService.lock(false);
								$scope.viewLoading = false;
								$location.path("/dashboardAgreement");
							//Feature 7855 Code changes ends
							}else if(data.operationMode == "UNKNOWN"){
								/* We can hardcode the isHybrid cookie value to true below,
								 * just for development purpose, if storeInfo service fails and 
								 * is giving operationMode as "UNKNOWN" always.
								 */
								login.showLoginError = true;
								authentication.resetAll();
							}else{
								/* Execution will come here only if it is 
								 * manned on unmanned operation mode.
								 */
								$cookies.putObject('isHybrid', false);
								SharedDataSvc.globalVars.storeSetup = data;
								console.log("[Login.enter] store setup: ", data);
								$cookies.putObject('auth_timeout', null);
								modalService.lock(false);
								$scope.viewLoading = false;
								$location.path('/dashboard');
							}
							
						}else{
							/* If api fails by any reason and do not provides operation mode, by the script below, we
							 * are going in the old manner and are hardcoding the hybrid flag as false, by default.
							 * Once, storeInfo changes are stable we can uncomment the logout script and comment
							 * all other lines below. Ideally, we should not allow login if operation mode is absent.
							 */
							/*
							console.log("OperationMode is absent in storeInfo response, hence going with old way.");
							SharedDataSvc.globalVars.storeSetup = data;
							console.log("[Login.enter] store setup: ", data);
							$cookies.putObject('auth_timeout', null);
							modalService.lock(false);
							$scope.viewLoading = false;
							$location.path('/dashboard');
							*/
							
							console.log("OperationMode is absent in storeInfo response, so setting hybrid to false and logging out");
							login.showLoginError = true;
							authentication.resetAll();
						}	
					}, function(data) {
						$scope.showenterstoreError = true;
						angular.forEach(data.processingErrors, function(value, key) {
							this.push(key + ': ' + value);
						}, SharedDataSvc.globalmessage);
						$scope.viewLoading = false;
						//this.globalmessageShow = true;
						login.passphrase = '';
						//login.truePassphrase = '';
						MemoryCache.removeAll();
					});
				}, function fail(data){
					console.log("[Login.enter.fail] ", data);
					$scope.viewLoading = false;
					login.showLoginError = true;
					//login.truePassphrase = '';
					login.passphrase = '';
					MemoryCache.removeAll();
				});
			};

			$rootScope.hideMenuButtons = true;
		}

	})(); 
