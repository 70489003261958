(function() {
  angular
    .module('myApp')
    .factory('productFactory', product);


  /* @ngInject */
  function product(
    $q,
    $log,
    $http,
    SharedDataSvc,
    localStorageService,
    productCategoriesFactory,
    API_DOMAIN
  ) {

    return {
      getProductModelInfo: getProductModelInfo,
      getProductUnitType: getProductUnitType,
      getProductInfo: getProductInfo,
      getProductCategories: getProductCategories,
      getRacModelNumber: getRacModelNumber,
      buildItemOptions: buildItemOptions,
      sortBuckets: sortBuckets
    };

    function getProductCategories() {
      return productCategoriesFactory.getData()
        .then(function(response) {
          return response;
        });
    }

    function getProductModelInfo(productCategories, item, selectedLanguage) {//VAN-693 - PRB0042959 - PR stores Spanish order issues - Added the selectedlanguage
      var matching,
          filtered,
          subType,
          priceBuckets,
          bedItem;
          
    //   VAN-3239-PRB0043451 - AWP-Brand/Model not found on model master - Start
    if(selectedLanguage == 'en'){
      matching = _.where(productCategories, { 'labelEn': item.category });}
 
      else{
          matching = _.where(productCategories, { 'labelEs': item.category });
      }
    //   matching = _.where(productCategories, { 'labelEn': item.category });
    // VAN-3239-PRB0043451 - AWP-Brand/Model not found on model master - End

     
      // Need to find correct product for beds, this is a special case
      if(item.iconURL === 'Bed') {
        filtered = [];
        bedItem = _.findWhere(productCategories, { 'labelEn' : item.category }); //PRB0041531 – Agreement stuck in processing
        filtered.push(bedItem);
       
        // $log.log('matching beds', filtered);
        //VAN-2826-PRB0043300 - Order issue - Brand/Model not found on model master for TV accessories - Starts
      } else if(item.category === 'TV Accessories') {
    	  matching = _.where(productCategories, { 'iconURL': item.iconURL });
    	  filtered = _.filter(matching, function(val) {
    		  return _.has(val, 'htSubTypeCode');
    	  });	 
      }else {//VAN-2826-PRB0043300 - Order issue - Brand/Model not found on model master for TV accessories - Ends 
        // Filter only the matches that have a subtype code
    	
    	filtered = _.filter(matching, function(val) {
    		
    		return _.has(val, 'htSubTypeCode');
        });	
        
      }
     
      if(!_.isEmpty(matching) && item.iconURL !== 'Bed') {
        priceBuckets = _.last(matching).priceBuckets;
      } else {

        if(filtered[0]){
            priceBuckets = filtered[0].priceBuckets;
        }
        
      }
      
      // Item with subtype wasn't found, now search by custom attributes
      
      console.log('[getProductModelInfo().priceBuckets]', priceBuckets);
//      alert(JSON.stringify(filtered.length+'--'+ item.attributes.length));
      if (filtered.length < 1 && item.attributes.length > 0) {
    	  
    	 
        var attributeObject;
        for (var i = 0; i < item.attributes.length; i++) {
          // find the attribute object to check if it has a subtype
        	//VAN-693 - PRB0042959 - PR stores Spanish order issues - Starts
//      	  alert(JSON.stringify(subType));
        	if(selectedLanguage == 'en'){
        		attributeObject = findDeep(_.last(matching).customAttributeGroups, {labelEn: item.attributes[i].value});}
        	else{
        		attributeObject = findDeep(_.last(matching).customAttributeGroups, {labelEs: item.attributes[i].value});
        	}
        	//VAN-693 - PRB0042959 - PR stores Spanish order issues - Added the selectedlanguage - Ends
        	if (typeof attributeObject !== 'undefined' && attributeObject.htSubTypeCode) {
            subType = attributeObject.htSubTypeCode;
            if (priceBuckets.length < 1) priceBuckets = attributeObject.priceBuckets;
            break;
          }
        }
      } else {
    	 
    	
     	  subType = _.pluck(filtered, 'htSubTypeCode');
    	  
    	  subType = typeof subType[0] !== 'undefined' ? subType[0] : '';

     
      }

      console.log('[getProductModelInfo().priceBuckets]', priceBuckets);
      console.log('[getProductModelInfo().subType]', subType);

      return {
        subType: subType,
        priceBuckets: priceBuckets
      };
    }

    function getProductUnitType(productCategories, product) {
      var parentLabel = _.has(product, 'parent') ? product.parent : false,
          parent = _.findWhere(productCategories, { 'labelEn': parentLabel });

      if(product.htUnitTypeCode) {
        return product.htUnitTypeCode;
      }

      if(parent.htUnitTypeCode) {
        return parent.htUnitTypeCode;
      }

      // Call again if no htUnitTypeCode has been found
      if(!product.htUnitTypeCode || !parent.htUnitTypeCode) {
        return getProductUnitType(productCategories, parent);
      }
    }

    function getProductInfo(orderProduct) {
      // console.log('ORDER PRODUCT', orderProduct);
      var deferred = $q.defer();
      getProductCategories()
        .then(function(response){
          var product = null,
              returnObj = { 'subType' : null, 'unitType' : null };

          if(orderProduct.iconURL) {
            product = _.findWhere(response.productCategories, { 'iconURL': orderProduct.iconURL });
          } else {
            product = _.findWhere(response.productCategories, { 'iconURL': orderProduct.category });
          }

          product.subType = product.htSubTypeCode;
          var unitType = getProductUnitType(response.productCategories, product);
          returnObj.subType = product.subType;
          returnObj.unitType = unitType;
          deferred.resolve(returnObj);

          // $log.log('PRODUCT SUBTYPE', product.subType);
          // $log.log('PRODUCT', product);
          // $log.log('HT UNIT TYPE', unitType);
        });
      return deferred.promise;
    }

    function buildItemOptions(item) {
      var props = ['material', 'color', 'quantity', 'other'],
          options = [];

      _.forEach(props, function(prop, i) {
        if(_.has(item, prop)) {
          options.push(item[prop]);
        }
      });

      return options.join(' ');
    }

    function sortBuckets(buckets) {
      var buckets;

      buckets = _.sortBy(_.pluck(buckets, 'value'), function(num) {
                  return num * -1;
                });
      return buckets;
    }

    function getRacModelNumber(productCategories, item, selectedLanguage) {//VAN-693 - PRB0042959 - PR stores Spanish order issues - Added the selectedlanguage
      var product = getProductModelInfo(productCategories, item, selectedLanguage),//VAN-693 - PRB0042959 - PR stores Spanish order issues - Added the selectedlanguage
          bucket = product.priceBuckets,
          productSubType = product.subType,
          buckets = sortBuckets(bucket),
          selectedBucket,
          i = 0,
          len = buckets.length;

          console.log(product);
      // console.log('BUCKET', bucket);
      // console.log('BUCKETs', buckets);
      // console.log('item', item);
      // console.log('product', product);
      // console.log('subType', product.prod.htSubTypeCode);
_
      while(i < len) {
        if(buckets[i] < item.itemPrice) {
          selectedBucket = buckets[i - 1];
          break;
        }
        i++;
      }
   if(!selectedBucket) {
        selectedBucket = _.last(buckets);
      }
      // console.log('SELECT BUCKET', selectedBucket);
      // console.log('MODEL NUMBER', product.prod.htSubTypeCode + selectedBucket);
      console.log(String(productSubType) + String(selectedBucket));
      return String(productSubType) + String(selectedBucket);
    }
  }

  function findDeep(items, attrs) {
    function match(value) {
      for (var key in attrs) {
        if(!_.isUndefined(value)) {
          if (attrs[key] !== value[key]) {
            return false;
          }
        }
      }
      return true;
    }
    function traverse(value) {
      var result;
      _.forEach(value, function (val) {
        if (match(val)) {
          result = val;
          return false;
        }

    if (_.isObject(val) || _.isArray(val)) {
          result = traverse(val);
        }

        if (result) {
          return false;
        }
      });
      return result;
    }
    return traverse(items);
  }
})();