(function() {
	'use strict';

	angular
		.module('myApp')
		.factory('dashboardServices', dashboardServices);

	/* @ngInject */
	function dashboardServices(
		$q,
		$http,
		SharedDataSvc,
		API_DOMAIN,
		PROXY_ACTION_URL,
		authentication,
		ServiceAgent
	) {
		var service = {
			getCreditCardDetails: getCreditCardDetails,
			getCustomerReview: getCustomerReview,
			getAdditionalInfo: getAdditionalInfo,
			setAdditionalInfo: setAdditionalInfo,
			viewApplication: viewApplication

		};
		return service;

		////////////////

		function getCreditCardDetails (engagementId) {
			console.log("[dashboardServices.getCreditCardDetails] engagementId: ", engagementId);
			var deferred = $q.defer();
			if (!engagementId) {
				console.warn("[dashboardServices.getCreditCardDetails] No engagementId provided");
				deferred.reject(null);
				return deferred.promise;
			}
			var url = PROXY_ACTION_URL + '?engagementId=' + engagementId;
			//var params = '?engagementId=' + engagementId;
			//var encryptParams = SharedDataSvc.encrypt(params, SharedDataSvc.globalVars.storeSetup.serverPublicRSAKey); //ServiceAgent.encryptData(params);
			var headers = {
				'endTarget': 'rentacentervrto/rest/payment/getcreditcarddetails',
				'sessionToken' : authentication.getCurrentSessionId(),
				//'params' : encryptParams,
				'Access-Control-Allow-Origin':'endTarget,sessionToken,Encrypt-Mode,RAC-KIOSK-LOCATION-ID,Public-Key,RAC-CORRELATION-ID'//AC 27054 code Changes
			};

			ServiceAgent.httpCall(
				url, 
				'POST', 
				headers, 
				null,
				true
		    ).then(function (response) {
		    	console.log("[dashboardServices.getCreditCardDetails] success response: ", response);
		    	var successData = (typeof response !== "object") ? JSON.parse(response) : response;
		    	deferred.resolve(successData);
		    },
		    function (response) {
		    	console.log("[dashboardServices.getCreditCardDetails] fail ", response);
		      	deferred.reject(response);
		    });

		    return deferred.promise;
		}

		function getCustomerReview(customerId){
            var deferred = $q.defer();
            var headers = {
              'endTarget': 'rentacentervrto/rest/user/getCustomerReview',
              'sessionToken' : authentication.getCurrentSessionId(),
              'Access-Control-Allow-Origin':'endTarget,sessionToken,Encrypt-Mode,RAC-KIOSK-LOCATION-ID,Public-Key,RAC-CORRELATION-ID'//Story 26289 code changes
            };
            var url = PROXY_ACTION_URL  + '?customerId=' + customerId;
            ServiceAgent.httpCall(
	            url,
	            'POST',
	            headers,
	            null,
	            true
            ).then(function (response) {
            	console.log("[dashboardServices.getCustomerReview] success: ", response);
                var successData = (typeof response !== "object") ? JSON.parse(response) : response;
            	deferred.resolve(successData);
            },
            function (response) {
                console.log("[dashboardServices.getCustomerReview] fail ", response);
                deferred.reject(response);
            });

            return deferred.promise;
		}

		function getAdditionalInfo(engagementId){
            var deferred = $q.defer();
            var headers = {
              'endTarget': 'rentacentervrto/rest/customer/customerQuestions',
              'sessionToken' : authentication.getCurrentSessionId(),
              'Access-Control-Allow-Origin':'endTarget,sessionToken,Encrypt-Mode,RAC-KIOSK-LOCATION-ID,Public-Key,RAC-CORRELATION-ID'//Story 26289 code changes
            };
            var url = PROXY_ACTION_URL  + '?engagementId=' + engagementId;
            ServiceAgent.httpCall(
	            url,
	            'POST',
	            headers,
	            null,
	            true
            ).then(function (response) {
            	console.log("[dashboardServices.getAdditionalInfo] success: ", response);
                var successData = (typeof response !== "object") ? JSON.parse(response) : response;
            	deferred.resolve(successData);
            },
            function (response) {
                console.log("[dashboardServices.getAdditionalInfo] fail ", response);
                deferred.reject(response);
            });

            return deferred.promise;
		}


		function setAdditionalInfo(data){
			 var deferred = $q.defer();
            var headers = {
              'endTarget': 'rentacentervrto/rest/customer/update',
              'sessionToken' : authentication.getCurrentSessionId(),
              'Public-Key': SharedDataSvc.globalVars.storeSetup.serverPublicRSAKey
              //'Access-Control-Allow-Origin':'Public-Key,endTarget,sessionToken,Encrypt-Mode,RAC-KIOSK-LOCATION-ID,RAC-CORRELATION-ID'
            };
            var url = PROXY_ACTION_URL;
            ServiceAgent.httpCall(
	            url,
	            'POST',
	            headers,
	            data,
	            false
            ).then(function (response) {
            	console.log("[dashboardServices.postAdditionalInfo] success: ", response);
                var successData = (typeof response !== "object") ? JSON.parse(response) : response;
            	deferred.resolve(successData);
            },
            function (response) {
                console.log("[dashboardServices.getAdditionalInfo] fail ", response);
                deferred.reject(response);
            });

            return deferred.promise;
		}

		function viewApplication(customerId) {
			console.log("[dashboardServices.viewApplication] customerId: ", customerId);
			var deferred = $q.defer();
			if (!customerId) {
				console.warn("[dashboardServices.viewApplication] No customerId provided");
				deferred.reject(null);
				return deferred.promise;
			}
			var url = PROXY_ACTION_URL + '?customerId=' + customerId;
			var headers = {
				'endTarget': 'rentacentervrto/rest/application/viewApplication',
				'sessionToken' : authentication.getCurrentSessionId(),
				'Access-Control-Allow-Origin':'endTarget,sessionToken,Encrypt-Mode,RAC-KIOSK-LOCATION-ID,Public-Key,RAC-CORRELATION-ID'//AC 27054 code Changes
			};

			ServiceAgent.httpCall(
				url, 
				'POST', 
				headers, 
				null,
				true
		    ).then(function (response) {
		    	console.log("[dashboardServices.getCreditCardDetails] success response: ", response);
		    	var successData = (typeof response !== "object") ? JSON.parse(response) : response;
		    	deferred.resolve(successData);
		    },
		    function (response) {
		    	console.log("[dashboardServices.getCreditCardDetails] fail ", response);
		      	deferred.reject(response);
		    });

		    return deferred.promise;
		}

	}
})();