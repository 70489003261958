

///////////////////////////////////////////
//////////////////////////////////////////
///   TRAINING CONTROLLER   ////////////
////////////////////////////////////////
///////////////////////////////////////
(function(){

	'use strict';

	angular
	.module('app.training')
	.controller('Training', Training);

	/* @ngInject */
	function Training(
		$scope,
		$rootScope,
		$location,
		$idle,
		$routeParams,
		$log,
		$sce,
		$cookies,
		modalService,
		authentication,
		SharedDataSvc,
		getTrainingLibraryFactory,
		getStoreInfoFactory,
		API_DOMAIN
		) {

		/*jshint validthis: true */
		var training = this;
		training.documentSelected = documentSelected;
		training.documentIcon = documentIcon;
		training.hideShowBanner = hideShowBanner;
		training.showModal = showModal;
		training.formatDate = formatDate;
		training.cleanText = cleanText;
		training.showTrainingItem = showTrainingItem;

		training.modal;
		training.videoTitle;
		training.videoUrl;
		training.storeInfo;
		
		init();
		function init()
		{
			$rootScope.hideDashboardButton = false;
			$rootScope.hideDashboardAgreementButton = false;
			$rootScope.hideTrainingLibButton = true;

			getStoreInfoFactory.getData()
			.then(function(response) {
				training.storeInfo = response;
				$rootScope.invoiceMethod = response.invoiceMethod;
				console.log('STORE INFO', training.storeInfo);
			}, function(response) {
				$log.log('error getting storeInfo', response);
			});

			SharedDataSvc.trainLibraryDocs = JSON.parse(sessionStorage.getItem('trainLibraryDocs'));
			/*VAN 499 - PR: Training library videos and PDF's should be related to PR flow (Spanish) - Starts*/			
			//$rootScope.documentList = $rootScope.selectedLanguage == 'es' ? SharedDataSvc.trainLibraryDocs.documents_PR : SharedDataSvc.trainLibraryDocs.documents;
			var documentList_Spa =[];
			var documentList_Eng =[];
			if($rootScope.state=="PR"){
				if(SharedDataSvc.trainLibraryDocs.documents_PR){
					for(var j=0;j<SharedDataSvc.trainLibraryDocs.documents_PR.length;j++){
						if(SharedDataSvc.trainLibraryDocs.documents_PR[j].language=="SPA"){
							documentList_Spa.push(SharedDataSvc.trainLibraryDocs.documents_PR[j]);
						}else{
							documentList_Eng.push(SharedDataSvc.trainLibraryDocs.documents_PR[j]);
						}
					}
					$rootScope.documentList = $rootScope.selectedLanguage == 'es' ? documentList_Spa: documentList_Eng;
				}
			}
			else{
				$rootScope.documentList = SharedDataSvc.trainLibraryDocs.documents;
			}
			/*VAN 499 - PR: Training library videos and PDF's should be related to PR flow (Spanish) - Ends*/
			
			if(_.size($rootScope.documentList) == 0)
			{
				var item = {
					en : {
						title : "There are no training documents to display at this time.",
						summary : "",
						contentType : ""
					}, es: {
						title : "No hay documentos de formación para mostrar en este momento.",
						summary : "",
						contentType : ""
					}
				};
				$rootScope.documentList = $rootScope.selectedLanguage == 'es' ? [item.es] : [item.en];
			}			
		}

		function showModal(name, windowClass, title) {
			windowClass = windowClass || '';
			$rootScope.modalTitle = title || 'Processing';

			training.modal = modalService.open({
				templateUrl: '../../../partials/modal_' + name + '.html',
				backdrop: 'static',
				windowClass: windowClass,
				// controller: 'Training',
				controllerAs: 'training',
				scope: $scope
			});
		}

		function showTrainingItem(item) {
			if(item.manned && training.storeInfo.isManned) {
				return true;
			} 
			if(item.unmanned && !training.storeInfo.isManned) {
				return true;
			}
		}

		function documentIcon(item)
		{
			var itemType = item.contentType;
			if (itemType == 'VID') {
				return 'training-vid';
			}
			else if (itemType == 'URL') {
				return 'training-web';
			}
			else if (itemType == 'PDF') {
				return 'training-pdf';
			}
			else
			{	
				return '';
			}

		}

		function formatDate(date) {
			var year = String(date).substring(0, 4),
			month = String(date).substring(4, 6),
			day = String(date).substring(6, 8),
			formattedDate = month + '-' + day + '-' + year;

			return moment(formattedDate, 'MM-DD-YYYY').format('MM/DD/YYYY');
		}

		function documentSelected (item) {
			console.log('CLICKED ', item.contentType);
			if(item.contentType === 'PDF') {
				showModal('Processing', 'processing-modal');
				/*getTrainingLibraryFactory.getPdfData(item.contentURL)
				.then(
					// Success
					function(response) {
						modalService.dismiss();
						var pdfBlob = SharedDataSvc.createPdfBlob(response);
						console.log('[Training Library: Get PDF Data]');
						if (navigator.msSaveOrOpenBlob) {
							navigator.msSaveOrOpenBlob(pdfBlob);
						} else {
							var objectURL = window.URL.createObjectURL(pdfBlob);
							window.open(objectURL);
						}
					},
					// Error
					function(error) {
						
					}
				);*/
				
//				Feature 8407 - Move training library docs to S3 bucket
				getTrainingLibraryFactory.getPdfDataFromS3(item.s3ContentURL)
				.then(
					function(response) {
		            var pdfFile = new Blob([ response.data ], {
		                type : 'application/pdf'
		            });
		            modalService.dismiss();
		            
		            if (navigator.msSaveOrOpenBlob) {
						navigator.msSaveOrOpenBlob(pdfFile);
					} else {
						var pdfUrl = window.URL.createObjectURL(pdfFile);
						window.open(pdfUrl);
					}
		          
		        },
		        function(response) {
		        	modalService.dismiss();
		        });
				// showModal('trainingViewPdf');
			} else if(item.contentType === 'VID') {
				showModal('trainingVideo', 'training-video-modal');
				training.videoTitle = item.title;
				training.videoUrl = $sce.trustAsResourceUrl(item.contentURL);
			} else {
				window.open(item.contentURL);
			}
		}

		// Clean bad characters returned from service
		function cleanText(text) {
			return text.replace(/[^\u0000-\u007F]/g, "'");
		}

		//TODO: method for hide/show isNew banner
		function hideShowBanner(item)
		{
			if(item.isNew)
				return true;
			else
				return false;
		} 
	}
})();
