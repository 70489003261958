///////////////////////////////////////////
//////////////////////////////////////////
///   ESTIMATE CONTROLLER   /////////////
////////////////////////////////////////
///////////////////////////////////////
(function () {

	'use strict';

	angular
		.module('app.estimate')
		.controller('Estimate', Estimate)

	/* @ngInject */
	function Estimate(
		$q,
		$scope,
		$rootScope,
		$cookies,
		$http,
		$routeParams,
		$location,
		orderEstimatePutFactory,
		agreementFactory,
		modalService,
		authentication,
		SharedDataSvc,
		orderService,
		datesFactory,
		stateRestrictFactory,
		updateEngagementItemFactory,
		getStoreInfoFactory,
		productFactory,
		customerDataFactory,
		applicationService
	) {
		/*jshint validthis: true */
		var estimate = this;
		var monthNames = ["January", "February", "March", "April", "May", "June",
			"July", "August", "September", "October", "November", "December"
		];

		$rootScope.hideMenuButtons = false;

		estimate.engagementId = $routeParams.engagementId;
		estimate.estimateSub = {};
		estimate.estimatePut = {};
		estimate.sessionVars = [];
		estimate.orderDataArray = [];
		estimate.routeParam = $routeParams.engagementId;
		estimate.globalVars = {};
		estimate.globalVars.order = [];
		estimate.globalVars.order = SharedDataSvc.globalVars.order;
		estimate.globalmessage = SharedDataSvc.globalmessage;
		estimate.globalmessageShow = SharedDataSvc.globalmessageShow;
		estimate.storeInfo;
		estimate.restrictions;
		estimate.paymentModeInput;
		estimate.prodCat;
		estimate.engagement;
		$scope.deliveryFeePaymentValue = {
			feePaymentMode: SharedDataSvc.globalVars.estimateDeliveryOption
		}
		$scope.parseFloat = function (value) {
			return parseFloat(value);
		}

		$scope.noEsvNoDel;
		$scope.onlyEsvNoDel;
		$scope.noESVonlyDel;
		$scope.withEsvDel;
		$scope.selectionFlag;

		estimate.estimatePut = SharedDataSvc.globalVars.orderEstimatePut;
		estimate.estimateSub = SharedDataSvc.globalVars.estimateReturn;
		estimate.estimateAll = SharedDataSvc.globalVars.estimateReturnAll;
		estimate.confirmId = confirmId;
		estimate.associatePassFail = false;
		estimate.invalidPassPhrase = false;
		estimate.empPassPhrase;
		estimate.errorMessage = '';
		
		/*VAN-774 -  Biweekly renewal payments Long Term - Starts*/
		estimate.paymentModeSelected = 'Monthly';
		estimate.estimateSubMonthly = SharedDataSvc.globalVars.estimateReturnMonthly;
		estimate.estimateSubBiweekly = SharedDataSvc.globalVars.estimateReturnBiweekly;
		/*VAN-774 -  Biweekly renewal payments Long Term - Ends*/
		
		/*VAN-858 - Quad Weekly Payment Frequency - Starts*/
		estimate.estimateSubQuarterly = SharedDataSvc.globalVars.estimateReturnQuarterly;
		estimate.changePaymentMode = changePaymentMode;
		/*VAN-858 - Quad Weekly Payment Frequency - Ends*/
		
		//Public Methods
		estimate.showModal = showModal;
		estimate.getOrderEstimate = getOrderEstimate;
		estimate.confirmOrder = confirmOrder;
		estimate.proceedOrderConfirmation = proceedOrderConfirmation;
		estimate.submitInvoiceAndSalesDetails = submitInvoiceAndSalesDetails;
		$scope.submitEmployeePassWithOutSaveOrder = submitEmployeePassWithOutSaveOrder;
		$scope.submitEmployeePass = submitEmployeePass;
		
		estimate.invoiceNumber = '';
		estimate.associate = '';
		estimate.errors = {
			invoiceNumber: false,
			associate: false
		};

		estimate.navigateTo = navigateTo;
		estimate.verifyEmployeePassPhrase = verifyEmployeePassPhrase;
		// LOAD EXISTING RECORD?
		if (typeof estimate.routeParam !== "undefined") {
			// existing record
			console.log("loaded existing record");
		} else {
			// new record
		}

		estimate.showDiscountPrompt = false;
		estimate.discountMsg = '';

		init();

		function init() {

			if (estimate.engagementId === null) {
				estimate.navigateTo('/dashboard');
			}
			
			getStoreInfoFactory.getData()
			.then(function(response) {
				console.log('[Estimate] STORE INFO', response);
				estimate.storeInfo = response;
				
				/*VAN-858 - Quad Weekly Payment Frequency - Starts*/
				if(estimate.storeInfo.paymentModeEnabled!=undefined){
					$scope.biWeeklyEnabled =  estimate.storeInfo.paymentModeEnabled[0].biweekly;
					$scope.quadEnabled =  estimate.storeInfo.paymentModeEnabled[0].quad;
				}
				/*VAN-858 - Quad Weekly Payment Frequency - Ends*/
				
				// if there is no estimate data coming from order summary, redirect to estimate or dashboard
				/*VAN - 774 -  Biweekly renewal payments Long Term - Starts*/
				/*VAN-858 - Quad Weekly Payment Frequency - Starts*/
				if(estimate.storeInfo.lowInitialPaymentEnabled && estimate.storeInfo.paymentModeEnabled!=undefined){
					if((_.isEmpty(estimate.estimateSubMonthly)) && (_.isEmpty(estimate.estimateSubBiweekly)) && (_.isEmpty(estimate.estimateSubQuarterly))){
						estimateRedirect();
					}
				/*VAN-858 - Quad Weekly Payment Frequency - Ends*/
				}else{
					if ((_.isEmpty(estimate.estimateSub))){
						estimateRedirect();
					}
				}
				function estimateRedirect(){
					var target = (estimate.engagementId) ? '/ordersummary/' + estimate.engagementId : '/dashboard';
					estimate.navigateTo(target);
					return;
				}
				/*VAN - 774-  Biweekly renewal payments Long Term - Ends*/
				
				if(estimate.storeInfo.infoPrompts){
					estimate.showDiscountPrompt = true;
					estimate.discountMsg =  ($rootScope.selectedLanguage =='en' ? estimate.storeInfo.infoPrompts.discount.msg_en : estimate.storeInfo.infoPrompts.discount.msg_es);
				}
				checkEpoSchedule();//VAN-1415-Bob's - Change EPO array to 120 days
				$scope.agreementAmount = response.minAgreementAmount;
				stateRestrictFactory.getAllStoreRestrictions(estimate.storeInfo.state)
				.then(function(response) {
					console.log('[Estimate] STORE RESTRICTIONS', response);
					estimate.restrictions = response;
				});
			});
			
			//get product category list
			productFactory.getProductCategories().then(function(response) {
				estimate.prodCat = response.productCategories;
			});

			//converting milliseconds to date : defect : AC - 10770
			/*VAN - 774 -  Biweekly renewal payments Long Term - Starts*/
			if(estimate.estimateAll!=undefined){
				for (var i = 0, len = estimate.estimateAll.length; i < len; i++) {
					var date = new Date(estimate.estimateAll[i].paymentDueDate);
					estimate.estimateAll[i].paymentDueDateString = monthNames[date.getMonth()] + ' ' + date.getDate();
				}
			}
			/*VAN - 774 -  Biweekly renewal payments Long Term - Ends*/
			//checkEpoSchedule();//Commented as part of //VAN-1415-Bob's - Change EPO array to 120 days
			console.log('[Estimate] estimate.engagementId', estimate.engagementId);
			console.log('[Estimate] estimate.estimateSub', estimate.estimateSub);

			$scope.selectionFlag = !estimate.estimatePut.feeArray[1].feeAcrossTerm && estimate.estimatePut.feeArray[1].feeAmount > 0;

			$scope.noEsvNoDel = (estimate.estimatePut.feeArray[0].feeAmount <= 0 && estimate.estimatePut.feeArray[1].feeAmount <= 0);
			$scope.onlyEsvNoDel = (estimate.estimatePut.feeArray[0].feeAmount > 0 && estimate.estimatePut.feeArray[1].feeAmount <= 0);
			$scope.noESVonlyDel = (estimate.estimatePut.feeArray[0].feeAmount <= 0 && estimate.estimatePut.feeArray[1].feeAmount > 0);
			$scope.withEsvDel = (estimate.estimatePut.feeArray[0].feeAmount > 0 && estimate.estimatePut.feeArray[1].feeAmount > 0);
		}

		function checkEpoSchedule() {
			/*VAN 774 -  Biweekly renewal payments Long Term - Starts*/
			if(SharedDataSvc.globalVars.estimateReturn.epoPaymentArray!=undefined){
				SharedDataSvc.globalVars.epoSchedule = SharedDataSvc.globalVars.estimateReturn.epoPaymentArray;
				if (SharedDataSvc.globalVars.epoSchedule === undefined || SharedDataSvc.globalVars.epoSchedule.length === 0) {
					estimate.epoSchedule = [];
					return;
				}
			}else{
				if(SharedDataSvc.globalVars.estimateReturnMonthly.epoPaymentArray!=undefined)
					SharedDataSvc.globalVars.epoSchedule = SharedDataSvc.globalVars.estimateReturnMonthly.epoPaymentArray;
			}
			// set to scope
			estimate.epoSchedule = SharedDataSvc.globalVars.epoSchedule;
			formatEpoSchedule();
			/*else{
				showModal('orderError');
			}*/
			/*VAN 774 -  Biweekly renewal payments Long Term - Ends*/
		}
		
		function formatEpoSchedule(){
			if(estimate.epoSchedule !=undefined && estimate.epoSchedule.length!=0){
				var firstPaymentMonth = estimate.epoSchedule[0].epoAfterPaymentNumber;
				/*VAN-1390 - EPO showing incorrectly in review estimate*/
				if (firstPaymentMonth >= 3) {
					var days = (firstPaymentMonth - 1) * 30;
					//VAN-1415-Bob's - Change EPO array to 120 days - Starts
					if(estimate.storeInfo != undefined && estimate.storeInfo.epoOptionDays != undefined ){
						if(estimate.storeInfo.epoOptionDays === '120'){
							for (var i = firstPaymentMonth - 1; i > 0; i--) {
								var epoEntry = {
										epoAfterPaymentNumber: i,
										//VAN-1390 - EPO showing incorrectly in review estimate
										epoCashPrice: "120" + " Days EPO"
								}
								estimate.epoSchedule.unshift(epoEntry);
							}
						}else if(estimate.storeInfo.epoOptionDays === '90'){
							for (var i = firstPaymentMonth - 1; i > 0; i--) {
								var epoEntry = {
										epoAfterPaymentNumber: i,
										//VAN-1390 - EPO showing incorrectly in review estimate
										epoCashPrice: "90" + " Days EPO"
								}
								estimate.epoSchedule.unshift(epoEntry);
							}	
						}
						else if(estimate.storeInfo.epoOptionDays === '100'){
							for (var i = firstPaymentMonth - 1; i > 0; i--) {
								var epoEntry = {
										epoAfterPaymentNumber: i,
										epoCashPrice: "100" + " Days EPO"
								}
								estimate.epoSchedule.unshift(epoEntry);
							}	
						}
					}else{
						for (var i = firstPaymentMonth - 1; i > 0; i--) {
							var epoEntry = {
									epoAfterPaymentNumber: i,
									//VAN-1390 - EPO showing incorrectly in review estimate
									epoCashPrice: "90" + " Days EPO"
							}
							estimate.epoSchedule.unshift(epoEntry);
						}
					}
					//VAN-1415-Bob's - Change EPO array to 120 days - Ends
				}
			}
		}
		
		/*VAN 774 -  Biweekly renewal payments Long Term - Starts*/
		function changePaymentMode(selectedPayment){
			if(selectedPayment=='Monthly'){
				SharedDataSvc.globalVars.epoSchedule = SharedDataSvc.globalVars.estimateReturnMonthly.epoPaymentArray;
			}else if(selectedPayment=='Biweekly'){
				SharedDataSvc.globalVars.epoSchedule = SharedDataSvc.globalVars.estimateReturnBiweekly.epoPaymentArray;
			}else{
				SharedDataSvc.globalVars.epoSchedule = SharedDataSvc.globalVars.estimateReturnQuarterly.epoPaymentArray;
			}
			estimate.epoSchedule = SharedDataSvc.globalVars.epoSchedule;
			formatEpoSchedule();
		}
		/*VAN 774 -  Biweekly renewal payments Long Term - Ends*/

		function navigateTo(target) {
			$location.path(target);
		}

		function getOrderEstimate(paymentMode) {
			if (paymentMode !== estimate.paymentModeInput) {
				console.log('Selected payment mode:', paymentMode);
				estimate.paymentModeInput = paymentMode;

				if (paymentMode === "upfront") {
					$scope.selectionFlag = true;
				} else {
					$scope.selectionFlag = false;
				}

				var delFeePaymentOptionFlagValue = estimate.storeInfo.deliveryFeePaymentOption;
				var esvdelFeePaymentOptionFlagValue = estimate.storeInfo.esvTermOption;

				var orderData = orderEstimatePutFactory.compileData(SharedDataSvc.globalVars.orderSummary, esvdelFeePaymentOptionFlagValue, delFeePaymentOptionFlagValue, estimate.prodCat, paymentMode, $rootScope.selectedLanguage);//VAN-737 - PRB0042979 - AWP - Adding RAnges/Electric giving error in AWP on order screen - Added the selectedlanguage
				console.log("Order data payload for payment mode as " + paymentMode + ":", orderData);
				SharedDataSvc.globalVars.orderEstimatePut = orderData;

				// to calculate quotes on review estimate
				var currentDate = datesFactory.getCurrentDate();
				var quoteDates = datesFactory.setQuoteDates(currentDate);

				estimate.orderDataArray = [_.clone(orderData),
				_.clone(orderData),
				_.clone(orderData)];

				_.forEach(quoteDates, function (paymentDate, i) {
					estimate.orderDataArray[i].requestDte = currentDate;
					estimate.orderDataArray[i].nextRenewDte = paymentDate.date;
				});

				agreementFactory.setQuotes(estimate.orderDataArray)
					.then(function () {
						estimate.quotes = agreementFactory.quotes;
						_.forEach(quoteDates, function (paymentDate, i) {
							estimate.quotes[i].paymentDueDate = moment(paymentDate.date);
						});
						//var target = '/estimate/' + $routeParams.engagementId;
						SharedDataSvc.globalVars.estimateReturn = estimate.quotes[0];
						estimate.estimateSub = estimate.quotes[0];

						SharedDataSvc.globalVars.estimateReturnAll = estimate.quotes;
						estimate.estimateAll = estimate.quotes;

					}, function (response) {
						// sending error to Raygun
						SharedDataSvc.reportRaygun(response);
						estimate.navigateTo('/dashboard');
					});
			}
		}

		

		function confirmOrder(paymentModeSelected) {
			/*VAN 774 -  Biweekly renewal payments Long Term - Starts*/
			/*VAN-858 - Quad Weekly Payment Frequency */
			if(!estimate.storeInfo.lowInitialPaymentEnabled || estimate.storeInfo.paymentModeEnabled==undefined){
				paymentModeSelected ="";
			}else{
				SharedDataSvc.globalVars.paymentModeSelected=paymentModeSelected;
			}
			/*VAN 774 -  Biweekly renewal payments Long Term - Ends*/

			$scope.noEmpPass = false;
			$scope.errEmpPass = false;
			if (!checkMinMax()) return;

			if (SharedDataSvc.globalVars.orderSummary.hasItems === false) {
				// alert 'you need to complete order'
				var confirmOrderModal = modalService.open({
					title: 'Confirm Order',
					content: 'this is the content',
					show: true,
					scope: $scope,
					controllerAs: 'orderSummary',
					templateUrl: 'partials/alert_confirmOrderModal.tpl.html',
					backdrop: 'static'
				});
			} else {
				if(estimate.showDiscountPrompt) {
					var discountPromptModal = modalService.open({
						scope: $scope,
						size: 'xs',
						templateUrl: 'partials/alert_discountPromotion.html',
						backdrop: 'static'
					});

					discountPromptModal.result.then(function (result) {
						proceedOrderConfirmation();
					});
				} else {
					proceedOrderConfirmation();
				}				
			}			
		}

		function proceedOrderConfirmation() {
			// confirm 'are you sure you want to confirm?'
			if (!SharedDataSvc.globalVars.orderSummary.storeInfo.isManned || $cookies.getObject("isHybrid")) {
				var confirmOrderModal = modalService.open({
					title: 'Confirm ID',
					content: 'this is the content',
					show: true,
					scope: $scope,
					// templateUrl: 'partials/confirm_confirmOrderConfirmModal.tpl.html',
					templateUrl: 'partials/modal_checkCustomerId.html',
					backdrop: 'static'
				});
			} else {					
				if (SharedDataSvc.globalVars.orderSummary.storeInfo.invoiceAtOrderconfirmation == 1) {
					// set ID confirm to 0
					SharedDataSvc.globalVars.order.currentEngagement.isIdConfirmed = 0;
					var confirmOrderModal = modalService.open({
						title: 'Confirm Order',
						content: 'this is the content',
						show: true,
						scope: $scope,
						// templateUrl: 'partials/confirm_confirmOrderConfirmModal.tpl.html',
						templateUrl: 'partials/confirm_confirmModalWithOutSaveOrder.tpl.html',
						backdrop: 'static'
					});
				}
				else {
					// set ID confirm to 0
					SharedDataSvc.globalVars.order.currentEngagement.isIdConfirmed = 0;
					if(SharedDataSvc.globalVars.orderSummary.storeInfo.immediateInvoicing || (SharedDataSvc.globalVars.orderSummary.storeInfo.immediateInvoicing === undefined)) {
						var confirmOrderModal = modalService.open({
							title: 'Confirm Order',
							content: 'this is the content',
							show: true,
							scope: $scope,
							templateUrl: 'partials/confirm_confirmOrderConfirmModal.tpl.html',
							//templateUrl: 'partials/modal_confirmOrderLoginCheck.html',
							backdrop: 'static'
						});
					}else {
						var confirmOrderModal = modalService.open({
							title: 'Confirm Order',
							content: 'this is the content',
							show: true,
							scope: $scope,
							// templateUrl: 'partials/confirm_confirmOrderConfirmModal.tpl.html',
							templateUrl: 'partials/modal_confirmOrderLoginCheck.html',
							backdrop: 'static'
						});
					}
				}
			}
		}

		function submitInvoiceAndSalesDetails() {
			console.log('Final Data stored');
			SharedDataSvc.globalVars.orderSummary.errors.invoiceNumber = false;
			SharedDataSvc.globalVars.orderSummary.errors.associate = false;

			if (estimate.invoiceNumber == '') {
				SharedDataSvc.globalVars.orderSummary.errors.invoiceNumber = true;
			}
			if (estimate.associate == '') {
				SharedDataSvc.globalVars.orderSummary.errors.associate = true;
			}
			if (!SharedDataSvc.globalVars.orderSummary.errors.invoiceNumber && !SharedDataSvc.globalVars.orderSummary.errors.associate) {
				SharedDataSvc.globalVars.order.currentEngagement.invoiceNum = estimate.invoiceNumber;
				SharedDataSvc.globalVars.order.currentEngagement.salesAssociateID = estimate.associate;
				//AC21230 - Web - Agreement status remains in voided status after editing and confirming the order - starts
				updateEngagementItemFactory.getData(estimate.engagementId).then(function (response) {
					SharedDataSvc.globalVars.order.currentEngagement.agreementStatus = response.agreementStatus;
					saveOrder('Confirmed', '/dashboard');
				}, function (response) {
					// error
					console.log('[Estimate] failed getting engagement data', response);
				});
				//AC21230 - Web - Agreement status remains in voided status after editing and confirming the order - ends
			}
		}

		function deliveryModalOptions(engagement, deliveryPart, windowClass) {
			deliveryPart = deliveryPart || '';
			windowClass = windowClass || '';
			return {
				templateUrl: 'partials/modal_invoiceAndDelivery' + deliveryPart + '.html',
				backdrop: 'static',
				controller: 'delivery',
				controllerAs: 'delivery',
				windowClass : windowClass,
				scope: $scope
			}
		}

		function verifyEmployeePassPhrase(val) {
			if(val) {
				agreementFactory.validatePassPhrase(val).then(function(response) {
						console.log(response);
						estimate.associatePassFail = false;
						saveOrder('Confirmed');
					}, function (response) {
						console.log("Passphrase validated error", response);
						SharedDataSvc.validatePassPhraseAttempts = SharedDataSvc.validatePassPhraseAttempts + 1;
						console.log('Validate Passphrase Attempts', SharedDataSvc.validatePassPhraseAttempts);
						if(SharedDataSvc.validatePassPhraseAttempts === 3) {
							SharedDataSvc.validatePassPhraseAttempts = 0;
							navigateTo('dashboard');
							estimate.invalidPassPhrase = false;
							estimate.associatePassFail = false;
						} else {
							estimate.errorMessage = response.data.errorData.status_message;
							estimate.invalidPassPhrase = true;
							estimate.associatePassFail = true;
						}
				});

			}else {
				estimate.associatePassFail = true;
				estimate.invalidPassPhrase = false;
			}

		}

		function submitEmployeePass(pass) {
			$scope.noEmpPass = false;
			$scope.errEmpPass = false;

			if (pass) {
				var auth = authentication.storePass;

				if (auth === pass) {
					console.log('associate password passes');
					//AC21230 - Web - Agreement status remains in voided status after editing and confirming the order - starts
					updateEngagementItemFactory.getData(estimate.engagementId).then(function (response) {
						//SharedDataSvc.globalVars.order.currentEngagement = response;
						SharedDataSvc.globalVars.order.currentEngagement.agreementStatus = response.agreementStatus;
						saveOrder('Confirmed', '/dashboard');
					}, function (response) {
						// error
						console.log('[Estimate] failed getting engagement data', response);
					});
					//AC21230 - Web - Agreement status remains in voided status after editing and confirming the order - ends
				} else {
					$scope.errEmpPass = true;
					console.log('associate password failure');
				}
			} else {
				$scope.noEmpPass = true;
				console.log('no associate password', estimate.noEmpPass);
			}
		}

		function submitEmployeePassWithOutSaveOrder(pass) {
			$scope.noEmpPass = false;
			$scope.errEmpPass = false;
			if (pass) {
				var auth = authentication.storePass;
				if (auth === pass) {
					console.log('associate password passes');
					//modalService.dismiss();
					var modal_invoiceAndSales = modalService.open({
						title: 'Confirm Order',
						content: 'this is the content',
						show: true,
						scope: $scope,
						templateUrl: 'partials/modal_invoiceAndDeliveryDetailsEstimate.html',
						backdrop: 'static'
					});
				} else {
					$scope.errEmpPass = true;
					console.log('associate password failure');
				}
			} else {
				$scope.noEmpPass = true;
				console.log('no associate password', estimate.noEmpPass);
			}
		}

		function checkMinMax() {
			console.log('[orderSummary.checkMinMax orderSubtotal]', SharedDataSvc.globalVars.orderSummary.orderSubtotal);
			console.log('[orderSummary.checkMinMax approvalAmount]', SharedDataSvc.globalVars.orderSummary.approvalAmount);

			if (!SharedDataSvc.globalVars.orderSummary.orderSubtotal || SharedDataSvc.globalVars.orderSummary.orderSubtotal < $scope.agreementAmount) {
				var confirmOrderModal = modalService.open({
					show: true,
					scope: $scope,
					controllerAs: 'estimate',
					templateUrl: 'partials/alert_minOrderModal.tpl.html',
					backdrop: 'static'
				});
				return false;
			}
			if (SharedDataSvc.globalVars.orderSummary.currentEngagement.applicationStatus != 'Accepted') {
				$scope.approvalAmount = SharedDataSvc.globalVars.orderSummary.approvalAmount;
				var confirmOrderModal = modalService.open({
					show: true,
					scope: $scope,
					controllerAs: 'estimate',
					templateUrl: 'partials/alert_manualOrderConfirmError.tpl.html',
					backdrop: 'static'
				});
				confirmOrderModal.result.then(function(action){
					if(action){
						saveOrder('InProgress', '/dashboard');
					}
				});
				return false;
			}

			/*var totalOrderAmount = SharedDataSvc.globalVars.orderSummary.orderSubtotal + 
				SharedDataSvc.globalVars.orderSummary.currentEngagementOrder.orderContent.extendedServiceAmount;

			if(totalOrderAmount > SharedDataSvc.globalVars.orderSummary.approvalAmount){
				var totalOrderAmountErrorModal = modalService.open({
					scope: $scope,
					templateUrl: 'partials/alert_totalOrderAmountError.html',
					backdrop: 'static'
				});
				return false;
			}*/
			
			/*VAN-4036 -AWP - order screen invoice reduction payment changes
			hide the max order alert in the order screen if the flag is enabled */

			if (SharedDataSvc.globalVars.orderSummary.orderSubtotal > SharedDataSvc.globalVars.orderSummary.approvalAmount) {
				if( !SharedDataSvc.invoiceReductionPayment) {
					$scope.approvalAmount = SharedDataSvc.globalVars.orderSummary.approvalAmount;
					var confirmOrderModal = modalService.open({
						show: true,
						scope: $scope,
						controllerAs: 'estimate',
						templateUrl: 'partials/alert_maxOrderModal.tpl.html',
						backdrop: 'static'
					});
					return false;
				}
			}
			//AC-27936 - Commenting the orderLimit check as the maximumPriceLimit for an item can be more than 3000. - Start
//			if (SharedDataSvc.globalVars.orderSummary.orderSubtotal > 3000) {
//				$scope.approvalAmount = 3000;
//				var confirmOrderModal = modalService.open({
//					show: true,
//					scope: $scope,
//					controllerAs: 'estimate',
//					templateUrl: 'partials/alert_maxOrderModal.tpl.html',
//					backdrop: 'static'
//				});
//				return false;
//			}
			//AC-27936 - Commenting the orderLimit check as the maximumPriceLimit for an item can be more than 3000. - End
			return true;
		}

		function confirmId() {
			console.log('confirm ID');
			SharedDataSvc.globalVars.order.currentEngagement.isIdConfirmed = 1;
			//VAN-3201-Enable remote signing option for AWP generated agreements- xpartner for retailers - Starts
			if($rootScope.xpartnerLogin){
				updateEngagementItemFactory.getData(estimate.engagementId).then(function (response) {
					//SharedDataSvc.globalVars.order.currentEngagement = response;
					SharedDataSvc.globalVars.order.currentEngagement.agreementStatus = response.agreementStatus;
					saveOrder('Confirmed', '/dashboard');
				}, function (response) {
					// error
					console.log('[Estimate] failed getting engagement data', response);
				});
				//VAN-3201-Enable remote signing option for AWP generated agreements- xpartner for retailers - Ends
			}else if(estimate.storeInfo.invoiceAtOrderconfirmation == 1){
				// set ID confirm to 0
				var confirmOrderModal = modalService.open({
					title: 'Confirm Order',
					content: 'this is the content',
					show: true,
					scope: $scope,
					// templateUrl: 'partials/confirm_confirmOrderConfirmModal.tpl.html',
					templateUrl: 'partials/confirm_confirmModalWithOutSaveOrder.tpl.html',
					backdrop: 'static'
				});
			}
			else {
				if(SharedDataSvc.globalVars.orderSummary.storeInfo.immediateInvoicing || (SharedDataSvc.globalVars.orderSummary.storeInfo.immediateInvoicing === undefined)) {
					var confirmOrderModal = modalService.open({
						title: 'Confirm Order',
						content: 'this is the content',
						show: true,
						scope: $scope,
						templateUrl: 'partials/confirm_confirmOrderConfirmModal.tpl.html',
						backdrop: 'static'
					});
				} else {
					var confirmOrderModal = modalService.open({
						title: 'Confirm Order',
						content: 'this is the content',
						show: true,
						scope: $scope,
						templateUrl: 'partials/modal_confirmOrderLoginCheck.html',
						backdrop: 'static'
					});
				}
			}
		}

		function saveOrder(orderStatus, navigateTo) {
			// check for unneeded param
			$location.search('confirm', null);
			// Start VAN 3066
			if(SharedDataSvc.globalVars.orderSummary.currentEngagementOrder.orderContent.deliveryFee == ''){
				SharedDataSvc.globalVars.orderSummary.currentEngagementOrder.orderContent.deliveryFee = 0;
			}
			if(SharedDataSvc.globalVars.orderSummary.currentEngagementOrder.orderContent.extendedServiceAmount == ''){
				SharedDataSvc.globalVars.orderSummary.currentEngagementOrder.orderContent.extendedServiceAmount = 0;
			}
			// End
			//SharedDataSvc.globalVars.orderSummary.order.currentEngagementOrder.orderContent = SharedDataSvc.globalVars.orderSummary.currentEngagementOrder.orderContent;
			console.log("Save order data", SharedDataSvc.globalVars.orderSummary.order);

			orderService.save(SharedDataSvc.globalVars.orderSummary.order, orderStatus, navigateTo).then(function(){
				console.log("Order saved successfully");
				//Opening the schedule delivery pop up here
				if(!SharedDataSvc.globalVars.orderSummary.storeInfo.immediateInvoicing && (SharedDataSvc.globalVars.orderSummary.storeInfo.immediateInvoicing !== undefined) && (SharedDataSvc.globalVars.orderSummary.order.currentEngagement.applicationStatus.toLocaleLowerCase() === "accepted") && (estimate.storeInfo.invoiceAtOrderconfirmation !== 1)) {
					estimate.onConfirmOrderSelectDeliveryModal = modalService.open(deliveryModalOptions(estimate.engagementId, "OnConfirmOrder", "no-Invoice-Modal"));
				}				
			}, function(){
				console.log("Order saved failed");
			});
		};

		function showModal(name, windowClass) {
			windowClass = windowClass || '';
	
			estimate.modal = modalService.open({
				templateUrl: '../../../partials/modal_' + name + '.html',
				backdrop: 'static',
				windowClass: windowClass,
				scope: $scope,
				show: true
			});
		}

	}
})();
