// application (entering)
myApp.factory('getSpecificApplication', function ($http, $q, SharedDataSvc, $cookies, $rootScope, API_DOMAIN, PROXY_ACTION_URL, authentication, ServiceAgent) {
	return {
		searchHT : function(mData){
			
			var deferred = $q.defer();
			var headers = SharedDataSvc.commonHeadersJSON;
			headers.endTarget = 'rentacentervrto/rest/customer/create';
			headers.sessionToken = authentication.getCurrentSessionId();
			headers.orderOriginator = 'AWP';
			
			var entryPointFromCookie = $cookies.getObject('AwpEntryPoint');
			if(typeof entryPointFromCookie !== "undefined"){
				mData.entryPointName = entryPointFromCookie;
				console.log("[entryPointName in create customer call from specific-application.js]", entryPointFromCookie);
			}
			
			console.log('[getSpecificApplication.searchHT] mData', mData);
			
			// NEW CUSTOMERS
			ServiceAgent.httpCall(
				PROXY_ACTION_URL,
				'POST',
				headers,
				mData,
				true
		    ).then(function (response) {
		    	console.log("[getSpecificApplication.searchHT] success response: ", response);
		    	deferred.resolve(response);
		    },
		    function (response) {
		    	console.log("[getSpecificApplication.searchHT] fail ", response);
		      	deferred.reject(response);
		    });
			return deferred.promise;
		}
	};
});
