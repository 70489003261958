///////////////////////////////////////////
//////////////////////////////////////////
///   DASHBOARD CONTROLLER   ////////////
////////////////////////////////////////
///////////////////////////////////////
(function(){

	'use strict';

	angular
		.module('app.dashboard')
		.controller('Dashboard', Dashboard);

	/* @ngInject */
	function Dashboard(
		$scope,
		$rootScope,
		$location,
		$route,
		$idle,
		$sce,
		$routeParams,
		$anchorScroll,
		$timeout,
		$window,//VAN-441 - VAN Docusign - Remote signing documents on personal device
		$cookies,
		$translate,
		modalService,
		authentication,
		SharedDataSvc,
		getInitialDashboard,
		applicationService,
		updateEngagementItemFactory,
		paymentFactory,//VAN-441 - VAN Docusign - Remote signing documents on personal device
		getStoreInfoFactory,
		manualOverrideFactory,
		getTrainingLibraryFactory,
		dashboardServices,
		datesFactory, //AC-28614 Changes removed MANNED_STORE_PHONE and UNMANNED_STORE_PHONE
		customerDataFactory,
		stateRestrictFactory,
		orderEstimatePutFactory,
		productFactory
	) {

		/*jshint validthis: true */
		var dashboard = this;

		/*
		 TODO:
		 - Change icons for Dashboard progress
		 - Fix status order
		 */

		// DEFINE VARS
		$rootScope.hideDashboardButton = true;
		$rootScope.hideMenuButtons = false;
		$rootScope.hideTrainingLibButton = false;
		$rootScope.hideDashboardAgreementButton = false;
		$rootScope.agreementAmount;
		$rootScope.creditAmount;
		$rootScope.creditCardNo;
		$rootScope.creditCardExp;
		$rootScope.creditCardCVV;
		$rootScope.servicePhoneNumber = '';
		$scope.searchValue = SharedDataSvc.searchValue;
		
		//VAN-441 - VAN Docusign - Remote signing documents on personal device - Starts
		$scope.remoteEmail={};
		$scope.remoteEmail.lanType ='1';
		$rootScope.showDocusign = false;
		dashboard.isDocusign = isDocusign;
		//VAN-441 - VAN Docusign - Remote signing documents on personal device - Ends
		
		// used by modal for DocuSigning rewritten agreements
		$scope.raUpdate = {};

		dashboard.openModal = false;
		dashboard.noSearchValue = true;
		dashboard.getInitialDashboard = [];
		dashboard.globalVars = SharedDataSvc.globalVars;
		dashboard.showButtons = false;
		dashboard.order = 'Date';
		dashboard.sortingOrder = '';
		dashboard.loadingDashboard = true;
		dashboard.globalmessage = SharedDataSvc.globalmessage;
		dashboard.globalmessageShow = SharedDataSvc.globalmessageShow;
		dashboard.deliveryTempId = '';
		dashboard.transformedOrderStatus = [];
		SharedDataSvc.hasECmodalRun = false;
		dashboard.isStoreManned;
		//VAN - 1430 - IE Performance starts
		$scope.detailsView = true;
		$scope.val;
		//VAN - 1430 - IE Performance ends
		/*VAN 238 - VAN - AWP/Kiosk QQ - Deliver monthly payment and number of months w/o initial payment - Starts*/ 		
		dashboard.estimatedInitialPaymentQq;
		/*VAN 238 - VAN - AWP/Kiosk QQ - Deliver monthly payment and number of months w/o initial payment - Ends*/
		
		
		dashboard.engagementId;
		dashboard.employeeId;
		dashboard.noAssociatePass = false;
		dashboard.invalidAssociatePass = false;
		dashboard.creditCardDetail;
		//AC 25996 code changes starts
		dashboard.currentEngagement;
		dashboard.agreementHtId;
		//AC 25996 code changes ends
		dashboard.restrictions;
		dashboard.prodCat;
		dashboard.quickQuoteEstimate;
		dashboard.quoteInput = {
			deliveryMaxAmount : 500.00,
			ESVMaxAmount : 999.99
		};
		dashboard.itemMinimumPrice;
		dashboard.itemMaximumPrice;
		dashboard.initialRentalFee;
		dashboard.monthlyRentalFee;
		dashboard.quickQuoteInitialInfo;
		//dashboard.quickQuoteDisclaimer;
		dashboard.letsGetStarted = true;
		dashboard.isQuickQuoteEnabled;
		dashboard.nextRenewDte = [];
		//VAN-353 code changes
		dashboard.noEsvNoDel;
		dashboard.onlyEsvNoDel;
		dashboard.noESVonlyDel;
		dashboard.withEsvDel;
		dashboard.selectionFlag;

		// PUBLIC FUNCTIONS
		dashboard.orderStatusVerbiage = orderStatusVerbiage;
		dashboard.setOrderStatusClass = setOrderStatusClass;
		dashboard.agreementStatusVerbiage = agreementStatusVerbiage;
		dashboard.setAgreementStatusClass = setAgreementStatusClass;
		dashboard.deliveryStatusVerbiage = deliveryStatusVerbiage;
		dashboard.setDeliveryStatusClass = setDeliveryStatusClass;
		dashboard.applicationStatusVerbiage = applicationStatusVerbiage;
		dashboard.setApplicationStatusClass = setApplicationStatusClass;
		dashboard.paymentStatusVerbiage = paymentStatusVerbiage;
		dashboard.setPaymentStatusClass = setPaymentStatusClass;
		dashboard.saleStatusVerbiage = saleStatusVerbiage;
		dashboard.setSaleStatusClass = setSaleStatusClass;
		dashboard.summaryStatus = summaryStatus;
		dashboard.summaryStatusForSortingFn = summaryStatusForSortingFn;
		dashboard.editApplication = editApplication;
		dashboard.editDelivery = editDelivery;
		dashboard.showDeliveryModal = showDeliveryModal;
		dashboard.statusIcon = statusIcon;
		dashboard.navigateTo = navigateTo;
		dashboard.showConfirmOrderBtn = showConfirmOrderBtn;
		dashboard.showAddInfoBtn = showAddInfoBtn;
		dashboard.getManualInterventionStatus = getManualInterventionStatus;
		dashboard.isApplicationEditable = isApplicationEditable;
		dashboard.showScheduleDeliveryCta = showScheduleDeliveryCta;
		//dashboard.showGenerateCreditCardBtn = showGenerateCreditCardBtn;
		dashboard.showViewPrintVccBtn = showViewPrintVccBtn;
		dashboard.showModalCreditCard = showModalCreditCard;
		dashboard.showModal = showModal;
		//dashboard.confirmGenerateCreditCard = confirmGenerateCreditCard;
		dashboard.viewVccBarcodeDetails = viewVccBarcodeDetails;
		dashboard.getAdditionalDetails = getAdditionalDetails;
		dashboard.setAdditionalDetails = setAdditionalDetails;
		//dashboard.generateCreditCardCheck = generateCreditCardCheck;
		//dashboard.generateCreditCard = generateCreditCard;
		dashboard.summaryStatusSortFn = summaryStatusSortFn;
		dashboard.showSearchIndicator = showSearchIndicator;
		dashboard.showModalSearchIndicator = showModalSearchIndicator;
		//AC 25996 code changes starts
		dashboard.modifyOrderSummary = modifyOrderSummary;
		dashboard.checkForAgreementData = checkForAgreementData;
		dashboard.declineAgreementModalOptions = declineAgreementModalOptions;
		dashboard.declineAgreement = declineAgreement;
		//AC 25996 code changes ends
		dashboard.ssnCheckForAgreements = ssnCheckForAgreements;
		dashboard.continueToAgreement = continueToAgreement;//AC24649 code changes
		dashboard.getQuoteModal = getQuoteModal;
		dashboard.getRAUpdateSearchModal = getRAUpdateSearchModal;
		dashboard.inStoreRAUpdateDocuSign = inStoreRAUpdateDocuSign;
		dashboard.remoteRAUpdateDocuSign = remoteRAUpdateDocuSign;
		dashboard.quickQuoteCalculation = quickQuoteCalculation;
		dashboard.quoteModalReset = quoteModalReset;
		dashboard.feeCheck = feeCheck;
		/*VAN-934 - Quick Quote – Add biweekly and 4weeks quote, along with monthly, to both AWP and Kiosk - starts*/
		dashboard.resetQQEPO = resetQQEPO;
		dashboard.resetQQWhenCatChange = resetQQWhenCatChange;
		/* VAN-975 - AWP : Quick Quote Screen - Payment Details were not getting cleared on relaunch*/
		dashboard.showPaymentSectionForBiweekly = true;
		/*VAN-934 - Quick Quote – Add biweekly and 4weeks quote, along with monthly, to both AWP and Kiosk - Ends*/
		dashboard.viewApplication = viewApplication;
		dashboard.verifyInfo = verifyInfo;
		dashboard.resetSearchResults = resetSearchResults;
		dashboard.navigateToAgreement = navigateToAgreement;
		dashboard.filterByVerifyInfo = filterByVerifyInfo;
		dashboard.remoteSignFromDashboard = remoteSignFromDashboard;//VAN-441 - VAN Docusign - Remote signing documents on personal device
		dashboard.verifyInfoCount = 0;
		dashboard.isVerified = isVerified;
		dashboard.isVeriFiltered = false;
		dashboard.allData = [];
		//VAN - 1430 - IE Performance starts
		dashboard.loadMore = loadMore;
		dashboard.detailsView = detailsView;
		//VAN - 1430 - IE Performance ends
		dashboard.processingModal;//VAN-3229-AWP - Remove associate password popup for user login with XPARTNER during  entering estimated delivery date
		var itemStatus = {
			application: {
				notStarted: 'Pending',
				inProgress: 'InProgress',
				declined: 'Declined',
				accepted: 'Accepted',
				manualIntervention: 'Manual',
				outOfServiceArea: 'OutOfArea',
				associateReview: 'AssocRvw'
			},
			order: {
				notStarted: 'NotStarted',
				inProgress: 'InProgress',
				pendingConfirm: 'Pending',
				confirmed: 'Confirmed',
				reconfirm: 'Reconfirm'  /*Fixed defect 24952 by handling the newly added 'Reconfirm' as order status.*/
			},
			agreement: {
				notStarted: 'Pending',
				inProgress: 'InProgress',
				declined: 'Declined',
				accepted: 'Accepted',
				voided: 'Voided'  //AC 25996 updated code changes
			},
			payment: {
				pendingConfirm: 'Pending',
				inProgress: 'InProgress',
				complete: 'Complete'
			},
			delivery: {
				notStarted: 'NotStarted',
				pendingPayment: 'Pending',
				complete: 'Complete',
				scheduled: 'Scheduled',
				pickup: 'Pickup',
				confirmed: 'Confirmed',
				readyToSchedule: 'ReadySched'
			},
			sales: {
				pending: 'Pending',
				complete: 'Complete',
				ready: 'Ready',
				submitted: 'Submitted'
			}
		};
		dashboard.itemStatus = itemStatus;

		$scope.stepsListEn = [
			'Application and Order (either can be first)',
			'Order Confirmation',
			'Agreements',
			'Payment',
			'Invoice & Delivery or Generate Credit Card (either can be first)'
		];
		$scope.stepsListEs = [
			"Aplicación y orden (Cualquiera puede ser primero)",
			"Confirmación del pedido",
			"Acuerdos",
			"Pago",
			"Factura y entrega o genere la tarjeta de crédito (Cualquiera puede ser primero)"	
		];

		var excludedCategories = [];

		init();

		function init () {
			if($scope.searchValue !== undefined && $scope.searchValue.length > 0) {
				//$scope.filterByName = $scope.searchValue;
				$scope.filterByName = {
					customerFullName : $scope.searchValue
				}
			}
			// Remove url param
			$location.search('data', null);//Defect 28266 - VAN: References getting wiped off in HT
			if($location.$$search.confirm) {
				$location.search('confirm', null);
			}

			SharedDataSvc.clearPrintSection();
			SharedDataSvc.globalVars.navigateToDashboard = false;
			$scope.isSpanishStore = $cookies.getObject('isSpanishStore');

			// get storeInfo data
			getStoreInfoFactory.getData()
				.then(function(response){
					dashboard.storeInfo = response;
					$rootScope.storeInfo = response;
					dashboard.enableRemoteForDocusign = response.enableRemoteForDocusign;//VAN-441 - Remote signing documents on personal device
					SharedDataSvc.storeInfo = response;
					$scope.vccPrint = response.vccPrintAWP;
					if(!response.quickQuoteProductList){
						dashboard.hideQQProductList = true;
					} else if(response.quickQuoteProductList.product_en.length == 1) {
						dashboard.quoteInput.selectedProduct = response.quickQuoteProductList.product_en[0];
						dashboard.hideQQProductList = true;
					} else {
						dashboard.hideQQProductList = false;
					}
					// Set store info in global service
					SharedDataSvc.globalVars.storeSetup = response;
					dashboard.isStoreManned = SharedDataSvc.globalVars.storeSetup.isManned;
					dashboard.isHybrid = SharedDataSvc.globalVars.storeSetup.hybridEnabled;
					
					/*VAN 238 - VAN - AWP/Kiosk QQ - Deliver monthly payment and number of months w/o initial payment - Starts*/
					dashboard.estimatedInitialPaymentQq = SharedDataSvc.globalVars.storeSetup.estimatedInitialPaymentQq;
					/*VAN 238 - VAN - AWP/Kiosk QQ - Deliver monthly payment and number of months w/o initial payment - Ends*/
					
					dashboard.overrideOption = response.overrideOption;
					dashboard.itemMinimumPrice = response.minItemAmount;
					dashboard.itemMaximumPrice = response.maxItemPrice;
					dashboard.isQuickQuoteEnabled = response.isQuickQuoteEnabled;
					dashboard.allowExtendedWarranty = response.allowExtendedWarranty;
					dashboard.arrAdjustablePayment = SharedDataSvc.globalVars.storeSetup.adjustableInitPayment.split(",");
					dashboard.isAdjustablePayment = parseInt(dashboard.arrAdjustablePayment[0]);
					if(!dashboard.isAdjustablePayment) {
						var currentDate = datesFactory.getCurrentDate();
						dashboard.nextRenewDteOptions = datesFactory.setQuoteDates(currentDate);
						_.forEach(dashboard.nextRenewDteOptions, function(item, index) {
							if($scope.isSpanishStore) {
								dashboard.nextRenewDte[index] = {
									id: index,
									option: item.day + ' ' + item.month,
									formattedDate: item.nextDate
								}
							} else {
								dashboard.nextRenewDte[index] = {
									id: index,
									option: item.month + ' ' + item.day,
									formattedDate: item.nextDate
								}
							}
 						})
						console.log("Quick quote dates", dashboard.nextRenewDte);
					}

					if (response.excludedCategories) {
						excludedCategories = response.excludedCategories;
					}
					if(response.immediateInvoicing == false){
						/*$scope.stepsList = [
							$translate.instant('modal_enforceOrder_desc2'),
							$translate.instant('modal_enforceOrder_desc3'),
							$translate.instant('modal_enforceOrder_desc4'),
							$translate.instant('modal_enforceOrder_desc5'),
							$translate.instant('modal_enforceOrder_desc6')							
						];*/
						$scope.stepsListEn = [
							'Application and Order (either can be first)',
							'Order Confirmation',
							'Agreements',
							'Payment',
							'Invoice & Delivery or Generate Credit Card (either can be first)'
						];
						$scope.stepsListEs = [
							"Aplicación y orden (Cualquiera puede ser primero)",
							"Confirmación del pedido",
							"Acuerdos",
							"Pago",
							"Factura y entrega o genere la tarjeta de crédito (Cualquiera puede ser primero)"	
						];
					}

					//ITIN flag
					$rootScope.itinAllowed = SharedDataSvc.globalVars.storeSetup.itinAllowed;
					$rootScope.invoiceMethod = response.invoiceMethod;
					$rootScope.vccPrint = response.vccPrintAWP;
					if(response.vanStoreId){
						$rootScope.storeNo = response.vanStoreId;
						$rootScope.state = response.state;
						
						/*VAN 501 - PR: Lines 2-12, Associate review should not display for PR store*/
						$scope.showLinesBtn = $rootScope.state!="PR" ? $scope.showLinesBtn=true : $scope.showLinesBtn=false;
						
						$rootScope.isHybrid = $cookies.getObject("isHybrid");

						/* Generating random string below as per VAN-2783 */
						var randomString = "";
			    		var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
			    		for( var i=0; i < 9; i++ ){
			    			randomString += possible.charAt(Math.floor(Math.random() * possible.length));
			    		}

			    		var awpEntryPointName = "AWP-"+response.vanStoreId+"-"+randomString;
			        	console.log("[New AwpEntryPoint]:", awpEntryPointName);

			        	var crrCookie = $cookies.getObject('AwpEntryPoint');

			        	if(typeof crrCookie === "undefined"){
			        		var curDate = new Date();
			        		var curYear = curDate.getFullYear();
			        		var expYear = parseInt(curYear) + 10;
			        		var curMonth = curDate.getMonth();
			        		var curDay = curDate.getDate();

			        		var expDate = new Date(expYear, curMonth, curDay);
			        		console.log("[ExpDate]", expDate);

			        		$cookies.putObject('AwpEntryPoint', awpEntryPointName, {'expires': expDate} );
			        	}else{
			        		console.log("[AwpEntry point already set is]", crrCookie);
			        	}

					}

					stateRestrictFactory.getAllStoreRestrictions(response.state)
					.then(function(response) {
						dashboard.restrictions = response;
						console.log('[Dashboard.init] store restrictions', dashboard.restrictions);
					});
				}, function(response){
					console.log('error while getting store info', response);
				});
			// get the data!
			getInitialDashboard.getData().then(function (data) {
					//console.log("[dashboard.init] success ", data);
					// DECRYPT this data
					dashboard.loadingDashboard = false;
					dashboard.dashboardData = data;
					$scope.originalDashboardData = angular.copy(dashboard.dashboardData);
					//Adding element to subset arrays of response data for Search functionality
					var infoCount = 0;
					$scope.dashboard.dashboardData = angular.forEach(dashboard.dashboardData, function(value, key) {
						var customerFullName = value.customerFirstName + ' ' + value.customerLastName;
						$scope.dashboard.dashboardData[key].customerFullName = customerFullName;
						
						if($scope.dashboard.dashboardData[key].verifyInfoAssociateName == undefined || $scope.dashboard.dashboardData[key].verifyInfoAssociateName.length <= 0){
							if($scope.dashboard.dashboardData[key].applicationStatus === "Accepted"){
								infoCount++;
							}
							
						}
						if($scope.dashboard.dashboardData[key].agreementOriginator == undefined){
							/*VAN-277 - VAN - Change VAN/AWP to Kiosk/AWP on i button on dashboard - Starts*/
							/*$scope.dashboard.dashboardData[key].agreementOriginator = "AWP/VAN";*/
							$scope.dashboard.dashboardData[key].agreementOriginator = "AWP/Kiosk";
							/*VAN-277 - VAN - Change VAN/AWP to Kiosk/AWP on i button on dashboard - Ends*/
						}else if($scope.dashboard.dashboardData[key].agreementOriginator == "HighTouch"){
							$scope.dashboard.dashboardData[key].agreementOriginator = "Point of Sale";
						}
						//VAN-441 - VAN Docusign - Remote signing documents on personal device - Starts
						if(dashboard.enableRemoteForDocusign){
							if($scope.dashboard.dashboardData[key].applicationStatus  === 'Accepted' && $scope.dashboard.dashboardData[key].orderStatus  === 'Confirmed' && $scope.dashboard.dashboardData[key].agreementStatus === 'Pending' 
								&& $scope.dashboard.dashboardData[key].agreementId !== 'null'/* && $scope.dashboard.dashboardData[key].agreementOriginator === 'Point of Sale' */){//VAN-3189-Enable remote signing option for AWP generated agreements- xteam changes- xteam changes
								$rootScope.showDocusign = true;
							}
						}
						//VAN-441 - VAN Docusign - Remote signing documents on personal device - Ends

					});
					dashboard.verifyInfoCount = infoCount;
					console.log("[dashboard.init] success ", dashboard.dashboardData);
					SharedDataSvc.globalVars.dashboardListCache = data;
					var routeEngId = $routeParams.engagementId;
					if (routeEngId) {
						$timeout(function() {
							//VAN - 1430 - IE Performance starts
							var old = $location.hash();
							$location.hash('engagementId_' + routeEngId);
							$anchorScroll();
							$location.hash(old);
							//VAN - 1430 - IE Performance starts
							$("#hide_btn_" + routeEngId).removeClass("collapsed");
							$("#panel_" + routeEngId).addClass("in");
							$("#panel_" + routeEngId).css("height", "auto");
							$scope.val = parseInt(routeEngId);
						}, 1000);
					}
				},
				function (data) {
					console.log("[dashboard.init] fail ", data);
				});
			getTrainingLibraryFactory.getData().then(function(response){
				console.log('New Docs', getTrainingLibraryFactory.getNewDocuments(response));
				$rootScope.trainingCount = getTrainingLibraryFactory.getNewDocuments(response);
			});

			//get product category data
			productFactory.getProductCategories().then(function(response) {
				dashboard.prodCat = response.productCategories;
			});

		}
		//VAN-441 Legal/Compliance - Remote signing documents on personal device - Starts
		function isDocusign(item){
			if(dashboard.enableRemoteForDocusign){
				if(item.applicationStatus  === 'Accepted' && item.orderStatus  === 'Confirmed' && item.agreementStatus === 'Pending' 
					&& item.agreementId !== null && item.agreementId !== undefined /*&& item.agreementOriginator === 'Point of Sale' */){//VAN-3189-Enable remote signing option for AWP generated agreements- xteam changes- xteam changes
					return true;
				}
				else{
					return false;
				}
			}else{
				return false;}
		}
		//VAN-441 Legal/Compliance - Remote signing documents on personal device - Ends
		function isVerified(item){
			if($scope.isSpanishStore){
				if(item.verifyInfoAssociateName == undefined || item.verifyInfoAssociateName.length <= 0){
					if(item.applicationStatus === "Accepted"){
							return true;
					}
				}else{
					return false;
				}
			}else{
				return false;
			}
			
		}

		function filterByVerifyInfo(){
			if(dashboard.isVeriFiltered === false){
				var verifyDashboardData = angular.copy($scope.dashboard.dashboardData);
				dashboard.allData = jQuery.extend(true, {}, verifyDashboardData);
				dashboard.isVeriFiltered  = true;
				if(dashboard.isVeriFiltered){
					var filtered = [];
					angular.forEach(dashboard.dashboardData, function(value, key) {
						if($scope.dashboard.dashboardData[key].verifyInfoAssociateName == undefined || $scope.dashboard.dashboardData[key].verifyInfoAssociateName.length <= 0){
							if($scope.dashboard.dashboardData[key].applicationStatus === "Accepted"){
								filtered.push($scope.dashboard.dashboardData[key]);
							}
						}
					});
				    console.log(filtered);
				    $scope.dashboard.dashboardData = filtered;
			    }
			}else{
				 dashboard.dashboardData = $scope.originalDashboardData;
				 dashboard.isVeriFiltered = false;
			}
		}

		function resetSearchResults() {
			var data = $scope.dashboard.dashboardData;
			SharedDataSvc.searchValue = "";
			$scope.searchValue = "";
			$scope.filterByName = {};
			$scope.filterByVerifyInfo = {};
			dashboard.loadingDashboard = true;
			dashboard.noSearchValue = false;
			$scope.dashboard.dashboardData = "";
			$timeout(function() {
				$scope.dashboard.dashboardData = data;
				dashboard.loadingDashboard = false;
				dashboard.noSearchValue = true;
			}, 100);
		}
		
		function provideSSN(target, item){
			var confirmCustomerWithSSN = modalService.open({
				title: 'Customer Verification',
				templateUrl: 'partials/modal_confirmCustomerWithSSN.html',
				scope: $scope,
				backdrop: 'static'
			});
			confirmCustomerWithSSN.result
				.then(function(data) {
					var person = $scope.personData;				
					person.ssn = data.ssn1 + data.ssn2 + data.ssn3;
					var modalTitle = "Confirming customer";
					modalService.processing(modalTitle);
					verifySSN(target,item, person);
			});
		}
		
		function verifySSN(target, item, personData){
			applicationService.getApplication(personData).then(function(successData){
				SharedDataSvc.globalVars.engagementId = item.engagementId; //story 26289 code changes
				target += '/'+item.engagementId;
				$location.path(target);
			}, function(failData){
				//modalService.lock(false);
				modalService.dismiss();
				console.log(failData);
				$scope.ssnInvalid = true;
				provideSSN(target, item);
			});
		}
		
		function ssnCheckForAgreements(target, item){
			$scope.ssnInvalid = false;
			customerDataFactory.findByEngagementId(item.engagementId).then(function(customerData){
				var personData = {
					firstName: customerData.customer.fName,
					lastName: customerData.customer.lName,
					phone: customerData.customer.phNum
				};
				
				$scope.itinFlag = customerData.customer.itinFlag;
				$scope.personData = personData;
				provideSSN(target, item);					
			}, function(failData){
				console.log(failData);
				modalService.errorModal();
			});
	
		}		
		

		////////////////////////////////////////
		/// STATUS ICONS/TEXT /////////////////
		//////////////////////////////////////

		// APPLICATION STATUS
		// ===============================================================================

		function getManualInterventionStatus(engagement, isManned, isHybrid) {
			if(isManned && !isHybrid) {
				if(engagement.overallApprovalReason.indexOf('Skip Stolen') > -1) {
					return 'S/S Inquiry';
				} else if(engagement.overallApprovalReason.indexOf('Conditional Approval') > -1) {
					return 'Verification Required';
				} else if(engagement.overallApprovalReason.indexOf('Address') > -1 && engagement.overallApprovalReason.indexOf('income') > -1) {
					return 'Income and Address Verification';
				} else if(engagement.overallApprovalReason.indexOf('Address') > -1) {
					return 'Address Verification';
				//AC23434 code changes start
				} else if(engagement.overallApprovalReason.indexOf('Outstanding balance with AcceptanceNow or Rent-A-Center') > -1) {
					return 'Outstanding balance verification';
				//AC23434 code changes end
				} else {
					return 'Income Verification';
				}
			} else {
				if(isHybrid) {
					return 'See AcceptanceNOW representative';
				}
				return 'Contact Support Center';
			}
		}

		function applicationStatusVerbiage(item) { //Story 30557 code change
			var status = item.applicationStatus, outstandingBalance = item.outstandingBalance;
			var applicationStatus = itemStatus.application;
			switch (status) {
				case applicationStatus.notStarted:
					var translatedString = $translate.instant("dashboard_span_applicationStatus_1");
					return translatedString;
				case applicationStatus.declined:
					if(item.isCoWorkerDeclined){
						var translatedString = $translate.instant("dashboard_span_applicationStatus_7");
						return translatedString;
					} else {						
						var translatedString = $translate.instant("dashboard_span_applicationStatus_2");
						return translatedString;
					}
				case applicationStatus.accepted:
					var translatedString = $translate.instant("dashboard_span_applicationStatus_3");
					return translatedString;
				case applicationStatus.outOfServiceArea:
					var translatedString = $translate.instant("dashboard_span_applicationStatus_4");
					return translatedString;
				case applicationStatus.manualIntervention:
					var translatedString = $translate.instant("dashboard_span_applicationStatus_5");
					return translatedString;
				case applicationStatus.associateReview:			
					var translatedString = $translate.instant("dashboard_span_applicationStatus_1");
					return outstandingBalance && outstandingBalance >0 ? translatedString :''; //Story 30557 code change
				case applicationStatus.inProgress:
					var translatedString = $translate.instant("dashboard_span_applicationStatus_6");
					return translatedString;
				default:
					var translatedString = $translate.instant("dashboard_span_applicationStatus_1");
					return translatedString;
			}
		}

		function setApplicationStatusClass(status) {
			var applicationStatus = itemStatus.application;
			switch (status) {
				case applicationStatus.notStarted:
					return "process-standby"; // gray
				case applicationStatus.inProgress:
					return "process-yellow"; // yellow
				case applicationStatus.declined:
					return "process-problem itemNoEdit"; // red
				case applicationStatus.accepted:
					return "process-complete"; //green
				case applicationStatus.manualIntervention:
				case applicationStatus.associateReview:
					return "process-yellow"; // yellow
				case applicationStatus.outOfServiceArea:
					return "process-problem"; // red
				default:
					return "process-standby"; // gray
			}
		}

		function isApplicationEditable (item) {
			if (dashboard.isStoreManned && item.applicationStatus !== 'Declined') {
				//VAN-2861 Start - Disable Application edit icon when Current Available Amount is 0 or lesser than 0
				if(item.applicationStatus === 'Accepted' && item.approvalAmount <= 0 && !$scope.isSpanishStore){
					return false;
				}else{
					return true;
				}
				//VAN-2861 End
				return true;
			} else {
				if (item.applicationStatus === 'Accepted' || item.applicationStatus === 'Declined' || item.applicationStatus === 'AssocRvw' && !dashboard.overrideOption) {
					return false;
				}
				return true;
			}
		}

		// ORDER STATUS
		// ===============================================================================

		function orderStatusVerbiage(status) {
			var orderStatus = itemStatus.order;
			switch(status) {
				case orderStatus.notStarted:
					var translatedString = $translate.instant("dashboard_span_orderStatus_1");
					return translatedString;
				case orderStatus.inProgress:
					var translatedString = $translate.instant("dashboard_span_orderStatus_2");
					return translatedString;
				case orderStatus.pendingConfirm:
					var translatedString = $translate.instant("dashboard_span_orderStatus_3");
					return translatedString;
				case orderStatus.confirmed:
					var translatedString = $translate.instant("dashboard_span_orderStatus_4");
					return translatedString;
				case orderStatus.reconfirm:
					var translatedString = $translate.instant("dashboard_span_orderStatus_4");
					return translatedString;      
				default:
					var translatedString = $translate.instant("dashboard_span_orderStatus_1");
					return translatedString;      
			}
		}

		function setOrderStatusClass(status) {
			var orderStatus = itemStatus.order;
			switch(status) {
				case orderStatus.notStarted:
					return "process-standby";
				case orderStatus.inProgress:
					return "process-yellow";
				case orderStatus.pendingConfirm:
					return "process-problem";
				case orderStatus.confirmed:
					return "process-complete";
				default:
					return "process-standby";
			}
		}

		function showConfirmOrderBtn(item) {
			var currentOrderStatus = item.orderStatus,
				currentApplicationStatus = item.applicationStatus;
			if (currentOrderStatus === dashboard.itemStatus.order.pendingConfirm &&
				currentApplicationStatus === dashboard.itemStatus.application.accepted) {
				return true;
			}
			return false;
		}

		function showAddInfoBtn(item){
			var currentPaymentStatus = item.paymentStatus;
			if(dashboard.isStoreManned && !dashboard.isHybrid && item.isAdditionalDetailsNeeded && currentPaymentStatus == 'Complete' && 
				(item.deliveryStatus === dashboard.itemStatus.delivery.scheduled || item.deliveryStatus === dashboard.itemStatus.delivery.pickup)){
				return true
			}
			return false;
		}

		// AGREEMENTS STATUS
		// ===============================================================================

		function agreementStatusVerbiage(status) {
			var agreementStatus = itemStatus.agreement;
			switch(status) {
				case agreementStatus.notStarted:
					var translatedString = $translate.instant("dashboard_span_agreementStatus_1");
					return translatedString;
				case agreementStatus.inProgress:
					var translatedString = $translate.instant("dashboard_span_agreementStatus_2");
					return translatedString;
				case agreementStatus.accepted:
					var translatedString = $translate.instant("dashboard_span_agreementStatus_3");
					return translatedString;
				case agreementStatus.declined:
					var translatedString = $translate.instant("dashboard_span_agreementStatus_4");
					return translatedString;
				case agreementStatus.voided: 
					var translatedString = $translate.instant("dashboard_span_agreementStatus_5");
					return translatedString;
				default:
					var translatedString = $translate.instant("dashboard_span_agreementStatus_1");
					return translatedString;
			}
		}

		function setAgreementStatusClass(status) {
			var agreementStatus = itemStatus.agreement;
			switch(status) {
				case agreementStatus.notStarted:
					return "process-standby"; //gray
				case agreementStatus.inProgress:
					return "process-yellow"; //yellow
				case agreementStatus.accepted:
					return "process-complete itemNoEdit"; //green
				case agreementStatus.declined:
					return "process-problem itemNoEdit"; //red
				default:
					return "process-standby"; //gray
			}
		}

		// PAYMENT STATUS
		// ===============================================================================

		function paymentStatusVerbiage(status) {
			var paymentStatus = itemStatus.payment;
			switch (status) {
				case paymentStatus.pendingConfirm:
					var translatedString = $translate.instant("dashboard_span_paymentStatus_1");
					return translatedString;
				case paymentStatus.inProgress:
					var translatedString = $translate.instant("dashboard_span_paymentStatus_2");
					return translatedString;
				case paymentStatus.complete:
					var translatedString = $translate.instant("dashboard_span_paymentStatus_3");
					return translatedString;
				default:
					var translatedString = $translate.instant("dashboard_span_paymentStatus_1");
					return translatedString;
			}
		}

		function setPaymentStatusClass(status) {
			var paymentStatus = itemStatus.payment;
			switch (status) {
				case paymentStatus.pendingConfirm:
					return "process-standby";
				case paymentStatus.inProgress:
					return "process-yellow";
				case paymentStatus.complete:
					return "process-complete";
			}
		}

		// DELIVERY STATUS
		// ===============================================================================

		function deliveryStatusVerbiage(status, date) {
			var deliveryStatus = itemStatus.delivery;
			switch(status) {
				case deliveryStatus.notStarted:
					var translatedString = $translate.instant("dashboard_span_deliveryStatus_1");
					return translatedString;
				case deliveryStatus.pendingPayment:
					var translatedString = $translate.instant("dashboard_span_deliveryStatus_2");
					return translatedString;
				case deliveryStatus.complete:
					var translatedString = $translate.instant("dashboard_span_deliveryStatus_3");
					return translatedString;
				case deliveryStatus.scheduled:
					var translatedString = $translate.instant("dashboard_span_deliveryStatus_4");
					return translatedString + moment(date).format('MM/DD/YYYY');
				case deliveryStatus.pickup:
					var translatedString = $translate.instant("dashboard_span_deliveryStatus_5");
					return translatedString + moment(date).format('MM/DD/YYYY');
				case deliveryStatus.confirmed:
					var translatedString = $translate.instant("dashboard_span_deliveryStatus_6");
					return translatedString;
				case deliveryStatus.readyToSchedule:
					var translatedString = $translate.instant("dashboard_span_deliveryStatus_7");
					return translatedString;
				default:
					var translatedString = $translate.instant("dashboard_span_deliveryStatus_8");
					return translatedString;
			}
		}

		function setDeliveryStatusClass(status) {
			var deliveryStatus = itemStatus.delivery;
			switch(status) {
				case deliveryStatus.notStarted:
				case deliveryStatus.pendingPayment:
				case deliveryStatus.complete:
					return "process-standby";
				case deliveryStatus.pickup:
				case deliveryStatus.scheduled:
					return "process-yellow itemNoEdit";
				case deliveryStatus.readyToSchedule:
					return "process-problem";
				case deliveryStatus.confirmed:
					return "process-complete itemNoEdit";
				default:
			}
		}

		// SALE STATUS
		// ===============================================================================

		function saleStatusVerbiage(status) {
			var salesStatus = itemStatus.sales;
			switch(status) {
				case salesStatus.pending:
					var translatedString = $translate.instant("dashboard_span_saleStatus_1");
					return translatedString;
				case salesStatus.complete:
					var translatedString = $translate.instant("dashboard_span_saleStatus_2");
					return translatedString;
				case salesStatus.ready:
					var translatedString = $translate.instant("dashboard_span_saleStatus_3");
					return translatedString;
				case salesStatus.submitted:
					var translatedString = $translate.instant("dashboard_span_saleStatus_4");
					return translatedString;
				default:
					var translatedString = $translate.instant("dashboard_span_saleStatus_5");
					return translatedString;
			}
		}

		function setSaleStatusClass(status) {
			var salesStatus = itemStatus.sales;
			switch (status) {
				case salesStatus.pending:
					return "process-standby itemNoEdit"; //gray
				case salesStatus.complete:
					return "process-standby itemNoEdit"; //gray
				case salesStatus.ready:
					return "process-problem itemNoEdit"; //yellow				default:
				case salesStatus.submitted:
					return "process-complete itemNoEdit"; //green
				default:
					return "process-standby itemNoEdit"; //gray
			}
		}

		// OVERALL STATUS
		// ===============================================================================

		function summaryStatus(engagement) {
			// console.log(engagement.deliveryDate);
			if (engagement.applicationStatus === itemStatus.application.declined) {
				if(engagement.isCoWorkerDeclined){
					var translatedString = $translate.instant("dashboard_span_summaryStatus_18");
					return translatedString;
				} else {
					var translatedString = $translate.instant("dashboard_span_summaryStatus_1");
					return translatedString;
				}
			} else if (engagement.agreementStatus === itemStatus.agreement.declined) {
				var translatedString = $translate.instant("dashboard_span_summaryStatus_2");
				return translatedString;
			} else if (engagement.agreementStatus  === itemStatus.agreement.voided) { 
				var translatedString = $translate.instant("dashboard_span_summaryStatus_3");
				return translatedString;
			} else if (engagement.applicationStatus === itemStatus.application.associateReview) {
				if (dashboard.isStoreManned) {
					var translatedString = $translate.instant("dashboard_span_summaryStatus_4");
					return translatedString;
				}
				var translatedString = $translate.instant("dashboard_span_summaryStatus_5");
				return translatedString;
			} else if (engagement.applicationStatus === itemStatus.application.manualIntervention) {
				var translatedString = $translate.instant("dashboard_span_summaryStatus_6");
				return translatedString;
			} else if (engagement.applicationStatus === itemStatus.application.outOfServiceArea) {
				var translatedString = $translate.instant("dashboard_span_summaryStatus_7");
				return translatedString;
			} else if (engagement.orderStatus === 'Pending') {
				var translatedString = $translate.instant("dashboard_span_summaryStatus_8");
				return translatedString;
			} else if ((engagement.orderStatus === itemStatus.order.confirmed && engagement.estimatedDeliveryDate === undefined) || (engagement.estimatedDeliveryDate !== undefined && engagement.paymentStatus !== itemStatus.payment.complete)) {
				if(engagement.agreementStatus === itemStatus.agreement.notStarted){
					var translatedString = $translate.instant("dashboard_span_summaryStatus_9");
					return translatedString;
				}else if(engagement.agreementStatus  === itemStatus.agreement.inProgress){
					var translatedString = $translate.instant("dashboard_span_summaryStatus_10");
					return translatedString;
				}else if (engagement.paymentStatus !== itemStatus.payment.complete) {
					var translatedString = $translate.instant("dashboard_span_summaryStatus_11");
					return translatedString;
				}
				var translatedString = $translate.instant("dashboard_span_summaryStatus_12");
				return translatedString;
			} else if (engagement.deliveryStatus === itemStatus.delivery.confirmed) {
				var translatedString = $translate.instant("dashboard_span_summaryStatus_13");
				return translatedString;
			} else if (engagement.deliveryStatus === itemStatus.delivery.scheduled) {
				var translatedString = $translate.instant("dashboard_span_summaryStatus_14");
				return translatedString;
			} else if (engagement.deliveryStatus === itemStatus.delivery.pickup) {
				var translatedString = $translate.instant("dashboard_span_summaryStatus_15");
				return translatedString;
			} else {
				var translatedString = $translate.instant("dashboard_span_summaryStatus_5");
				return translatedString;
			}
		}

		function summaryStatusForSortingFn(engagement) {
			if (engagement.applicationStatus === itemStatus.application.declined) {
				var translatedString = $translate.instant("dashboard_span_summaryStatus_1");
				return translatedString;
			} else if (engagement.agreementStatus === itemStatus.agreement.declined) {
				var translatedString = $translate.instant("dashboard_span_summaryStatus_2");
				return translatedString;
			} else if (engagement.agreementStatus  === itemStatus.agreement.voided) { 
				var translatedString = $translate.instant("dashboard_span_summaryStatus_3");
				return translatedString;
			} else if (engagement.applicationStatus === itemStatus.application.associateReview) {
				if (dashboard.isStoreManned) {
					var translatedString = $translate.instant("dashboard_span_summaryStatus_4");
					return translatedString;
				}
				var translatedString = $translate.instant("dashboard_span_summaryStatus_5");
				return translatedString;
			} else if (engagement.applicationStatus === itemStatus.application.manualIntervention) {
				var translatedString = $translate.instant("dashboard_span_summaryStatus_6");
				return translatedString;
			} else if (engagement.applicationStatus === itemStatus.application.outOfServiceArea) {
				var translatedString = $translate.instant("dashboard_span_summaryStatus_7");
				return translatedString;
			} else if (engagement.orderStatus === 'Pending' && engagement.applicationStatus == itemStatus.application.accepted) {
				var translatedString = $translate.instant("dashboard_span_summaryStatus_8");
				return translatedString;
			} else if (engagement.orderStatus === itemStatus.order.confirmed && engagement.estimatedDeliveryDate === undefined) {
				if (engagement.paymentStatus !== itemStatus.payment.complete) {
					var translatedString = $translate.instant("dashboard_span_summaryStatus_11");
					return translatedString;
				}
				/*if(showGenerateCreditCardBtn(engagement)){
					return 'Generate Credit Card';
				}else*/ if(showScheduleDeliveryCta(engagement)){
					var translatedString = $translate.instant("dashboard_span_summaryStatus_16");
					return translatedString;
				}
				else {
					var translatedString = $translate.instant("dashboard_span_summaryStatus_12");
					return translatedString;
				}
			} else if (engagement.deliveryStatus === itemStatus.delivery.confirmed) {
				var translatedString = $translate.instant("dashboard_span_summaryStatus_17");
				return translatedString;
			} else if (engagement.deliveryStatus === itemStatus.delivery.scheduled) {
				var translatedString = $translate.instant("dashboard_span_summaryStatus_14");
				return translatedString;
			} else if (engagement.deliveryStatus === itemStatus.delivery.pickup) {
				var translatedString = $translate.instant("dashboard_span_summaryStatus_15");
				return translatedString;
			} else {
				var translatedString = $translate.instant("dashboard_span_summaryStatus_5");
				return translatedString;
			}
		}

		function statusIcon(engagement) {
			if (engagement.applicationStatus === itemStatus.application.declined
				|| engagement.agreementStatus === itemStatus.agreement.declined
				|| engagement.agreementStatus === itemStatus.agreement.voided) {//AC 25996 updated code changes
				return 'icon-declined';
			} else if (engagement.applicationStatus === itemStatus.application.manualIntervention
				|| engagement.applicationStatus === itemStatus.application.associateReview) {
				return 'icon-manualintervention';
			} else if (engagement.applicationStatus === itemStatus.application.outOfServiceArea) {
				return 'icon-outofservicearea';
			} else if (engagement.orderStatus === itemStatus.order.pendingConfirm) {
				return 'icon-confirm';
			} else if (engagement.orderStatus === itemStatus.order.confirmed
				&& engagement.estimatedDeliveryDate !== undefined) {
				return 'icon-delivery';
			} else if (engagement.deliveryStatus === itemStatus.delivery.confirmed) {
				return 'icon-complete';
			} else if (engagement.deliveryStatus === itemStatus.delivery.scheduled) {
				return 'icon-delivery';
			} else if (engagement.deliveryStatus === itemStatus.delivery.pickup) {
				return 'icon-delivery';
			} else {
				return '';
			}
		}

		function summaryStatusSortFn(item){
			var status = summaryStatus(item); //summaryStatusForSortingFn(item);
			var sortMap = {
				en : {
					'Pending Order Confirmation': 1,
					'Associate Review': 2,
					'Generate Credit Card': 3,
					'Schedule Delivery':4,
					'Pending Delivery Confirmation': 5,
					'Pending Pickup Confirmation': 6,
					'Pending Delivery Date': 7,
					'Pending Payment': 8,
					'Manual Review': 9,
					'Out of Service Area': 10,
					'In Progress': 11,
					'Complete': 12,
					'Agreement Declined': 13,
					'Declined': 14
				},
				es : {
					'Confirmación de pedido pendiente': 1,
					'Revisión del Asociado': 2,
					'Genere la tarjeta de crédito': 3,
					'Fecha de entrega':4,
					'Confirmación de entrega pendiente': 5,
					'Confirmación de recogida pendiente': 6,
					'Fecha de entrega pendiente': 7,
					'Pago pendiente': 8,
					'Revisión manual': 9,
					'Fuera del área de servicio': 10,
					'En progreso': 11,
					'Completo': 12,
					'El Contrato ha sido rechazado': 13,
					'Rechazada': 14
				}
			};
			var val = $rootScope.selectedLanguage == 'es' ? sortMap.es[status] : sortMap.en[status];
			return val;
		}


		////////////////////////////////////////////////
		//  METHODS  ///////////////////////////////////
		////////////////////////////////////////////////
		//VAN - 1430 - IE Performance starts
		window.onscroll = function() {loadMore()};
		$scope.totalDisplayed = 20;

		function loadMore() {
			if(dashboard.dashboardData !== undefined) {
				if ($scope.totalDisplayed < dashboard.dashboardData.length) {
					$scope.totalDisplayed += 20;  
				}
			}
		};

		function detailsView(item) {
			if($scope.detailsView === true && $scope.val !== undefined && $scope.val === item)
			{
				$scope.detailsView = false;
			}
			else
			{
				$scope.detailsView = true;			
			}
			$scope.val= item;
		}
			//VAN - 1430 - IE Performance ends
		function navigateTo(target, engagement) {
			console.log("[dashboard.navigateTo] target: " + target + " engagement: ", engagement);
			var allowContinue = false,
				completeStr = itemStatus.payment.complete;
			engagement = engagement || {};
			if (engagement.engagementId) {
				storeCurrentEngagement(engagement.engagementId);
			}
			//AC 25996 code changes starts
			if (target === 'agreement' || target === 'digitalAgreement') {
				//AC 22565 code changes  - Added showAgreementsPage flag for agreement status voided and agreementhtid null
				if (engagement.applicationStatus === itemStatus.application.accepted && engagement.orderStatus === itemStatus.order.confirmed && engagement.paymentStatus != completeStr && (dashboard.showAgreementsPage || ( engagement.agreementStatus != itemStatus.agreement.voided && engagement.agreementStatus != itemStatus.agreement.declined))) {//AC 25996 updated code changes
					if(engagement.agreementStatus === itemStatus.agreement.accepted && engagement.agreementOriginator && engagement.agreementOriginator.toLowerCase() == 'point of sale'){
						$rootScope.successMsgDocusign = $rootScope.selectedLanguage == 'es' ? es.modal_afterDocusignSigningSuccess_desc3 : en.modal_afterDocusignSigningSuccess_desc3;
						modalService.open({
							windowClass: '',
							templateUrl: 'partials/modal_afterDocusignSigningSuccess.html',
							//backdrop: 'static'
						});
						return false;
					}
					allowContinue = true;
				} else if((engagement.orderStatus === itemStatus.order.confirmed && engagement.paymentStatus == completeStr)||engagement.orderStatus !== itemStatus.order.confirmed || engagement.applicationStatus !== itemStatus.application.accepted){
					allowContinue = false;
				} else {
					if($rootScope.xpartnerLogin){//VAN-3228-AWP - same state rule apply to only XPARTNER and not XTEAM
					//VAN-3209-Use customers zip code on agreement creation - Starts
					if(engagement && engagement.customerState && engagement.agreementOriginator != "Point of Sale" && (engagement.applicationStatus  != itemStatus.application.inProgress || engagement.applicationStatus  != itemStatus.application.declined)){
						if(engagement.customerState != dashboard.storeInfo.state){
							$scope.invalidState = modalService.open({
								title: 'State is invalid',
								templateUrl: 'partials/alert_invalidStateError.tpl.html',
								backdrop: 'static',
								size: 'sm',
								scope: $scope
							});
							return;
						}
					}
					//VAN-3209-Use customers zip code on agreement creation - Ends
					}//VAN-3228-AWP - same state rule apply to only XPARTNER and not XTEAM
					checkForAgreementData(target, engagement);
					return;
				} //AC 25996 code changes ends
			} else if (target === 'epay') {
				if (engagement.applicationStatus === itemStatus.application.accepted && engagement.orderStatus === itemStatus.order.confirmed && engagement.agreementStatus === itemStatus.agreement.accepted && engagement.paymentStatus != completeStr) {
					if(engagement.agreementOriginator && engagement.agreementOriginator.toLowerCase() == 'point of sale'){
						$rootScope.successMsgDocusign = $rootScope.selectedLanguage == 'es' ? es.modal_afterDocusignSigningSuccess_desc4 : en.modal_afterDocusignSigningSuccess_desc4;
						modalService.open({
							windowClass: '',
							templateUrl: 'partials/modal_afterDocusignSigningSuccess.html',
							//backdrop: 'static'
						});
						return false;
					}
					allowContinue = true;
				}
			} else if (target === 'delivery') {
				if (engagement.paymentStatus === completeStr || (!SharedDataSvc.globalVars.storeSetup.immediateInvoicing && (engagement.deliveryStatus.toLowerCase() == 'scheduled' || engagement.deliveryStatus.toLowerCase() == 'pickup') && engagement.orderStatus.toLowerCase() == 'confirmed')) {
				//Comment - Delivery Confirmation - PR override changes
					/*if($scope.isSpanishStore && (engagement.verifyInfoAssociateName === undefined || engagement.verifyInfoAssociateName.length <= 0)) {
						showVerifyInfoAlert();
					}
					else {*/
						showDeliveryModal(engagement);
//					}
					return;
				}
			} else if (target === "ordersummary") {
				//AC 25996 code changes starts
				if((dashboard.showOrderSummary) || (engagement.paymentStatus != itemStatus.payment.complete && (engagement.agreementStatus === itemStatus.agreement.declined || engagement.agreementStatus === itemStatus.agreement.voided)) || (engagement.applicationStatus === itemStatus.application.inProgress)){//AC 25996 & AC 14458 updated code changes
					allowContinue = true;
				} else if((engagement.orderStatus === itemStatus.order.confirmed && engagement.paymentStatus == completeStr) || (engagement.applicationStatus === itemStatus.application.declined) || (engagement.applicationStatus === itemStatus.application.outOfServiceArea)){
					allowContinue = false;
				} else {
					if($rootScope.xpartnerLogin){//VAN-3228-AWP - same state rule apply to only XPARTNER and not XTEAM
					//VAN-3209-Use customers zip code on agreement creation - Starts
					if(engagement && engagement.customerState && engagement.agreementOriginator != "Point of Sale" && (engagement.applicationStatus  != itemStatus.application.inProgress || engagement.applicationStatus  != itemStatus.application.declined)){
						if(engagement.customerState != dashboard.storeInfo.state){
							$scope.invalidState = modalService.open({
								title: 'State is invalid',
								templateUrl: 'partials/alert_invalidStateError.tpl.html',
								backdrop: 'static',
								size: 'sm',
								scope: $scope
							});
							return;
						}
					}
					//VAN-3209-Use customers zip code on agreement creation - Ends
					}//VAN-3228-AWP - same state rule apply to only XPARTNER and not XTEAM
					checkForSLICustomer(target, engagement);
					return;
					//AC 25996 code changes ends
				}
			} else if (target === "dashboard") {
				$route.reload();
				return;
			}
			else {
				allowContinue = true;
			}

			if(allowContinue){
				$rootScope.hideDashboardButton = true;
				$rootScope.hideTrainingLibButton = false;
				$rootScope.hideDashboardAgreementButton = false;
				$rootScope.invoiceMethod = SharedDataSvc.globalVars.storeSetup.invoiceMethod;
				if($rootScope.xpartnerLogin){//VAN-3228-AWP - same state rule apply to only XPARTNER and not XTEAM
				//VAN-3209-Use customers zip code on agreement creation - Starts
				if(engagement && engagement.customerState && engagement.agreementOriginator != "Point of Sale" && (engagement.applicationStatus  != itemStatus.application.inProgress || engagement.applicationStatus  != itemStatus.application.declined)){
					if(engagement.customerState != dashboard.storeInfo.state){
						$scope.invalidState = modalService.open({
							title: 'State is invalid',
							templateUrl: 'partials/alert_invalidStateError.tpl.html',
							backdrop: 'static',
							size: 'sm',
							scope: $scope
						});
						return;
					}
				}
				//VAN-3209-Use customers zip code on agreement creation - Ends
				}//VAN-3228-AWP - same state rule apply to only XPARTNER and not XTEAM
				
				if((target == "agreement" || target == 'digitalAgreement') && (!dashboard.isStoreManned || $cookies.getObject("isHybrid"))){
					console.log("[Engagement here]", engagement);
					ssnCheckForAgreements(target, engagement);	
				}else{
					if (engagement.engagementId) {
						SharedDataSvc.globalVars.engagementId = engagement.engagementId; //story 26289 code changes
						target += '/'+engagement.engagementId;
					}
					$location.path(target);
				}
			}else{
				modalService.open({
					templateUrl: 'partials/modal_enforceOrder.html',
					windowClass: 'enforce-order',
					scope: $scope
				});
			}
		}

		$scope.sortByClick = function () {
			if(dashboard.order == 'Date') {
				dashboard.sortingOrder = ['-modifiedDateStamp'];
			}else if(dashboard.order == 'Name') {
				dashboard.sortingOrder = ['customerLastName', 'customerFirstName', '-modifiedDateStamp'];
			} else{
				dashboard.sortingOrder = [dashboard.summaryStatusSortFn, '-modifiedDateStamp'];
			}
		}

		$scope.sortByClick(dashboard.order);

		//show schedule delivery associate pass check modal
		function showModalDeliveryPass(name, windowClass) {
	      	windowClass = windowClass || '';
	      	modalService.open({
		        templateUrl: '/partials/modal_' + name + '.html',
		        backdrop: 'static',
		        windowClass: windowClass,
		        controller: 'delivery',
		        controllerAs: 'delivery',
		        scope: $scope
		   	});
	    }

		//Story 33452 changes starts
		function checkForSLICustomer(target,engagement){
			var personData={};
			personData.firstName = engagement.customerFirstName;
			personData.lastName = engagement.customerLastName,
			personData.phone = engagement.customerPhoneNum,
			personData.disableHT = true;
			applicationService.getApplication(personData)
			.then(function(response){
				console.log(response);
				//Defect 28370	- From AWP/Kiosk order link is not clickable - Starts
				if(response.agreement){
					SharedDataSvc.globalVars.htAgreementStatus = response.agreement.agreementStatus;
					console.log('HT agreement status',SharedDataSvc.globalVars.htAgreementStatus);}
				//Defect 28370	- From AWP/Kiosk order link is not clickable - Ends
				//VAN-736 - PRB0042956 - AWP- New agreement is getting created next day after declining the order for an active agreement in HT - Starts
				if (SharedDataSvc.globalVars.htAgreementStatus != null && SharedDataSvc.globalVars.htAgreementStatus != undefined && SharedDataSvc.globalVars.htAgreementStatus === 'ACTIVE'){
					console.log('open active agreement modal at order flow');
					var wrongvendor = modalService.open({
						show: true,
						templateUrl: 'partials/alert_activeAgreementErrorAtOrder.tpl.html',
						backdrop: 'static'         
					});
					return;
				}
				//VAN-736 - PRB0042956 - AWP- New agreement is getting created next day after declining the order for an active agreement in HT - Ends
				if(response.VanEngagement && response.VanEngagement.customer){
					if(response.VanEngagement.customer.customerOriginator && 
							response.VanEngagement.customer.customerOriginator == "SLI" || 
							response.VanEngagement.customer.customerOriginator == "PreApprove" || //VAN-510 - Electronic Consent moved to app flow -Allow NEw/Existing Customer Popup for VA customers.
							response.VanEngagement.customer.customerOriginator == "HighTouch")  // VAN-813 New originator for HighTouch for HT originated approvals
					{
						if(response.VanEngagement.orderContent){
							checkForAgreementData(target,engagement);
						}else{
							//show new/existing customer popup without start appln and email
							SharedDataSvc.globalVars.isApplicationFromOrder = true;
							SharedDataSvc.globalVars.currentSLICustEngId = response.VanEngagement.engagementId;
							$location.path('application/');
						}
					}  
					else {
						checkForAgreementData(target,engagement);
					}
				} else {
					checkForAgreementData(target,engagement);
				}
			});
		}
		//Story 33452 changes ends
		//AC 25996 code changes starts
		function checkForAgreementData(target, engagement) {
			var customerData;
			customerDataFactory.findByEngagementId(engagement.engagementId)
					.then(function(response){
						customerData = response;
						if(target == "ordersummary") {
							console.log('success retrieving the customer details ', customerData);
							if(customerData.agreementHTId == null && engagement.paymentStatus != itemStatus.payment.complete && (engagement.agreementStatus === itemStatus.agreement.notStarted || engagement.agreementStatus === itemStatus.agreement.inProgress)){
								dashboard.showOrderSummary = true;
								navigateTo('ordersummary',engagement);
							}else if (customerData.orderStatus === itemStatus.order.confirmed && (customerData.agreementStatus !== itemStatus.agreement.declined && customerData.agreementStatus !== itemStatus.agreement.voided) && customerData.agreementHTId != undefined && customerData.agreementHTId != "" && customerData.paymentStatus != itemStatus.payment.complete) {//AC 25996 updated code changes
								dashboard.engagement = {};
								var engagementProps = [
									'engagementId',
									'orderStatus',
									'deliveryStatus',
									'paymentStatus',
									'salesStatus',
									'agreementStatus',
									'applicationStatus',
									'orderOriginator'
								];
								// cherry pick the needed properties for a proper engagement
								for (var i = 0; i < engagementProps.length; i++) {
									dashboard.engagement[engagementProps[i]] = response[engagementProps[i]];
								}
								dashboard.engagement['customerId'] = response.customer.customerId;
								dashboard.engagement['customerHtID'] = response.customerHTId;
								dashboard.engagement['customerHtStoreId'] = response.customerHTStoreId;
								dashboard.engagement['vanStoreId'] = response.vanStore.storeId;
								dashboard.declineAgreementModal = modalService.open(declineAgreementModalOptions(engagement));
								}

						} else if(target == "agreement" || target == 'digitalAgreement') {
							dashboard.agreementHtId = customerData.agreementHTId;
							//AC 25996 code changes
							if(engagement.agreementStatus === itemStatus.agreement.declined || engagement.agreementStatus === itemStatus.agreement.voided){//AC 25996 updated code changes
								dashboard.currentEngagement = engagement;
								modalService.open({
									templateUrl: 'partials/modal_modifyOrder.html',
									scope: $scope,
									backdrop: 'static'// AC 25996 updated code changes
								})
							}
							else {
								dashboard.showAgreementsPage = true; // AC 22565 code changes
								navigateTo(target, engagement);
							}
						}
						//Defect 28370	- From AWP/Kiosk order link is not clickable - Starts
						if(SharedDataSvc.globalVars.htAgreementStatus === 'ACTIVE' && engagement.agreementStatus === itemStatus.agreement.accepted && customerData.paymentStatus == itemStatus.payment.complete){
							console.log('open active agreement modal');
							var wrongvendor = modalService.open({
								show: true,
								templateUrl: 'partials/alert_activeAgreementError.tpl.html',
								backdrop: 'static'         
							});    
							navigateTo('dashboard');
						}
						//Defect 28370	- From AWP/Kiosk order link is not clickable - Ends
					}).catch(function(response){
				console.log('could not get customer data', response);
			});
		}
//AC24649 code changes
		function continueToAgreement(){
			dashboard.engagement = {};
			var engagementProps = [
				'engagementId',
				'orderStatus',
				'deliveryStatus',
				'paymentStatus',
				'salesStatus',
				'agreementStatus',
				'applicationStatus',
				'orderOriginator',
				'vanStoreId',
				'customerId'
			];

			for (var i = 0; i < engagementProps.length; i++) {
				dashboard.engagement[engagementProps[i]] = dashboard.currentEngagement[engagementProps[i]];
			}

			dashboard.modalTitle = 'Processing';
			dashboard.processingModal = modalService.processing(dashboard.modalTitle);
			dashboard.engagement.agreementStatus = 'Pending';
			updateEngagementItemFactory.putData(dashboard.engagement)
				.then(function(response) {
					console.log('Successfully updated engagement with agreement status to pending');
					modalService.dismiss();
					navigateToAgreement(dashboard.engagement);
					//navigateTo('agreement',dashboard.engagement);
				}, function(response){
					console.log('Updating engagement with agreement status to pending failed');
					modalService.dismiss();
				});
		}//AC24649 code changes ends
		function modifyOrderSummary() {
			navigateTo('ordersummary',dashboard.currentEngagement);
		}

		//Defect 28370	- From AWP/Kiosk order link is not clickable - Starts
		function activeAgreementErrorAlert(engagement) {
			return {
				templateUrl: 'partials/alert_activeAgreementError.tpl.html',
				//controller: '',
				scope: $scope,
				backdrop: 'static'//AC 25996 updated code changes
			}
		}
		function activeAgreementError(engagement){
			console.log('on click of ok for active agreement error popup');
			dashboard.modalTitle = 'Processing';
			dashboard.processingModal = modalService.processing(dashboard.modalTitle);
			engagement.paymentStatus = 'Complete';
			updateEngagementItemFactory.putData(engagement)
					.then(function(response) {
						console.log('Successfully updated engagement with payment status completed');
						modalService.dismiss();
						navigateTo('dashboard');
					}, function(response){
						console.log('Updating engagement for payment status completed failed');
						modalService.dismiss();
					});
		
		}
		//Defect 28370	- From AWP/Kiosk order link is not clickable - Ends
		
		function declineAgreementModalOptions(engagement) {
			return {
				templateUrl: 'partials/modal_agreementDecline_editOrder.html',
				//controller: '',
				scope: $scope,
				backdrop: 'static'//AC 25996 updated code changes
			}
		}

		//Story 29237 code changes starts
		function declineAgreement(engagement){
			SharedDataSvc.globalVars.voidAgreementData = engagement;
			dashboard.modalTitle = 'Processing';
			dashboard.processingModal = modalService.processing(dashboard.modalTitle);
			var engagement = SharedDataSvc.globalVars.voidAgreementData;
			engagement.agreementStatus = 'Voided';
			updateEngagementItemFactory.putData(engagement)
					.then(function(response) {
						console.log('Successfully updated engagement with agreement status voided');
						modalService.dismiss();
						dashboard.showOrderSummary = true;
						navigateTo('ordersummary',engagement);
					}, function(response){
						console.log('Updating engagement for agreement status voided failed');
						modalService.dismiss();
					});
		
		}

		function cancelAgreementDecline() {
			navigateTo('dashboard');
		}

	    //handle modal as per delivery or pickup
		function showDeliveryModal(engagement) {
			SharedDataSvc.deliveryTempId = engagement.engagementId;
			dashboard.deliveryTempId = engagement.engagementId;
			SharedDataSvc.invoiceDeliveryData.engagement = engagement;
			if(SharedDataSvc.globalVars.storeSetup.immediateInvoicing) {
				dashboard.myDeliveryModal = modalService.open(deliveryModalOptions(engagement));
				dashboard.myDeliveryModal
					.result.then(
					function (result) {
						//console.log('[Delivery Modal complete]', result);
						if (result && result.status === 'confirm') {
							showDeliveryConfirmation(result.data);
							console.log('[Schedule Delivery Address Check Modal] success');
						} else if(result && result.status === 'pickup') {
						//VAN-3229-AWP - Remove associate password popup for user login with XPARTNER during  entering estimated delivery date-Starts
							if($rootScope.xpartnerLogin)
							{
							setDeliveryDate();
							}
							else
								{
							showModalDeliveryPass('scheduleDeliveryPass');
							console.log('[Schedule Pickup Modal Pass Check] success:', result.status);
								}
						//VAN-3229-AWP - Remove associate password popup for user login with XPARTNER during  entering estimated delivery date-Ends
						}
					},
					function (result) {
						console.log('[Delivery Modal dismiss]');
					}
				)
			} else {
				dashboard.myDeliveryModal = modalService.open(deliveryModalOptions(engagement, "OnConfirmOrder", "no-Invoice-Modal"));
			}
		}

		//address confirm modal for schedule delivery
		function showDeliveryConfirmation(engagement) {
			var deliveryConfirmModal = modalService.open(deliveryModalOptions(engagement, 'Confirm'));
			deliveryConfirmModal
				.result.then(
				function (result) {
					if (result && result.status === 'confirm') {
					//VAN-3229-AWP - Remove associate password popup for user login with XPARTNER during  entering estimated delivery date-Starts
						if($rootScope.xpartnerLogin)
							{
							setDeliveryDate();
							}
						else
							{
						showModalDeliveryPass('scheduleDeliveryPass');
						console.log('[Schedule Delivery Modal Pass Check] success:', result.status);
							}
					//VAN-3229-AWP - Remove associate password popup for user login with XPARTNER during  entering estimated delivery date-Ends
					}					
				},
				// dismissed
				function (result) {
					if (result && result.status === 'declined') {
						modalService.open({
							templateUrl: 'partials/modal_invoiceAndDeliveryFailed.html',
							scope: $scope
						});
					}
				}
			)
		}

		function deliveryModalOptions(engagement, deliveryPart, windowClass) {
			deliveryPart = deliveryPart || '';
			windowClass = windowClass || '';
			return {
				templateUrl: 'partials/modal_invoiceAndDelivery'+deliveryPart+'.html',
				controller: 'delivery',
				controllerAs: 'delivery',
				windowClass : windowClass,
				scope: $scope
			}
		}

		function showModal(name, windowClass, controller, controllerAs) {
			windowClass = windowClass || '';
			var modal = modalService.open({
				// title: 'Schedule Delivery',
				templateUrl: '/partials/modal_' + name + '.html',
				scope: $scope,
				backdrop: 'static',
				controller: controller || 'manualOverride',
				controllerAs: controllerAs || 'override',
				windowClass: windowClass || 'override-initial-modal',
				show: true
			});
			SharedDataSvc.activeModal = modal;
		}

		function setDeliveryDate() {
			showProcessingModal();//VAN-3229-AWP - Remove associate password popup for user login with XPARTNER during  entering estimated delivery date
			dashboard.viewLoading = true;
			var thisId = SharedDataSvc.deliveryTempId;
			SharedDataSvc.globalVars.order.currentEngagement.engagementId = thisId;
			SharedDataSvc.globalVars.order.deliveryDate=moment(SharedDataSvc.invoiceDeliveryData.date).format('YYYY-MM-DD') + ' 00:00';//VAN-3229-AWP - Remove associate password popup for user login with XPARTNER during  entering estimated delivery date
			//SharedDataSvc.globalVars.order.deliveryDate = moment(dashboard.dashboardData.estimatedDeliveryDate).format('YYYY-MM-DD') + ' 00:00';
			//console.log('557 delivery date='+ SharedDataSvc.globalVars.order.deliveryDate);
			// SAVE delivery date
			var preO = _.where(SharedDataSvc.globalVars.dashboardListCache, {
				engagementId: Number(thisId)
			});
			//var oData = _.pluck(preO, '');
			//var oData = ;
			// go grab engagement data + orderItems
			updateEngagementItemFactory.getData(SharedDataSvc.globalVars.order.currentEngagement.engagementId).then(function (response) {
				// console.log('564_gottenData='+angular.toJson(response));
				// response.engagementId
				// response.estimatedDeliveryDate
				// SharedDataSvc.globalVars.order.currentEngagement = response.data;
				// re-64 the order items
				// add new deliveryDate
				dashboard.tempEngagementData = response;
				dashboard.tempEngagementData.estimatedDeliveryDate = SharedDataSvc.globalVars.order.deliveryDate;
				dashboard.tempEngagementData.deliveryStatus = 'Scheduled';
				//VAN-3229-AWP - Remove associate password popup for user login with XPARTNER during  entering estimated delivery date-Starts
				SharedDataSvc.invoiceDeliveryData.deliveryType = "Scheduled";
			      if(SharedDataSvc.invoiceDeliveryData.type === 'pickup') {
			        
			    	dashboard.tempEngagementData.deliveryStatus = "Pickup";
			        SharedDataSvc.invoiceDeliveryData.deliveryType = "Pickup";
			      } else {
			    	  dashboard.tempEngagementData.deliveryStatus = "Scheduled";
			        SharedDataSvc.invoiceDeliveryData.deliveryType = "Scheduled";
			      }
			      if(SharedDataSvc.globalVars.storeSetup.immediateInvoicing) {
			    	  dashboard.tempEngagementData.salesStatus = "Submitted";
			    	  dashboard.tempEngagementData.invoiceNum = SharedDataSvc.invoiceDeliveryData.invoiceNum;
			    	  dashboard.tempEngagementData.salesAssociateID = SharedDataSvc.invoiceDeliveryData.associateId;  
			      }  
			    //VAN-3229-AWP - Remove associate password popup for user login with XPARTNER during  entering estimated delivery date-Ends
			      var oData = dashboard.tempEngagementData;
				// console.log('engagement data',oData);
				updateEngagementItemFactory.putData(oData).then(function (response) {
					//console.log('574_oData PUT='+angular.toJson(oData));
					//VAN-3229-AWP - Remove associate password popup for user login with XPARTNER during  entering estimated delivery date-Starts
					console.log('[delivery.updateEngagement] successfully updated engagement', response);
		            SharedDataSvc.globalVars.order.currentEngagement.deliveryStatus = SharedDataSvc.invoiceDeliveryData.deliveryType;
		            if(dashboard.immediateInvoicing) {
		              SharedDataSvc.globalVars.order.currentEngagement.salesStatus = "Submitted";
		            }
		            if(response.estimatedDeliveryDate) {
		              SharedDataSvc.globalVars.order.currentEngagement.estimatedDeliveryDate = response.estimatedDeliveryDate;
		            } else {
		              SharedDataSvc.globalVars.order.currentEngagement.estimatedDeliveryDate = SharedDataSvc.globalVars.order.deliveryDate;
		            }
		            //AC 32805 code changes starts
		            if(response.invoiceNum){
		            	SharedDataSvc.globalVars.order.currentEngagement.invoiceId = response.invoiceNum;
		            } else {
		              SharedDataSvc.globalVars.order.currentEngagement.invoiceId = SharedDataSvc.invoiceDeliveryData.invoiceNum;
		            }
		            //AC 32805 code changes ends
		            //dashboard.viewLoading = false;
		            dashboard.processingModal.dismiss();
		            dashboard.myDeliveryModal.dismiss();
		            //VAN-3229-AWP - Remove associate password popup for user login with XPARTNER during  entering estimated delivery date-Ends
				}, function (response) {
					console.log('[Dashboard.setDeliveryDate] 577_PUTerror=' + angular.toJson(response));
				});
				// change delivery status to 'Scheduled'
			}).error(function(response) {
				console.log("[Dashboard.setDeliveryDate] 566_GETerror=" + angular.toJson(response));
				dashboard.viewLoading = false;
			});

			SharedDataSvc.globalVars.order.currentEngagement.engagementId = thisId;
		};

		function getApplication(engagement) {
			var person = {
				firstName: engagement.customerFirstName,
				lastName: engagement.customerLastName,
				phone: engagement.customerPhoneNum
			};
			var personData = window.btoa(JSON.stringify(person));
			$location.path('application/'+engagement.engagementId).search({data: personData});
		}
	//VAN-3229-AWP - Remove associate password popup for user login with XPARTNER during  entering estimated delivery date-Starts
		function showProcessingModal () {
	        $rootScope.modalTitle = 'Processing';
	        dashboard.processingModal = modalService.open({
	          templateUrl: '/partials/modal_Processing.html',
	          title: $scope.title,
	          backdrop: 'static',
	          windowClass: 'processing-modal',
	          show: true
	        });
	    }
	//VAN-3229-AWP - Remove associate password popup for user login with XPARTNER during  entering estimated delivery date-Ends
	    
		function storeCurrentEngagement(itemID) {
			console.log("[dashboard.storeCurrentEngagement] itemID: " + itemID);
			var selectedEngagement = _.find(dashboard.dashboardData, {
				engagementId: itemID
			});
			console.log("[dashboard.storeCurrentEngagement] selectedEngagement: ", selectedEngagement);
			SharedDataSvc.globalVars.order.currentEngagement = selectedEngagement;
			SharedDataSvc.globalVars.order.currentEngagementId = itemID;
			SharedDataSvc.globalVars.order.currentEngagement.vanStoreId = SharedDataSvc.vanStoreId;
			//console.log('539_sds currEng = '+angular.toJson(SharedDataSvc.globalVars.order.currentEngagement));
		};
		// item.customerFirstName, item.customerLastName, item.customerPhoneNum, item.engagementId
		// Skip Stolen Record Found
		function editApplication(engagement) {
			//engagement.overallApprovalReason = "Conditional Approval;";
			console.log('engagement item', engagement);
			var storeInfo = SharedDataSvc.globalVars.storeSetup;
			console.log('store info', storeInfo);
			SharedDataSvc.manualOverrideData = engagement;
			
			/* VAN 456 -Add customer's name to manual intervention verification title*/
			$scope.customerName = SharedDataSvc.manualOverrideData.customerFullName;
			resetManualOverrideData();
			if(engagement.applicationStatus === 'Accepted' && dashboard.isStoreManned && storeInfo.overrideOption) {
				/*VAN 272 - VAN - Associate Manual Override screen for PR - Starts*/
				$scope.showOverridePRCosChange = true;
				if(storeInfo.state=="PR" && SharedDataSvc.manualOverrideData.approvalAmount==0){
					$scope.showOverridePRCosChange = false;
				}
				/*VAN 272 - VAN - Associate Manual Override screen for PR - Ends*/
				showModal('overrideInitial');
			} else if(engagement.overallApprovalReason && engagement.overallApprovalReason.indexOf('Address Verification did not match') > -1) {
				showModal('reviewAddress', 'associate-review-modal');
				return;
			} else if(engagement.overallApprovalReason && 
				(engagement.overallApprovalReason.indexOf('Reported income outside of allowable range') > -1) && 
				!(engagement.overallApprovalReason.indexOf('Skip Stolen') > -1 ) &&
				!(engagement.overallApprovalReason.indexOf('Conditional Approval') > -1 )) {
					
				showModal('reviewIncome', 'associate-review-modal');
				return;
				
			}	else if(engagement.overallApprovalReason && engagement.overallApprovalReason.indexOf('Skip Stolen') > -1) {
				if(engagement.applicationStatus !== 'Declined') {
					showModal('skipStolen', 'associate-review-modal');
				}
				return;
			}	else if(engagement.overallApprovalReason && engagement.overallApprovalReason.indexOf('Conditional Approval') > -1) {
				if(engagement.applicationStatus !== 'Declined') {

					/*VAN-249 VAN- Configurable Manual Intervention Response - Starts*/
					$scope.declineReasonsList =[];
					if( _(engagement).has('verifyReasons')){
							if((engagement.verifyReasons).indexOf("Verify References (Min. 2)") > -1){
								modalService.processing(dashboard.modalTitle);
								applicationService.getApplicationByEngagement(engagement)
								.then(function(response){
									modalService.dismiss();
									console.log("engagement success response:"+response);
									conditionalApprovalAssocReviewData(engagement,response);
								},function(failData){
									modalService.dismiss();
									$rootScope.modalMessage = failData.data.errorData.status_message;
									var modal = modalService.open({
										templateUrl : '../../../partials/alert_serviceError.tpl.html',
										backdrop : 'static',
										scope : $scope
									});
								});
							}else{
								conditionalApprovalAssocReviewData(engagement,"");
							}
					}else{
						showModal('conditionalApproval', 'associate-review-modal');
					}
					/*VAN-249 VAN- Configurable Manual Intervention Response - Ends*/
					return;
				}
			}
			//AC23434 code changes start
			else if(engagement.overallApprovalReason && engagement.overallApprovalReason.indexOf('Outstanding balance with AcceptanceNow or Rent-A-Center') > -1) {
				if(engagement.applicationStatus !== 'Declined') {
					showModal('outstandingBalance', 'associate-review-modal');
				}
				return;
			}
			//AC23434 code changes end
			else {
				if(engagement.applicationStatus !== 'Declined' && engagement.applicationStatus !== 'Accepted') {
					$rootScope.applicationLink = true//PRB0042346 - Application stuck inprocess due to different phone number
					getApplication(engagement);
				}
			}
		}
		
		/*VAN-249 VAN- Configurable Manual Intervention Response - Starts*/
		function conditionalApprovalAssocReviewData(engagement,response){
			$scope.showCustAddress = false;
			$scope.showCustReferences= false;
			if(engagement.verifyReasons.length>0){
				for(var i=0;i<engagement.verifyReasons.length;i++){
				if(engagement.verifyReasons[i].indexOf("Verify Address") > -1){
					$scope.showCustAddress = true;
					$scope.verifyAddress = engagement.verifyReasons[i];
					$scope.customerAddress1 =engagement.customerAddress1;
					$scope.customerAddress2 = engagement.customerCity+","+engagement.customerState+","+engagement.customerZipCode;
				}else if(engagement.verifyReasons[i].indexOf("Verify References (Min. 2)") > -1){
						$scope.showCustReferences= true;
						$scope.verifyReference = engagement.verifyReasons[i];
						$scope.referenceList =[];
						if(response!=undefined && response!=""){
							for(var r=0;r<response.personalReferences.length;r++){
								/*MI screen showing empty reference value for two reference objects- Starts*/
								if(response.personalReferences[r].firstName!="" && response.personalReferences[r].lastName!="" &&
										response.personalReferences[r].phone.areaCode!="" && response.personalReferences[r].phone.number!=""){
								var custPersonalReferences = response.personalReferences[r].firstName+" "+response.personalReferences[r].lastName+","
								+"             "+'('+response.personalReferences[r].phone.areaCode+')'+'-'+response.personalReferences[r].phone.number;
								$scope.referenceList.push(custPersonalReferences);
								}
								/*MI screen showing empty reference value for two reference objects - Ends*/
							}
						}
				}
				else{
					var tempObj = {
							"isSelected": false,
							"reason": engagement.verifyReasons[i]
					}
					$scope.declineReasonsList.push(tempObj);
				}
			}
			showModal('conditionalApprovalAssociateReview', 'associate-review-modal');
			}
		}
		/*VAN-249 VAN- Configurable Manual Intervention Response - Ends*/
		
		function showSearchIndicator(engagement){
			SharedDataSvc.manualOverrideData = engagement;
			$rootScope.searchIndicatorDetails = {};
			modalService.processing($translate.instant("common_label_processing")); 
			dashboardServices.getCustomerReview(engagement.customerId)
	        .then(function(response){
	        	modalService.dismiss();
				$rootScope.searchIndicatorDetails = {
					searchDetails: response.van41SearchDetail,
					searchDetailsInactive: response.inactiveAgreements,
					//searchDetailsInactive: response.van41SearchDetail,
					getLossInfo: response.getLossInfo,
					orderStatus : summaryStatus(engagement),
					paymentStatus: engagement.paymentStatus,
					overallApprovalReason : engagement.overallApprovalReason
				};
				if($rootScope.searchIndicatorDetails.orderStatus == 'Declined' || $rootScope.searchIndicatorDetails.orderStatus == 'Declined by Coworker'){
					$rootScope.searchIndicatorDetails.hideDecline = true;
				} /*else if(!$rootScope.searchIndicatorDetails.searchDetails || 
					$rootScope.searchIndicatorDetails.searchDetails.length <= 0){
					$rootScope.searchIndicatorDetails.hideDecline = true;
				} */
				else {
					$rootScope.searchIndicatorDetails.hideDecline = false;
				}
				showModalSearchIndicator('searchIndicatorDetails', 'associate-review-modal');
			}, function(error){
				console.log('[dashboard.showSearchIndicator] failed getting engagement data', error);
			});
		}

		function resetManualOverrideData() {
			// Reset override amount
			manualOverrideFactory.approvalModel.requestedAmount = null;
			// Reset skip stolen fields
			manualOverrideFactory.associateReview.employeeName = '';
			manualOverrideFactory.associateReview.employeePass = '';
			manualOverrideFactory.associateReview.verifyExplain = '';
			manualOverrideFactory.associateReview.verifyEmployment = false;
			manualOverrideFactory.associateReview.verifyReference = false;
			manualOverrideFactory.associateReview.verifyAddress = false;
			manualOverrideFactory.approvalModel.employeeName = '';
			manualOverrideFactory.approvalModel.rightSelling = false;
			manualOverrideFactory.approvalModel.verifyLease = false;
			// Reset associate review
			manualOverrideFactory.associateReviewverifyEmployment = null;
			manualOverrideFactory.associateReview.verifyReference = null;
			manualOverrideFactory.associateReviewverifyAddress = null;
			manualOverrideFactory.associateReview.notVerified = false;
			manualOverrideFactory.associateReview.summary = null;
			manualOverrideFactory.associateReview.checkedDl = false;
			manualOverrideFactory.associateReview.confirmDl = false;
			manualOverrideFactory.associateReview.calledLandlord = false;
			manualOverrideFactory.associateReview.confirmUtility = false;
			manualOverrideFactory.associateReview.other = false;
			manualOverrideFactory.associateReview.verifyExplain = "";
			manualOverrideFactory.associateReview.verifyIncome = false;
			manualOverrideFactory.associateReview.employeeName = null;
			manualOverrideFactory.associateReview.employeePass = undefined;
			manualOverrideFactory.associateReview.employeePassSubmitted = false;
		}

		function editDelivery(engagement) {
			console.log('[Dashboard.editDelivery called]');
			SharedDataSvc.manualOverrideData = engagement;
			showModal('invoiceAndDelivery');
			// showScheduleDeliveryCta(engagement);
		}

		function showScheduleDeliveryCta(engagement) {
			var storeInfo = SharedDataSvc.globalVars.storeSetup;
			if(storeInfo.invoiceMethod !== undefined && storeInfo.invoiceMethod.toLowerCase() === 'creditcardonpayment') {
				if(engagement.orderStatus === dashboard.itemStatus.order.confirmed && engagement.paymentStatus == 'Complete') {
					if(engagement.vccTransactionId !== undefined && engagement.vccTransactionId.trim() !== "") {
						if(engagement.deliveryStatus == 'Pending' || engagement.deliveryStatus == 'ReadySched') {
							return true;
						}
					}
				}
			} else if(engagement.orderStatus === dashboard.itemStatus.order.confirmed && engagement.paymentStatus == 'Complete') {
				if(engagement.deliveryStatus == 'Pending' || engagement.deliveryStatus == 'ReadySched') {
					return true;
				}
			}
			
			return false;
			// item.deliveryStatus === 'Scheduled' || item.deliveryStatus === 'Pickup'
		}

		// format Date
		function formatDateGenerateCard (date){
			return moment(date, 'YYYY-MM-DD').format('YYYY-MM-DD');
		}

		// check for displaying Generate Credit Card button
		/*function showGenerateCreditCardBtn(engagement) {
			var storeInfo = SharedDataSvc.globalVars.storeSetup;
			if(!storeInfo.isBarcodeEnabled && storeInfo.invoiceMethod !== undefined && storeInfo.invoiceMethod.toLowerCase() === 'creditcardonpayment') {
				if(engagement.orderStatus === dashboard.itemStatus.order.confirmed && engagement.paymentStatus == 'Complete' && (engagement.deliveryStatus.toLowerCase() == 'scheduled' || engagement.deliveryStatus.toLowerCase() == 'pickup')) {
					return true;
				}
			}
			return false;
		}*/

		// check for displaying View/Print button for VCC details
		function showViewPrintVccBtn (engagement) {
			var storeInfo = SharedDataSvc.globalVars.storeSetup;
			if(storeInfo.isBarcodeEnabled && storeInfo.invoiceMethod !== undefined && !engagement.barcodeExpired) {
				if(engagement.orderStatus === dashboard.itemStatus.order.confirmed && 
					engagement.paymentStatus == dashboard.itemStatus.payment.complete && 
					(engagement.deliveryStatus.toLowerCase() == 'scheduled' || engagement.deliveryStatus.toLowerCase() == 'pickup')) {
					return true;
				}
			}
		}

		// generic modal for Generate Credit Card flow
		function showModalCreditCard(name, windowClass) {
			windowClass = windowClass || '';
			dashboard.modal = modalService.open({
				templateUrl: '../../../partials/modal_' + name + '.html',
				backdrop: 'static',
				windowClass: windowClass,
				scope: $scope
			});
		}

		function showModalSearchIndicator(name, windowClass) {
			windowClass = windowClass || '';
			dashboard.modal = modalService.open({
				templateUrl: '../../../partials/modal_' + name + '.html',
				controller: 'manualOverride',
				controllerAs: 'override',
				backdrop: 'static',
				windowClass: windowClass,
				scope: $scope
			});
		}

		// confirmation for generating credit card
		/*function confirmGenerateCreditCard(engagement) {
			dashboard.engagementId = engagement.engagementId;
			$rootScope.agreementAmount = engagement.totalAgreementAmount;
			$rootScope.creditAmount = engagement.totalCreditAmount;
			showModalCreditCard('confirmGenerateCreditCard');
		}*/

		function viewVccBarcodeDetails(engagementID) {
			console.log('EngagementID for VCC',engagementID);
			showModalCreditCard($translate.instant("common_label_processing"), 'processing-modal');
			dashboardServices.getCreditCardDetails(engagementID)
	        .then(function(response){
	          console.log('Generate credit card details success', response);

	          var pdfBlob = SharedDataSvc.createPdfBlob(response.vccInvoicePdf);
	          if (navigator.msSaveOrOpenBlob) {
	            navigator.msSaveOrOpenBlob(pdfBlob);
	            dashboard.modal.dismiss();
	          } else {
	            var objectURL = URL.createObjectURL(pdfBlob);
	            $rootScope.securePdf = $sce.trustAsResourceUrl(objectURL);
	          }
	          if($rootScope.securePdf) {
	            var vccPrintDetailModal = modalService.open({
	              templateUrl: '/partials/modal_vccPrintReceipt.html',
	              backdrop: 'static',
	              show:true
	            });
	            vccPrintDetailModal.result.then(function(){
	              SharedDataSvc.clearPrintSection();
	              //navigateTo('dashboard');
	            });
	          }

	        },function(response){
	          	console.log('Generate credit card details failure',response);
		        if(response.data.errorData.status_code === 'ENG-V116-404' || response.data.errorData.status_code === 'ENG-V101-500') {
		            $rootScope.modalMessage = response.data.errorData.status_message;  
		            var errorModal = modalService.open({
			            templateUrl: '/partials/modal_vccPrintReceiptError.html',
			            backdrop: 'static',
			            show:true
			        });
			        errorModal.result.then(function(){
			            //navigateTo('dashboard');
			        });        
		        }
	        });
		}

		//HT2-12 additional details modal
		function getAdditionalDetails(item){
			modalService.processing($translate.instant("common_label_processing"));
			dashboardServices.getAdditionalInfo(item)
	        .then(function(response){
	        	modalService.dismiss();

	        	var locale;
				if($rootScope.selectedLanguage == 'es'){
					locale = 'spanish';
				}else{
					locale = 'english';
				}

	        	$rootScope.additionalInfoQue = {
					infoDetails: response.questions,
					engagementId : item,
					locale: locale
				};
				
				for(var z=0; z < response.questions.length ; z++){
					response.questions[z].language = locale;
					if(locale == 'english'){
						if(response.questions[z].possibleValues_en){
							response.questions[z].possibleValues_en = getAddCheckOptions(response.questions[z].possibleValues_en);
						}
					}else if(locale == 'spanish'){
						if(response.questions[z].possibleValues_es){
							response.questions[z].possibleValues_es = getAddCheckOptions(response.questions[z].possibleValues_es);
						}
					}	
				}
				
				var quickQuoteModal = modalService.open({
		            templateUrl: '/partials/modal_getAdditionalDetails.html',
		            backdrop: 'static',
		            show:true,
		            scope: $scope,
		            windowClass: 'quick-quote'
	       		 });
			}, function(error){
				console.log('[dashboard.getAdditionalDetails] failed getting engagement data', error);
			});
		}

		// funtion checkbox value string into object
		function getAddCheckOptions(options){
			var checkOptions = [];
			options.replace(/^\[(.+)\]$/,'');
			options = options.split(',');

			options.forEach(function(entry) {
   				var obj = {
   					name : entry,
   					isSelected : false
   				 }
   				checkOptions.push(obj);
			});
			return checkOptions;
		}

		// funtion to format selected checkbox value into comma seperated single string	
		function formatCheckOptions(options){
			var optionList = [];
				for(var z=0; z < options.length ; z++){
					if(options[z].isSelected){
						optionList.push(options[z].name);
					}
				}
				return optionList.join(',');
		}


		function setAdditionalDetails(){
			modalService.processing($translate.instant("common_label_processing"));
			console.log("$rootScope.additionalInfoQue" + $rootScope.additionalInfoQue);
			var infoList = $rootScope.additionalInfoQue.infoDetails;
			var infoData = {};
			infoData.engagementId =  $rootScope.additionalInfoQue.engagementId.toString();
				for(var z=0; z < infoList.length ; z++){
					if(infoList[z].inputType == "checkbox"){
						if(infoList[z].language === "english"){
							var formatList = formatCheckOptions(infoList[z].possibleValues_en);
							infoList[z].value = formatList;
						}else{
							var formatList = formatCheckOptions(infoList[z].possibleValues_es);
							infoList[z].value = formatList;
						}
					}
					if(infoList[z].value && infoList[z].inputType == "text"){
						infoData[infoList[z].key] = infoList[z].value;
					}
					if(infoList[z].value && infoList[z].inputType == "dropdown"){
						infoData[infoList[z].key] = infoList[z].value.name;
					}
				}
				console.log(infoData);

				dashboardServices.setAdditionalInfo(infoData)
	        		.then(function(response){
	        			modalService.dismiss();
	        			navigateTo('dashboard');
	        	}, function(error){
	        		modalService.dismiss();
				console.log('[dashboard.postAdditionalDetails] failed posting additional data', error);
			});
		}

		//Show quick quote modal
		function getQuoteModal(selectedProduct) {	
			if(selectedProduct){
				dashboard.quoteInput.selectedProduct = selectedProduct; 
			} else {
				dashboard.quoteInput.selectedProduct = undefined; 
			}	
			/*VAN 492 - PR-AWP: Categories in Quick quote are in English even though language selected is Spanish - starts*/
			if($scope.dashboard.storeInfo.quickQuoteProductList){
				$scope.dashboard.storeInfo.quickQuoteProductList.product = $rootScope.selectedLanguage=="es" ? dashboard.storeInfo.quickQuoteProductList.product_es: dashboard.storeInfo.quickQuoteProductList.product_en;
			}
			/*VAN 492 - PR-AWP: Categories in Quick quote are in English even though language selected is Spanish - Ends*/
			
			var quickQuoteModal = modalService.open({
	            templateUrl: '/partials/modal_quickQuoteCalculator.html',
	            backdrop: 'static',
	            show:true,
	            scope: $scope,
	            windowClass: 'quick-quote'
	        });
	        quickQuoteModal.result.then(function(){
	            //navigateTo('dashboard');
	        });
		}

		// show RA update agreement search modal
		function getRAUpdateSearchModal(invalidAgreementError) {
			if (invalidAgreementError) {
				$scope.raUpdate.invalidAgreementNo = true;
			} else {
				resetDocuSign();
			}
			var docSignModal = modalService.open({
				templateUrl: '/partials/modal_raUpdateSearch.html',
				backdrop: 'static',
				show:true,
				scope: $scope,
				windowClass: 'quick-quote'
			});
			docSignModal.result.then(function(){
				showProcessingModal();
				// lock modals so we can use our own error handling
				modalService.lock(true);
				applicationService.getApplicationByAgreementId($scope.raUpdate.agreementNo).then(function(engagement) {
					modalService.lock(false);
					getRAUpdateResultModal(engagement);
				}, function(error) {
					modalService.lock(false);
					if (error.data.errorData.status_message === 'Engagement not found') {
						getRAUpdateSearchModal(true);
					}
					console.log("[getRAUpdateSearchModal error]", error);
				});
			});
		}

		// show RA update agreement search results modal
		function getRAUpdateResultModal(engagement) {
			$rootScope.engagementInfo = {
				VanEngagement: engagement,
			};
			$scope.raUpdate.results = {
				agreementNo: engagement.agreementHTId,
				firstName: engagement.customer.fName,
				lastName: engagement.customer.lName,
				customerId: engagement.customer.customerId,
				engagementId: engagement.engagementId,
				email: engagement.customer.email,
			};
			$scope.docuSignResultModal = modalService.open({
				templateUrl: '/partials/modal_raUpdateResult.html',
				backdrop: 'static',
				show:true,
				scope: $scope,
				windowClass: 'smallModals',
			});
		}

		// RA update in-store DocuSign
		function inStoreRAUpdateDocuSign(engagement) {
			showProcessingModal();
			var payload = {
		              "customerId" : engagement.customerId,
		              "engagementId" : String(engagement.engagementId),
		              "signersPresent" : 'APPLICANT',
		              "paymentToDate" : "",
		              "coapplicantFirstName" : "",
		              "coapplicantLastName" : "",
			};

			var storedSessionId = $cookies.getObject("okta_session_id");

			var domain = $window.location;
			var destination = domain.hash.split('/')[1];

			payload.returnURL = domain.origin + '/#/login' +
								"?Session=" + storedSessionId + "&engagementId=" + engagement.engagementId;
			console.log("returnURL", payload.returnURL);

			$cookies.putObject("isCoCustomerSignerPending", null);

			console.log('docuSign Data', payload);
			$cookies.putObject("navigatingToDocusign", true);

			paymentFactory.docuSign(payload, true).then(function(response) {
				// normally signingStatus is undefined for
				// in-store, but it is 'Accepted' if the
				// document was already signed remotely
				if (response.signingStatus === 'Accepted') {
					if($rootScope.selectedLanguage == 'es') {
						modalService.error('El documento de actualización de renta ya está firmado.');
					}
					else {
						modalService.error('Rental update document is already signed.');
					}
					return;
				}

				$window.location.href = response.signatureURL;
			}, function(error) {
				$cookies.putObject("navigatingToDocusign", false);
				$cookies.putObject("isCoCustomerSignerPending", null);
				//$location.url('dashboard');
				console.log(error);
			});
		}

		// RA update remote DocuSign
		function remoteRAUpdateDocuSign(engagementInfo) {
			console.log("in remoteDocResign");
			$scope.emailAddress = engagementInfo.email;
			var remoteSignModal = modalService.open({
				title: "Customer Email Verification for RemoteSign in Dashboard",
				templateUrl: 'partials/modal_remoteEmailConfirm.tpl.html',
				scope: $scope,
				backdrop: 'static',
				controller: 'emailAgreementRemoteCtrl'
			});
			remoteSignModal.result.then(function(email) {
				//$scope.email.language = $scope.remoteEmail.lanType;
				$scope.confirmedEmail = email;
				emailRAUpdateToPersonalDevice();//function to call Create Envelope service or EML service
			});
		}

		function emailRAUpdateToPersonalDevice() {
			var engagement = $rootScope.engagementInfo;
			//call create envelope service with remoteconfirmation emailID
			console.log("Create Envelope Service");
			if (engagement.VanEngagement.agreementHTId != null){
				showProcessingModal();
				var storedSessionId = $cookies.getObject("okta_session_id");
				if (engagement.VanEngagement.payToDate == null) {
					engagement.VanEngagement.payToDate = engagement.VanEngagement.nextPaymentDate;
				}
				if (engagement.VanEngagement.payToDate != null) {
					var isValid = moment(engagement.VanEngagement.payToDate, 'YYYYMMDD',true).isValid();
					if (isValid) {
						var payDate = engagement.VanEngagement.payToDate;
					} else {
						var payDate = moment(engagement.VanEngagement.payToDate).format('YYYYMMDD');
					}
				}
				var payload = {
						"customerId" : engagement.VanEngagement.customer.customerId,
						"engagementId" : engagement.VanEngagement.engagementId,
						"signersPresent" : "APPLICANT",
						"clubSwitch" : engagement.VanEngagement.clubSelection,
						"waiverSwitch" : engagement.VanEngagement.waiverSelection,
						"paymentToDate" : payDate,
						"coapplicantFirstName" : null,
						"coapplicantLastName" : null,
						"isRemoteSigningOpted": "1",
						"remoteEmailAddress" : $scope.confirmedEmail,
						"language":$scope.remoteEmail.lanType
				};
				var domain = $window.location;
				var destination = domain.hash.split('/')[1];
				payload.returnURL = domain.origin + '/#/login' +
										"?Session=" + storedSessionId + "&engagementId=" + engagement.VanEngagement.engagementId;
				console.log("returnURL", payload.returnURL);
				console.log('DocuSign Data - CreateEnvelope Service', payload);
				paymentFactory.docuSign(payload, true).then(function(response) {
					console.log('Success Response',response);
					if (response.signingStatus === 'Accepted') {
						if($rootScope.selectedLanguage == 'es') {
							modalService.error('El documento de actualización de alquiler ya está firmado. No se enviará un correo electrónico.');
						}
						else {
							modalService.error('Rental update document is already signed. An email will not be sent.');
						}
						return;
					}

					modalService.dismiss();
					$route.reload();
				}, function(error) {
					console.log(error);
				});
			}
		}

		function quickQuoteCalculation(inputValue) {
			/*VAN-934 - Quick Quote – Add biweekly and 4weeks quote, along with monthly, to both AWP and Kiosk*/
			dashboard.resetQQPaymentEstimate = false;
			dashboard.showPaymentSectionForBiweekly = true;//VAN-3705 - QuickQuote - When service called for more than once the response doesn't get loaded as expected
			showModalCreditCard($translate.instant("common_label_processing"), 'processing-modal');
			console.log("Merchandise Total:", inputValue.merchandiseTotal);
			if(inputValue.deliveryFee)
				console.log("Delivery Fee:", inputValue.deliveryFee);
			if(dashboard.hideQQProductList && dashboard.storeInfo.quickQuoteProductList && dashboard.storeInfo.quickQuoteProductList.product_en.length == 1){
				inputValue.selectedProduct = dashboard.storeInfo.quickQuoteProductList.product_en[0];
			}
			var quickQuoteData = orderEstimatePutFactory.compileQuickQuoteData(SharedDataSvc.globalVars.storeSetup.vanStoreId ,inputValue, dashboard.prodCat);
			if(dashboard.storeInfo.isQQCalendarRequired) {
				quickQuoteData.nextRenewDte = dashboard.isAdjustablePayment ? SharedDataSvc.quoteCalculator.date : dashboard.nextRenewDte[inputValue.quoteDate].formattedDate;
			}

			/*VAN-934 - Quick Quote – Add biweekly and 4weeks quote, along with monthly, to both AWP and Kiosk - Starts*/
			if(dashboard.storeInfo.lowInitialPaymentEnabled && dashboard.storeInfo.paymentModeEnabled!=undefined){
			quickQuoteData.paymentMode = 'Biweekly';
			orderEstimatePutFactory.putData(quickQuoteData, false, 'HT')
			.then(function(biweeklyResponse) {
				dashboard.quickQuoteBiweeklyEstimate = biweeklyResponse;
				quickQuoteData.paymentMode = 'FOURWEEK';
				orderEstimatePutFactory.putData(quickQuoteData, false, 'HT')
				.then(function(fourWeekResponse) {
					dashboard.quickQuoteFourWeekEstimate = fourWeekResponse;
					quickQuoteData.paymentMode = 'Monthly';
					/*VAN-934 - Quick Quote – Add biweekly and 4weeks quote, along with monthly, to both AWP and Kiosk - Ends*/
					orderEstimatePutFactory.putData(quickQuoteData, false, 'HT')
					.then(function(response) {
						dashboard.letsGetStarted = false;
						dashboard.quoteInput.merchandiseTotal = inputValue.merchandiseTotal;
						dashboard.quoteInput.deliveryFee = inputValue.deliveryFee;
						if(dashboard.storeInfo.isQQCalendarRequired) {
							dashboard.quoteInput.quoteDate = dashboard.nextRenewDte[inputValue.quoteDate];
						}
						getQuoteModal(inputValue.selectedProduct);
						console.log("Quick quote response from HT", response);
						dashboard.quickQuoteEstimate = response;
					    //VAN-3691 - Total cost of lease to be added in Quick Quote and review estimate - Starts
						if(dashboard.quickQuoteBiweeklyEstimate.totalLeasePurchasePrice!=undefined && dashboard.quickQuoteFourWeekEstimate.totalLeasePurchasePrice!=undefined
								&& dashboard.quickQuoteEstimate.totalLeasePurchasePrice!=undefined){
							console.log("Biweekly:::"+dashboard.quickQuoteBiweeklyEstimate.totalLeasePurchasePrice+":::4week:::"+dashboard.quickQuoteFourWeekEstimate.totalLeasePurchasePrice+":::Monthly::::"+
									dashboard.quickQuoteEstimate.totalLeasePurchasePrice);
						dashboard.quickQuoteEstimate.totalLeasePurchasePrice = Math.max(dashboard.quickQuoteBiweeklyEstimate.totalLeasePurchasePrice, 
								dashboard.quickQuoteFourWeekEstimate.totalLeasePurchasePrice,dashboard.quickQuoteEstimate.totalLeasePurchasePrice);
						}
						//VAN-3691 - Total cost of lease to be added in Quick Quote and review estimate - Ends
						var deliveryData = [];

						dashboard.initialRentalFee = dashboard.quickQuoteEstimate.initialPaymentWTax;
						/*var fixedAmounts = dashboard.quickQuoteEstimate.initialPaymentWTax +
					 dashboard.quickQuoteEstimate.ldwAmountInitial +
					  dashboard.quickQuoteEstimate.ldwTaxInitial +
					   dashboard.quickQuoteEstimate.bplusAmountInitial +
					    dashboard.quickQuoteEstimate.bplusTaxInitial;


					if(inputValue.deliveryFee > 0){
						for (var item in dashboard.quickQuoteEstimate.feeArray) {
	  						if (dashboard.quickQuoteEstimate.feeArray[item].feeType == "Delivery") {
	    							deliveryData.push(dashboard.quickQuoteEstimate.feeArray[item]);
	 						 }
						}
					}else{
						var noDevly = {
							"feeInitialPayment": 0,
							"feeInitialTax": 0
						}
						deliveryData.push(noDevly);
					}*/


						if(Number(inputValue.deliveryFee) > 0 && dashboard.quickQuoteEstimate.processingFee > 0 && inputValue.extendedServiceAmount > 0 ) {
							//dashboard.initialRentalFee = fixedAmounts + deliveryData[0].feeInitialPayment+ deliveryData[0].feeInitialTax + dashboard.quickQuoteEstimate.processingFee + dashboard.quickQuoteEstimate.processingFeeTax;

							dashboard.quickQuoteInitialInfo = $rootScope.selectedLanguage == 'es' ? es.modal_quickQuoteCalculator_FeesDesc1 : en.modal_quickQuoteCalculator_FeesDesc1; 
							//dashboard.quickQuoteDisclaimer = 'The "Estimated Initial Payment" includes your processing fee of $' + dashboard.quickQuoteEstimate.processingFee+ ', a delivery charge of $'+Number(inputValue.deliveryFee) + ', and an extended services of $'+ Number(inputValue.extendedServiceAmount);
						}else if((inputValue.deliveryFee) > 0 && dashboard.quickQuoteEstimate.processingFee > 0 && (inputValue.extendedServiceAmount === undefined || Number(inputValue.extendedServiceAmount) <= 0)){
							//dashboard.initialRentalFee = fixedAmounts + deliveryData[0].feeInitialPayment+deliveryData[0].feeInitialTax + dashboard.quickQuoteEstimate.processingFee + dashboard.quickQuoteEstimate.processingFeeTax;

							dashboard.quickQuoteInitialInfo = $rootScope.selectedLanguage == 'es' ? es.modal_quickQuoteCalculator_FeesDesc4 : en.modal_quickQuoteCalculator_FeesDesc4; 
							//dashboard.quickQuoteDisclaimer = 'The "Estimated Initial Payment" includes your processing fee of $' + dashboard.quickQuoteEstimate.processingFee+ ' and a delivery charge of $'+Number(inputValue.deliveryFee);

						}else if(Number(inputValue.deliveryFee) > 0 && dashboard.quickQuoteEstimate.processingFee <= 0 && inputValue.extendedServiceAmount > 0) {
							//dashboard.initialRentalFee = fixedAmounts +  deliveryData[0].feeInitialPayment + deliveryData[0].feeInitialTax;
							dashboard.quickQuoteInitialInfo = $rootScope.selectedLanguage == 'es' ? es.modal_quickQuoteCalculator_FeesDesc5 : en.modal_quickQuoteCalculator_FeesDesc5; 
							//dashboard.quickQuoteDisclaimer = 'The "Estimated Initial Payment" includes your delivery charge of $' + Number(inputValue.deliveryFee) + " and an extended services of $" + Number(inputValue.extendedServiceAmount);

						}else if(Number(inputValue.deliveryFee) > 0 && dashboard.quickQuoteEstimate.processingFee <= 0 && (inputValue.extendedServiceAmount === undefined || Number(inputValue.extendedServiceAmount) <= 0)) {
							//dashboard.initialRentalFee = fixedAmounts +  deliveryData[0].feeInitialPayment + deliveryData[0].feeInitialTax;
							dashboard.quickQuoteInitialInfo = $rootScope.selectedLanguage == 'es' ? es.modal_quickQuoteCalculator_FeesDesc6 : en.modal_quickQuoteCalculator_FeesDesc6; 
							//dashboard.quickQuoteDisclaimer = 'The "Estimated Initial Payment" includes your delivery charge of $' + Number(inputValue.deliveryFee);

						}else if((inputValue.deliveryFee === undefined || Number(inputValue.deliveryFee) <= 0) && dashboard.quickQuoteEstimate.processingFee > 0 && inputValue.extendedServiceAmount > 0){
							//dashboard.initialRentalFee = fixedAmounts +  dashboard.quickQuoteEstimate.processingFee + dashboard.quickQuoteEstimate.processingFeeTax;
							dashboard.quickQuoteInitialInfo = $rootScope.selectedLanguage == 'es' ? es.modal_quickQuoteCalculator_FeesDesc2 : en.modal_quickQuoteCalculator_FeesDesc2; 
							dashboard.quickQuoteDisclaimer = ($rootScope.selectedLanguage == 'es' ? es.modal_quickQuoteCalculator_FeesDesc7 : en.modal_quickQuoteCalculator_FeesDesc7)  + dashboard.quickQuoteEstimate.processingFee + ($rootScope.selectedLanguage == 'es' ? es.modal_quickQuoteCalculator_FeesDesc8 : en.modal_quickQuoteCalculator_FeesDesc8 ) + Number(inputValue.extendedServiceAmount);

						}else if((inputValue.deliveryFee === undefined || Number(inputValue.deliveryFee) <= 0) && dashboard.quickQuoteEstimate.processingFee > 0 && (inputValue.extendedServiceAmount === undefined || Number(inputValue.extendedServiceAmount) <= 0)){
							//dashboard.initialRentalFee = fixedAmounts +  dashboard.quickQuoteEstimate.processingFee + dashboard.quickQuoteEstimate.processingFeeTax;
							dashboard.quickQuoteInitialInfo = $rootScope.selectedLanguage == 'es' ? es.modal_quickQuoteCalculator_FeesDesc3 : en.modal_quickQuoteCalculator_FeesDesc3; 
							//dashboard.quickQuoteDisclaimer = 'The "Estimated Initial Payment" includes your processing fee of $' + dashboard.quickQuoteEstimate.processingFee;

						}else if((inputValue.deliveryFee === undefined || Number(inputValue.deliveryFee) <= 0) && dashboard.quickQuoteEstimate.processingFee <= 0 && inputValue.extendedServiceAmount > 0) {
							//dashboard.initialRentalFee = fixedAmounts
							dashboard.quickQuoteInitialInfo = $rootScope.selectedLanguage == 'es' ? es.modal_quickQuoteCalculator_FeesDesc9 : en.modal_quickQuoteCalculator_FeesDesc9; 
							//dashboard.quickQuoteDisclaimer = 'The "Estimated Initial Payment" includes your extended services of $' + Number(inputValue.extendedServiceAmount);

						}else if((inputValue.deliveryFee === undefined || Number(inputValue.deliveryFee) <= 0) && dashboard.quickQuoteEstimate.processingFee <= 0 && (inputValue.extendedServiceAmount === undefined || Number(inputValue.extendedServiceAmount) <= 0)) {
							//	dashboard.initialRentalFee = fixedAmounts
							dashboard.quickQuoteInitialInfo = "";
							//dashboard.quickQuoteDisclaimer = "";
						}

						if(dashboard.quickQuoteEstimate !== undefined && 
								dashboard.quickQuoteEstimate.epoPaymentArray !== undefined) {
							var epoSchedule = dashboard.quickQuoteEstimate.epoPaymentArray;
							if (epoSchedule === undefined || epoSchedule.length === 0) {
								dashboard.epoSchedule = [];
								return;
							}
							// set to scope
							dashboard.epoSchedule = epoSchedule;
							var firstPaymentMonth = dashboard.epoSchedule[0].epoAfterPaymentNumber;
							//Commented as part of VAN-1415-Bob's - Change EPO array to 120 days
							/*if (firstPaymentMonth > 3) {
								var days = (firstPaymentMonth - 1) * 30;
								for (var i = firstPaymentMonth - 1; i > 0; i--) {
									var epoEntry = {
											epoAfterPaymentNumber: i,
											epoCashPrice: days + " Days EPO"
									}
									dashboard.epoSchedule.unshift(epoEntry);
								}
							} else if (firstPaymentMonth == 3) {
								var days = firstPaymentMonth * 30;
								for (var i = firstPaymentMonth - 1; i > 0; i--) {
									var epoEntry = {
											epoAfterPaymentNumber: i,
											epoCashPrice: days + " Days EPO"
									}
									dashboard.epoSchedule.unshift(epoEntry);
								}
							}*/
							//Commented as part of VAN-1415-Bob's - Change EPO array to 120 days
							//VAN-1415-Bob's - Change EPO array to 120 days - Starts
							if (firstPaymentMonth >= 3) {
								if(dashboard.storeInfo != undefined && dashboard.storeInfo.epoOptionDays != undefined ){
									if(dashboard.storeInfo.epoOptionDays === '120'){
										for (var i = firstPaymentMonth - 1; i > 0; i--) {
											var epoEntry = {
													epoAfterPaymentNumber: i,
													//VAN-1390 - EPO showing incorrectly in review estimate
													epoCashPrice: "120" + " Days EPO"
											}
											dashboard.epoSchedule.unshift(epoEntry);
										}
									}else if(dashboard.storeInfo.epoOptionDays === '100'){
										for (var i = firstPaymentMonth - 1; i > 0; i--) {
											var epoEntry = {
													epoAfterPaymentNumber: i,
													epoCashPrice: "100" + " Days EPO"
											}
											dashboard.epoSchedule.unshift(epoEntry);
										}
									}else if(dashboard.storeInfo.epoOptionDays === '90'){
										for (var i = firstPaymentMonth - 1; i > 0; i--) {
											var epoEntry = {
													epoAfterPaymentNumber: i,
													epoCashPrice: "90" + " Days EPO"
											}
											dashboard.epoSchedule.unshift(epoEntry);
										}	
									}
								}else{
									for (var i = firstPaymentMonth - 1; i > 0; i--) {
										var epoEntry = {
												epoAfterPaymentNumber: i,
												epoCashPrice: "90" + " Days EPO"
										}
										dashboard.epoSchedule.unshift(epoEntry);
									}
								}
								//VAN-1415-Bob's - Change EPO array to 120 days - Ends
							}
						}

						dashboard.selectionFlag = !dashboard.quickQuoteEstimate.feeArray[1].feeAcrossTerm && dashboard.quickQuoteEstimate.feeArray[1].feeAmount > 0;

						dashboard.noEsvNoDel = (dashboard.quickQuoteEstimate.feeArray[0].feeAmount <= 0 && dashboard.quickQuoteEstimate.feeArray[1].feeAmount <= 0);
						dashboard.onlyEsvNoDel = (dashboard.quickQuoteEstimate.feeArray[0].feeAmount > 0 && dashboard.quickQuoteEstimate.feeArray[1].feeAmount <= 0);
						dashboard.noESVonlyDel = (dashboard.quickQuoteEstimate.feeArray[0].feeAmount <= 0 && dashboard.quickQuoteEstimate.feeArray[1].feeAmount > 0);
						dashboard.withEsvDel = (dashboard.quickQuoteEstimate.feeArray[0].feeAmount > 0 && dashboard.quickQuoteEstimate.feeArray[1].feeAmount > 0);

					}, function(response){
						dashboard.letsGetStarted = true;
						console.log("Quick quote failed response from HT(Monthly)", response);
					});
					/*VAN-934 - Quick Quote – Add biweekly and 4weeks quote, along with monthly, to both AWP and Kiosk - Starts*/
				}, function(fourWeekResponse){
					dashboard.letsGetStarted = true;
					console.log("Quick quote failed response from HT(FourWeek)", fourWeekResponse);
				});
			}, function(biweeklyResponse){
				dashboard.letsGetStarted = true;
				console.log("Quick quote failed response from HT(Biweekly)", biweeklyResponse);
			});
		}else if(!dashboard.storeInfo.lowInitialPaymentEnabled || dashboard.storeInfo.paymentModeEnabled==undefined){
			/*quickQuoteData.paymentMode = '';*/
			orderEstimatePutFactory.putData(quickQuoteData, false, 'HT')
			.then(function(response) {
				dashboard.letsGetStarted = false;
				dashboard.quoteInput.merchandiseTotal = inputValue.merchandiseTotal;
				dashboard.quoteInput.deliveryFee = inputValue.deliveryFee;
				if(dashboard.storeInfo.isQQCalendarRequired) {
					dashboard.quoteInput.quoteDate = dashboard.nextRenewDte[inputValue.quoteDate];
				}
				getQuoteModal(inputValue.selectedProduct);
				console.log("Quick quote response from HT", response);
				dashboard.quickQuoteEstimate = response;
				var deliveryData = [];

				dashboard.initialRentalFee = dashboard.quickQuoteEstimate.initialPaymentWTax;
				/*var fixedAmounts = dashboard.quickQuoteEstimate.initialPaymentWTax +
			 dashboard.quickQuoteEstimate.ldwAmountInitial +
			  dashboard.quickQuoteEstimate.ldwTaxInitial +
			   dashboard.quickQuoteEstimate.bplusAmountInitial +
			    dashboard.quickQuoteEstimate.bplusTaxInitial;


			if(inputValue.deliveryFee > 0){
				for (var item in dashboard.quickQuoteEstimate.feeArray) {
						if (dashboard.quickQuoteEstimate.feeArray[item].feeType == "Delivery") {
							deliveryData.push(dashboard.quickQuoteEstimate.feeArray[item]);
						 }
				}
			}else{
				var noDevly = {
					"feeInitialPayment": 0,
					"feeInitialTax": 0
				}
				deliveryData.push(noDevly);
			}*/


				if(Number(inputValue.deliveryFee) > 0 && dashboard.quickQuoteEstimate.processingFee > 0 && inputValue.extendedServiceAmount > 0 ) {
					//dashboard.initialRentalFee = fixedAmounts + deliveryData[0].feeInitialPayment+ deliveryData[0].feeInitialTax + dashboard.quickQuoteEstimate.processingFee + dashboard.quickQuoteEstimate.processingFeeTax;

					dashboard.quickQuoteInitialInfo = $rootScope.selectedLanguage == 'es' ? es.modal_quickQuoteCalculator_FeesDesc1 : en.modal_quickQuoteCalculator_FeesDesc1; 
					//dashboard.quickQuoteDisclaimer = 'The "Estimated Initial Payment" includes your processing fee of $' + dashboard.quickQuoteEstimate.processingFee+ ', a delivery charge of $'+Number(inputValue.deliveryFee) + ', and an extended services of $'+ Number(inputValue.extendedServiceAmount);
				}else if((inputValue.deliveryFee) > 0 && dashboard.quickQuoteEstimate.processingFee > 0 && (inputValue.extendedServiceAmount === undefined || Number(inputValue.extendedServiceAmount) <= 0)){
					//dashboard.initialRentalFee = fixedAmounts + deliveryData[0].feeInitialPayment+deliveryData[0].feeInitialTax + dashboard.quickQuoteEstimate.processingFee + dashboard.quickQuoteEstimate.processingFeeTax;

					dashboard.quickQuoteInitialInfo = $rootScope.selectedLanguage == 'es' ? es.modal_quickQuoteCalculator_FeesDesc4 : en.modal_quickQuoteCalculator_FeesDesc4; 
					//dashboard.quickQuoteDisclaimer = 'The "Estimated Initial Payment" includes your processing fee of $' + dashboard.quickQuoteEstimate.processingFee+ ' and a delivery charge of $'+Number(inputValue.deliveryFee);

				}else if(Number(inputValue.deliveryFee) > 0 && dashboard.quickQuoteEstimate.processingFee <= 0 && inputValue.extendedServiceAmount > 0) {
					//dashboard.initialRentalFee = fixedAmounts +  deliveryData[0].feeInitialPayment + deliveryData[0].feeInitialTax;
					dashboard.quickQuoteInitialInfo = $rootScope.selectedLanguage == 'es' ? es.modal_quickQuoteCalculator_FeesDesc5 : en.modal_quickQuoteCalculator_FeesDesc5; 
					//dashboard.quickQuoteDisclaimer = 'The "Estimated Initial Payment" includes your delivery charge of $' + Number(inputValue.deliveryFee) + " and an extended services of $" + Number(inputValue.extendedServiceAmount);

				}else if(Number(inputValue.deliveryFee) > 0 && dashboard.quickQuoteEstimate.processingFee <= 0 && (inputValue.extendedServiceAmount === undefined || Number(inputValue.extendedServiceAmount) <= 0)) {
					//dashboard.initialRentalFee = fixedAmounts +  deliveryData[0].feeInitialPayment + deliveryData[0].feeInitialTax;
					dashboard.quickQuoteInitialInfo = $rootScope.selectedLanguage == 'es' ? es.modal_quickQuoteCalculator_FeesDesc6 : en.modal_quickQuoteCalculator_FeesDesc6; 
					//dashboard.quickQuoteDisclaimer = 'The "Estimated Initial Payment" includes your delivery charge of $' + Number(inputValue.deliveryFee);

				}else if((inputValue.deliveryFee === undefined || Number(inputValue.deliveryFee) <= 0) && dashboard.quickQuoteEstimate.processingFee > 0 && inputValue.extendedServiceAmount > 0){
					//dashboard.initialRentalFee = fixedAmounts +  dashboard.quickQuoteEstimate.processingFee + dashboard.quickQuoteEstimate.processingFeeTax;
					dashboard.quickQuoteInitialInfo = $rootScope.selectedLanguage == 'es' ? es.modal_quickQuoteCalculator_FeesDesc2 : en.modal_quickQuoteCalculator_FeesDesc2; 
					dashboard.quickQuoteDisclaimer = ($rootScope.selectedLanguage == 'es' ? es.modal_quickQuoteCalculator_FeesDesc7 : en.modal_quickQuoteCalculator_FeesDesc7)  + dashboard.quickQuoteEstimate.processingFee + ($rootScope.selectedLanguage == 'es' ? es.modal_quickQuoteCalculator_FeesDesc8 : en.modal_quickQuoteCalculator_FeesDesc8 ) + Number(inputValue.extendedServiceAmount);

				}else if((inputValue.deliveryFee === undefined || Number(inputValue.deliveryFee) <= 0) && dashboard.quickQuoteEstimate.processingFee > 0 && (inputValue.extendedServiceAmount === undefined || Number(inputValue.extendedServiceAmount) <= 0)){
					//dashboard.initialRentalFee = fixedAmounts +  dashboard.quickQuoteEstimate.processingFee + dashboard.quickQuoteEstimate.processingFeeTax;
					dashboard.quickQuoteInitialInfo = $rootScope.selectedLanguage == 'es' ? es.modal_quickQuoteCalculator_FeesDesc3 : en.modal_quickQuoteCalculator_FeesDesc3; 
					//dashboard.quickQuoteDisclaimer = 'The "Estimated Initial Payment" includes your processing fee of $' + dashboard.quickQuoteEstimate.processingFee;

				}else if((inputValue.deliveryFee === undefined || Number(inputValue.deliveryFee) <= 0) && dashboard.quickQuoteEstimate.processingFee <= 0 && inputValue.extendedServiceAmount > 0) {
					//dashboard.initialRentalFee = fixedAmounts
					dashboard.quickQuoteInitialInfo = $rootScope.selectedLanguage == 'es' ? es.modal_quickQuoteCalculator_FeesDesc9 : en.modal_quickQuoteCalculator_FeesDesc9; 
					//dashboard.quickQuoteDisclaimer = 'The "Estimated Initial Payment" includes your extended services of $' + Number(inputValue.extendedServiceAmount);

				}else if((inputValue.deliveryFee === undefined || Number(inputValue.deliveryFee) <= 0) && dashboard.quickQuoteEstimate.processingFee <= 0 && (inputValue.extendedServiceAmount === undefined || Number(inputValue.extendedServiceAmount) <= 0)) {
					//	dashboard.initialRentalFee = fixedAmounts
					dashboard.quickQuoteInitialInfo = "";
					//dashboard.quickQuoteDisclaimer = "";
				}

				if(dashboard.quickQuoteEstimate !== undefined && 
						dashboard.quickQuoteEstimate.epoPaymentArray !== undefined) {
					var epoSchedule = dashboard.quickQuoteEstimate.epoPaymentArray;
					if (epoSchedule === undefined || epoSchedule.length === 0) {
						dashboard.epoSchedule = [];
						return;
					}
					// set to scope
					dashboard.epoSchedule = epoSchedule;
					var firstPaymentMonth = dashboard.epoSchedule[0].epoAfterPaymentNumber;
					//Commented as part of VAN-1415-Bob's - Change EPO array to 120 days
					/*if (firstPaymentMonth > 3) {
						var days = (firstPaymentMonth - 1) * 30;
						for (var i = firstPaymentMonth - 1; i > 0; i--) {
							var epoEntry = {
									epoAfterPaymentNumber: i,
									epoCashPrice: days + " Days EPO"
							}
							dashboard.epoSchedule.unshift(epoEntry);
						}
					} else if (firstPaymentMonth == 3) {
						var days = firstPaymentMonth * 30;
						for (var i = firstPaymentMonth - 1; i > 0; i--) {
							var epoEntry = {
									epoAfterPaymentNumber: i,
									epoCashPrice: days + " Days EPO"
							}
							dashboard.epoSchedule.unshift(epoEntry);
						}
					}*/
					//Commented as part of VAN-1415-Bob's - Change EPO array to 120 days
					//VAN-1415-Bob's - Change EPO array to 120 days - Starts
					if (firstPaymentMonth >= 3) {
						if(dashboard.storeInfo != undefined && dashboard.storeInfo.epoOptionDays != undefined ){
							if(dashboard.storeInfo.epoOptionDays === '120'){
								for (var i = firstPaymentMonth - 1; i > 0; i--) {
									var epoEntry = {
											epoAfterPaymentNumber: i,
											//VAN-1390 - EPO showing incorrectly in review estimate
											epoCashPrice: "120" + " Days EPO"
									}
									dashboard.epoSchedule.unshift(epoEntry);
								}
							}else if(dashboard.storeInfo.epoOptionDays === '90'){
								for (var i = firstPaymentMonth - 1; i > 0; i--) {
									var epoEntry = {
											epoAfterPaymentNumber: i,
											epoCashPrice: "90" + " Days EPO"
									}
									dashboard.epoSchedule.unshift(epoEntry);
								}	
							}
						}else{
							for (var i = firstPaymentMonth - 1; i > 0; i--) {
								var epoEntry = {
										epoAfterPaymentNumber: i,
										epoCashPrice: "90" + " Days EPO"
								}
								dashboard.epoSchedule.unshift(epoEntry);
							}
						}
					}
					//VAN-1415-Bob's - Change EPO array to 120 days - Ends
				}

				dashboard.selectionFlag = !dashboard.quickQuoteEstimate.feeArray[1].feeAcrossTerm && dashboard.quickQuoteEstimate.feeArray[1].feeAmount > 0;

				dashboard.noEsvNoDel = (dashboard.quickQuoteEstimate.feeArray[0].feeAmount <= 0 && dashboard.quickQuoteEstimate.feeArray[1].feeAmount <= 0);
				dashboard.onlyEsvNoDel = (dashboard.quickQuoteEstimate.feeArray[0].feeAmount > 0 && dashboard.quickQuoteEstimate.feeArray[1].feeAmount <= 0);
				dashboard.noESVonlyDel = (dashboard.quickQuoteEstimate.feeArray[0].feeAmount <= 0 && dashboard.quickQuoteEstimate.feeArray[1].feeAmount > 0);
				dashboard.withEsvDel = (dashboard.quickQuoteEstimate.feeArray[0].feeAmount > 0 && dashboard.quickQuoteEstimate.feeArray[1].feeAmount > 0);

			}, function(response){
				dashboard.letsGetStarted = true;
				console.log("Quick quote failed response from HT(Monthly)", response);
			});
		}
		/*VAN-934 - Quick Quote – Add biweekly and 4weeks quote, along with monthly, to both AWP and Kiosk - Ends*/
	}

		function quoteModalReset() {
			//console.log("Quick quote lets get started button state", dashboard.letsGetStarted);
			SharedDataSvc.quoteCalculator.selectedDate = null;
			dashboard.quoteInput.merchandiseTotal = undefined;
			dashboard.quoteInput.deliveryFee = undefined;
			dashboard.quoteInput.extendedServiceAmount = undefined;
			dashboard.quickQuoteEstimate = "";
			dashboard.initialRentalFee = undefined;
			dashboard.quickQuoteInitialInfo = "";
			//dashboard.quickQuoteDisclaimer = "";
			dashboard.letsGetStarted = true;
			dashboard.epoSchedule = "";
			/*VAN-975 - AWP : Quick Quote Screen - Payment Details were not getting cleared on relaunch*/
			dashboard.showPaymentSectionForBiweekly = false;
		}

		function feeCheck(value, name) {
			/*VAN-934 - Quick Quote – Add biweekly and 4weeks quote, along with monthly, to both AWP and Kiosk - Starts*/
			dashboard.epoSchedule = undefined;
			/*VAN-976 - AWP: Quick Quote Screen - On change of Delivery Charge/Extended Services system reloads incorrect payment details - Starts*/
			dashboard.quickQuoteBiweeklyEstimate = undefined;
			dashboard.quickQuoteFourWeekEstimate = undefined;
			/*VAN-976 - AWP: Quick Quote Screen - On change of Delivery Charge/Extended Services system reloads incorrect payment details - Ends*/
			dashboard.resetQQPaymentEstimate = true;
			dashboard.letsGetStarted = true;
			/*VAN-934 - Quick Quote – Add biweekly and 4weeks quote, along with monthly, to both AWP and Kiosk - Ends*/
			if (value && String(value).indexOf('.') > -1) {
				var values = String(value).split('.');
				if (values[1].length > 2) { 
					if(name == 'extendedServiceAmount') {
						dashboard.quoteInput.extendedServiceAmount = Number(value.toString().match(/^\d+(?:\.\d{0,2})?/));
					} else if (name == 'deliveryFee'){
						dashboard.quoteInput.deliveryFee = Number(value.toString().match(/^\d+(?:\.\d{0,2})?/));
					}			
				}
			}
		}
		
		/*VAN-934 - Quick Quote – Add biweekly and 4weeks quote, along with monthly, to both AWP and Kiosk - Starts*/
		function resetQQEPO(){
			/*SharedDataSvc.quoteCalculator.selectedDate = null;*/
			dashboard.epoSchedule = undefined;
			dashboard.quickQuoteEstimate = undefined;
			dashboard.quickQuoteBiweeklyEstimate = undefined;
			dashboard.quickQuoteFourWeekEstimate = undefined;
			dashboard.quoteInput.deliveryFee = undefined;
			dashboard.quoteInput.extendedServiceAmount = undefined;
			dashboard.resetQQPaymentEstimate = true;
			dashboard.letsGetStarted = true;
			dashboard.letsGetStarted = true;
		}
		
		function resetQQWhenCatChange(){
			SharedDataSvc.quoteCalculator.selectedDate = null;
			dashboard.epoSchedule = undefined;
			dashboard.quickQuoteBiweeklyEstimate = undefined;
			dashboard.quickQuoteFourWeekEstimate = undefined;
			dashboard.quoteInput.merchandiseTotal = undefined;
			dashboard.quoteInput.deliveryFee = undefined;
			dashboard.quoteInput.extendedServiceAmount = undefined;
			dashboard.resetQQPaymentEstimate = true;
		}
		/*VAN-934 - Quick Quote – Add biweekly and 4weeks quote, along with monthly, to both AWP and Kiosk - Ends*/

		function showVerifyInfoAlert() {
			var showVerifyInfoAlertModal = modalService.open({
              templateUrl: '/partials/alert_showVerifyInfoAlert.tpl.html',
              backdrop: 'static',
              show:true,
              scope: $scope
            });
		}

		function viewApplication(engagement) {
			console.log("CustomerId for VCC generation", engagement.customerId);
			resetManualOverrideData();
			dashboard.verifyInfoEngagement = engagement;
			showModalCreditCard($translate.instant("common_label_processing"), 'processing-modal');
			dashboardServices.viewApplication(engagement.customerId)
				.then(function(response) {
					console.log('View application call success', response);

					var pdfBlob = SharedDataSvc.createPdfBlob(response.leaseOrderPdf);
		          	if (navigator.msSaveOrOpenBlob) {
		            	navigator.msSaveOrOpenBlob(pdfBlob);
		            	dashboard.modal.dismiss();
		          	} else {
		            	var objectURL = URL.createObjectURL(pdfBlob);
		            	$rootScope.securePdf = $sce.trustAsResourceUrl(objectURL);
		          	}
		          	if($rootScope.securePdf) {
			            //vm.processingModal.dismiss();
			            var viewApplicationModal = modalService.open({
			              templateUrl: '/partials/modal_viewApplication.html',
			              backdrop: 'static',
			              show:true,
			              scope: $scope
			            });
			            viewApplicationModal.result.then(function(){
			              SharedDataSvc.clearPrintSection();
			            });
		          	}
				}, function(response) {
					SharedDataSvc.clearPrintSection();
				})
		}

		function verifyInfo(engagement) {
			SharedDataSvc.manualOverrideData = engagement;
			resetManualOverrideData();
			if(engagement.applicationStatus !== 'Declined') {
				showModal('conditionalApproval', 'associate-review-modal');
			}
		}
		
		//VAN-441 - VAN Docusign - Remote signing documents on personal device - Start
		function remoteSignFromDashboard(engagement){
			//VAN-587 - AWP/KIOSK: User able to sign the agreements using remote docusign for VOIDED/ChargedOFF agreements - Enabled the HT flag and changed request payload.
			modalService.processing('Processing');//VAN-3189-Enable remote signing option for AWP generated agreements- xteam changes- xteam changes
			customerDataFactory.findByEngagementId(engagement.engagementId, true).then(function(response) {
				modalService.dismiss();//VAN-3189-Enable remote signing option for AWP generated agreements- xteam changes- xteam changes
				$rootScope.engagementInfo = response;
				$scope.emailAddress = response.VanEngagement.customer.email;
				//VAN-587 - AWP/KIOSK: User able to sign the agreements using remote docusign for VOIDED/ChargedOFF agreements
				if(response.VanEngagement.agreementStatus == 'Pending'){
					//Hide language option while calling EML Service
					if(response.VanEngagement.envelopId !== null){
						$scope.hideLanguageOption = true;}
					var remoteSignModal = modalService.open({
						title: "Customer Email Verification for RemoteSign in Dashboard",
						templateUrl: 'partials/modal_remoteEmailConfirm.tpl.html',
						scope: $scope,
						backdrop: 'static',
						controller: 'emailAgreementRemoteCtrl'
					});
					remoteSignModal.result.then(function(email) {
						//$scope.email.language = $scope.remoteEmail.lanType;
						$scope.confirmedEmail = email;
						emailAgrToPersonalDevice();//function to call Create Envelope service or EML service
					}); 
				}
				//VAN-587 - AWP/KIOSK: User able to sign the agreements using remote docusign for VOIDED/ChargedOFF agreements - Starts
				else{
					$location.path('dashboard/'+response.VanEngagement.engagementId);
				}
				//VAN-587 - AWP/KIOSK: User able to sign the agreements using remote docusign for VOIDED/ChargedOFF agreements - Ends
			}, function(error){
				console.log('[emailAgreement] failed getting engagement data', error);
				return false;
			});
		}

		//function to call Create Envelope service or EML service
		function emailAgrToPersonalDevice(){
			var engagement = $rootScope.engagementInfo;
			//Call EML Service with email id
			//VAN-779 - HT/AWP - Customer is not receiving agreement email after selecting agreement link (did not complete docusign) and trying to send email through Remote Docusign
			if(engagement.VanEngagement.agreementHTId != null && engagement.VanEngagement.envelopId != null && engagement.VanEngagement.agreementStatus == itemStatus.agreement.notStarted && engagement.VanEngagement.isRemoteSigningOpted == "1"){
				console.log("EMl Service");
				dashboard.modalTitle = 'Processing';
				dashboard.processingModal = modalService.processing(dashboard.modalTitle);
				var storedSessionId = $cookies.getObject("okta_session_id");
				var payload = {
						"envelopeId" : engagement.VanEngagement.envelopId,     
						"agreementId" : engagement.VanEngagement.agreementHTId,  
						"customerFirstName" : engagement.VanEngagement.customer.fName,
						"customerLastName": engagement.VanEngagement.customer.lName,   
						"emailAddress" : $scope.confirmedEmail,
						"customerId" : engagement.VanEngagement.customer.customerId,
						"engagementId" : engagement.VanEngagement.engagementId,
						"isRemoteSigningOpted" : "1",
						"language":$scope.remoteEmail.lanType
				};
				console.log('DocuSign Data - EML Service', payload);

				paymentFactory.sendEmailEML(payload).then(function(response) {
					console.log('Success Response ', response);
					modalService.dismiss();
					$route.reload();
				}, function(error) {
					showModal('agreementError');
					modalService.dismiss();
					console.log(error);
				});
			}
			else{
				//call create envelope service with remoteconfirmation emailID
				console.log("Create Envelope Service");
				if(engagement.VanEngagement.agreementHTId != null){
					dashboard.modalTitle = 'Processing';
					dashboard.processingModal = modalService.processing(dashboard.modalTitle);
					var storedSessionId = $cookies.getObject("okta_session_id");
					//VAN-676 - PRB0042928 - Remote Signing throwing error for Charge off
					//VAN-3210-Initial Payment break down is not displaying in payment screen when we select beyond 15 days by using Xpartner Login - Starts
					if(engagement.VanEngagement.payToDate == null){
						engagement.VanEngagement.payToDate = engagement.VanEngagement.nextPaymentDate;
					}//VAN-3210-Initial Payment break down is not displaying in payment screen when we select beyond 15 days by using Xpartner Login - Ends
					if(engagement.VanEngagement.payToDate != null){
						//VAN-3189-Enable remote signing option for AWP generated agreements- xteam changes- xteam changes - Starts
						var isValid = moment(engagement.VanEngagement.payToDate, 'YYYYMMDD',true).isValid();
						if(isValid){
							var payDate = engagement.VanEngagement.payToDate;
						}else{
							var payDate = moment(engagement.VanEngagement.payToDate).format('YYYYMMDD');
						}//VAN-3189-Enable remote signing option for AWP generated agreements- xteam changes- xteam changes - Ends
					}
					var payload = {
							"customerId" : engagement.VanEngagement.customer.customerId,     
							"engagementId" : engagement.VanEngagement.engagementId,  
							"signersPresent" : "APPLICANT",  
							"clubSwitch" : engagement.VanEngagement.clubSelection,   
							"waiverSwitch" : engagement.VanEngagement.waiverSelection,
							//"paymentToDate" : engagement.payToDate,
							"paymentToDate" : payDate, //VAN-676 - PRB0042928 - Remote Signing throwing error for Charge off
							"coapplicantFirstName" : null,
							"coapplicantLastName" : null,
							"isRemoteSigningOpted": "1",
							"remoteEmailAddress" : $scope.confirmedEmail,
							"language":$scope.remoteEmail.lanType
					};
					var domain = $window.location;
					var destination = domain.hash.split('/')[1];
					payload.returnURL = domain.origin + '/#!/login' + 
											"?Session=" + storedSessionId + "&engagementId=" + engagement.VanEngagement.engagementId;
					console.log("returnURL", payload.returnURL);
					console.log('DocuSign Data - CreateEnvelope Service', payload);
					paymentFactory.docuSign(payload).then(function(response) {
						console.log('Success Response',response);
						modalService.dismiss();
						$route.reload();
					}, function(error) {
						showModal('agreementError');
						modalService.dismiss();
						console.log(error);
					});
				}
			}
		}
		//VAN-441 - VAN Docusign - Remote signing documents on personal device - Ends

		function navigateToAgreement(engagement) {
			//VAN-969-Unable to create new agreement-Agreement status changed from accepted to Pending - Starts
			if(engagement.applicationStatus === dashboard.itemStatus.application.accepted && engagement.orderStatus === dashboard.itemStatus.order.confirmed && engagement.agreementStatus === dashboard.itemStatus.agreement.accepted && engagement.paymentStatus === dashboard.itemStatus.payment.pendingConfirm){
				verifyActiveAgreement(engagement);
			}
			else if(dashboard.storeInfo.isDigitalSigningEnabled){
				dashboard.navigateTo('digitalAgreement', engagement);
			} else {
				dashboard.navigateTo('agreement', engagement);
			}
		}

		function verifyActiveAgreement(engagement){
			modalService.processing("Processing");
			var findData = { 
					'firstName' : engagement.customerFirstName,
					'lastName' : engagement.customerLastName,
					'phone' : engagement.customerPhoneNum,
					'engagementId' :engagement.engagementId
			};
			applicationService.getApplication(findData).then(function(successData){
				console.log("Got Success Response. ", successData);
				if(successData.agreement !== undefined && successData.agreement.agreementStatus === 'ACTIVE'){
					var engagementData = {
							'customerId' : successData.VANCustomerID,
							'engagementId' : successData.VANEngagementId,
							'paymentStatus' : dashboard.itemStatus.payment.complete,
							'vanStoreId' : successData.VANEngagementId,
							'customerHtID' : successData.VanEngagement.customerHTId
					};
					updateEngagementItemFactory.putData(engagementData)
					.then(function(response) {
						console.log('Successfully updated engagement with payment status to completed');
						modalService.dismiss();
						navigateTo('dashboard', engagement);
					}, function(response){
						console.log('Updating engagement with agreement status to pending failed');
						modalService.dismiss();
					});	
				}
				else if(dashboard.storeInfo.isDigitalSigningEnabled){
					modalService.dismiss();
					dashboard.navigateTo('digitalAgreement', engagement);
				} else {
					modalService.dismiss();
					dashboard.navigateTo('agreement', engagement);
				}
			}, function(failData){
				modalService.dismiss();
				console.log(failData);
			});
		}
		//VAN-969-Unable to create new agreement-Agreement status changed from accepted to Pending - Ends

		function resetDocuSign() {
			$scope.raUpdate.agreementNo = '';
			$scope.raUpdate.results = {};
			$scope.raUpdate.invalidAgreementNo = false;
		}

		// check for associate test for generating credit card 
		/*function generateCreditCardCheck(pass){
			if (pass) {
				dashboard.noAssociatePass = false;
				var auth = SharedDataSvc.base64.decode($cookies.getObject('store_nick_name'));
				if(auth === pass) {
					dashboard.employeeId = '';
					dashboard.invalidAssociatePass = false;
					dashboard.noAssociatePass = false;
					generateCreditCard(dashboard.engagementId);
				} else {
					console.log('invalid associate pin', dashboard.invalidAssociatePass);
					dashboard.invalidAssociatePass = true;
				}
			}else{
				console.log('no associate pin', dashboard.noAssociatePass);
				dashboard.noAssociatePass = true;
			}
		}*/

		// formatting credit card no received from services
		/*function formatCreditCardNo(num){
			var num1 = String(num).substring(0, 4),
				num2 = String(num).substring(4, 8),
				num3 = String(num).substring(8, 12),
				num4 = String(num).substring(12, 16),
				formattedNo = num1 + '-' + num2 + '-' + num3 + '-' + num4;

			return(formattedNo);
		}*/

		// formatting date received from services
		/*function formatDate(date) {
			var year = String(date).substring(2, 4),
				month = String(date).substring(4, 6),
				day = String(date).substring(6, 8),
				formattedDate = month + '/' + year;

			return moment(formattedDate, 'MM-YY').format('MM/YY');
		}*/

		// generate credit card service call
		/*function generateCreditCard(engagementID){
			console.log('Associate engagementID',engagementID);
			showModalCreditCard($translate.instant("common_label_processing"), 'processing-modal');
			$rootScope.servicePhoneNumber = SharedDataSvc.globalVars.storeSetup.vipPhoneNumber; //AC-28614 Changes 
			dashboardServices.getCreditCardDetails(engagementID)
				.then(function(response){
					console.log('generate credit card details',response);
					dashboard.creditCardDetail = response;
					$rootScope.creditCardNo = formatCreditCardNo(dashboard.creditCardDetail.creditCardNumber);
					$rootScope.creditCardExp = formatDate(dashboard.creditCardDetail.expiryDate);
					$rootScope.creditCardCVV = dashboard.creditCardDetail.cvv;
					showModalCreditCard('creditCardDetails');
				},function(response){
					console.log('generate credit card details failure',response);
					showModalCreditCard('creditCardDetailsFailure');
				});
		}*/

	}; // end of DASHBOARD controller
})();
