(function() {
  'use strict';

  angular
    .module('myApp')
    .factory('stateRestrictFactory', stateRestrictions);

  function stateRestrictions($http, $log, $q, API_DOMAIN, PROXY_ACTION_URL, ServiceAgent, authentication, SharedDataSvc) {
    var stateRestrictions,
        restrictions = {
          skipLdw: null,
          skipBp: null,
          bpNotContingentOnLdw: null,
          skipEpo: null,
          hideDeliveryFee: null,
          hideExtendedService: null,
          dontSignDeclineBp: null,
          isUnsupportedState: null, 
          state: null
        }

    return {
      stateRestrictions: stateRestrictions,
      storeRestrictions: restrictions,
      getStateRestrictions: getStateRestrictions,
      getAllStoreRestrictions: getAllStoreRestrictions
    };

    function skipLdw(state) {
      if(stateRestrictions && state) {
        var skipLdw = stateRestrictions.skipLDW.split(",") || '';
        return _.indexOf(skipLdw, state) > -1 ? true : false;
      }
    }

    function skipBp(state) {
      if(stateRestrictions && state) {
        var skipBp = stateRestrictions.skipBP.split(",") || '';
        return _.indexOf(skipBp, state) > -1 ? true : false;
      }
    }

    function bpNotContingentOnLdw(state) {
      if(stateRestrictions && state) {
        var bp = stateRestrictions.bpNotContingentOnLDW.split(",") || '';
        return _.indexOf(bp, state) > -1 ? true : false;
      }
    }

    function skipEpo(state) {
      if(stateRestrictions && state) {
        var skipEpo = stateRestrictions.skipEPO.split(",") || '';
        return _.indexOf(skipEpo, state) > -1 ? true : false;
      }
    }

    function hideDeliveryFee(state) {
      if(stateRestrictions && state) {
        var hdf = stateRestrictions.hideDeliveryFee.split(",") || '';
        return _.indexOf(hdf, state) > -1 ? true : false;
      }
    }

    function hideExtendedService(state) {
      if(stateRestrictions && state) {
        var hes = stateRestrictions.hideExtendedService.split(",") || '';
        return _.indexOf(hes, state) > -1 ? true : false;
      }
    }

    function dontSignDeclineBp(state) {
      if(stateRestrictions && state) {
        var dsbp = stateRestrictions.dontSignDeclineBenefitsPlus.split(",") || '';
        return _.indexOf(dsbp, state) > -1 ? true : false;
      }
    }

    function isUnsupportedState(state) {
      if(stateRestrictions && state) {
        var us = stateRestrictions.unsupportedStates.split(",") || '';
        return _.indexOf(us, state) > -1 ? true : false;
      }
    }

    function setAllRestrictions(state) {
      restrictions.skipLdw = skipLdw(state);
      restrictions.skipBp = skipBp(state);
      restrictions.bpNotContingentOnLdw = bpNotContingentOnLdw(state);
      restrictions.skipEpo = skipEpo(state);
      restrictions.hideDeliveryFee = hideDeliveryFee(state);
      restrictions.hideExtendedService = hideExtendedService(state);
      restrictions.dontSignDeclineBp = dontSignDeclineBp(state);
      restrictions.isUnsupportedState = isUnsupportedState(state);
      restrictions.state = state;
    }

    function getAllStoreRestrictions(state) {
      var deferred = $q.defer();

      if(!stateRestrictions) {
        getStateRestrictions()
          .then(function(response) {
            stateRestrictions = response.stateRestrictions[0];
            console.log('STATE RESTRICTIONS', stateRestrictions);
            setAllRestrictions(state);
            deferred.resolve(restrictions);
          });
      } else {
        setAllRestrictions(state);
        deferred.resolve(restrictions);
      }

      return deferred.promise;
    }

    function getStateRestrictions() {
      console.log("[stateRestrictFactory.getStateRestrictions]");
      var deferred = $q.defer();
      var headers = {
        'endTarget': 'site/json/stateRestrictions.json',
        'sessionToken' : authentication.getCurrentSessionId(),
        'Public-Key': SharedDataSvc.globalVars.storeSetup.serverPublicRSAKey
      };
      ServiceAgent.httpCall(
        PROXY_ACTION_URL,
        'POST',
        headers,
        null,
        false
        ).then(function (response) {
          console.log("[stateRestrictFactory.getStateRestrictions] success response: ", response);
          deferred.resolve(response);
        },
        function (response) {
          console.log("[stateRestrictFactory.getStateRestrictions] fail ", response);
            deferred.reject(response);
        });

        return deferred.promise;
    }

  }

})();