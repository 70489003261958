myApp.factory('authentication', function ($rootScope, $http, $q, $window, $cookies, PROXY_SESSION_URL, SharedDataSvc, localStorageService) {

  /**
   * URL for authentication
   */
  var oktaAuthURL = 'https://rentacenter-admin.oktapreview.com';

  /**
   * API Key
   */
  var APIKey = '00j2sR-yEYrC7TWx-wib-uRDolF_69CNfd0tSpA-1d';

  /**
   * Current session ID
   */
  var currentSessionId = null;

  /**
   * Okta cookie store session id name
   */
  var OKTA_SESSION_ID = "okta_session_id";

  /**
   * Okta cookie store nick name. This is actually the associate password.
   */
  var STORE_NICK_NAME = "store_nick_name";
  var STORE_FIRST_NAME = "store_first_name";

  /**
   * Log in to get auth token. Format for POST url:
   * {{url}}/api/v1/sessions?additionalFields=cookieToken
   * @param user (String) Username string
   * @param pass (String) Password string
   * @return (Object) Successful return will resemble this:
   *  {
        "id": "s01hzzzwtfkRfus5junx0jKkg",
        "userId": "00u3o1grw6g1PGlrm0h7",
        "mfaActive": false,
        "cookieToken": "00-4MgvWg_h8lbcX3Ts8YHCe7Cf4DfWO-3dfVg5r8m"
      }
   */
  var createSessionWithCredentials = function (user) {
    var defer = $q.defer(),
        requestObj = {
          "url": PROXY_SESSION_URL,
          "method": "POST",
          "data": {
            "username" : user
          },
          "withCredentials": true,
          "headers": { 
            'Access-Control-Allow-Origin': 'endTarget,sessionToken,oktaUsername,RAC-CORRELATION-ID',//AC 27054 code Changes
        	/*'Access-Control-Allow-Origin': 'endTarget,sessionToken,oktaUsername,RAC-CORRELATION-ID,https://dev-awp.acceptancenow.com,*',*/
            'Content-Type': 'text/plain',
            'Accept': 'application/json',
            "oktaUsername" : user
          }
        };

    $http(requestObj).
      then(function (response) {
        var data = response.data;
        var status = response.status;
        var headers = response.headers;
        var config = response.config;
        console.log("[authentication.createSessionWithCredentials] Login success. data ", data);
        authServiceObject.isAuthenticated = true;
        $cookies.putObject(OKTA_SESSION_ID, headers('sessionToken'));
        currentSessionId = headers('sessionToken');
        defer.resolve(data);
      }).
      catch(function (response) {
        var data = response.data;
        console.warn("[authentication.createSessionWithCredentials] Login has failed. ", data);
        defer.reject(data);
      });

      return defer.promise;
  };

  /**
   * Get user data for current user ['Steak', 'Rice', 'Beans', 'Cheese']
   * @return (Object) 
   */
  var getUserData = function (userId) {
    var defer = $q.defer(),
        requestObj = {
          "url": oktaAuthURL + '/api/v1/users/' + userId,
          "method": "GET",
          "headers": { 
            'Content-Type': 'application/json; charset=utf-8',
            'Authorization': 'SSWS ' + APIKey 
          }
        };

    $http(requestObj).
      then(function (response) {
        var data = response.data;
        console.warn("[authentication.getUserData] success. ", data);
        defer.resolve(data);
      }).
      catch(function (response) {
        var data = response.data;
        console.warn("[authentication.getUserData] fail. ", data);
        defer.reject(data);
      });

      return defer.promise;
  };

  /**
   * The object returned which represents this service
   */
  var authServiceObject = {

    /**
     * Boolean for auth status. Default is false.
     */
    isAuthenticated: false,

    /**
     * User data. It should resemble this:
     * {
          "id": "00u3o1grw6g1PGlrm0h7",
          "status": "ACTIVE",
          "created": "2015-03-31T02:18:40.000Z",
          "activated": "2015-03-31T02:18:41.000Z",
          "statusChanged": "2015-03-31T19:56:31.000Z",
          "lastLogin": "2015-04-07T22:07:12.000Z",
          "lastUpdated": "2015-03-31T19:56:31.000Z",
          "passwordChanged": "2015-03-31T19:56:31.000Z",
          "profile": {
            "email": "christmoore@deloitte.com",
            "firstName": "Chris",
            "lastName": "Moore",
            "login": "christmoore@deloitte.com",
            "mobilePhone": null,
            "secondEmail": "",
            "nickName": "Chris"
          }
       }
     */
    user: null,

    /**
     * Store password. Used for log in to store kiosk. Comes from getUserData service
     */
    storePass: null,

    /**
     * Agreement Acknowledgement PIN
     */
    agreementPin: null,

    /**
     * Get the current session id, if it exists
     * @return (String) 
     */
    getCurrentSessionId : function () {
      return currentSessionId;
    },

    /**
     * Logs out a user.
     * DELETE {{url}}/api/v1/sessions/{{sessionId}}
     * @return (Object) 
     */
    logoutUser : function () {
      var defer = $q.defer();
        
      authServiceObject.isAuthenticated = false;
      currentSessionId = null;
      $cookies.putObject(OKTA_SESSION_ID, null);
      $cookies.putObject('PP', null);
      $cookies.putObject('isHybrid', null);
      $cookies.putObject('operationMode', null);
      $cookies.putObject('POS-TYPE', null);	  
      $cookies.putObject('isHybridMode', null);
      $cookies.putObject('isMannedMode', null);
      $cookies.putObject('helpdeskNo', null);
      $cookies.putObject('isSpanishStore', null);
      sessionStorage.removeItem('selectedLanguage');
      $rootScope.selectedLanguage = $rootScope.languageSettings[0].id;
      // hard refresh and clearing local storage when user logs out AC-10831
      $window.location.reload(true);
      localStorageService.clearAll();
      defer.resolve({});

      return defer.promise;
    },

    /**
     * Checks if a user is logged in already. Only looks for session cookie
     * @return (Object) A promise Object 
     */
    isLoggedIn : function () {
      var storedSessionId = $cookies.getObject(OKTA_SESSION_ID);
      var storedNickNamePass;
		
	  $rootScope.isHybridMode = $cookies.getObject('isHybridMode');
      $rootScope.isMannedMode = $cookies.getObject('isMannedMode');
	  $rootScope.helpdeskNo = $cookies.getObject('helpdeskNo');

      if(typeof $cookies.getObject(STORE_NICK_NAME) !== "undefined" && $cookies.getObject(STORE_NICK_NAME) !== '') {
        storedNickNamePass = SharedDataSvc.base64.decode($cookies.getObject(STORE_NICK_NAME));
      }

      if (storedSessionId && storedNickNamePass) {
        console.log("[authentication.checkLogin] success. ");
        authServiceObject.isAuthenticated = true;
        currentSessionId = storedSessionId;
        authServiceObject.storePass = storedNickNamePass;
        return true;
      } else {
        console.log("[authentication.checkLogin] fail. ");
        return false;
      }
    },

    /**
     * Log in to get auth token. Format for POST:
     * {{url}}/api/v1/sessions?additionalFields=cookieToken
     * @param user (String) Username string
     * @param pass (String) Password string
     * @return (Object) Successful return will resemble this:
     *  {
          "id": "s01hzzzwtfkRfus5junx0jKkg",
          "userId": "00u3o1grw6g1PGlrm0h7",
          "mfaActive": false,
          "cookieToken": "00-4MgvWg_h8lbcX3Ts8YHCe7Cf4DfWO-3dfVg5r8m"
        }
     */
    loginUser : function (user) {
      //clearing local storage when user first time login AC-10831
      localStorageService.clearAll();
      if (!user) {
        console.warn("[authentication.loginUser] Username and/or password are not defined! user: " + user + " pass: " + pass);
        return;
      }
      var defer = $q.defer();

      createSessionWithCredentials(user).then(function sessSuccess (sessData) {
        console.log("[authentication.loginUser] createSessionWithCredentials success ", sessData);
        var storeId, userProfile;
        if (sessData.UserInfo && sessData.UserInfo.profile) {
          userProfile = sessData.UserInfo.profile;
          storeId = userProfile.lastName;
          $cookies.putObject(STORE_NICK_NAME, SharedDataSvc.base64.encode(userProfile.nickName));
          $cookies.putObject(STORE_FIRST_NAME, SharedDataSvc.base64.encode(userProfile.firstName));
          $cookies.putObject('PP', SharedDataSvc.base64.encode(sessData.passPhrase));
          
          localStorageService.remove('trainingLibraryDocs');
          authServiceObject.storePass = userProfile.nickName;
          authServiceObject.agreementPin = userProfile.firstName;
        } else {
          console.warn("[authentication.loginUser] sessData.profile not available");
          var atIndex = user.indexOf('@');
          storeId = user.substring(1, atIndex);
          authServiceObject.storePass = 'test';
        }
        console.log("[authentication.loginUser] storeId: ", storeId);
        $cookies.putObject('storeId', storeId);
        SharedDataSvc.setStoreId(storeId);
        defer.resolve(sessData);
      }, function sessFail (data) {
        console.warn("[authentication.loginUser] Login failed: ", data);
        defer.reject(data);
      });

      return defer.promise;
    },
    
  	resetAll: function(){
  	  authServiceObject.isAuthenticated = false;
      currentSessionId = null;
      $cookies.putObject(OKTA_SESSION_ID, null);
      $cookies.putObject('PP', null);
      $cookies.putObject('isHybrid', null);
      $cookies.putObject('operationMode', null);
      $cookies.putObject('POS-TYPE', null);
      localStorageService.clearAll();
  	}
  };

  return authServiceObject;
});
