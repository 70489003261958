angular
    .module('myApp')
    .directive('calendar', calendar);

function calendar() {
    var directive = {
        link: link,
        template: '<div class="calendar" datepicker ng-model="calendar.dt" ng-change="calendar.calChange();" max-date="calendar.twoMonthsOut" min-date="calendar.today" show-weeks="dateOptions.showWeeks"></div>',
        restrict: 'EA',
        controller: CalendarController,
        controllerAs: 'calendar'
    };

    return directive;

    function link(scope, element, attrs) {

    }
}

CalendarController.$inject = ['$scope', 'SharedDataSvc'];

function CalendarController($scope, SharedDataSvc) {
    var vm = this;
    vm.dt;
    vm.today = moment().format('YYYY-MM-DDTHH:mm:ss');
    //vm.twoMonthsOut = moment(vm.today).add(1, 'M').endOf('month')._d;
    vm.twoMonthsOut = moment(vm.today).add(90, 'd');//VAN-3545-van delivery date-Updated to 90 days
    vm.twoMonthsOut = moment(vm.twoMonthsOut).format('YYYY-MM-DDTHH:mm:ss');

    vm.calChange = calChange;

    function calChange() {
        SharedDataSvc.invoiceDeliveryData.date = vm.dt
        console.log(vm.dt);
    }
}