'use strict';

myApp
.directive('editorderitem', ['$location', function($location) {
  return function ( scope, element, attrs ) {
    console.log('editorderitem DIRECTIVE has loaded');
    var thisIdx;
    jQuery('#addItem').on('show.bs.modal', function(){
        jQuery('#addItem').addClass('editmode_idx'+thisIdx);
    });
    attrs.$observe('editorderitem', function (id) {
      thisIdx = id;
      element.bind( 'click', function () {
          scope.$apply( function () {
            console.log('directive32 - you clicked!');
          });
      });
    });
  };
}])